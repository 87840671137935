<template>
  <V3MapFilter :active="value !== null" label="Operator" @click="toggle(value)">
    <V3Button :active="true === value" @click="toggle(true)"> Yes </V3Button>
    <V3Button :active="false === value" @click="toggle(false)"> No </V3Button>
  </V3MapFilter>
</template>

<script>
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'

import V3MapFilter from '../v3dafi/V3MapFilter.vue'

export default {
  name: 'OperatorFilter',
  components: {
    V3MapFilter,
    V3Button,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    data: Object,
    selected: Object,
    value: Boolean,
  },
  methods: {
    toggle(value) {
      if (this.value === value) {
        this.$emit('change', null)
      } else {
        this.$emit('change', value)
      }
    },
  },
}
</script>
