<template>
  <div class="p-3 video-player-container">
    <div v-if="vodStatus === 'init'" class="text-center">Loading</div>

    <div class="video-player-container" v-else>
      <div v-if="!roundReplayUrl" class="mt-2 mb-5">
        <p v-if="!vodStatus" class="text-center text-info">
          No video for this round. Please, use the form below to add one.
        </p>
        <p v-if="vodStatus === 'failed'" class="text-center text-info">
          Failed to upload the video. Please, try again.
        </p>
        <p v-if="vodStatus === 'provisioning'" class="text-center text-info">The video is being provisioned</p>
        <p v-if="vodStatus === 'executing'" class="text-center text-info">The video is being processed</p>
        <p v-if="vodStatus === 'partial'" class="text-center text-info">Match videos are partially available</p>

        <SubmitMatchVod v-if="!vodStatus || vodStatus === 'failed'" />
      </div>

      <VodReplay
        ref="vod"
        v-else
        v-on="listeners"
        :control-bar="controlBar"
        :current-time.sync="localCurrentTime"
        :playing="playing"
        :playback-rate="playbackRate"
        :url="roundReplayUrl"
      />
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import VodReplay from '../replay/VodReplay.vue'

import SubmitMatchVod from './SubmitMatchVod.vue'

export default {
  components: {
    VodReplay,
    SubmitMatchVod,
  },

  props: {
    controlBar: px.bool,
    currentTime: px.number.def(0),
    offsetTime: px.number.def(0),
    playing: px.bool.def(false),
    playbackRate: px.number.def(1.0),
    roundReplayUrl: px.string,
    startTime: px.number.def(0),
    endTime: px.number.def(Number.MAX_SAFE_INTEGER),
    vodStatus: px.string,
  },

  computed: {
    listeners() {
      const {
        // eslint-disable-next-line no-unused-vars
        ['update:currentTime']: _,
        ...listeners
      } = this.$listeners
      return listeners
    },
    localCurrentTime: {
      get() {
        return Math.max(0, this.startTime + this.currentTime + this.offsetTime)
      },
      set(value) {
        this.$emit('update:currentTime', value - this.offsetTime - this.startTime)
      },
    },
  },

  methods: {
    takeScreenshot() {
      return this.$refs.vod?.takeScreenshot()
    },
  },
}
</script>

<style lang="scss" scoped>
// fit the video player in vertically available space
.video-player-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
  max-height: 100%;
  position: relative;
}
</style>
