<template>
  <div class="mf-container">
    <V3Button
      class="mf-row"
      v-for="match in normalizedMatches"
      :key="match.id"
      @click="toggleMatch(match.id)"
      :class="{
        selected: state.filters.base.matches.includes(match.id),
      }"
    >
      <div class="header">
        <div v-if="match.event" class="mf-row-event">
          {{ match.event.name }}
        </div>
        <div v-else class="mf-row-date">
          <time :datetime="match.created | isoString">
            <span>{{ match.created | localDate }}</span>
            <span>{{ match.created | localTime }}</span>
          </time>
        </div>
        <V3Image v-if="match.winnerTeam.image" class="mf-row-team-logo" :src="match.winnerTeam.image" :outline="true" />
        <div v-else class="mf-row-team-name">
          {{ match.winnerTeam.abbr || match.winnerTeam.name }}
        </div>
        <div class="mf-row-score">
          <span class="mf-row-score-winner" :class="match.winnerTeam.grid.toLowerCase()">
            {{ match.winnerTeam ? match.winnerTeam.score : '-' }}
          </span>
          <span class="mf-row-score-sep">/</span>
          <span class="mf-row-score-loser" :class="match.loserTeam.grid.toLowerCase()">
            {{ match.loserTeam ? match.loserTeam.score : '-' }}
          </span>
        </div>
        <V3Image v-if="match.loserTeam.image" class="mf-row-team-logo" :src="match.loserTeam.image" :outline="true" />
        <div v-else class="mf-row-team-name">
          {{ match.loserTeam.abbr || match.loserTeam.name }}
        </div>
      </div>
      <div class="compositions">
        <div class="compositions-row">
          <V3Image
            v-if="match.winnerTeam.image"
            class="mf-row-team-logo compositions-row-team"
            :src="match.winnerTeam.image"
            :outline="true"
          />
          <div v-else class="mf-row-team-name compositions-row-team">
            {{ match.winnerTeam.abbr || match.winnerTeam.name }}
          </div>
          <template v-for="teamAgent in match.winnerTeam.composition">
            <div class="compositions-row-player" :key="teamAgent.player.id">
              <V3Agent :id="teamAgent.agent.id" class="compositions-row-player-agent" />
            </div>
          </template>
        </div>
        <div class="compositions-row">
          <V3Image
            v-if="match.loserTeam.image"
            class="mf-row-team-logo compositions-row-team"
            :src="match.loserTeam.image"
            :outline="true"
          />
          <div v-else class="mf-row-team-name compositions-row-team">
            {{ match.loserTeam.abbr || match.loserTeam.name }}
          </div>
          <template v-for="teamAgent in match.loserTeam.composition">
            <div class="compositions-row-player" :key="teamAgent.player.id">
              <V3Agent :id="teamAgent.agent.id" class="compositions-row-player-agent" />
            </div>
          </template>
        </div>
      </div>
    </V3Button>
  </div>
</template>

<script>
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import isoString from '@/filters/isoString'
import localDate from '@/filters/localDate'
import localTime from '@/filters/localTime'

import stringCompare from '../../../utils/stringCompare.js'

import V3Agent from './v3dafi/V3Agent.vue'
import V3Button from './v3dafi/V3Button.vue'
import V3Image from './v3dafi/V3Image.vue'

export default {
  name: 'MapMatchFilter',
  mixins: [exposedDataState],
  components: {
    V3Image,
    V3Button,
    V3Agent,
  },
  computed: {
    normalizedMatches() {
      return Object.values(this.data.matches).map(match => {
        const teams = match?.match_teams.map(matchTeamId => this.data.matchTeams?.[matchTeamId]).filter(Boolean) || []

        const winnerTeam = teams[0].score > teams[1].score ? teams[0] : teams[1]
        const loserTeam = teams[0].score > teams[1].score ? teams[1] : teams[0]
        const winnerTeamComposition = this.calcTeamAgents(winnerTeam)
        const loserTeamComposition = this.calcTeamAgents(loserTeam)
        const winnerTeamCompositionAgentIds = winnerTeamComposition.map(teamAgent => teamAgent.agent.id)
        const loserTeamCompositionAgentIds = loserTeamComposition.map(teamAgent => teamAgent.agent.id)

        return {
          ...match,
          winnerTeam: {
            ...this.data.teams[winnerTeam?.team_id],
            ...winnerTeam,
            composition: winnerTeamComposition.map(teamAgent => ({
              ...teamAgent,
              intersecting:
                loserTeamCompositionAgentIds.includes(teamAgent.agent.id) &&
                winnerTeamCompositionAgentIds.includes(teamAgent.agent.id),
            })),
          },
          loserTeam: {
            ...this.data.teams[loserTeam?.team_id],
            ...loserTeam,
            composition: loserTeamComposition
              .map(teamAgent => ({
                ...teamAgent,
                intersecting:
                  loserTeamCompositionAgentIds.includes(teamAgent.agent.id) &&
                  winnerTeamCompositionAgentIds.includes(teamAgent.agent.id),
              }))
              .sort((a, b) => {
                if (a.intersecting) {
                  if (b.intersecting) {
                    return a.agent.name < b.agent.name ? -1 : 1
                  }
                  return -1
                } else {
                  if (b.intersecting) {
                    return 1
                  }
                  return a.agent.name < b.agent.name ? -1 : 1
                }
              }),
          },
        }
      })
    },
  },
  filters: {
    localTime,
    localDate,
    isoString,
  },
  methods: {
    toggleMatch(matchId) {
      if (this.state.filters.base.matches.includes(matchId)) {
        const index = this.state.filters.base.matches.indexOf(matchId)
        this.state.filters.base.matches.splice(index, 1)
      } else {
        this.state.filters.base.matches.push(matchId)
      }
    },
    calcTeamAgents(team) {
      return Object.values(this.data.teamCompositions)
        ?.find(teamComposition => teamComposition.team_id === team.team_id)
        ?.composition?.map(composition => {
          if (!this.data.agents[composition.agent_id]) {
            console.log('Missing agent', composition, team)
          }
          return {
            agent: this.data.agents[composition.agent_id],
            player: this.data.players[this.data.teamPlayers[composition.team_player_id].player_id],
          }
        })
        .sort(stringCompare(({ agent }) => agent.name))
    },
  },
}
</script>

<style lang="scss" scoped>
.mf-container {
  .mf-row {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 100%;
    width: 100%;
    cursor: pointer;

    .header {
      display: flex;
      flex-flow: row nowrap;
      flex: 1 1 100%;
      overflow: hidden;
      align-items: center;
      font-size: 10px;
      color: #8e8b9a;
      transition: color 0.2s ease-in-out;
      gap: 0.25rem;
      padding: 0.75rem 0.375rem;
      width: 100%;
    }

    .compositions {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;

      &-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: start;
        margin-bottom: 0.5rem;

        &-team {
          margin-right: 1rem;
        }

        &-player {
          flex: 1 1 auto;

          &-agent {
            height: 1rem;
          }
        }
      }
    }

    &-date {
      flex: 1 1 100%;
      display: flex;

      time {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
    }

    &-event {
      flex: 1 1 100%;
      line-height: 1.25;
      max-height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-transform: uppercase;
      text-align: left;
      margin-right: 0.25rem;
    }

    &-team-logo {
      height: 1.5rem;
    }

    &-score {
      font-size: 12px;
      white-space: nowrap;

      &-winner,
      &-loser {
        color: #8e8b9a;
        transition: color 0.2s ease-in-out;
      }
    }

    &-score {
      &-winner,
      &-loser {
        &.red {
          color: #d73e4a;
        }

        &.sep {
          color: #9795a1;
        }

        &.blue {
          color: #099b83;
        }
      }
    }

    &.selected {
      background-color: #2f2c3b;

      .mf-row-event {
        color: #fff;
      }
    }
  }
}
</style>
