<template>
  <V3MapFilter :active="value != null" label="Half" @click="$emit('change', null)">
    <V3Button
      v-for="(option, key) of options"
      :key="key"
      :active="option.value === value"
      :disabled="option.disabled"
      @click="$emit('change', option.value === value ? null : option.value)"
    >
      {{ option.text }}
    </V3Button>
  </V3MapFilter>
</template>

<script>
import { pxHalf, pxToolData } from '../../types.js'
import V3Button from '../v3dafi/V3Button.vue'
import V3MapFilter from '../v3dafi/V3MapFilter.vue'

export default {
  name: 'HalvesFilter',
  components: {
    V3Button,
    V3MapFilter,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: pxHalf(),
    data: pxToolData(),
  },
  computed: {
    hasOvertime() {
      return Object.entries(this.data.rounds).some(([, round]) => round.round_num > 23)
    },
    options() {
      return [
        { text: 'First', value: 'first' },
        { text: 'Second', value: 'second' },
        { text: 'Overtime', value: 'overtime', disabled: !this.hasOvertime },
      ]
    },
  },
}
</script>
