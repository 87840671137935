<template>
  <g :opacity="hidden ? 0 : faded ? $options.meta.fadeOpacity : 1">
    <component
      :is="$options.components.MapPoi"
      :color="data.roundTeams[data.roundPlayers[event.round_player_id].round_team_id].role | roleColor"
      :direction="event.location.view_radians"
      :rotate="data.map.rotate_deg"
      :x="event.location.x"
      :y="event.location.y"
    />
    <component
      :is="$options.components.MapImageEntry"
      :image-url="data.agents[data.matchPlayers[event.match_player_id].agent_id].display_icon_small_url"
      :rotate="data.map.rotate_deg"
      :x="event.location.x"
      :y="event.location.y"
    />
  </g>
</template>

<script>
import { MAP_FADED_EVENTS_OPACITY } from '@/constants.js'
import roleColor from '@/filters/roleColor.js'

import MapImageEntry from './MapImageEntry.vue'
import MapPoi from './MapPoi.vue'

export default {
  name: 'MapEventEntry',
  components: { MapPoi, MapImageEntry },
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
    faded: { type: Boolean, default: false },
    hidden: { type: Boolean, default: false },
  },
  filters: {
    roleColor,
  },
  meta: {
    fadeOpacity: MAP_FADED_EVENTS_OPACITY,
  },
}
</script>
