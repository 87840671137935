<template>
  <V3ScrollableContainer class="lsb-container" vertical>
    <V3Panel
      :expandable="vodPlayer !== 'vod' || mapMode !== 'replay2d'"
      :expanded.sync="viewsExpanded"
      :icon="$options.components.IconCalendarView"
      title="views"
    >
      <MapViewOptions
        :expanded="viewsExpanded"
        :vod-platform="vodPlatform"
        :has-playback-positions="hasPlaybackPositions"
        :has-minimap-vods="hasMinimapVods"
        :has-broadcast-vod="hasBroadcastVod"
      />
    </V3Panel>
    <V3Panel
      v-if="drawingConfig && drawings && mapSettings.drawingEnabled"
      class="lsb-drawing"
      expandable
      :expanded.sync="state.drawingEnabled"
      :icon="$options.components.IconPaintBrushAlt"
      title="Draw"
    >
      <V3Container :visible="state.drawingEnabled">
        <DrawingConfig @clear="clearDrawings" @undo="undoLastDrawing" />
      </V3Container>
    </V3Panel>
    <V3Panel
      v-if="multimatch"
      class="lsb-matches"
      expandable
      :expanded.sync="matchesExpanded"
      :icon="$options.components.IconSelect"
      title="Matches"
    >
      <V3Container :visible="matchesExpanded">
        <MapMatchFilter />
      </V3Container>
    </V3Panel>
    <V3Panel
      v-if="!multimatch && mapSettings.bookmarksEnabled"
      class="lsb-bookmarks"
      expandable
      :expanded.sync="bookmarksExpanded"
      :icon="$options.components.IconBookmark"
      title="Bookmarks"
    >
      <V3Container :visible="bookmarksExpanded">
        <template v-for="match in data.matches">
          <MapBookmarks
            :key="match.id"
            :bookmarks="bookmarks"
            :match="match"
            @select="$emit('selectBookmark', $event)"
          />
        </template>
      </V3Container>

      <template v-if="singleMatch">
        <V3Container :visible="!creatingBookmark">
          <V3Button class="lsb-add-bookmark" :disabled="savingBookmark" @click="onAddBookmarkClick">
            <template v-if="savingBookmark">
              <LoadingCell />
            </template>
            <template v-else>
              <BIconJournalPlus />
              <div>Add bookmark</div>
            </template>
          </V3Button>
        </V3Container>

        <V3Container :visible="creatingBookmark">
          <CreateBookmarkForm
            ref="createBookmarkForm"
            :note-tags="noteTags"
            @submit="createBookmark"
            @cancel="creatingBookmark = false"
          />
        </V3Container>
      </template>
    </V3Panel>
    <slot />
  </V3ScrollableContainer>
</template>

<script>
import { BIconJournalPlus } from 'bootstrap-vue'

import CreateBookmarkForm from '@/components/Map/components/CreateBookmarkForm.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import V3Container from '@/components/Map/components/v3dafi/V3Container.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import IconSelect from '@/components/UI/IconSelect.vue'
import mixpanel from '@/mixpanel'

import LoadingCell from '../../../Table/cells/LoadingCell.vue'
import IconBookmark from '../../../UI/IconBookmark.vue'
import IconCalendarView from '../../../UI/IconCalendarView.vue'
import IconPaintBrushAlt from '../../../UI/IconPaintBrushAlt.vue'
import DrawingConfig from '../DrawingConfig.vue'
import MapBookmarks from '../MapBookmarks.vue'
import MapMatchFilter from '../MapMatchFilter.vue'
import MapViewOptions from '../MapViewOptions.vue'

import V3Panel from './V3Panel.vue'
import V3ScrollableContainer from './V3ScrollableContainer.vue'

export default {
  name: 'LeftSideBar',
  mixins: [exposedDataState],
  components: {
    LoadingCell,
    V3Container,
    V3ScrollableContainer,
    BIconJournalPlus,
    CreateBookmarkForm,
    V3Button,
    MapViewOptions,
    // eslint-disable-next-line vue/no-unused-components
    IconBookmark,
    // eslint-disable-next-line vue/no-unused-components
    IconCalendarView,
    // eslint-disable-next-line vue/no-unused-components
    IconPaintBrushAlt,
    DrawingConfig,
    MapBookmarks,
    V3Panel,
    // eslint-disable-next-line vue/no-unused-components
    IconSelect,
    MapMatchFilter,
  },
  props: {
    bookmarks: Array,
    noteTags: Array,
    replayTrack: String,
    savingBookmark: Boolean,
    vodPlatform: String,
    hasPlaybackPositions: Boolean,
    hasMinimapVods: Boolean,
    hasBroadcastVod: Boolean,
  },
  data() {
    return {
      bookmarksExpanded: true,
      creatingBookmark: false,
      viewsExpanded: true,
      matchesExpanded: true,
    }
  },
  computed: {
    drawingConfig: {
      get() {
        return this.state.drawingConfig
      },
      set(value) {
        this.state.drawingConfig = value
      },
    },
    drawings: {
      get() {
        return this.exposed.drawings
      },
      set(value) {
        this.exposed.drawings = value
      },
    },
    singleMatch() {
      return Object.keys(this.data.matches).length === 1
    },
    vodPlayer: {
      get() {
        return this.state.vodPlayer
      },
      set(value) {
        this.state.vodPlayer = value
      },
    },
    multimatch() {
      return Object.keys(this.data.matches).length > 1
    },
  },
  watch: {
    creatingBookmark(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.createBookmarkForm.init()
        })
      }
    },
  },
  methods: {
    clearDrawings() {
      this.drawings = []
      mixpanel.track_2d_map_drawing_clear({ mapMode: this.mapMode })
    },
    undoLastDrawing() {
      if (this.drawings.length === 0) return
      this.drawings.pop()
      mixpanel.track_2d_map_drawing_undo({ mapMode: this.mapMode })
    },
    createBookmark(bookmark) {
      this.creatingBookmark = false
      this.$emit('saveNote', bookmark)
    },
    onAddBookmarkClick() {
      this.creatingBookmark = true
      this.state.playing = false
    },
  },
}
</script>

<style scoped lang="scss">
.lsb {
  &-container {
    flex: 1 1 100%;
    display: flex;
    flex-flow: column-reverse nowrap;
    gap: 0.5rem;
    padding: 0.25rem;
    background-color: #211f2b;
  }

  &-bookmarks {
    margin-bottom: auto;
  }

  &-matches {
    margin-bottom: auto;
  }

  &-drawing {
  }

  &-add-bookmark {
    width: 100%;
    color: #a4a2ad;
    font-size: 10px;
    gap: 0.25rem;

    &:hover {
      color: #fff;
    }
  }
}
</style>
