import * as Sentry from '@sentry/vue'

const TWITCH_PLAYER_SRC = 'https://player.twitch.tv/js/embed/v1.js'

let scriptTag

let cache

export default async function ensureTwitch() {
  if (cache != null) return cache
  if (global.Twitch?.Player) {
    cache = global.Twitch.Player
    return cache
  }
  if (!scriptTag) {
    scriptTag = [...document.querySelectorAll('script')].find(script => script.src === TWITCH_PLAYER_SRC)
  }
  if (!scriptTag) {
    scriptTag = document.createElement('script')
    scriptTag.src = TWITCH_PLAYER_SRC
    scriptTag.async = true
  }
  cache = new Promise((resolve, reject) => {
    scriptTag.addEventListener('load', () => {
      if (global.Twitch?.Player) {
        cache = global.Twitch.Player
        resolve(cache)
      } else {
        cache = Promise.reject(new Error('twitch loaded, but api is missing'))
        reject(cache)
      }
    })
    scriptTag.addEventListener('error', e => {
      console.error('Failure loading twitch player', e)
      Sentry.captureException(e)
      cache = Promise.reject(e)
      reject(cache)
    })
  })
  if (!scriptTag.parentNode) {
    document.head.appendChild(scriptTag)
  }
  return cache
}
