<template>
  <!-- role and aria-checked are for accessibility and tests -->
  <div
    :is="tag"
    v-bind="$attrs"
    class="round"
    :class="[winner.role, winner.grid, { disabled, error, highlighted, selected }]"
    role="checkbox"
    :title="errorMessage"
    v-b-tooltip.hover.noninteractive
    :aria-checked="selected ? 'true' : 'false'"
  >
    <div class="number">
      {{ number }}
      <span class="note" v-if="hasNote">
        <sup><b-icon-card-text /></sup>
      </span>
    </div>
    <div class="winner" :class="[winner.role, winner.grid]">
      <component :is="icon" />
    </div>
    <div class="loser" :class="[loser.role, loser.grid]" />
  </div>
</template>

<script>
import { BIconCardText } from 'bootstrap-vue'
import px from 'vue-types'

import { pxGrid, pxNullable, pxRole, pxRoundOutcome } from '@/components/Map/types'
import { OUTCOME } from '@/components/maptool2/consts'

export default {
  name: 'MatchRoundSelectorItem',
  components: {
    BIconCardText,
  },
  props: {
    disabled: Boolean,
    loser: px.shape({
      grid: pxGrid().isRequired,
      role: pxRole().isRequired,
    }).loose.isRequired,
    error: px.oneOf([px.bool, px.string]).def(false),
    highlighted: px.bool.def(false),
    outcome: pxNullable(pxRoundOutcome()),
    number: px.number.isRequired,
    selected: px.bool.def(false),
    tag: px.oneOfType([px.string, px.object]).def('div'),
    winner: px.shape({
      grid: pxGrid().isRequired,
      role: pxRole().isRequired,
    }).loose.isRequired,
    hasNote: px.bool.def(false),
  },
  computed: {
    errorMessage() {
      if (typeof this.error === 'string') {
        return this.error
      }
      return this.error ? 'Missing data' : ''
    },
    icon() {
      return OUTCOME[this.outcome]?.icon || 'span'
    },
  },
}
</script>

<style lang="scss" scoped>
.round {
  display: flex;
  flex-flow: column nowrap;
  color: $link-color;
  flex: 0 0 1.5rem;

  // v3 design
  position: relative;
  width: 1.5rem;
  height: 5rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
  }

  &::after {
    --color: #898795;

    top: 1.5625rem;
    height: 3px;
    background: var(--color);
  }

  &.Red::before {
    --bg-direction: 0deg;

    top: calc(0.25rem - 3px);
    height: 1.5rem;
  }

  &.Blue::before {
    --bg-direction: 180deg;

    top: calc(1.5625rem + 3px);
    height: 1.5rem;
  }

  &:not(.highlighted):not(.selected) {
    opacity: (1/3);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.highlighted {
    &.atk {
      &::before {
        --bg-color: 172 73 58;
      }

      &::after {
        --color: #ac493a;
      }
    }

    &.def {
      &::before {
        --bg-color: 9 155 131;
      }

      &::after {
        --color: #099b83;
      }
    }

    &::before {
      background-image: linear-gradient(
        var(--bg-direction),
        rgb(var(--bg-color) / 50%) 0%,
        rgb(var(--bg-color) / 46.5%) 19%,
        rgb(0 0 0 / 10%) 100%
      );
    }
  }

  &.selected {
    &::before {
      top: 0;
      height: auto;
      bottom: 0;
      background-image: linear-gradient(
        180deg,
        rgb(116 112 137 / 50%) 0%,
        rgb(116 112 137 / 50%) 18%,
        rgb(39 37 52 / 50%) 100%
      );
    }

    &::after {
      --color: #fff !important;
    }
  }

  &.highlighted {
    color: color-yiq(transparentize($primary, 0.7));
  }

  &.error {
    background: transparentize($danger, 0.7);
    color: color-yiq(transparentize($danger, 0.7));

    .number {
      color: $danger;

      &::after {
        vertical-align: super;
      }
    }
  }

  &.selected,
  &:hover {
    color: color-yiq($primary);
  }

  &.selected {
    color: $primary;
  }

  .number {
    position: absolute;
    bottom: 0.25rem;
    width: 1.5rem;
    height: auto;
    font-size: 14px;
    line-height: 1;
    font-weight: 800;
    color: #fff;
    text-align: center;
  }

  .winner,
  .loser {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.Red {
      order: -1;
      top: 5px;
    }

    &.Blue {
      top: 35px;
      order: 1;
    }

    &.atk {
      color: #ac493a;
    }

    &.def {
      color: #099b83;
    }

    svg {
      fill: currentcolor;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .note {
    font-size: 0.75rem;
    font-weight: bold;
    margin-left: 0.25rem;
  }
}
</style>
