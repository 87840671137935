<template>
  <V3MapFilter :active="value !== null" label="Outcome" @click="update(null)">
    <V3Button
      v-for="(option, key) in options"
      :key="key"
      :active="value === option.value"
      @click="toggle(option.value)"
    >
      <component v-if="option.icon" class="rof-icon" :is="option.icon" />
    </V3Button>
  </V3MapFilter>
</template>

<script>
import { OUTCOME } from '../../../maptool2/consts.js'
import { pxRoundOutcome } from '../../types.js'
import V3Button from '../v3dafi/V3Button.vue'
import V3MapFilter from '../v3dafi/V3MapFilter.vue'

export default {
  name: 'RoundOutcomeFilter',
  components: {
    V3Button,
    V3MapFilter,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: pxRoundOutcome(),
  },
  computed: {
    options() {
      return Object.entries(OUTCOME).map(([value, op]) => ({
        value,
        text: op.label,
        icon: op.icon,
      }))
    },
  },
  methods: {
    toggle(val) {
      this.update(this.value === val ? null : val)
    },
    update(val) {
      this.$emit('change', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.rof-icon {
  font-size: 1rem;
}
</style>
