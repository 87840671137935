<template>
  <div class="tl-container" :class="[dir, size]">
    <V3Image class="tl-logo" :gray="gray" :grayscale="grayscale" :outline="!gray && !grayscale" :src="src" />
  </div>
</template>

<script>
import V3Image from './V3Image.vue'
export default {
  name: 'V3TeamLogo',
  components: { V3Image },
  props: {
    dir: { default: 'right', type: String },
    gray: Boolean,
    grayscale: Boolean,
    size: { default: 'md', type: String },
    src: String,
  },
}
</script>

<style scoped lang="scss">
.tl {
  &-container {
    --padding: calc((var(--logo-height) - var(--img-height)) / 2);

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.right {
      padding: var(--padding) var(--arrow-width) var(--padding) max(calc(var(--padding) / 2), 0.25rem);
      clip-path: polygon(
        calc(100% - var(--arrow-width)) 0%,
        100% 50%,
        calc(100% - var(--arrow-width)) 100%,
        0% 100%,
        0 0
      );
    }

    &.left {
      padding: var(--padding) max(calc(var(--padding) / 2), 0.25rem) var(--padding) var(--arrow-width);
      clip-path: polygon(calc(var(--arrow-width)) 0, 0 50%, calc(var(--arrow-width)) 100%, 100% 100%, 100% 0);
    }

    &.md {
      --img-height: 1.25rem;
      --logo-height: 2rem;
      --arrow-width: 1rem;
    }

    &.mdp {
      --img-height: 1.25rem;
      --logo-height: 45px;
      --arrow-width: 12px;
    }

    &.lg {
      --img-height: 2rem;
      --logo-height: 4rem;
      --arrow-width: 1.5rem;
    }

    &.md.right {
      background-image: linear-gradient(90deg, #484959 0%, #484959 8%, #1f1d29 100%);
    }

    &.mdp.right {
      background-image: linear-gradient(90deg, #484959 0%, #484959 8%, #2f2b3b 100%);
    }

    &.lg.right {
      background-image: linear-gradient(90deg, #1f1d29 0%, #393945 92%, #393945 100%);
    }

    &.lg.left {
      background-image: linear-gradient(90deg, #393945 0%, #393945 8%, #1f1d29 100%);
    }
  }

  &-logo {
    object-fit: contain;
    height: var(--img-height);
  }
}
</style>
