<template>
  <div class="map-controller">
    <template v-if="loading">
      <MapPortal to="header-center" />
      <MapPortal to="header-right" />
      <MapPortal to="sidebar-left" />
      <MapPortal to="sidebar-right" />

      <V3MatchHeader loading />
    </template>
    <template v-else>
      <MapPortal class="w-100" to="header-center">
        <V3MatchHeader />
      </MapPortal>
      <Portal to="menu">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ active: exposed.presenterMode }"
            event=""
            :to="{ query: { ...$route.query, presenter: !exposed.presenterMode } }"
            @click.native.prevent="togglePresenterMode"
          >
            <template v-if="exposed.presenterMode">☑</template>
            <template v-else>☐</template>
            Presenter mode
          </router-link>
        </li>
        <li class="nav-item pointer">
          <span class="nav-link" :class="{ active: exposed.presenterMode }" @click="toggleFullscreenMode">
            <template v-if="exposed.fullscreenMode">☑</template>
            <template v-else>☐</template>
            Fullscreen mode
          </span>
        </li>
        <li v-if="gridLive" class="nav-divider"><hr /></li>
        <li v-if="gridLive" class="nav-item">
          <router-link
            class="nav-link"
            :class="{ active: exposed.gridPositions }"
            event=""
            :to="{ query: { ...$route.query, pos: exposed.gridPositions ? 'grid' : undefined } }"
            @click.native.prevent="toggleGridPositions"
          >
            <template v-if="exposed.gridPositions">☑</template>
            <template v-else>☐</template>
            GRID positions
          </router-link>
        </li>
        <li class="nav-divider"><hr /></li>
      </Portal>
      <MapTool2d
        class="map-container"
        v-if="mapToolData && mapToolEvents"
        :report="report"
        :data="mapToolData"
        :disable-replay="multiMatch"
        :events="filteredEvents"
        :notes="cleanedNotes"
        :filters="mapToolFilters"
        :has-advanced="hasAdvanced"
        :has-economy="hasEconomy"
        :has-orbs="hasOrbs"
        :has-outcome="hasOutcome"
        :has-plants="hasPlants"
        :has-wins="hasWins"
        :has-vod="hasVod"
        :has-playback-positions="hasPlaybackPositions"
        :has-minimap-vods="hasMinimapVods"
        :has-abilities-usage="hasAbilitiesUsage"
        :is-scrim="isScrim"
        :playback-rate.sync="playbackRate"
        :playing.sync="playing"
        :round-duration="roundDuration"
        :saving-bookmark="savingBookmark"
        :selected-round-info="selectedRoundStats"
        :supported-playback-rates="supportedPlaybackRates"
        :take-screenshot="takeScreenshot"
        :vod-platform="singleMatch && singleMatch.vod_platform"
        :note-tags="noteTags"
        :ads-enabled="adsEnabled"
        @loadBookmark="loadBookmark"
        @saveNote="saveNote"
      >
        <template #replay2d v-if="multiMatch || !selectedRound || hasVod">
          <div v-if="singleMatch" style="height: 100%">
            <TwitchReplay
              v-if="
                singleRound && singleMatch.vod_platform === 'twitch' && singleMatch.vod_id && vodPlayer === 'twitch'
              "
              :current-time.sync="currentTime"
              :id="singleMatch.vod_id"
              :offset-time="roundOffsetTime"
              :playing.sync="playing"
              :playback-rate.sync="playbackRate"
              :start-time="selectedRoundVodStartMillis"
              :end-time="selectedRoundVodStartMillis + singleRound.vod_duration_millis"
              :supported-playback-rates.sync="supportedPlaybackRates"
            />

            <YoutubeReplay
              v-else-if="
                singleRound && singleMatch.vod_platform === 'youtube' && singleMatch.vod_id && vodPlayer === 'youtube'
              "
              :current-time.sync="currentTime"
              :id="singleMatch.vod_id"
              :offset-time="roundOffsetTime"
              :playing.sync="playing"
              :playback-rate.sync="playbackRate"
              :start-time="selectedRoundVodStartMillis"
              :end-time="selectedRoundVodStartMillis + singleRound.vod_duration_millis"
              :supported-playback-rates.sync="supportedPlaybackRates"
            />

            <Replay2D
              v-else-if="
                singleRound &&
                singleMatch.vod_platform === 'augment' &&
                vodPlayer === 'augment' &&
                singleRound.vod_broadcast_url
              "
              :control-bar="false"
              :current-time.sync="currentTime"
              :round-replay-url="singleRound.vod_broadcast_url"
              :offset-time="roundOffsetTime"
              :playing.sync="playing"
              :playback-rate.sync="playbackRate"
              :supported-playback-rates.sync="supportedPlaybackRates"
              :vod-status="vodStatus"
            />

            <div
              style="height: 100%"
              v-else-if="
                singleRound && singleMatch.vod_platform === 'augment' && vodPlayer === 'augment' && singleMatch.vod_id
              "
            >
              <OverwolfVideoAd v-if="false" @close="closeVideoAd" @complete="resetAdCounters" />

              <Replay2D
                v-else
                :control-bar="false"
                :current-time.sync="currentTime"
                :round-replay-url="singleMatch.vod_id"
                :offset-time="roundOffsetTime"
                :playing.sync="playing"
                :playback-rate.sync="playbackRate"
                :start-time="selectedRoundVodStartMillis"
                :end-time="selectedRoundVodStartMillis + singleRound.vod_duration_millis"
                :supported-playback-rates.sync="supportedPlaybackRates"
                :vod-status="vodStatus"
              />
            </div>

            <Replay2D
              v-else-if="vodPlayer === 'vod' && selectedRound"
              ref="minimap"
              :control-bar="false"
              :current-time.sync="currentTime"
              :round-replay-url="selectedRound.vod_replay_url"
              :offset-time="roundOffsetTime"
              :playing.sync="playing"
              :playback-rate.sync="playbackRate"
              :supported-playback-rates.sync="supportedPlaybackRates"
              :vod-status="vodStatus"
            />

            <div v-else-if="!selectedRound">No round is selected</div>
          </div>
        </template>
        <template #rounds>
          <MapToolRoundSelector
            v-if="!multiMatch && mapToolData && mapToolFilters"
            :data="mapToolData"
            :filters="mapToolFilters"
            :selected.sync="selected.rounds"
            :multiple="mapMode !== 'replay2d' || vodPlayer === 'playback'"
            @switch-role="switchRole"
          />
          <!-- need to clear the default content of the slot -->
          <div v-else />
        </template>
      </MapTool2d>
    </template>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { polygonContains } from 'd3-polygon'
import { Portal } from 'portal-vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { submitPersistentData } from '@/api/persistentData.js'
import { createShortcut, getShortcut } from '@/api/shortcuts'
import axios from '@/axios.js'
import MapPortal from '@/components/Map/components/MapPortal.vue'
import MapToolRoundSelector from '@/components/Map/components/MapToolRoundSelector.vue'
import V3MatchHeader from '@/components/Map/components/v3dafi/V3MatchHeader.vue'
import OverwolfVideoAd from '@/components/overwolf/OverwolfVideoAd.vue'
import { BOOKMARK_VERSION, VIDEO_AD_ROUNDS_COUNT } from '@/constants'
import mixpanel from '@/mixpanel.js'
import byKey from '@/utils/byKey.js'
import deepClone from '@/utils/deepClone'
import groupBy from '@/utils/groupBy.js'

import binarySearch from '../../utils/binarySearch.js'
import Replay2D from '../Match.old/Replay2D.vue'
import TwitchReplay from '../replay/TwitchReplay.vue'
import YoutubeReplay from '../replay/YoutubeReplay.vue'

import MapTool2d from './MainComponent.vue'
import genAbilityHashId from './utils/genAbilityHashId.js'
import genDamageId from './utils/genDamageId.js'
import genRoundId from './utils/genRoundId.js'
import genRoundTeamId from './utils/genRoundTeamId.js'

const DEFAULT_MAP_STATE = {
  drawingConfig: {
    color: 1,
    mode: 'alt',
    opacity: 2,
    width: 1,
    tool: 'pencil',
  },
  drawingEnabled: true,
  filters: {
    round: {
      teamRole: null,
      half: null,
      site: null,
      outcome: null,
      win: null,
      eco: [],
      ecoOpponents: [],
      operator: null,
      trades: null,
      orbs: {},
      abilities: null,
      scenario: {
        snapshots: [],
        rounds: [],
      },
      fkd: null,
    },
    timeline: {
      killWeapons: null,
      abilitiesUsage: null,
    },
    display: {
      gids: null,
      players: null,
    },
    base: {
      matches: [],
    },
  },
  iconHide: {
    unselected: false,
  },
  internalCurrentTime: 0,
  internalTimelineStart: 0,
  internalTimelineEnd: 45000,
  mapOptions: ['smokes', 'walls', 'deaths'],
  mapSubMode: 'agents',
  kdZoneMode: {
    view: 'all',
    opps: true,
    first: false,
    legend: false,
    selected: '',
    zone: false,
    plantTime: 'all',
  },
  minimapSelector: false,
  marks: [],
  selected: {
    agents: null,
    alignRounds: 'start',
    damage: null,
    kills: { kills: true },
    players: null,
    positions: null,
    rounds: null,
    team: null,
    teamCompositions: null,
    time: null,
    utilities: null,
    zones: null,
    abilitiesUsage: null,
    gids: null,
  },
  spikeMode: 'icons',
  timeline: {
    scale: 1,
    selectable: false,
  },
}

const DEFAULT_MAP_SETTINGS = {
  startingVodPlayer: 'playback',
  reportsEnabled: true,
  drawingEnabled: true,
  bookmarksEnabled: true,
  analyticsEnabled: true,
}

const compareTimespans = (a, b) => a.start_time_millis - b.start_time_millis

const intersectTimespans = (a, b) => {
  const start_time_millis = Math.max(a.start_time_millis, b.start_time_millis)
  const end_time_millis = Math.min(a.end_time_millis, b.end_time_millis)
  return start_time_millis <= end_time_millis ? { start_time_millis, end_time_millis } : null
}

const matchesTimespans = (timespans, event) => {
  if (!timespans || !timespans.length) return false
  const time = event.round_time_millis
  const idx = binarySearch(timespans, timespan => timespan.start_time_millis > time) - 1
  if (idx === -1) return false
  return timespans[idx].start_time_millis <= time && time <= timespans[idx].end_time_millis
}

export default {
  name: 'MapController',

  components: {
    V3MatchHeader,
    MapPortal,
    MapToolRoundSelector,
    YoutubeReplay,
    TwitchReplay,
    Replay2D,
    MapTool2d,
    Portal,
    OverwolfVideoAd,
  },

  inject: ['layout'],

  props: {
    createBookmarkApi: Function,
    data: Object,
    isCollegiate: Boolean,
    isScrim: Boolean,
    isLocalScrim: {
      type: Boolean,
      default: false,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
    map: String,
    notes: Array,
    noteTags: Array,
    team: String,
    initialState: String,
    gridPositions: Boolean,
    gridLive: {
      type: Boolean,
      default: false,
    },
    serializeState: {
      type: Function,
      default: null,
    },
    deserializeState: {
      type: Function,
      default: null,
    },
    mapSettings: {
      type: Object,
      default: () => ({}),
    },
    adsEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    error: null,
    fetchDataError: null,
    playbackRate: 1.0,
    exposed: new (class {
      data = null
      clearRoundFilters() {
        this.currentState.filters.round = {
          ...deepClone(DEFAULT_MAP_STATE.filters.round),
          teamRole: this.currentState.filters.round.teamRole,
        }
      }
      clearTimelineFilters() {
        this.currentState.filters.timeline = deepClone(DEFAULT_MAP_STATE.filters.timeline)
      }
      get currentState() {
        return this.state[this.state.mapMode]
      }
      get currentTime() {
        return this.currentState.internalCurrentTime
      }
      set currentTime(newTime) {
        if (this.currentState.selected.time) {
          if (newTime < this.currentState.selected.time.start_time_millis) {
            newTime = this.currentState.selected.time.start_time_millis
          } else if (newTime >= this.currentState.selected.time.end_time_millis) {
            newTime = this.currentState.selected.time.start_time_millis
          }
        }
        if (newTime < this.timelineStart || newTime > this.timelineEnd) {
          newTime = this.timelineStart
        }
        this.currentState.internalCurrentTime = newTime
      }
      get drawings() {
        if (this.state.mapMode === 'replay2d') {
          return this.currentState.drawings?.[this.currentState.vodPlayer]
        } else {
          return this.currentState.drawings
        }
      }
      set drawings(value) {
        if (this.state.mapMode === 'replay2d') {
          Vue.set(this.currentState.drawings, this.currentState.vodPlayer, value)
        } else {
          this.currentState.drawings = value
        }
      }
      get isRoundFilterActive() {
        return (
          Object.entries(this.currentState.filters.round).filter(([k, v]) => {
            if (k === 'teamRole') return false
            if (v == null) return false
            if (Array.isArray(v)) return v.length > 0
            if (typeof v === 'object')
              return (
                Object.values(v).filter(vv => {
                  if (vv == null) return false
                  if (Array.isArray(vv)) return vv.length > 0
                  return true
                }).length > 0
              )
            return true
          }).length > 0
        )
      }
      get isTimelineFilterActive() {
        return (
          Object.values(this.currentState.filters.timeline).filter(v => {
            if (v == null) return false
            if (Array.isArray(v)) return v.length > 0
            if (typeof v === 'object') return Object.keys(v).length > 0
            return true
          }).length > 0
        )
      }

      presenterMode = false
      fullscreenMode = false
      gridPositions = false

      // must be pure json struct or any functionality will be lost when loading bookmark
      state = {
        mapMode: 'replay2d',
        replay2d: {
          ...deepClone(DEFAULT_MAP_STATE),
          drawings: {
            augment: [],
            playback: [],
            vod: [],
          },
          mapSubMode: 'agents',
          vodPlayer: 'vod',
          playing: false,
        },
        analytics: {
          ...deepClone(DEFAULT_MAP_STATE),
          drawings: [],
          mapSubMode: 'traces',
        },
      }

      settings = {
        ...DEFAULT_MAP_SETTINGS,
      }
      get timelineEnd() {
        return this.currentState.internalTimelineEnd
      }
      set timelineEnd(val) {
        this.currentState.internalTimelineEnd = val
        // trigger recheck
        // eslint-disable-next-line no-self-assign
        this.currentTime = this.currentTime
      }
      get timelineStart() {
        return this.currentState.internalTimelineStart
      }
      set timelineStart(val) {
        this.currentState.internalTimelineStart = val
        // trigger recheck
        // eslint-disable-next-line no-self-assign
        this.currentTime = this.currentTime
      }
    })(),
    matches: null,
    report: null,
    savingBookmark: false,
    supportedPlaybackRates: [1, 2, 3, 4],
    permissionError: null,
    internalLoading: false,
    showVideoAd: false,
    adCounters: {
      roundsPlayed: 0,
    },
  }),
  provide() {
    return {
      exposed: this.exposed,
      mapController: this,
    }
  },
  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
      getGearById: 'static/getGearById',
      getMapById: 'static/getMapById',
      getWeaponById: 'static/getWeaponById',
      has_assets: 'static/has_assets',
      maps: 'static/maps',
      weapons: 'static/weapons',
    }),
    // filtering section - start
    // filters are ordered by their priority
    // use this.data to access unfiltered data
    // use the result of the filter for filtered data
    _00_data_matches() {
      return this.data?.matches || {}
    },
    _00_data_rounds() {
      return this.data?.rounds || {}
    },
    _00_events_ability_uses() {
      return Object.freeze(
        (this.data?.utilitiesUsage || []).map(abilityUse =>
          Object.freeze({
            ...abilityUse,
            ability_slot: abilityUse.utility,
            ability_id: genAbilityHashId(this.agentsById[abilityUse.agent_id], abilityUse.utility),
          })
        )
      )
    },
    _00_events_kills() {
      return Object.freeze(
        (this.data?.kills || []).map(kill =>
          Object.freeze({
            ...kill,
            damage_id: genDamageId(
              kill.finishing_damage,
              this.data.agents[this.data.matchPlayers[kill.killer.match_player_id].agent_id]
            ),
          })
        )
      )
    },
    _00_events_positions() {
      return Object.freeze([
        ...(this.data?.positions || []),
        ...(this.data?.advancedPositions || []),
        ...(this.data?.utilities || []),
      ])
    },
    _01_activated_filter_selected_team() {
      return this.exposed.currentState.selected.team != null
    },
    _01_filtered_matches_by_filters() {
      if (!this._01_activated_filter_selected_team) return this._00_data_matches
      const selectedTeamId = this.exposed.currentState.selected.team
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this._00_data_matches)
            .filter(([matchId]) => {
              if (this.exposed.currentState.filters.base.matches.length > 0) {
                return this.exposed.currentState.filters.base.matches.includes(matchId)
              }
              return true
            })
            .filter(([, match]) =>
              match.match_teams.some(matchTeamId => this.data.matchTeams[matchTeamId].team_id === selectedTeamId)
            )
        )
      )
    },
    _02_activated_filter_selected_team() {
      return (
        this._01_activated_filter_selected_team &&
        Object.keys(this._01_filtered_matches_by_filters).length < Object.keys(this._00_data_matches).length
      )
    },
    _02_data_matches() {
      return this._01_filtered_matches_by_filters
    },
    _02_data_rounds() {
      if (!this._02_activated_filter_selected_team) return this._00_data_rounds
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this._00_data_rounds).filter(
            ([, round]) => this._01_filtered_matches_by_filters[round.match_id]
          )
        )
      )
    },
    _02_events_ability_uses() {
      if (!this._02_activated_filter_selected_team) return this._00_events_ability_uses
      return Object.freeze(
        this._00_events_ability_uses.filter(event => this._01_filtered_matches_by_filters[event.match_id])
      )
    },
    _02_events_kills() {
      if (!this._02_activated_filter_selected_team) return this._00_events_kills
      return Object.freeze(this._00_events_kills.filter(event => this._01_filtered_matches_by_filters[event.match_id]))
    },
    _02_events_positions() {
      if (!this._02_activated_filter_selected_team) return this._00_events_positions
      return Object.freeze(
        this._00_events_positions.filter(event => this._01_filtered_matches_by_filters[event.match_id])
      )
    },
    _03_activated_user_selected_matches() {
      return this.exposed.currentState.filters.base.matches.length > 0
    },
    _03_filtered_matches_by_user() {
      if (!this._03_activated_user_selected_matches) return this._01_filtered_matches_by_filters
      return this._01_filtered_matches_by_filters
    },
    _04_activated_user_selected_matches() {
      return (
        this._03_activated_user_selected_matches &&
        Object.keys(this._03_filtered_matches_by_user).length < Object.keys(this._02_data_matches).length
      )
    },
    _04_data_matches() {
      return this._03_filtered_matches_by_user
    },
    _04_data_rounds() {
      if (!this._04_activated_user_selected_matches) return this._02_data_rounds
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this._02_data_rounds).filter(([, round]) => this._03_filtered_matches_by_user[round.match_id])
        )
      )
    },
    _04_events_ability_uses() {
      if (!this._04_activated_user_selected_matches) return this._02_events_ability_uses
      return Object.freeze(
        this._02_events_ability_uses.filter(event => this._03_filtered_matches_by_user[event.match_id])
      )
    },
    _04_events_kills() {
      if (!this._04_activated_user_selected_matches) return this._02_events_kills
      return Object.freeze(this._02_events_kills.filter(event => this._03_filtered_matches_by_user[event.match_id]))
    },
    _04_events_positions() {
      if (!this._04_activated_user_selected_matches) return this._02_events_positions
      return Object.freeze(this._02_events_positions.filter(event => this._03_filtered_matches_by_user[event.match_id]))
    },
    _05_activated_filter_by_rounds() {
      return true
    },
    _05_filtered_rounds_by_filters() {
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this.data?.rounds || {}).filter(([roundId, round]) => {
            // check match is in filtered matches
            if (!(round.match_id in this._03_filtered_matches_by_user)) {
              return false
            }

            // check for various round filters
            // TODO: migrate here
            return this.filterRound(roundId, { includeSelectedRounds: false })
          })
        )
      )
    },
    _06_activated_filter_by_rounds() {
      return (
        this._05_activated_filter_by_rounds &&
        Object.keys(this._05_filtered_rounds_by_filters).length < Object.keys(this._04_data_rounds).length
      )
    },
    _06_data_rounds() {
      return this._05_filtered_rounds_by_filters
    },
    _06_events_ability_uses() {
      if (!this._06_activated_filter_by_rounds) return this._04_events_ability_uses
      return Object.freeze(
        this._04_events_ability_uses.filter(event => this._05_filtered_rounds_by_filters[event.round_id])
      )
    },
    _06_events_kills() {
      if (!this._06_activated_filter_by_rounds) return this._04_events_kills
      return Object.freeze(this._04_events_kills.filter(event => this._05_filtered_rounds_by_filters[event.round_id]))
    },
    _06_events_positions() {
      if (!this._06_activated_filter_by_rounds) return this._04_events_positions
      return Object.freeze(
        this._04_events_positions.filter(event => this._05_filtered_rounds_by_filters[event.round_id])
      )
    },
    _07_activated_user_selected_rounds() {
      return Object.entries(this.exposed.currentState.selected.rounds || {}).filter(([, v]) => v).length > 0
    },
    _07_filtered_rounds_by_user() {
      if (!this._07_activated_user_selected_rounds) {
        return this._05_filtered_rounds_by_filters
      }
      // check for selected round
      const selectedRounds = this.exposed.currentState.selected.rounds
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this._05_filtered_rounds_by_filters).filter(([roundId]) => selectedRounds[roundId])
        )
      )
    },
    _08_activated_user_selected_rounds() {
      return (
        this._07_activated_user_selected_rounds &&
        Object.keys(this._07_filtered_rounds_by_user).length < Object.keys(this._06_data_rounds).length
      )
    },
    _08_events_ability_uses() {
      if (!this._08_activated_user_selected_rounds) {
        return this._06_events_ability_uses
      }
      return Object.freeze(
        this._06_events_ability_uses.filter(event => this._07_filtered_rounds_by_user[event.round_id])
      )
    },
    _08_events_kills() {
      if (!this._08_activated_user_selected_rounds) {
        return this._06_events_kills
      }
      return Object.freeze(this._06_events_kills.filter(event => this._07_filtered_rounds_by_user[event.round_id]))
    },
    _08_events_positions() {
      if (!this._08_activated_user_selected_rounds) {
        return this._06_events_positions
      }
      return Object.freeze(this._06_events_positions.filter(event => this._07_filtered_rounds_by_user[event.round_id]))
    },
    _09_activated_filter_ability_uses() {
      return (
        Object.entries(this.exposed.currentState.filters.timeline.abilitiesUsage || {}).filter(
          ([, v]) => v && Object.values(v).filter(Boolean).length
        ).length > 0
      )
    },
    _09_activated_filter_kills() {
      return (
        Object.entries(this.exposed.currentState.filters.timeline.killWeapons || {}).filter(([, v]) => v).length > 0
      )
    },
    _09_filtered_ability_uses_by_ability_filter() {
      if (!this._09_activated_filter_ability_uses) {
        return this._08_events_ability_uses
      }
      const filter = this.exposed.currentState.filters.timeline.abilitiesUsage
      return Object.freeze(
        this._08_events_ability_uses.filter(event => filter[event.match_player_id]?.[event.ability_slot])
      )
    },
    _09_filtered_kills_by_kill_filter() {
      if (!this._09_activated_filter_kills) {
        return this._08_events_kills
      }
      const filter = this.exposed.currentState.filters.timeline.killWeapons
      return Object.freeze(this._08_events_kills.filter(kill => filter[kill.damage_id]))
    },
    _10_activated_filter_by_timeline() {
      return this._09_activated_filter_ability_uses || this._09_activated_filter_kills
    },
    _10_filtered_timespans_by_filters() {
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this._07_filtered_rounds_by_user).map(([roundId, round]) => {
            if (!this._10_activated_filter_by_timeline) {
              return [
                roundId,
                [
                  {
                    round_id: roundId,
                    start_time_millis: 0,
                    end_time_millis: round.round_duration_millis,
                  },
                ],
              ]
            }

            const filterTimespans = [
              ...(this._09_activated_filter_ability_uses
                ? this._09_filtered_ability_uses_by_ability_filter
                    .filter(event => event.round_id === roundId)
                    .map(event => ({
                      round_id: event.round_id,
                      start_time_millis: event.round_time_millis,
                      end_time_millis: event.round_time_millis,
                    }))
                : []),
              ...(this._09_activated_filter_kills
                ? this._09_filtered_kills_by_kill_filter
                    .filter(event => event.round_id === roundId)
                    .map(event => ({
                      round_id: event.round_id,
                      start_time_millis: event.round_time_millis,
                      end_time_millis: event.round_time_millis,
                    }))
                : []),
            ]

            return [roundId, filterTimespans.sort(compareTimespans)]
          })
        )
      )
    },
    _11_activated_filter_by_timeline() {
      return this._10_activated_filter_by_timeline
    },
    _11_events_ability_uses() {
      return this._09_filtered_ability_uses_by_ability_filter
    },
    _11_events_kills() {
      return this._09_filtered_kills_by_kill_filter
    },
    _11_events_positions() {
      if (!this._11_activated_filter_by_timeline) {
        return this._08_events_positions
      }
      return Object.freeze(
        this._08_events_positions.filter(event =>
          matchesTimespans(this._10_filtered_timespans_by_filters[event.round_id], event)
        )
      )
    },
    _12_activated_user_selected_timespan() {
      return this.exposed.currentState.selected.time != null
    },
    _12_filtered_timespans_by_user() {
      if (!this._12_activated_user_selected_timespan) {
        return this._10_filtered_timespans_by_filters
      }
      const selectedTime = this.exposed.currentState.selected.time
      return Object.freeze(
        Object.fromEntries(
          Object.entries(this._10_filtered_timespans_by_filters)
            .map(([roundId, timespans]) => {
              const filteredTimespans = timespans
                .map(timespan => {
                  const intersection = intersectTimespans(timespan, selectedTime)
                  if (!intersection) return null
                  return {
                    ...timespan,
                    ...intersection,
                  }
                })
                .filter(Boolean)
              if (filteredTimespans.length > 0) {
                return [roundId, filteredTimespans.sort(compareTimespans)]
              }
            })
            .filter(Boolean)
        )
      )
    },
    _13_activated_user_selected_timespan() {
      return this._12_activated_user_selected_timespan
    },
    _13_events_ability_uses() {
      if (!this._13_activated_user_selected_timespan) {
        return this._11_events_ability_uses
      }
      return Object.freeze(
        this._11_events_ability_uses.filter(event =>
          matchesTimespans(this._12_filtered_timespans_by_user[event.round_id], event)
        )
      )
    },
    _13_events_kills() {
      if (!this._13_activated_user_selected_timespan) {
        return this._11_events_kills
      }
      return Object.freeze(
        this._11_events_kills.filter(event =>
          matchesTimespans(this._12_filtered_timespans_by_user[event.round_id], event)
        )
      )
    },
    _13_events_positions() {
      if (!this._13_activated_user_selected_timespan) {
        return this._11_events_positions
      }
      return Object.freeze(
        this._11_events_positions.filter(event =>
          matchesTimespans(this._12_filtered_timespans_by_user[event.round_id], event)
        )
      )
    },
    // filtering section - end
    // used to trigger a watcher
    abilitesUsageFilter() {
      return this.exposed.currentState.filters.timeline.abilitiesUsage
    },
    advancedPositions() {
      return this.getSelectedAdvancesPositions()
    },
    cleanedNotes() {
      return Object.freeze(this.notes?.filter(n => n?.version === BOOKMARK_VERSION).map(n => Object.freeze(n)))
    },
    currentTime: {
      get: function () {
        return this.exposed.currentTime
      },
      set: function (newTime) {
        this.exposed.currentTime = newTime
      },
    },
    damagesPerRound() {
      const damages = {}
      for (const kill of this.mapToolEvents.kills) {
        damages[kill.round_id] = damages[kill.round_id] ?? {}

        const agent = this.mapToolData.agents[this.mapToolData.matchPlayers[kill.killer.match_player_id].agent_id]
        const damageId = genDamageId(kill.finishing_damage, agent)

        damages[kill.round_id][damageId] = true
      }
      return Object.freeze(damages)
    },
    fetchError() {
      return this.server_error || this.fetchDataError
    },
    filteredDeaths() {
      return Object.freeze(this.mapToolEvents?.deaths?.filter(e => this.filterEvent(e)) || [])
    },
    filteredEvents() {
      if (!this.mapToolEvents) return null

      return Object.freeze({
        deaths: this.filteredDeaths,
        defuses: Object.freeze(this.mapToolEvents.defuses.filter(e => this.filterEvent(e))),
        kills: this.selected.kills.kills ? this.filteredKills : this.filteredDeaths,
        feed: this.filteredKills,
        plants: Object.freeze(this.mapToolEvents.plants.filter(e => this.filterEvent(e))),
        positions: Object.freeze(this.selectedPositions.filter(e => this.filterEvent(e))),
        advancedPositions: Object.freeze(this.advancedPositions.filter(e => this.filterEvent(e))),
        smokes: Object.freeze(this.mapToolEvents.smokes.filter(e => this.filterEvent(e))),
        utilities: Object.freeze(this.mapToolEvents.utilities.filter(e => this.filterEvent(e))),
        walls: Object.freeze(this.mapToolEvents.walls.filter(e => this.filterEvent(e))),
        utilitiesUsage: Object.freeze(this.mapToolEvents.utilitiesUsage?.filter(e => this.filterEvent(e))),
        getSelectedPositions: this.mapToolEvents.getSelectedPositions,
      })
    },
    filteredKills() {
      return Object.freeze(this.mapToolEvents?.kills?.filter(e => this.filterEvent(e)) || [])
    },
    hasAdvanced() {
      return Boolean(
        this.mapToolEvents.advancedPositions.length ||
          this.mapToolEvents.smokes.length ||
          this.mapToolEvents.utilities.length ||
          this.mapToolEvents.walls.length
      )
    },
    hasOrbs() {
      return Boolean(
        Object.values(this.mapToolData.roundPlayers).some(roundPlayer => roundPlayer.ultimate_orbs != null)
      )
    },
    hasVod() {
      if (!this.mapToolData) {
        return false
      }
      if (!this.selectedRound) {
        return false
      }

      return (
        !!this.mapToolData.matches[this.mapToolData.rounds[this.selectedRound.id].match_id].vod_id &&
        ['succeeded', 'processed', 'partial'].includes(this.vodStatus)
      )
    },
    indexedRoundPlayers() {
      if (!this.mapToolData?.roundPlayers) return null
      const groupedByRound = groupBy(Object.values(this.mapToolData.roundPlayers), rp => rp.round_id)
      const mappedByPlayerId = Object.fromEntries(
        Object.entries(groupedByRound).map(([key, players]) => [
          key,
          Object.freeze(byKey(players, player => player.match_player_id)),
        ])
      )
      return Object.freeze(mappedByPlayerId)
    },
    mapData() {
      return this.getMapById(this.map)
    },
    mapFilters() {
      return {
        ...this.exposed.currentState.selected,
        ...this.exposed.currentState.filters,
      }
    },
    mapMode: {
      get() {
        return this.exposed.state.mapMode
      },
      set(value) {
        if (this.exposed.state[value]) {
          this.exposed.state.mapMode = value
        } else {
          throw new Error(`Invalid map mode ${value}`)
        }
      },
    },
    // used to trigger a watcher
    mapRoundFilters() {
      return this.exposed.currentState.filters.round
    },
    //used to trigger a watcher
    mapSnapshotFilters() {
      return this.exposed.currentState.filters.round.scenario.snapshots
    },
    mapState: {
      get() {
        return this.exposed.state
      },
      set(value) {
        this.exposed.state = value
      },
    },
    mapToolData() {
      if (!this.data) return null
      return Object.freeze({
        agents: this.data.agents,
        firstRoundKills: this.data.firstRoundKills,
        gids: this.data.gids,
        incompleteMlData: this.data.incompleteMlData,
        map: this.mapData,
        matches: this.data.matches,
        matchPlayers: this.data.matchPlayers,
        matchTeams: this.data.matchTeams,
        orbsPlayers: this.data.orbsPlayers,
        players: this.data.players,
        replay: this.data.replay,
        rounds: this.data.rounds,
        roundPlayers: this.roundPlayers,
        roundTeams: this.data.roundTeams,
        roundTeamStats: this.data.roundTeamStats,
        teamCompositions: this.data.teamCompositions,
        teamPlayers: this.data.teamPlayers,
        teams: this.data.teams,
        usedAbilities: this.data.usedAbilities,
        usedDamages: this.data.usedDamages,
        weapons: Object.freeze(Object.fromEntries(Object.values(this.weapons).map(weapon => [weapon.id, weapon]))),
        zones: this.data.zones,
      })
    },
    mapToolEvents() {
      return Object.freeze({
        advancedPositions: this.data?.advancedPositions || [],
        advancedRoundPositions: (this.data?.advancedPositions || []).reduce((acc, pos) => {
          acc[pos.round_id] = acc[pos.round_id] || []
          acc[pos.round_id].push(pos)
          return acc
        }, {}),
        deaths: this.data?.deaths || [],
        defuses: this.data?.defuses || [],
        getSelectedPositions: this.getSelectedPositions,
        kills: this.data?.kills || [],
        plants: this.data?.plants || [],
        positions: this.data?.positions || [],
        roundPlayerPositions: this.data?.roundPlayerPositions || {},
        roundPlayerUtilities: this.data?.roundPlayerUtilities || {},
        smokes: this.data?.smokes || [],
        utilities: this.data?.utilities || [],
        utilitiesUsage: this.data?.utilitiesUsage?.map(usage => ({
          ...usage,
          ability_slot: usage.utility,
          type: 'utility',
          sidebar: true,
          abilityHashId: genAbilityHashId(this.agentsById[usage.agent_id], usage.utility),
        })),
        walls: this.data?.walls || [],
      })
    },
    mapToolFilters() {
      return Object.freeze({
        event: this.filterEvent,
        round: this.filterRound,
        matchPlayer: this.filterMatchPlayer,
      })
    },
    matchesData() {
      return this.data?.matchesData
    },
    multiMatch() {
      return this.mapToolData ? Object.keys(this.mapToolData.matches).length !== 1 : null
    },
    playing: {
      get() {
        return this.exposed.currentState.playing
      },
      set(value) {
        this.exposed.currentState.playing = value
      },
    },
    roundOffsetTime() {
      return this.singleRound?.fixes?.best || 0
    },
    roundDuration() {
      return Math.ceil(
        Math.max(
          45000,
          Math.max.apply(
            Math,
            Object.values(this.mapToolData?.rounds ?? {})
              .filter(({ id }) => (this.mapFilters.rounds ? this.mapFilters.rounds[id] : this.mapToolFilters.round(id)))
              .map(({ round_duration_millis }) => round_duration_millis)
          )
        )
      )
    },
    roundPlayers() {
      return Object.freeze(
        Object.entries(this.data?.roundPlayers || {})
          ?.map(([, roundPlayer]) => {
            const player = this.roundsInfo[roundPlayer.round_id]?.player_stats?.find(
              player => player.puuid === roundPlayer.puuid
            )

            return {
              ...roundPlayer,
              armor: player?.armor_id,
              armor_name: this.getGearById(player?.armor_id)?.name,
              weapon: player?.weapon_id,
              weapon_name: this.getWeaponById(player?.weapon_id)?.name,
            }
          })
          ?.reduce((acc, roundPlayer) => {
            acc[roundPlayer.id] = roundPlayer
            return acc
          }, {})
      )
    },
    roundsInfo() {
      return Object.freeze(
        Object.entries(this.data.rounds)
          .map(([, round]) => {
            return {
              ...this.matchesData[round.match_id]?.rounds?.find(matchRound => round.round_num === matchRound.round_num),
              matchId: round.match_id,
            }
          })
          ?.reduce((acc, roundInfo) => {
            acc[genRoundId(roundInfo.matchId, roundInfo.round_num)] = roundInfo
            return acc
          }, {})
      )
    },
    selected: {
      get() {
        if (this.mapMode === 'analytics') {
          return this.mapState.analytics.selected
        } else {
          return this.mapState.replay2d.selected
        }
      },
      set(value) {
        if (this.mapMode === 'analytics') {
          this.mapState.analytics.selected = value
        } else {
          this.mapState.replay2d.selected = value
        }
      },
    },
    selectedMatchId() {
      return this.singleMatch ? this.singleMatch.id : null
    },
    selectedPositions() {
      return this.getSelectedPositions()
    },
    selectedRound() {
      return this.singleRound
    },
    selectedRoundStats() {
      if (this.singleMatch && this.selectedMatchId && this.selectedRound) {
        return this.mapToolData?.roundTeamStats[this.selectedRound.id]
      } else {
        return null
      }
    },
    selectedRoundVodStartMillis() {
      if (this.singleRound) {
        return this.singleRound.vod_start_millis
      } else {
        return 0
      }
    },
    // used to trigger watcher
    selectedTeam() {
      return this.exposed.currentState.selected.team
    },
    // used to trigger watcher
    selectedTeamRole() {
      return this.exposed.currentState.filters.round.teamRole
    },
    singleMatch() {
      return !this.multiMatch && this.mapToolData ? Object.values(this.mapToolData.matches).pop() : null
    },
    singleRound() {
      return this.mapToolData && this.selected.rounds && Object.keys(this.selected.rounds).length === 1
        ? this.mapToolData.rounds[Object.keys(this.selected.rounds).pop()]
        : null
    },
    timelineStart() {
      switch (this.selected.alignRounds) {
        case 'start':
          return 0
        case 'end':
          return -this.roundDuration
        case 'plant': {
          return Math.floor(-this.roundDuration / 2)
        }
      }
      return 0
    },
    timelineEnd() {
      return this.timelineStart + this.roundDuration
    },
    utilitiesPerRound() {
      const utilities = {}
      for (const utilityUse of this.mapToolEvents.utilities) {
        utilities[utilityUse.round_id] = utilities[utilityUse.round_id] ?? {}

        const agent = this.mapToolData.agents[this.mapToolData.matchPlayers[utilityUse.match_player_id].agent_id]
        const damageId = genDamageId({ damage_type: 'Ability', damage_item: utilityUse.ability_slot }, agent)

        utilities[utilityUse.round_id][damageId] = utilities[utilityUse.round_id][damageId] ?? {}
        utilities[utilityUse.round_id][damageId].players = utilities[utilityUse.round_id][damageId].players ?? {}
        utilities[utilityUse.round_id][damageId].players[
          this.mapToolData.matchPlayers[utilityUse.match_player_id].player_id
        ] = true
      }
      return Object.freeze(utilities)
    },
    vodPlayer() {
      return this.exposed.currentState.vodPlayer
    },
    vodStatus() {
      if (this.singleMatch && this.selectedMatchId) {
        return this.matchesData[this.selectedMatchId]?.vod_status
      } else {
        return null
      }
    },
    loading() {
      return this.parentLoading || this.internalLoading
    },
    hasEconomy() {
      return Object.entries(this.mapToolData.roundTeams).some(([, roundTeam]) => {
        if (roundTeam.eco) {
          return true
        }
      })
    },
    hasOutcome() {
      return Object.entries(this.mapToolData.rounds).some(([, round]) => {
        if (round.outcome) {
          return true
        }
      })
    },
    hasPlants() {
      return Object.entries(this.mapToolData.rounds).some(([, round]) => {
        if (round.plant_site) {
          return true
        }
      })
    },
    hasWins() {
      return Object.entries(this.mapToolData.roundTeams).some(([, roundTeam]) => {
        if (typeof roundTeam.win !== 'undefined' || roundTeam.win !== null) {
          return true
        }
      })
    },
    hasPlaybackPositions() {
      return this._08_events_positions?.length > 0 || false
    },
    hasMinimapVods() {
      return Object.entries(this.mapToolData.rounds).some(([, round]) => {
        if (round.vod_replay_url) {
          return true
        }
      })
    },
    hasAbilitiesUsage() {
      return this.mapToolEvents?.utilitiesUsage?.length > 0 || false
    },
    shouldShowAd() {
      return this.adsEnabled && this.showVideoAd
    },
  },
  watch: {
    abilitesUsageFilter: {
      deep: true,
      immediate: true,
      handler() {
        if (this.mapMode === 'analytics' && this.exposed?.currentState?.selected.rounds) {
          this.exposed.currentState.selected.rounds = Object.fromEntries(
            Object.entries(this.exposed.currentState.selected.rounds)
              .filter(([, selected]) => selected)
              .map(([roundId]) => {
                return this.filterRound(roundId) ? [roundId, true] : null
              })
              .filter(Boolean)
          )
          if (Object.keys(this.exposed.currentState.selected.rounds).length === 0) {
            this.exposed.currentState.selected.rounds = null
          }
        }
      },
    },
    mapData: {
      immediate: true,
      handler(val) {
        this.exposed.map = val
      },
    },
    mapRoundFilters: {
      deep: true,
      immediate: true,
      handler() {
        if (this.exposed?.currentState?.selected.rounds) {
          const roundEntries = Object.entries(this.exposed.currentState.selected.rounds).filter(
            ([, selected]) => selected
          )
          const selectOne = roundEntries.length === 1
          const roundEntriesAfterFilters = roundEntries.filter(([roundId]) =>
            this.filterRound(roundId, { includeSelectedRounds: false })
          )

          // select first matching round if one round was selected before it's no longer selected
          if (roundEntriesAfterFilters.length === 0) {
            if (selectOne) {
              const availableRoundId = Object.keys(this.data.rounds).find(roundId =>
                this.filterRound(roundId, { includeSelectedRounds: false })
              )
              if (availableRoundId) {
                roundEntriesAfterFilters.push([availableRoundId, true])
              }
            }
          }

          this.exposed.currentState.selected.rounds =
            roundEntriesAfterFilters.length > 0 ? Object.fromEntries(roundEntriesAfterFilters) : null
        }
      },
    },
    mapSnapshotFilters: {
      immediate: true,
      handler() {
        this.filterSnapshotRounds()
        this.filterUtilitySnapshotRounds()
      },
    },
    mapToolData: {
      immediate: true,
      async handler(val) {
        this.exposed.data = val
        this.exposed.settings = {
          ...this.exposed.settings,
          ...this.mapSettings,
        }
        await this.resetMapState()
      },
    },
    playbackRate: {
      handler(val) {
        if (!this.loading) {
          mixpanel.track_2d_map_playback_rate(val)
        }
      },
    },
    playing: {
      handler(val) {
        if (!this.loading) {
          mixpanel.track_2d_map_playing(val)
        }
      },
    },
    selected: {
      deep: true,
      handler(val) {
        if (!this.loading) {
          mixpanel.track_2d_map_selection(val)
        }
      },
    },
    selectedRound: {
      immediate: true,
      handler(val, old) {
        this.currentTime = 0
        if (old?.id && val?.id && old.id !== val.id) {
          this.increaseAdCounters()
        }
      },
    },
    selectedTeam(val) {
      for (const key in this.exposed.state) {
        const state = this.exposed.state[key]
        if (!('team' in (state?.selected || {}))) continue
        state.filters.round.scenario = { snapshots: [], rounds: [] }
        if (state.selected.team === val) continue
        state.selected.team = val
      }
    },
    selectedTeamRole(val) {
      for (const key in this.exposed.state) {
        const state = this.exposed.state[key]
        if (!('teamRole' in (state?.filters?.round || {}))) continue
        state.filters.round.scenario = { snapshots: [], rounds: [] }
        if (state.filters.round.teamRole === val) continue
        state.filters.round.teamRole = val
      }
    },
    team: {
      immediate: true,
      handler(team_id) {
        this.mapState.replay2d.selected.team = team_id
        this.mapState.analytics.selected.team = team_id
      },
    },
    singleRound: {
      handler(val) {
        if (!val || val.vod_start_millis == null || !val.vod_duration_millis) {
          this.player = 'vod'
        }
      },
    },
    timelineEnd: {
      immediate: true,
      handler(val) {
        this.exposed.timelineEnd = val
      },
    },
    timelineStart(val) {
      this.exposed.timelineStart = val
    },
    vodPlayer(val) {
      if (!this.loading) {
        mixpanel.track_2d_map_vod_player(val)
      }
      if (val !== 'playback' && !this.singleRound && this.selected.rounds) {
        this.selected.rounds = Object.freeze({
          [Object.keys(this.selected.rounds).pop()]: true,
        })
      }
    },
    mapState: {
      immediate: true,
      deep: true,
      handler() {
        if (this.loading) {
          return
        }
        this.scheduleMapStateSerialize()
      },
    },
    adCounters: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.roundsPlayed >= VIDEO_AD_ROUNDS_COUNT) {
          this.showVideoAd = true
        }
      },
    },
  },
  mounted() {
    window.exposed = this.exposed
    window.mapController = this
    this.exposed.presenterMode = this.$route.query.presenter === 'true'
    this.exposed.gridPositions = this.gridPositions

    document.addEventListener('fullscreenchange', this.onFullScreenChange)
  },
  beforeDestroy() {
    clearTimeout(this.mapStateSerializeTimeout)
    document.removeEventListener('fullscreenchange', this.onFullScreenChange)
  },
  methods: {
    onFullScreenChange() {
      this.exposed.fullscreenMode = document.fullscreenElement != null
    },
    getBombPlantEvent(roundId) {
      return this.mapToolData?.rounds[roundId].plant
    },
    getFilteredAdvancedPositions({
      eventFilters = [() => true],
      roundFilters = [this.mapToolFilters.round],
      mapFilters = this.mapFilters,
    } = {}) {
      const result = []
      for (const roundId in this.mapToolEvents.advancedRoundPositions) {
        if (!roundFilters.every(roundFilter => roundFilter(roundId, { mapFilters }))) continue
        const advancedPositions = this.mapToolEvents.advancedRoundPositions[roundId]
        result.push(advancedPositions.filter(pos => eventFilters.every(eventFilter => eventFilter(pos))))
      }
      return Object.freeze(result.flat())
    },
    getFilteredBestPositions(
      compare = null,
      {
        eventFilter = () => true,
        roundFilter = this.mapToolFilters.round,
        matchPlayerFilter = this.mapToolFilters.matchPlayer,
        selected = this.selected,
      } = {}
    ) {
      switch (compare) {
        case null:
          break
        case 'min':
          compare = (pos, old) => old.round_time_millis > pos.round_time_millis
          break
        case 'max':
          compare = (pos, old) => old.round_time_millis < pos.round_time_millis
          break
        default:
          if (!(compare instanceof Function)) {
            throw new Error(`Unhandled order type ${compare}`)
          }
          break
      }
      const result = []
      for (const roundId in this.mapToolEvents.roundPlayerPositions) {
        if (!roundFilter(roundId, { selected })) continue
        const playerPositions = this.mapToolEvents.roundPlayerPositions[roundId]
        for (const matchPlayerId in playerPositions) {
          if (!matchPlayerFilter(matchPlayerId, { selected })) continue
          const positions = playerPositions[matchPlayerId]
          if (compare) {
            const reduced = positions.reduce((best, pos) => {
              if (!eventFilter(pos)) return best
              if (!best || compare(pos, best)) {
                return pos
              }
              return best
            })
            if (reduced) {
              result.push(reduced)
            }
          } else {
            result.push(positions)
          }
        }
      }
      return Object.freeze(compare ? result : result.flat())
    },
    getSelectedAdvancesPositions({ mapFilters = this.mapFilters } = {}) {
      const eventFilters = []
      const roundFilters = []

      switch (mapFilters.positions) {
        case null:
          break
        case 'first':
          eventFilters.push(pos => 30000 < pos.round_time_millis)
          break
        case 'pre':
          eventFilters.push(pos => {
            const bombTime = this.getBombPlantEvent(pos.round_id).round_time_millis
            return bombTime - 30000 <= pos.round_time_millis && pos.round_time_millis < bombTime
          })
          roundFilters.push((roundId, opts) => {
            return this.getBombPlantEvent(roundId) && this.filterRound(roundId, opts)
          })
          break
        case 'post':
          eventFilters.push(pos => {
            const bombTime = this.getBombPlantEvent(pos.round_id).round_time_millis
            return bombTime < pos.round_time_millis && pos.round_time_millis <= bombTime + 30000
          })
          roundFilters.push((roundId, opts) => {
            return this.getBombPlantEvent(roundId) && this.filterRound(roundId, opts)
          })
          break
        case 'on':
          eventFilters.push(pos => {
            const bombTime = this.getBombPlantEvent(pos.round_id).round_time_millis
            return bombTime - 30000 < pos.round_time_millis && pos.round_time_millis < bombTime + 30000
          })
          roundFilters.push((roundId, opts) => {
            return this.getBombPlantEvent(roundId) && this.filterRound(roundId, opts)
          })
          break
        default:
          throw new Error(`Unhandled positions ${this.selected.positions}`)
      }

      if (this.mapMode === 'analytics' && mapFilters.timeline.abilitiesUsage != null) {
        const eventRoundTimes = this.mapToolEvents.utilitiesUsage
          .filter(usage => {
            return mapFilters.timeline.abilitiesUsage?.[usage.match_player_id]?.[usage.ability_slot]
          })
          .map(usage => usage.round_time_millis)

        if (eventRoundTimes.length > 0) {
          eventFilters.push(pos => eventRoundTimes.some(roundTime => Math.abs(pos.round_time_millis - roundTime) < 250))
        }
      }

      return this.getFilteredAdvancedPositions({
        mapFilters,
        ...(eventFilters.length ? { eventFilters } : {}),
        ...(roundFilters.length ? { roundFilters } : {}),
      })
    },
    getSelectedPositions({ selected = this.selected } = {}) {
      switch (selected.positions) {
        case null:
          return this.getFilteredBestPositions(undefined, { selected })
        case 'first':
          return this.getFilteredBestPositions('max', {
            selected,
            eventFilter: pos => 30000 < pos.round_time_millis,
          })
        case 'pre':
          return this.getFilteredBestPositions('min', {
            selected,
            eventFilter: pos => {
              const bombTime = this.getBombPlantEvent(pos.round_id).round_time_millis
              return bombTime - 30000 <= pos.round_time_millis && pos.round_time_millis < bombTime
            },
            roundFilter: (roundId, opts) => {
              return this.getBombPlantEvent(roundId) && this.filterRound(roundId, opts)
            },
          })
        case 'post':
          return this.getFilteredBestPositions('max', {
            selected,
            eventFilter: pos => {
              const bombTime = this.getBombPlantEvent(pos.round_id).round_time_millis
              return bombTime < pos.round_time_millis && pos.round_time_millis <= bombTime + 30000
            },
            roundFilter: (roundId, opts) => {
              return this.getBombPlantEvent(roundId) && this.filterRound(roundId, opts)
            },
          })
        case 'on':
          return this.getFilteredBestPositions(
            (cur, best) => {
              const bombTime = this.getBombPlantEvent(cur.round_id).round_time_millis
              return Math.abs(cur.round_time_millis - bombTime) < Math.abs(best.round_time_millis - bombTime)
            },
            {
              selected,
              eventFilter: pos => {
                const bombTime = this.getBombPlantEvent(pos.round_id).round_time_millis
                return bombTime - 30000 < pos.round_time_millis && pos.round_time_millis < bombTime + 30000
              },
              roundFilter: (roundId, opts) => {
                return this.getBombPlantEvent(roundId) && this.filterRound(roundId, opts)
              },
            }
          )
        default:
          throw new Error(`Unhandled positions ${this.selected.positions}`)
      }
    },
    filterEvent(event, { mapFilters = this.mapFilters } = {}) {
      switch (event.type) {
        case 'utility':
        case 'death':
        case 'defuse':
        case 'kill':
        case 'utility-usage':
          return (
            this.filterRound(event.round_id, { mapFilters }) &&
            (this.mapMode === 'replay2d' || this.filterMatchPlayer(event.match_player_id, { mapFilters }))
          )
        case 'smoke':
        case 'wall':
        case 'plant':
          return this.filterRound(event.round_id, { mapFilters })
        case 'position':
          return true
        default:
          throw new Error(`Unhandled event type ${event.type}`)
      }
    },
    filterMatchPlayer(matchPlayerId, { mapFilters = this.mapFilters } = {}) {
      if (!(matchPlayerId in this.mapToolData.matchPlayers)) return false
      const matchPlayer = this.mapToolData.matchPlayers[matchPlayerId]
      if (mapFilters.players != null) {
        if (!mapFilters.players[matchPlayer.player_id]) return false
      }
      if (mapFilters.agents != null) {
        if (!mapFilters.agents[matchPlayer.agent_id]) return false
      }
      if (mapFilters.teamCompositions != null) {
        if (
          // no composition match
          !Object.entries(mapFilters.teamCompositions).some(
            ([compId, items]) =>
              // from same composition
              compId === matchPlayer.team_composition_id &&
              // and the player/agent is selected
              items.some(
                (selected, idx) =>
                  selected &&
                  this.mapToolData.teamCompositions[compId].composition[idx].player_id === matchPlayer.player_id &&
                  this.mapToolData.teamCompositions[compId].composition[idx].agent_id === matchPlayer.agent_id
              )
          )
        )
          return false
      }
      return true
    },
    filterSnapshotRounds() {
      if (this.mapFilters.round.scenario.snapshots.length === 0) {
        this.mapFilters.round.scenario.rounds = []
        return
      }

      let searchPlayers = this.mapFilters.round.scenario.snapshots.filter(snap => snap.type === 'player')
      let rounds = []
      let advPos = this.mapToolEvents.advancedPositions.length === 0 ? false : true

      let pos = advPos ? this.mapToolEvents.advancedRoundPositions : this.mapToolEvents.roundPlayerPositions

      if (advPos) {
        Object.entries(pos).forEach(([roundId, p]) => {
          let flag = true
          for (let snap of searchPlayers) {
            if (!flag) break

            let players = {}
            let matchPlayers = {}
            snap.players.forEach(plr => {
              players[plr.player.id] = {}
              plr.player.matchId.forEach(mId => {
                matchPlayers[mId] = plr.player.id
              })
            })

            for (let i = 0; i < p.length; i++) {
              if (!(p[i].match_player_id in matchPlayers)) continue
              if (!polygonContains(snap.poly, [p[i].location.x, p[i].location.y])) continue
              let pId = matchPlayers[p[i].match_player_id]
              players[pId][p[i].round_time_millis] = p[i].round_time_millis
            }

            const playerKeys = Object.keys(players)
            for (const plrKey of playerKeys) {
              if (!flag) break
              if (Object.keys(players[plrKey]).length === 0) flag = false
              if (playerKeys.length > 1 && plrKey !== playerKeys[0]) {
                const matchingTimes = Object.keys(players[plrKey]).filter(time =>
                  Object.keys(players[playerKeys[0]]).includes(time)
                )
                if (matchingTimes.length === 0) flag = false
              }
            }
          }
          if (flag) rounds.push(roundId)
        })
        this.mapFilters.round.scenario.rounds = Object.fromEntries(rounds.map(k => [k, true]))
      } else {
        Object.entries(pos).forEach(([roundId, roundPlayers]) => {
          let flag = true
          for (let snap of searchPlayers) {
            if (!flag) break

            let players = {}
            let matchPlayers = {}
            snap.players.forEach(plr => {
              players[plr.player.id] = {}
              plr.player.matchId.forEach(mId => {
                matchPlayers[mId] = plr.player.id
              })
            })

            for (let plr of snap.players) {
              if (!flag) break

              for (let mId of plr.player.matchId) {
                if (!(mId in roundPlayers)) continue
                for (let p of roundPlayers[mId]) {
                  if (!polygonContains(snap.poly, [p.location.x, p.location.y])) continue
                  let pId = matchPlayers[p.match_player_id]
                  players[pId][p.round_time_millis] = p.round_time_millis
                }
                if (Object.keys(players[plr.player.id]).length === 0) flag = false
              }
            }

            const playerKeys = Object.keys(players)
            for (const plrKey of playerKeys) {
              if (!flag) break
              if (playerKeys.length > 1 && plrKey !== playerKeys[0]) {
                const matchingTimes = Object.keys(players[plrKey]).filter(time =>
                  Object.keys(players[playerKeys[0]]).includes(time)
                )
                if (matchingTimes.length === 0) flag = false
              }
            }
          }
          if (flag) rounds.push(roundId)
        })
        this.mapFilters.round.scenario.rounds = Object.fromEntries(rounds.map(k => [k, true]))
      }
    },
    filterUtilitySnapshotRounds() {
      if (this.mapFilters.round.scenario.snapshots.length === 0) {
        this.mapFilters.round.scenario.rounds = []
        return
      }

      let playerSnapshots = this.mapFilters.round.scenario.snapshots.filter(snap => snap.type === 'player').length
      if (playerSnapshots && !Object.keys(this.mapFilters.round.scenario.rounds).length) {
        return
      }

      let searchUtility = this.mapFilters.round.scenario.snapshots.filter(snap => snap.type === 'utility')

      if (searchUtility.length === 0) return
      let roundCheck = playerSnapshots > 0 ? true : false

      let smokeSearch = []
      let wallSearch = []
      let utilSearch = []

      searchUtility.forEach(snap => {
        snap.utility.forEach(util => {
          if ('smoke' in util) smokeSearch.push(snap.poly)
          else if ('wall' in util) wallSearch.push(snap.poly)
          else utilSearch.push({ poly: snap.poly, util: util.utility })
        })
      })

      let smokeRounds = null
      let wallRounds = null
      let utilRounds = null

      if (smokeSearch.length > 0) {
        let filteredSmokes = roundCheck
          ? this.mapToolEvents.smokes.filter(s => s.round_id in this.mapFilters.round.scenario.rounds)
          : this.mapToolEvents.smokes

        smokeRounds = Object.fromEntries(smokeSearch.map((_, index) => [index, {}]))

        for (let smoke of filteredSmokes) {
          let i = 0
          for (let snap of smokeSearch) {
            if (!(smoke.round_id in smokeRounds[i]) && polygonContains(snap, [smoke.location.x, smoke.location.y]))
              smokeRounds[i][smoke.round_id] = true
            i += 1
          }
        }

        smokeRounds = Object.values(smokeRounds).map(i => Object.keys(i))
        smokeRounds = Object.values(smokeRounds).reduce((a, b) => a.filter(c => b.includes(c)))
      }

      if (wallSearch.length > 0) {
        let filteredWalls = roundCheck
          ? this.mapToolEvents.walls.filter(s => s.round_id in this.mapFilters.round.scenario.rounds)
          : this.mapToolEvents.walls

        wallRounds = Object.fromEntries(wallSearch.map((_, index) => [index, {}]))

        for (let wall of filteredWalls) {
          let i = 0
          for (let snap of wallSearch) {
            if (!(wall.round_id in wallRounds[i]) && polygonContains(snap, [wall.location.x, wall.location.y]))
              wallRounds[i][wall.round_id] = true
            i += 1
          }
        }

        wallRounds = Object.values(wallRounds).map(i => Object.keys(i))
        wallRounds = Object.values(wallRounds).reduce((a, b) => a.filter(c => b.includes(c)))
      }

      if (utilSearch.length > 0) {
        let filteredUtil = roundCheck
          ? this.mapToolEvents.utilities.filter(s => s.round_id in this.mapFilters.round.scenario.rounds)
          : this.mapToolEvents.utilities

        utilRounds = Object.fromEntries(utilSearch.map((_, index) => [index, {}]))

        for (let util of filteredUtil) {
          let i = 0
          for (let snap of utilSearch) {
            const agent =
              util.match_player_id && this.mapToolData.agents[this.data.matchPlayers[util.match_player_id].agent_id]
            if (
              !(util.round_id in utilRounds[i]) &&
              polygonContains(snap.poly, [util.location.x, util.location.y]) &&
              this.mapToolData.roundTeams[util.round_team_id].role === snap.util.side.toLowerCase() &&
              snap.util.slot === util.ability_slot &&
              agent.name === snap.util.agent
            )
              utilRounds[i][util.round_id] = true
            i += 1
          }
        }

        utilRounds = Object.values(utilRounds).map(i => Object.keys(i))
        utilRounds = Object.values(utilRounds).reduce((a, b) => a.filter(c => b.includes(c)))
      }
      const combine = [smokeRounds, wallRounds, utilRounds].filter(arr => Array.isArray(arr))
      if (smokeRounds != null || wallRounds != null || utilRounds != null) {
        let filtered = combine.reduce((a, b) => a.filter(c => b.includes(c)))
        this.mapFilters.round.scenario.rounds = Object.fromEntries(filtered.map(k => [k, true]))
      }
    },
    filterRound(roundId, { includeSelectedRounds = true, mapFilters = this.mapFilters } = {}) {
      if (includeSelectedRounds && mapFilters.rounds != null) {
        if (!mapFilters.rounds[roundId]) {
          return false
        }
      }
      if (mapFilters.round.outcome != null) {
        if (this.mapToolData.rounds[roundId].outcome !== mapFilters.round.outcome) return false
      }
      if (mapFilters.round.site != null) {
        if (this.mapToolData.rounds[roundId].plant_site !== mapFilters.round.site) return false
      }

      if (mapFilters.team != null) {
        const roundTeamId = genRoundTeamId(roundId, mapFilters.team)
        if (!(roundTeamId in this.mapToolData.roundTeams)) return false
        const roundTeam = this.mapToolData.roundTeams[roundTeamId]
        if (mapFilters.round.win != null) {
          if (roundTeam.win !== mapFilters.round.win) return false
        }
        if (mapFilters.round.teamRole != null) {
          if (roundTeam.role !== mapFilters.round.teamRole) return false
        }

        if (mapFilters.round.eco != null && mapFilters.round.eco.length > 0) {
          if (!mapFilters.round.eco.includes(roundTeam.eco)) return false
        }

        if (mapFilters.round.operator !== null) {
          const hasOperator = Object.values(this.indexedRoundPlayers[roundId])?.some(
            roundPlayer => roundPlayer.weapon_name === 'Operator' && roundPlayer.round_team_id === roundTeamId
          )
          if (mapFilters.round.operator !== hasOperator) return false
        }
      }

      if (mapFilters.round.ecoOpponents != null && mapFilters.round.ecoOpponents.filter(e => e !== 'P').length > 0) {
        if (
          Object.entries(this.mapToolData.roundTeams)
            .filter(([, roundTeam]) => roundTeam.round_id === roundId && roundTeam.team_id !== mapFilters.team)
            .some(([, roundTeam]) => roundTeam.eco !== 'P' && !mapFilters.round.ecoOpponents.includes(roundTeam.eco))
        ) {
          return false
        }
      }

      if (mapFilters.round.half != null) {
        if (
          (mapFilters.round.half === 'first' && this.mapToolData.rounds[roundId].round_num > 11) ||
          (mapFilters.round.half === 'second' &&
            (this.mapToolData.rounds[roundId].round_num < 12 || this.mapToolData.rounds[roundId].round_num > 23)) ||
          (mapFilters.round.half === 'overtime' && this.mapToolData.rounds[roundId].round_num < 24)
        ) {
          return false
        }
      }

      if (mapFilters.round.fkd != null) {
        const roundTeamId = genRoundTeamId(roundId, mapFilters.team)
        const first = this.mapToolEvents.kills.find(
          kill => kill.id == this.mapToolData.firstRoundKills[roundId].kill_id
        )

        if (mapFilters.round.fkd === 'fk' && first.victim.round_team_id === roundTeamId) {
          return false
        }

        if (
          mapFilters.round.fkd === 'fd' &&
          first.killer.round_team_id === roundTeamId &&
          first.victim.round_team_id !== roundTeamId
        ) {
          return false
        }
      }

      if (this.hasOrbs && Object.keys(mapFilters.round.orbs).length) {
        const matchId = this.mapToolData.rounds[roundId].match_id
        if (
          !Object.entries(mapFilters.round.orbs).every(([orb_player_id, orbs]) => {
            if (!orbs || Object.keys(orbs).length === 0) return true

            const orbPlayer = this.mapToolData.orbsPlayers[orb_player_id]
            const match_player_id = orbPlayer.matchPlayerIds.find(
              matchPlayerId => this.mapToolData.matchPlayers[matchPlayerId].match_id === matchId
            )

            return orbs[this.indexedRoundPlayers[roundId]?.[match_player_id]?.ultimate_orbs]
          })
        ) {
          return false
        }
      }

      if (mapFilters.round.trades) {
        const roundTeamId = genRoundTeamId(roundId, mapFilters.team)
        if (
          !this.mapToolEvents.kills
            ?.filter(kill => kill.round_id === roundId)
            .some(kill => {
              if (!kill.is_trade) return false
              switch (mapFilters.round.trades) {
                case 'both':
                  return true
                case 'killer':
                  return kill.killer.round_team_id === roundTeamId
                case 'victim':
                  return kill.victim.round_team_id === roundTeamId
                case 'none':
                  return false
              }
            })
        ) {
          return false
        }
      }

      if (mapFilters.round.scenario.snapshots.length !== 0) {
        if (!mapFilters.round.scenario.rounds[roundId]) return false
      }

      if (!this.multiMatch && this.mapMode === 'kills') {
        if (mapFilters.damage != null) {
          if (!Object.entries(mapFilters.damage).find(([damageId]) => this.damagesPerRound[roundId][damageId]))
            return false
        }
      }

      if (!this.multiMatch && ['playback', 'utility', 'analytics'].includes(this.mapMode)) {
        if (mapFilters.round.abilities) {
          const foundUtility = Object.entries(mapFilters.round.abilities).some(([id, ability]) => {
            if (!this.utilitiesPerRound[roundId][id]) {
              return false
            }

            return Object.keys(this.utilitiesPerRound?.[roundId]?.[id]?.players).some(player => ability.players[player])
          })
          if (!foundUtility) return false
        }
      }

      if (mapFilters.timeline.abilitiesUsage != null) {
        if (
          !this.mapToolEvents.utilitiesUsage?.some(
            usage =>
              usage.round_id === roundId &&
              mapFilters.timeline.abilitiesUsage?.[usage.match_player_id]?.[usage.ability_slot]
          )
        ) {
          return false
        }
      }
      return true
    },
    snapshotStateCheck() {
      for (const key in this.exposed.state) {
        const state = this.exposed.state[key]
        if (key === 'mapMode') continue
        if (!state?.filters?.round?.scenario) {
          state.filters.round.scenario = { snapshots: [], rounds: [] }
        }
      }
    },
    kdZoneStateCheck(bookmark) {
      for (const key in bookmark.state.exposedState) {
        const state = bookmark.state.exposedState[key]
        if (key === 'mapMode') continue
        if (!state?.kdZoneMode) {
          state.kdZoneMode = {
            view: 'all',
            opps: true,
            first: false,
            legend: false,
            selected: '',
            zone: false,
            plantTime: 'all',
          }
        }
        if (!('view' in state.kdZoneMode)) state.kdZoneMode.view = 'all'
        if (!('opps' in state.kdZoneMode)) state.kdZoneMode.opps = true
        if (!('first' in state.kdZoneMode)) state.kdZoneMode.first = false
        if (!('legend' in state.kdZoneMode)) state.kdZoneMode.legend = false
        if (!('selected' in state.kdZoneMode)) state.kdZoneMode.selected = ''
        if (!('zone' in state.kdZoneMode)) state.kdZoneMode.zone = false
        if (!('plantTime' in state.kdZoneMode)) state.kdZoneMode.plantTime = 'all'
      }
    },
    otherStateCheck() {
      for (const key in this.exposed.state) {
        const state = this.exposed.state[key]
        if (key === 'mapMode') continue
        if (!state?.iconHide) {
          state.iconHide = { unselected: false }
        }
        if (!state?.filters?.round?.fkd) {
          state.filters.round.fkd = null
        }
      }
    },
    loadBookmark(bookmark) {
      this.matches = deepClone(bookmark.state.matches)
      this.supportedPlaybackRates = deepClone(bookmark.state.supportedPlaybackRates)
      this.playbackRate = bookmark.state.playbackRate
      this.kdZoneStateCheck(bookmark)
      this.exposed.state = deepClone(bookmark.state.exposedState)
      this.snapshotStateCheck()
      this.otherStateCheck()
      if (bookmark.state.startPlaying) {
        this.exposed.state.replay2d.playing = true
      }
      this.$nextTick(() => {
        this.currentTime = bookmark.round_millis
      })
    },

    async resetMapState() {
      // do nothing if no data
      if (!this.exposed.data) {
        return
      }

      this.internalLoading = true

      try {
        clearTimeout(this.mapStateSerializeTimeout)

        if (this.initialState) {
          try {
            const { version, ...state } = await this.deserializeMapState(this.initialState)
            if (version == null || version === BOOKMARK_VERSION) {
              this.mapState = state
              return
            }
          } catch (e) {
            console.log('failure to load initial state', e)
          }
        }

        // start in replay mode
        this.exposed.state.mapMode = 'replay2d'

        // use parent provided team or one of the teams
        const team = this.team || Object.values(this.exposed.data.teams || {}).pop()?.id

        this.exposed.state.replay2d.selected.team = this.exposed.state.analytics.selected.team = team

        // find the first round for that team
        const firstRound = Object.values(this.exposed.data.rounds || {}).reduce(
          (best, cur) =>
            !best
              ? cur
              : cur.round_num < best.round_num &&
                cur.round_teams.find(rt => this.exposed.data.roundTeams[rt].team_id === team)
              ? cur
              : best,
          null
        )

        // preselect starting role of selected team
        this.mapState.analytics.filters.round.teamRole = this.mapState.replay2d.filters.round.teamRole =
          firstRound?.round_teams.map(rt => this.exposed.data.roundTeams?.[rt]).find(rt => rt?.team_id === team)?.role

        // preselect first round of selected team for single match
        if (Object.values(this.exposed.data.matches || {}).length === 1) {
          if (firstRound) {
            this.exposed.state.replay2d.selected.rounds = {
              [firstRound.id]: true,
            }
          } else {
            this.exposed.state.replay2d.selected.rounds = Object.fromEntries(
              [
                Object.values(this.exposed.data.rounds || []).reduce(
                  (best, cur) => (!best || cur.round_num < best.round_num ? cur : best),
                  null
                )?.id,
              ]
                .filter(Boolean)
                .map(id => [id, true])
            )
          }

          // if there's a stream select that, otherwise fallback to playback
          this.exposed.state.replay2d.vodPlayer = this.exposed.settings.startingVodPlayer
        } else {
          // on multimatch we are working with filters
          this.exposed.state.replay2d.selected.rounds = null
          this.exposed.state.analytics.selected.rounds = null

          // and playback is the default
          this.exposed.state.replay2d.vodPlayer = this.exposed.settings.startingVodPlayer
        }
      } catch (e) {
        console.error('+++ error', e)
      } finally {
        this.internalLoading = false
      }
    },

    switchRole() {
      this.exposed.currentState.filters.round.teamRole =
        this.exposed.currentState.filters.round.teamRole === 'atk' ? 'def' : 'atk'
    },

    takeScreenshot() {
      return this.$refs.minimap?.takeScreenshot()
    },

    async saveNote({ note, ...rest }) {
      this.savingBookmark = true
      try {
        this.error = null
        const state = {
          ...rest,
          note,
          matches: deepClone(this.matches),
          supportedPlaybackRates: deepClone(this.supportedPlaybackRates),
          playbackRate: this.playbackRate,
          exposedState: deepClone(this.exposed.state),
        }

        const bookmark = {
          match: note.matchId || this.selectedMatchId || this.singleMatch.id,
          text: note.text,
          tags: note.tags,
          round_num: this.singleRound?.round_num || 0,
          round_millis: this.currentTime,
          version: BOOKMARK_VERSION,
          state,
        }

        await this.createBookmarkApi?.(bookmark)

        mixpanel.track_note_create({ target_id: bookmark.match, ...note })
      } catch (e) {
        this.error = axios.extractErrorMessage(e)
        Sentry.captureException(e)
      } finally {
        this.savingBookmark = false
      }
    },
    scheduleMapStateSerialize() {
      clearTimeout(this.mapStateSerializeTimeout)

      this.mapStateSerializeTimeout = setTimeout(this.serializeMapState, 5000)
    },
    async serializeMapState() {
      let stateId = null
      if (this.serializeState) {
        stateId = await this.serializeState(this.mapState)
      } else {
        stateId = await createShortcut({ ...this.mapState, version: BOOKMARK_VERSION })
      }

      this.$emit('update:initialState', stateId)
    },
    async deserializeMapState(stateId) {
      if (this.deserializeState) {
        return await this.deserializeState(stateId)
      }
      return await getShortcut(stateId)
    },
    togglePresenterMode() {
      this.exposed.presenterMode = !this.exposed.presenterMode
      this.layout.presenterMode = this.exposed.presenterMode
      this.$route.query.presenter = this.exposed.presenterMode
    },
    toggleFullscreenMode() {
      this.exposed.fullscreenMode = !this.exposed.fullscreenMode
      setTimeout(() => {
        if (this.exposed.fullscreenMode) {
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen()
          } else if (document.body.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen()
          } else if (document.body.mozRequestFullScreen) {
            document.body.mozRequestFullScreen()
          } else if (document.body.msRequestFullscreen) {
            document.body.msRequestFullscreen()
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen()
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
          }
        }
      }, 500)
    },
    async toggleGridPositions() {
      this.exposed.gridPositions = !this.exposed.gridPositions
      await submitPersistentData({ body: { grid_positions: this.exposed.gridPositions } })
      this.$router.go()
    },
    closeVideoAd() {
      this.showVideoAd = false
    },
    resetAdCounters() {
      this.adCounters = {
        roundsPlayed: 0,
      }
    },
    increaseAdCounters() {
      if (this.internalLoading || this.showVideoAd || !this.adCounters) return
      this.adCounters.roundsPlayed++
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper {
  // position: fixed;
  // width: 100vw;
  // height: 100vh;
  position: relative;
  height: 100%;
  min-height: 0;

  // display: flex;
  // flex-direction: column;
  // align-items: stretch;

  .map-container,
  .map-controller {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    min-height: 0;
  }
}

.round-meta {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.loading {
  text-align: center;
  margin-top: 30vh;
}

.pointer {
  cursor: pointer;
}

// TODO from here below is copied from 2d-map-tool, refactor this
::v-deep .btn {
  margin: 0 2px 2px 0;
  font-family: $font-sen;
  border: none;
  padding: 0.5em 1em;
  position: relative;

  &.disabled,
  &:disabled {
    border-color: $body-color;
    color: $body-color !important;

    &.active {
      background: $body-color;
      color: $body-bg !important;
    }
  }

  &.focus,
  &:focus {
    box-shadow: $input-focus-box-shadow !important;
  }
}

::v-deep .btn-warning {
  @include button-variant($warning, $warning);
}

::v-deep .btn-outline-warning {
  @include button-outline-variant($warning);
}

::v-deep a.h1,
::v-deep a.h2,
::v-deep a.h3,
::v-deep a.h4,
::v-deep a.h5,
::v-deep a.h6 {
  &:hover {
    text-decoration: none;
  }
}

::v-deep .btn-group-toggle {
  .btn {
    flex: 1 1 auto;
    width: 100%;
  }
}

::v-deep .col-form-label {
  text-transform: uppercase;
  font-size: 0.85rem;
  color: darken($body-color, 50%);
  font-weight: bold;
  text-shadow: -1px -1px 0 black;
}

::v-deep .form-group {
  margin-bottom: 2rem;
}

::v-deep .video-player-container {
  padding: 0 !important;

  .vjs-big-play-button {
    display: none;
  }
}
</style>
