<template>
  <div class="v3-btn-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'V3BtnGroup',
}
</script>

<style scoped lang="scss">
.v3-btn-group {
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
  overflow: hidden;

  .v3-button {
    flex: 1 1 auto;
    font-size: 11px;
    color: #a4a2ad;

    &.active {
      background-color: #23212e;
      color: #fff;
    }
  }
}
</style>
