<template>
  <g v-if="items.length">
    <g
      v-for="(item, index) in items"
      :key="item.id"
      :fill="item.color"
      :transform="`translate(1000, ${1014 - (index + 1) * 30})`"
    >
      <image v-if="item.image" x="0" y="2" width="26" height="26" :href="item.image | resolveUrl" />
      <text x="30" y="20">
        {{ item.label }}
      </text>
    </g>
  </g>
</template>

<script>
import px from 'vue-types'

import { pxNullable } from '@/components/Map/types.js'
import resolveUrl from '@/filters/resolveUrl.js'

export default {
  name: 'SvgLegend',
  props: {
    data: px.object,
    legend: pxNullable(px.object),
  },
  filters: {
    resolveUrl,
  },
  computed: {
    items() {
      if (!this.legend) return []
      return Object.entries(this.legend)
        .map(([gid, color]) => {
          const gidInfo = this.data.gids[gid]
          if (!gidInfo) {
            console.warn(`Missing gid ${gid}`)
            return
          }
          let image
          let label
          switch (gidInfo.type) {
            case 'pos': {
              const player = this.data.players[gidInfo.player_id]
              if (!player) {
                console.warn(`Missing player ${gidInfo.player_id}`)
                return
              }
              const agent = this.data.agents[gidInfo.agent_id]
              if (!agent) {
                console.warn(`Missing agent ${gidInfo.agent_id}`)
                return
              }
              label = player.name
              image = agent.display_icon_small_url
              break
            }
            case 'util': {
              const player = this.data.players[gidInfo.player_id]
              if (!player) {
                console.warn(`Missing player ${gidInfo.player_id}`)
                return
              }
              const agent = this.data.agents[gidInfo.agent_id]
              if (!agent) {
                console.warn(`Missing agent ${gidInfo.agent_id}`)
                return
              }
              const ability = agent.abilities[gidInfo.ability_slot]
              if (!ability) {
                console.warn(`Missing ability ${gidInfo.ability_slot} of ${agent.name}`)
                return
              }
              label = `${player.name} ${ability.displayName}`
              image = ability.displayIcon
              break
            }
            case 'wall': {
              const team = this.data.teams[gidInfo.team_id]
              if (!team) {
                console.warn(`Missing team ${gidInfo.team_id}`)
                return
              }
              label = team.name
              break
            }
            default:
              label = gidInfo.type
              break
          }
          return {
            id: gid,
            color,
            label,
            image,
          }
        })
        .filter(Boolean)
    },
  },
}
</script>

<style scoped></style>
