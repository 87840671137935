<template>
  <div class="map-modal">
    <div class="map-content">
      <div class="settings-container">
        <V3BtnGroup>
          <V3Button v-if="hasUtilities" class="utility-button" :active="true" @click="addUtility('utility')"
            >Add Utility</V3Button
          >
          <V3Button
            v-if="hasSmokes"
            class="utility-button"
            :active="!hasSmoke"
            :disabled="hasSmoke"
            @click="addUtility('smoke')"
            >Add Smoke</V3Button
          >
          <V3Button
            v-if="hasWalls"
            class="utility-button"
            :active="!hasWall"
            :disabled="hasWall"
            @click="addUtility('wall')"
            >Add Wall</V3Button
          >
        </V3BtnGroup>

        <template v-for="(util, idx) in this.utilitySearch">
          <div v-if="'smoke' in util" :key="idx" class="utility-container">
            <span class="smoke-or-wall">Smoke</span>
            <V3Button class="delete-button" @click="deleteUtility(idx)">DELETE</V3Button>
          </div>

          <div v-else-if="'wall' in util" :key="idx" class="utility-container">
            <span class="smoke-or-wall">Wall</span>
            <V3Button class="delete-button" @click="deleteUtility(idx)">DELETE</V3Button>
          </div>

          <div v-else-if="'utility' in util" class="utility-container" :key="'util' + idx">
            <select v-model="util.utility">
              <option v-for="u in utilitySideList" :key="u.side + u.agent + u.utilityName" :value="u">
                {{ u.side.toUpperCase() }} - {{ u.agent }} - {{ u.utilityName }}
              </option>
            </select>
            <span v-if="util.utility" :class="[util.utility.side]" class="role">{{ util.utility.side }}</span>

            <V3Button class="delete-button" @click="deleteUtility(idx)">DELETE</V3Button>
          </div>
        </template>
        <!--         <div>
          <template v-for="(plr, idx) in this.playerSearch">
            <div class="player-container" :key="idx">
              <select v-model="plr.player">
                <option v-for="player in agentPlayerList" :key="player.id + player.agent" :value="player">
                  {{ player.agent }} - {{ player.name }}
                </option>
              </select>
              <span v-if="plr" :class="[plr.player.side]" class="role">{{ plr.player.side }}</span>
              <V3Button class="delete-button" @click="deletePlayer(idx)">DELETE</V3Button>
            </div>
          </template>
        </div> -->

        <div class="settings-button-group">
          <V3Button class="normal-button" @click="closeModal()">Cancel</V3Button>
          <V3Button class="save-button" :disabled="!this.canSave" @click="saveSnapshot">Save</V3Button>
        </div>
      </div>

      <svg
        width="512"
        height="512"
        viewBox="0 0 1024 1024"
        id="utility-scenario-minimap"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mousemove="handleMouseMove"
        @mouseleave="handleMouseUp"
      >
        <image
          x="0"
          y="0"
          width="1024"
          height="1024"
          :href="data.map.display_icon_url"
          :transform="`rotate(${-data.map.rotate_deg} 512 512)`"
        />
        <g v-if="this.elements.length !== 0">
          <rect
            :x="this.elements[0].x"
            :y="this.elements[0].y"
            :width="this.elements[0].width"
            :height="this.elements[0].height"
            stroke="red"
            stroke-width="4"
            fill="none"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'

import exposedDataState from '../../mixins/exposedDataState.js'
import V3Button from '../v3dafi/V3Button.vue'

const makeUnique = (array = [], keys = []) => {
  if (!keys.length || !array.length) return []

  return array.reduce((list, item) => {
    const hasItem = list.find(listItem => keys.every(key => listItem[key] === item[key]))
    if (!hasItem) list.push(item)
    return list
  }, [])
}

export default {
  name: 'MapUtilityModal',
  mixins: [exposedDataState],
  components: {
    V3Button,
    V3BtnGroup,
  },
  data() {
    return {
      drawing: false,
      utilitySearch: [],
      hasSmoke: false,
      hasWall: false,
      elements: [],
      pt: 0,
      usedUtility: {},
    }
  },
  mounted() {
    let agentList = {}

    this.mapController.mapToolEvents.utilities.forEach(u => {
      let agent = u.match_player_id && this.data.agents[this.data.matchPlayers[u.match_player_id].agent_id]
      if (!(agent.id in agentList)) {
        agentList[agent.id] = agent

        this.usedUtility[agent.id] = {}
        this.usedUtility[agent.id][u.ability_slot] = agent.abilities[u.ability_slot]
        this.usedUtility[agent.id][u.ability_slot].agent = agentList[agent.id].name
      } else if (!(u.ability_slot in this.usedUtility[agent.id])) {
        this.usedUtility[agent.id][u.ability_slot] = agent.abilities[u.ability_slot]
        this.usedUtility[agent.id][u.ability_slot].agent = agentList[agent.id].name
      }
    })

    const svg = document.getElementById('utility-scenario-minimap')
    this.pt = svg.createSVGPoint()
  },
  computed: {
    hasUtilities() {
      return this.mapController.mapToolEvents?.utilities.length > 0
    },
    hasSmokes() {
      return this.mapController.mapToolEvents?.smokes.length > 0
    },
    hasWalls() {
      return this.mapController.mapToolEvents?.walls.length > 0
    },
    utilitySideList() {
      let side = this.state.filters.round.teamRole
      let oppSide = this.state.filters.round.teamRole === 'atk' ? 'def' : 'atk'

      let utility = []

      let listPlayerAgent = makeUnique(Object.values(this.data.matchPlayers), ['player_id', 'agent_id'])

      let agentSides = {}
      listPlayerAgent.forEach(player => {
        if (!(this.data.agents[player.agent_id].id in this.usedUtility)) return
        if (!(this.data.agents[player.agent_id].id in agentSides)) {
          agentSides[this.data.agents[player.agent_id].id] = {}
        }
        let playerSide = this.state.selected.team === player.team_id ? side : oppSide
        agentSides[this.data.agents[player.agent_id].id][playerSide] = true
      })

      Object.entries(this.usedUtility).forEach(([agentId, util]) => {
        Object.values(util).forEach(u => {
          for (let side of Object.keys(agentSides[agentId])) {
            utility.push({
              agent: u.agent,
              utilityName: u.displayName,
              utilityImg: u.displayIcon,
              side: side.toLowerCase(),
              slot: u.slot.toLowerCase(),
            })
          }
        })
      })

      return utility
    },
    canSave() {
      if (this.elements.length === 0) return false
      if (this.utilitySearch.length === 0) return false

      for (let i = 0; i < this.utilitySearch.length; i++) {
        if ('utility' in this.utilitySearch[i] && this.utilitySearch[i].utility === '') return false
      }

      return true
    },
  },
  methods: {
    closeModal() {
      this.elements = []
      this.utilitySearch = []
      this.hasWall = false
      this.hasSmoke = false
      this.$emit('close')
    },
    cursorPointer(e, svg) {
      this.pt.x = e.clientX
      this.pt.y = e.clientY
      return this.pt.matrixTransform(svg.getScreenCTM().inverse())
    },
    handleMouseDown(e) {
      const svg = document.getElementById('utility-scenario-minimap')
      let loc = this.cursorPointer(e, svg)

      if (this.elements.length === 1 && !this.drawing) this.elements = []
      this.elements.push({
        initX: loc.x,
        initY: loc.y,
        currX: loc.x,
        currY: loc.y,
        x: loc.x,
        y: loc.y,
        width: 0,
        height: 0,
      })

      this.drawing = true
    },
    convertBoundingBox(bbox) {
      if (this.data.map.rotate_deg === 0) {
        return [
          [bbox.x, bbox.y].map(p => p / 1024),
          [bbox.x + bbox.width, bbox.y].map(p => p / 1024),
          [bbox.x + bbox.width, bbox.y + bbox.height].map(p => p / 1024),
          [bbox.x, bbox.y + bbox.height].map(p => p / 1024),
        ]
      } else {
        let newX = 1024 - bbox.x
        return [
          [bbox.y, newX - bbox.width].map(p => p / 1024),
          [bbox.y + bbox.height, newX - bbox.width].map(p => p / 1024),
          [bbox.y + bbox.height, newX].map(p => p / 1024),
          [bbox.y, newX].map(p => p / 1024),
        ]
      }
    },
    handleMouseMove(e) {
      if (!this.drawing) return
      const svg = document.getElementById('utility-scenario-minimap')
      let loc = this.cursorPointer(e, svg)

      const ind = this.elements.length - 1
      this.elements[ind].currX = loc.x
      this.elements[ind].currY = loc.y
      this.elements[ind].x = Math.min(this.elements[ind].initX, this.elements[ind].currX)
      this.elements[ind].y = Math.min(this.elements[ind].initY, this.elements[ind].currY)
      this.elements[ind].width = Math.abs(this.elements[ind].currX - this.elements[ind].initX)
      this.elements[ind].height = Math.abs(this.elements[ind].currY - this.elements[ind].initY)
    },
    handleMouseUp() {
      if (!this.drawing) return
      this.drawing = false
    },
    addUtility(type) {
      if (type === 'smoke') {
        this.utilitySearch.push({
          smoke: '',
        })
        this.hasSmoke = true
      } else if (type === 'wall') {
        this.utilitySearch.push({
          wall: '',
        })
        this.hasWall = true
      } else if (type === 'utility') {
        this.utilitySearch.push({
          utility: '',
        })
      }
    },
    deleteUtility(index) {
      if ('smoke' in this.utilitySearch[index]) this.hasSmoke = false
      if ('wall' in this.utilitySearch[index]) this.hasWall = false
      this.utilitySearch.splice(index, 1)
    },
    saveSnapshot() {
      this.state.filters.round.scenario.snapshots.push({
        utility: this.utilitySearch,
        boundingBox: this.elements[0],
        poly: this.convertBoundingBox(this.elements[0]),
        type: 'utility',
      })
      this.closeModal()
    },
  },
}
</script>

<style scoped lang="scss">
.map-modal {
  width: 960px;
  height: 640px;
  display: flex;
  flex-direction: column;
}

.map-content {
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 100%;
}

.settings-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 50%;
}

.utility-button {
  margin-left: 4px;
  margin-right: 4px;
}

.settings-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.normal-button {
  background-color: white;
}

.save-button {
  background-color: white;
}

.delete-button {
  background-color: red;
  color: white;
}

#utility-scenario-minimap {
  align-self: center;
  background-color: #191822;
}

.utility-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 8px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 4px;
}

.smoke-or-wall {
  font-size: 1.125rem;
  line-height: 1.5rem;
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 0.75rem;
}

.role {
  height: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0.75rem;
  color: white;
  width: 56px;
  text-align: center;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}
</style>
