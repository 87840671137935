<template>
  <img class="v3-img" :class="{ gray, grayscale, outline }" draggable="false" :src="src" />
</template>

<script>
export default {
  name: 'V3Image',
  props: {
    gray: { type: Boolean, default: false },
    grayscale: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    src: String,
  },
}
</script>

<style scoped lang="scss">
.v3-img {
  transition: all 0.2s ease-in-out;

  &.gray {
    filter: brightness(0) invert(40%) sepia(5%) saturate(689%) hue-rotate(211deg) brightness(92%) contrast(90%);
  }

  &.grayscale {
    filter: grayscale(1);
  }

  &.outline {
    filter: drop-shadow(0 0 1px white);
  }
}
</style>
