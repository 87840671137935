<template>
  <V3Panel class="au-container" expandable :expanded.sync="expanded" title="Ability usages" variant="secondary">
    <V3Container :visible="expanded" class="au-filter">
      <template v-for="team in teams">
        <div :key="`${team.id}-team`" class="au-team-container">
          <V3Button :active="isTeamActive(team)" class="au-team" @click="toggleTeam(team)">
            <V3TeamLogo class="au-team-logo" :grayscale="!isTeamActive(team)" :src="team.image" />
            <div class="au-team-name">{{ team.name }}</div>
          </V3Button>
          <div class="au-matchPlayer-list">
            <template v-for="matchPlayer in teamMatchPlayers(team)">
              <V3MapFilter
                :active="isMatchPlayerActive(matchPlayer)"
                class="au-matchPlayer-container"
                :key="matchPlayer.id"
                :icon="data.agents[matchPlayer.agent_id].display_icon_small_url"
                :label="data.players[matchPlayer.player_id].name"
                @click="toggleMatchPlayer(matchPlayer)"
              >
                <template v-for="ability in agentAbilities(matchPlayer.agent_id)">
                  <V3Button
                    :active="isAbilityActive(matchPlayer, ability)"
                    :key="ability.slot"
                    class="au-ability"
                    :style="{ order: ability.order }"
                    @click="toggleAbility(matchPlayer, ability)"
                  >
                    <V3Image
                      :alt="ability.displayName"
                      class="au-ability-icon"
                      :gray="!isAbilityActive(matchPlayer, ability)"
                      :src="ability.displayIcon"
                      :title="ability.displayName"
                    />
                  </V3Button>
                </template>
              </V3MapFilter>
            </template>
          </div>
        </div>
      </template>
    </V3Container>
  </V3Panel>
</template>

<script>
import { mapGetters } from 'vuex'

import stringCompare from '../../../../utils/stringCompare.js'
import exposedDataState from '../../mixins/exposedDataState.js'
import V3Button from '../v3dafi/V3Button.vue'
import V3Container from '../v3dafi/V3Container.vue'
import V3Image from '../v3dafi/V3Image.vue'
import V3MapFilter from '../v3dafi/V3MapFilter.vue'
import V3Panel from '../v3dafi/V3Panel.vue'
import V3TeamLogo from '../v3dafi/V3TeamLogo.vue'

export default {
  name: 'AbilitesUsageFilter',
  mixins: [exposedDataState],
  components: {
    V3Image,
    V3MapFilter,
    V3Container,
    V3Panel,
    V3Button,
    V3TeamLogo,
  },
  data() {
    return {
      expanded: true,
    }
  },
  computed: {
    ...mapGetters({
      getAbilityByAgentSlot: 'static/getAbilityByAgentSlot',
    }),
    teams() {
      return [
        this.data.teams[this.selected.team],
        Object.values(this.data.teams).find(team => team.id !== this.selected.team),
      ]
    },
    value: {
      get() {
        return this.state.filters.timeline.abilitiesUsage
      },
      set(val) {
        this.state.filters.timeline.abilitiesUsage = val
      },
    },
  },
  methods: {
    agentAbilities(agent) {
      return Object.freeze(
        [
          'ability1',
          'ability2',
          'grenade',
          'ultimate',
          ...(agent === '41fb69c1-4189-7b37-f117-bcaf1e96f1bf' ? ['passive'] : []),
        ]
          .map(slot => this.getAbilityByAgentSlot(agent, slot))
          .filter(Boolean)
      )
    },
    isAbilityActive(matchPlayer, ability) {
      return !!this.value?.[matchPlayer.id]?.[ability.slot.toLowerCase()]
    },
    isMatchPlayerActive(matchPlayer) {
      return Object.values(this.value?.[matchPlayer.id] || {}).filter(Boolean).length > 0
    },
    isTeamActive(team) {
      return this.teamMatchPlayers(team).some(matchPlayer => this.isMatchPlayerActive(matchPlayer))
    },
    teamMatchPlayers(team) {
      return Object.freeze(
        Object.values(this.data.matchPlayers)
          .filter(matchPlayers => matchPlayers.team_id === team.id)
          .sort(stringCompare(matchPlayer => this.data.agents[matchPlayer.agent_id].name))
      )
    },
    toggleAbility(matchPlayer, ability, newValue = !this.isAbilityActive(matchPlayer, ability)) {
      const value = (this.value = this.value || {})
      this.$set(value, matchPlayer.id, value[matchPlayer.id] || {})
      this.$set(value[matchPlayer.id], ability.slot.toLowerCase(), newValue)
      if (!this.isMatchPlayerActive(matchPlayer)) {
        if (Object.values(value).filter(Boolean).length <= 1) {
          this.value = null
        } else {
          this.$set(value, matchPlayer.id, null)
        }
      }
    },
    toggleMatchPlayer(matchPlayer, newValue = !this.isMatchPlayerActive(matchPlayer)) {
      for (const ability of this.agentAbilities(matchPlayer.agent_id)) {
        this.toggleAbility(matchPlayer, ability, newValue)
      }
    },
    toggleTeam(team) {
      const newValue = !this.isTeamActive(team)
      for (const matchPlayer of this.teamMatchPlayers(team)) {
        this.toggleMatchPlayer(matchPlayer, newValue)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.au-filter {
  ::v-deep {
    .v3-filter-label {
      overflow: hidden;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-container {
        flex-flow: row nowrap;
      }
    }
  }
}

.au-team {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  background-color: #211f2b;
  font-size: 10px;
  color: #898795;
  padding: 0;

  &.active {
    background-color: #141319;
    color: #fefefe;
  }

  &-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 3px;

    & + & {
      margin-top: 0.5rem;
    }
  }
}

.au-matchPlayer-list {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: 0.25rem;
}
</style>
