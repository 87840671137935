<template>
  <div class="ado-container">
    <V3Button
      class="ado-player"
      :class="{ active: filters.display.players && filters.display.players[player.id] }"
      @click="togglePlayer()"
      :disabled="disabled"
    >
      <V3Agent
        :id="agent.id"
        class="ado-player-agent"
        :grayscale="!(filters.display.players && filters.display.players[player.id])"
      />
      <div class="ado-player-name">{{ player.name }}</div>
    </V3Button>
    <div class="ado-ability-container">
      <V3Button
        v-for="ability in abilities"
        class="ado-ability"
        :key="ability.slot"
        @click="toggleAbility(ability)"
        :disabled="disabled"
      >
        <V3Image
          :alt="ability.displayName"
          class="ado-ability-image"
          :class="[
            ability.slot.toLowerCase(),
            {
              active: isAbilitySelected(ability),
            },
          ]"
          :gray="!isAbilitySelected(ability)"
          :src="ability.displayIcon"
          :title="ability.displayName"
        />
      </V3Button>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import V3Agent from './v3dafi/V3Agent.vue'
import V3Button from './v3dafi/V3Button.vue'
import V3Image from './v3dafi/V3Image.vue'

export default {
  name: 'AgentAnalyticsDisplayOptions',
  components: {
    V3Button,
    V3Image,
    V3Agent,
  },
  props: {
    agent: px.object,
    player: px.object,
    abilities: px.object,
    filters: px.object,
    disabled: px.bool.def(false),
  },
  methods: {
    isAbilitySelected(ability) {
      return this.filters.display.gids && this.filters.display.gids[ability.gid]
    },
    togglePlayer() {
      const newSelectedPlayers = {
        ...this.filters.display.players,
        [this.player.id]: !this.filters.display.players?.[this.player.id],
      }
      if (Object.entries(newSelectedPlayers).filter(([, v]) => v).length === 0) {
        this.filters.display.players = null
      } else {
        this.filters.display.players = Object.fromEntries(Object.entries(newSelectedPlayers).filter(([, v]) => v))
      }
    },
    toggleAbility(ability) {
      // const newSelectedAbilities = this.filters.round.abilities ?? {}
      // newSelectedAbilities[ability.abilityHashId] = newSelectedAbilities[ability.abilityHashId] ?? {}

      // const newSelectedAbilityPlayers = newSelectedAbilities[ability.abilityHashId]?.players
      //   ? Object.entries(newSelectedAbilities[ability.abilityHashId]?.players)
      //       ?.filter(([, selected]) => selected)
      //       .map(([id]) => id)
      //   : []

      // if (newSelectedAbilityPlayers.includes(this.player.id)) {
      //   newSelectedAbilityPlayers.splice(newSelectedAbilityPlayers.indexOf(this.player.id), 1)
      // } else {
      //   newSelectedAbilityPlayers.push(this.player.id)
      // }

      // newSelectedAbilities[ability.abilityHashId].players = newSelectedAbilityPlayers.length
      //   ? Object.fromEntries(newSelectedAbilityPlayers.map(id => [id, true]))
      //   : {}

      // if (
      //   Object.entries(newSelectedAbilities).filter(
      //     ([, selectedAbility]) => Object.keys(selectedAbility.players)?.length > 0
      //   ).length === 0
      // ) {
      //   this.filters.round.abilities = null
      // } else {
      //   this.filters.round.abilities = Object.fromEntries(
      //     Object.entries(newSelectedAbilities).filter(
      //       ([, selectedAbility]) => Object.keys(selectedAbility.players)?.length > 0
      //     )
      //   )
      // }

      const newSelectedGids = {
        ...this.filters.display.gids,
        [ability.gid]: !this.filters.display.gids?.[ability.gid],
      }
      if (Object.entries(newSelectedGids).filter(([, v]) => v).length === 0) {
        this.filters.display.gids = null
      } else {
        this.filters.display.gids = Object.fromEntries(Object.entries(newSelectedGids).filter(([, v]) => v))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ado {
  &-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.25rem;
    gap: 0.25rem;
  }

  &-player {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 0.25rem;
    min-width: 0;

    &-agent {
      height: 1.5rem;
    }

    &-name {
      white-space: nowrap;
      text-align: left;
      justify-self: stretch;
      font-size: 11px;
      line-height: 0.75rem;
      max-height: 1.5rem;
      color: #a4a2ad;
      transition: color 0.2s ease-in-out;
    }

    &.active &-name {
      color: #fff;
    }
  }

  &-ability {
    padding: 0;
    align-items: flex-start;

    &-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    &-image {
      height: 1.5rem;

      &.ultimate {
        height: 2rem;
      }
    }
  }
}
</style>
