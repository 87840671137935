<template functional>
  <g :transform="`rotate(${props.rotate || 0} ${props.x * 1024} ${props.y * 1024})`">
    <image
      :class="[data.attrs.class, data.staticClass || '']"
      :x="(props.x - props.size / 2) * 1024"
      :y="(props.y - props.size / 2) * 1024"
      :href="props.imageUrl | resolveUrl"
      :height="props.size * 1024"
      :width="props.size * 1024"
    />
  </g>
</template>

<script>
import resolveUrl from '@/filters/resolveUrl.js'

export default {
  name: 'MapImageEntry',
  props: {
    imageUrl: String,
    rotate: Number,
    x: Number,
    y: Number,
    size: { type: Number, default: 28 / 1024 },
  },
  filters: {
    resolveUrl,
  },
}
</script>

<style lang="scss" scoped>
.map-image-entry {
  position: absolute;
}
</style>
