<template>
  <div class="v3-filter-container">
    <V3Button v-if="label || icon" :active="active" class="v3-filter-label-container" v-on="$listeners">
      <slot name="label">
        <V3Image
          v-if="typeof icon === 'string'"
          class="v3-filter-label-img"
          :grayscale="!active"
          :outline="active && outline"
          :src="icon"
          v-bind="iconAttrs"
        />
        <component v-if="typeof icon === 'object'" class="v3-filter-label-icon" :is="icon" v-bind="iconAttrs" />
        <div class="v3-filter-label">{{ label }}</div>
      </slot>
    </V3Button>
    <V3BtnGroup>
      <slot />
    </V3BtnGroup>
  </div>
</template>

<script>
import V3BtnGroup from './V3BtnGroup.vue'
import V3Button from './V3Button.vue'
import V3Image from './V3Image.vue'
export default {
  name: 'V3MapFilter',
  components: { V3BtnGroup, V3Button, V3Image },
  props: {
    active: Boolean,
    icon: [String, Object],
    iconAttrs: Object,
    label: String,
    outline: { type: Boolean, default: true },
  },
}
</script>

<style lang="scss" scoped>
.v3-filter {
  &-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    max-width: 100%;
    overflow: hidden;
    font-size: 10px;
    gap: 7px;

    .v3-button.active {
      color: #fff;
    }

    & > *:not(.v3-filter-label-container) {
      flex: 1 1 auto;
    }

    ::v-deep {
      .v3-button {
        flex-flow: column nowrap;
        min-height: 2rem;
        border-radius: 3px;
        background-color: #211f2b;
        font-size: 10px;
        color: #898795;
        padding: 0.5rem;
        line-height: 1;
        gap: 3px;
        flex: 1 1 0;

        &.active {
          background-color: #141319;
          color: #fefefe;

          .v3-img,
          svg {
            color: #e03a47;
          }
        }

        .v3-img {
          height: 1rem;
          font-size: 1rem;
          color: inherit;
        }
      }

      .v3-btn-group {
        gap: 3px;
      }
    }
  }

  &-label {
    &-container {
      width: 4rem;
      flex: 0 0 4rem !important;
    }

    &-icon {
      font-size: 1rem;
    }
  }
}
</style>
