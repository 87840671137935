<template>
  <V3Panel class="mb-container" expandable :expanded.sync="expanded" variant="secondary">
    <template #header-content="{ expanded }">
      <div class="mb-header-container" :class="{ expanded }">
        <div v-if="match.event" class="mb-header-event">
          {{ match.event.name }}
        </div>
        <div v-else class="mb-header-date">
          <time :datetime="match.created | isoString">
            <span>{{ match.created | localDate }}</span>
            <span>{{ match.created | localTime }}</span>
          </time>
        </div>
        <V3Image
          v-if="winnerTeam.image"
          class="mb-header-team-logo"
          :gray="!expanded"
          :outline="expanded"
          :src="winnerTeam.image"
        />
        <div v-else class="mb-header-team-name">
          {{ winnerTeam.abbr || winnerTeam.name }}
        </div>
        <div class="mb-header-score">
          <span class="mb-header-score-winner" :class="winnerGrid">
            {{ winnerTeam ? winnerTeam.score : '-' }}
          </span>
          <span class="mb-header-score-sep">/</span>
          <span class="mb-header-score-loser" :class="loserGrid">
            {{ loserTeam ? loserTeam.score : '-' }}
          </span>
        </div>
        <V3Image
          v-if="loserTeam.image"
          class="mb-header-team-logo"
          :gray="!expanded"
          :outline="expanded"
          :src="loserTeam.image"
        />
        <div v-else class="mb-header-team-name">
          {{ loserTeam.abbr || loserTeam.name }}
        </div>
      </div>
    </template>

    <V3Container :visible="expanded">
      <V3Button
        v-for="bookmark in normalizedBookmarks"
        :key="bookmark.id"
        class="mbi-container"
        @click="$emit('select', bookmark.raw)"
      >
        <div class="mbi-left-section">
          <div class="mbi-mark" :class="bookmark.teamRole">
            <IconTriangle />
          </div>
          <V3Image class="mbi-team-logo" outline :src="bookmark.teamLogo" />
        </div>
        <div class="mbi-content">
          <div class="mbi-content-note">
            {{ bookmark.text }}
          </div>
        </div>
        <div v-if="bookmark.icon" class="mbi-icon">
          <component v-if="bookmark.icon.icon" :is="bookmark.icon.icon" />
          <img v-else class="bookmark-icon" :src="bookmark.icon.asset" />
          <!-- TODO: display appropriate icon -->
        </div>
        <V3Image class="mbi-image" :src="bookmark.preview || '/images/sample-bookmark-screenshot.png'" />
      </V3Button>
    </V3Container>
  </V3Panel>
</template>

<script>
import px from 'vue-types'

import exposedDataState from '@/components/Map/mixins/exposedDataState'
import { BOOKMARK_ICONS } from '@/components/maptool2/consts'
import IconTriangle from '@/components/UI/IconTriangle.vue'
import isoString from '@/filters/isoString'
import localDate from '@/filters/localDate'
import localTime from '@/filters/localTime'

import formatTime from '../utils/formatTime.js'

import V3Button from './v3dafi/V3Button.vue'
import V3Container from './v3dafi/V3Container.vue'
import V3Image from './v3dafi/V3Image.vue'
import V3Panel from './v3dafi/V3Panel.vue'

export default {
  name: 'MapBookmarks',
  mixins: [exposedDataState],
  components: {
    IconTriangle,
    V3Container,
    V3Button,
    V3Image,
    V3Panel,
  },
  props: {
    bookmarks: px.array.def([]),
    match: Object,
  },
  data() {
    return {
      expanded: true,
    }
  },
  computed: {
    loserGrid() {
      return this.loserTeam?.grid.toLowerCase()
    },
    loserTeam() {
      const team =
        this.matchTeams.find(team => this.data.teams[team.team_id].is_self_team === false) ||
        this.matchTeams.find(team => !team.win)
      return Object.freeze({ ...this.data.teams[team?.team_id], ...team })
    },
    matchTeams() {
      return Object.freeze(
        this.match?.match_teams.map(matchTeamId => this.data.matchTeams?.[matchTeamId]).filter(Boolean) || []
      )
    },
    singleMatch() {
      return this.match
    },
    winnerGrid() {
      return this.winnerTeam?.grid.toLowerCase()
    },
    winnerTeam() {
      const team = this.matchTeams.find(team => team.id !== this.loserTeam?.id)

      return Object.freeze({ ...this.data.teams[team?.team_id], ...team })
    },
    normalizedBookmarks() {
      return Object.freeze(
        this.bookmarks
          .filter(bookmark => bookmark.match === this.match.id)
          .map(bookmark => {
            const state = bookmark.state.exposedState[bookmark.state.exposedState.mapMode]

            return {
              id: bookmark.id,
              teamRole: state.filters.round.teamRole,
              teamLogo: this.data.teams[state.selected.team].image,
              text: bookmark.text,
              preview: bookmark.state.preview,
              icon: (bookmark.state.icon && BOOKMARK_ICONS[bookmark.state.icon]) || null,
              raw: bookmark,
            }
          })
      )
    },
  },
  filters: {
    localDate,
    localTime,
    isoString,
  },
  methods: {
    onNoteClick(note) {
      this.$emit('select', note)
    },
    displayTime(time) {
      return formatTime(100000 - time)
    },
    getIcon(note) {
      return (note.state.icon && BOOKMARK_ICONS[note.state.icon]) || null
    },
  },
}
</script>

<style lang="scss" scoped>
.mb {
  &-container {
    ::v-deep {
      .v3-button {
        background: linear-gradient(90deg, #333043 0%, #333043 1%, #252432 100%);
        height: auto;
        transition: background 0.2s ease-in-out;
        gap: 0.5rem;
        padding: 0.75rem 0.375rem;

        &.expanded {
          background: #141319;
        }
      }
    }
  }

  &-header {
    &-container {
      display: flex;
      flex-flow: row nowrap;
      flex: 1 1 100%;
      overflow: hidden;
      align-items: center;
      font-size: 10px;
      color: #8e8b9a;
      transition: color 0.2s ease-in-out;
      gap: 0.25rem;

      &.expanded {
        color: #fff;

        .mb-header {
          &-score {
            &-winner,
            &-loser {
              &.red {
                color: #d73e4a;
              }

              &.sep {
                color: #9795a1;
              }

              &.blue {
                color: #099b83;
              }
            }
          }
        }
      }
    }

    &-date {
      time {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
    }

    &-event {
      line-height: 1.25;
      max-height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-transform: uppercase;
      text-align: left;
      margin-right: 0.25rem;
    }

    &-team-logo {
      height: 1.5rem;
    }

    &-score {
      font-size: 12px;
      white-space: nowrap;

      &-winner,
      &-loser {
        color: #8e8b9a;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}

.mbi {
  &-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    font-size: 11px;
    color: #8e8b9a;
    text-align: left;

    &:nth-of-type(2n) {
      background: #191822;
    }

    &:nth-of-type(2n + 1) {
      background: #141319;
    }

    &:hover {
      background: #2f2c3b;
      color: #fff;
    }
  }

  &-left-section {
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &-mark {
    font-size: 0.5rem;
    transform: rotate(-90deg);

    &.atk {
      color: #d73e4a;
    }

    &.def {
      color: #099b83;
    }
  }

  &-team-logo {
    height: 0.75rem;
  }

  &-icon {
    font-size: 1rem;
    color: #a4a2ad;
  }

  &-image {
    width: 3.5rem;
    height: 3.5rem;
    object-fit: cover;
  }
}

.note-item {
  line-height: 32px;
  background-color: $gray-900;
  padding-left: 0.5em;
  cursor: pointer;

  .timestamp {
    line-height: 30px;
    margin-left: -0.4em;
    padding: 0 2px;
  }

  .note-text {
    margin-left: 1em;
  }

  &:nth-of-type(odd) {
    background-color: rgba(255 255 255 / 5%);
  }

  &:hover {
    background: lighten(#234, 10%);
  }
}

.bookmark-icon {
  filter: grayscale(1);
  width: 1em;
  height: 1em;
  font-size: 1rem;
}
</style>
