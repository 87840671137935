<template>
  <V3Panel>
    <div class="xvy-container">
      <div class="selected-team">
        Team: {{ this.data.teams[this.selected.team].name }} -
        <span :class="[this.state.filters.round.teamRole]" class="role">{{ this.state.filters.round.teamRole }}</span>
      </div>
      <template v-for="(round, idx) in this.rounds">
        <div :key="idx">
          <div>
            <div class="header">
              Round {{ round.number }} -
              <span class="outcome" :class="[round.winner.role]">
                <component :is="icon(round.outcome)"></component>
                {{ round.plant_site }}
              </span>
            </div>
          </div>

          <div class="round">
            <template v-for="(state, id) in round.round_player_count">
              <div :key="id" class="state" :class="state.status" @click="$emit('select', state)">
                <img v-if="state.planted" src="/images/spike/spike-minimap.png" />
                {{ state.xvy }}
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </V3Panel>
</template>

<script>
import px from 'vue-types'

import { pxRoundData, pxRoundTeam, pxTeam } from '@/components/Map/types'
import { OUTCOME } from '@/components/maptool2/consts'

import V3Panel from './v3dafi/V3Panel.vue'

export default {
  name: 'MapToolRoundXvYSelector',
  components: { V3Panel },
  props: {
    // currentTime: Number,
    data: px.shape({
      rounds: px.objectOf(pxRoundData()).isRequired,
      roundTeams: px.objectOf(pxRoundTeam()).isRequired,
      teams: px.objectOf(pxTeam()).isRequired,
    }).loose.isRequired,
    selected: Object,
    state: Object,
  },
  computed: {
    rounds() {
      return Object.values(this.data.rounds)
        .map(({ id, round_num: round_number, round_teams, outcome, plant_site, round_player_count }) => ({
          id,
          number: round_number + 1,
          outcome,
          plant_site,
          loser: this.getTeam(this.getLoser(round_teams)),
          winner: this.getTeam(this.getWinner(round_teams)),
          selected: this.getTeam(this.getSelectedTeam(round_teams)),
          round_player_count: this.getStateCondition(round_player_count),
        }))
        .filter(round => round.selected.role === this.state.filters.round.teamRole)
    },
  },
  methods: {
    icon(outcome) {
      return OUTCOME[outcome]?.icon || 'span'
    },
    getTeam(roundTeamId) {
      return {
        id: this.data.roundTeams[roundTeamId].team_id,
        role: this.data.roundTeams[roundTeamId].role,
        grid: this.data.roundTeams[roundTeamId].grid,
      }
    },
    getSelectedTeam(roundTeams) {
      return roundTeams.find(roundTeamId => this.data.roundTeams[roundTeamId].team_id === this.selected.team)
    },
    getLoser(roundTeams) {
      return roundTeams.find(roundTeamId => !this.data.roundTeams[roundTeamId].win)
    },
    getWinner(roundTeams) {
      const loserId = this.getLoser(roundTeams)
      return roundTeams.find(roundTeamId => roundTeamId !== loserId)
    },
    getStateCondition(roundStates) {
      const selectedTeamId = this.selected.team
      let states = []
      roundStates.forEach(round => {
        let currState = {
          xvy: '',
          round_id: round.round_id,
          team_alive: 0,
          opp_alive: 0,
          round_time_millis: round.state_start,
          planted: round.planted,
          status: '',
        }
        Object.entries(round.state).forEach(([teamId, state]) => {
          if (this.data.roundTeams[teamId].team_id === selectedTeamId) {
            currState.team_alive = state.count
          } else {
            currState.opp_alive = state.count
          }
        })
        currState.xvy = `${currState.team_alive}v${currState.opp_alive}`
        if (currState.team_alive === currState.opp_alive) {
          currState.status = 'equal'
        } else if (currState.team_alive < currState.opp_alive) {
          currState.status = 'unfavorable'
        } else {
          currState.status = 'favorable'
        }
        states.push(currState)
      })
      return states
    },
  },
}
</script>

<style scoped lang="scss">
.xvy-container {
  margin-bottom: 40px;
}

.selected-team {
  margin-bottom: 10px;
  background-color: #a4a2ad;
  padding: 5px;
  color: black;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}

.role {
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0.75rem;
  color: white;
}

.outcome {
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0.75rem;
}

.round {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 10px;
  margin-bottom: 10px;
}

.equal {
  background-color: #1e3a8a;
}

.favorable {
  background-color: #064e3b;
}

.unfavorable {
  background-color: #881337;
}

.state {
  margin-right: 5px;
  height: 25px;
  display: flex;
  border-radius: 0.125rem;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
</style>
