<template>
  <div class="map-filters-container">
    <div class="team-select">
      <TeamSelect />
    </div>
    <V3MapFilter>
      <V3Button :active="isFilterActive" class="clear-filters" @click="clearFilters">
        <IconArrowRepeat />
        Clear all filters
      </V3Button>
    </V3MapFilter>

    <V3ScrollableContainer class="map-filters" vertical>
      <V3Panel title="Scenario Search" expandable :expanded.sync="scenarioExpanded">
        <V3Container :visible="scenarioExpanded">
          <V3BtnGroup>
            <V3Button
              :active="true"
              @click="$emit('new-player-snapshot')"
              style="background-color: #191822; color: white; width: 75%; align-self: center; margin: 4px"
            >
              Add Player Snapshot
            </V3Button>
            <V3Button
              v-if="hasUtilitySmokeWall"
              :active="true"
              @click="$emit('new-util-snapshot')"
              style="background-color: #191822; color: white; width: 75%; align-self: center; margin: 4px"
            >
              Add Utility Snapshot
            </V3Button>
          </V3BtnGroup>

          <div class="snapshot-container">
            <template v-for="(snapshot, idx) in state.filters.round.scenario.snapshots">
              <div v-if="snapshot.type === 'player'" :key="idx" class="snapshot">
                <template v-for="(plr, i) in snapshot.players">
                  <div :class="plr.player.side" :key="plr.player.id + i">
                    <img :src="plr.player.agentImg" :alt="plr.player.agent" class="agent-icon" />
                  </div>
                </template>
                <V3Button @click="deleteSnapshot(idx)" style="color: red; margin-left: auto; margin-right: 16px"
                  >Delete</V3Button
                >
              </div>

              <div v-else-if="snapshot.type === 'utility'" :key="idx" class="snapshot">
                <template v-for="(util, i) in snapshot.utility">
                  <div
                    v-if="'smoke' in util"
                    :key="'smoke' + i"
                    style="background-color: black; padding-left: 8px; padding-right: 8px; border-radius: 0.75rem"
                  >
                    S
                  </div>
                  <div
                    v-if="'wall' in util"
                    :key="'wall' + i"
                    style="background-color: black; padding-left: 8px; padding-right: 8px; border-radius: 0.75rem"
                  >
                    W
                  </div>
                  <div v-if="'utility' in util" :key="'utility' + i">
                    <div :class="util.utility.side" :key="util.utility.utilityName + i">
                      <img :src="util.utility.utilityImg" :alt="util.utility.utilityName" class="agent-icon" />
                    </div>
                  </div>
                </template>
                <V3Button @click="deleteSnapshot(idx)" style="color: red; margin-left: auto; margin-right: 16px"
                  >Delete</V3Button
                >
              </div>
            </template>
          </div>
        </V3Container>
      </V3Panel>

      <V3Panel v-if="!isScrim" title="Preset Round Filters" expandable :expanded.sync="presetExpanded">
        <V3Container :visible="presetExpanded">
          <template v-for="(preset, i) in this.presetFilters">
            <div v-if="checkPreset(preset.filter)" class="preset" :key="'preset' + i">
              <input
                v-if="renamePreset === i"
                type="text"
                v-model="preset.name"
                style="width: 60%; font-size: 0.75rem; line-height: 1rem"
              />
              <V3Button
                v-else
                style="
                  width: 60%;
                  background-color: #191822;
                  color: white;
                  padding: 4px;
                  font-size: 0.75rem;
                  line-height: 1rem;
                "
                @click="loadPreset(preset.filter)"
                >{{ preset.name }}</V3Button
              >

              <V3BtnGroup style="margin-left: auto">
                <V3Button v-if="renamePreset === false" @click="renamePreset = i">Rename</V3Button>

                <V3Button
                  v-if="renamePreset === i"
                  @click="savePreset()"
                  :disabled="!preset.name.length"
                  :active="!preset.name.length"
                  >Save</V3Button
                >

                <V3Button v-if="renamePreset === false" @click="deletePreset(i)" style="color: red; margin-right: 16px"
                  >Delete</V3Button
                >
              </V3BtnGroup>
            </div>
          </template>
        </V3Container>
      </V3Panel>

      <V3Panel
        class="map-filters-list"
        :icon="$options.components.IconTarget"
        title="Rounds"
        expandable
        :expanded.sync="roundsExpanded"
      >
        <V3Container :visible="roundsExpanded">
          <HalvesFilter v-model="state.filters.round.half" :data="data" />

          <FKDFilter v-model="state.filters.round.fkd" :data="data" />

          <BombsiteSelector
            v-if="hasPlants"
            :bombsites-count="data.map.bombsites.length"
            v-model="state.filters.round.site"
          />

          <RoundOutcomeFilter
            :data="data"
            :selected="state.selected"
            v-model="state.filters.round.outcome"
            v-if="hasOutcome"
          />

          <RoundWinFilter v-if="hasWins" :data="data" :selected="state.selected" v-model="state.filters.round.win" />

          <TeamEcoFilter
            v-if="hasEconomy"
            :data="data"
            :is-scrim="isScrim"
            :selected="state.filters.round"
            :selected-team-id="state.selected.team"
          />

          <OperatorFilter :data="data" :selected="state.selected" v-model="state.filters.round.operator" />

          <TradesFilter :data="data" :selected="state.selected" v-model="state.filters.round.trades" />

          <OrbsFilter
            v-if="hasOrbs"
            :data="data"
            :selected-team-id="state.selected.team"
            :v-model="state.filters.round.orbs"
          />
          <V3BtnGroup>
            <V3Button
              :active="true"
              @click="addPreset"
              style="background-color: #191822; color: white; width: 75%; align-self: center; margin: 4px"
            >
              Save as Preset Round Filter
            </V3Button>
          </V3BtnGroup>
        </V3Container>
      </V3Panel>

      <V3Panel
        class="map-filters-list"
        :icon="$options.components.IconTimelineEvent"
        expandable
        :expanded.sync="eventsExpanded"
        title="Time/Event"
      >
        <V3Container :visible="eventsExpanded">
          <WeaponAbilitySelector :data="data" v-model="state.filters.timeline.killWeapons" />

          <AbilitiesUsageFilter v-if="hasAbilitiesUsage" />

          <FilterContainer v-if="false" label="Positions">
            <PositionsFilter :value="state.selected.positions" @change="update('positions', $event)" />
          </FilterContainer>
        </V3Container>
      </V3Panel>

      <FilterContainer v-if="false" class="section-container" label="Display -> Move to left sidebar">
        <FilterContainer label="Kills">
          <KillsFilter :selected="state.selected" @update:selected="$emit('update:selected', $event)" />
        </FilterContainer>

        <FilterContainer v-if="hasZones" label="Zones">
          <ZoneFilter :data="data" :selected="state.selected" @update:selected="$emit('update:selected', $event)" />
        </FilterContainer>
      </FilterContainer>
    </V3ScrollableContainer>
  </div>
</template>

<script>
import px from 'vue-types'

import { submitPersistentData, getPersistentData } from '@/api/persistentData.js'
import OrbsFilter from '@/components/Map/components/filters/OrbsFilter.vue'
import TeamSelect from '@/components/Map/components/filters/TeamSelect.vue'
import V3Container from '@/components/Map/components/v3dafi/V3Container.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import IconTarget from '@/components/UI/IconTarget.vue'
import mixpanel from '@/mixpanel.js'

import IconArrowRepeat from '../../UI/IconArrowRepeat.vue'
import IconTimelineEvent from '../../UI/IconTimelineEvent.vue'

import AbilitiesUsageFilter from './filters/AbilitiesUsageFilter.vue'
import BombsiteSelector from './filters/BombsiteSelector.vue'
import FilterContainer from './filters/FilterContainer.vue'
import FKDFilter from './filters/FKDFilter.vue'
import HalvesFilter from './filters/HalvesFilter.vue'
import KillsFilter from './filters/KillsFilter.vue'
import OperatorFilter from './filters/OperatorFilter.vue'
import PositionsFilter from './filters/PositionsFilter.vue'
import RoundOutcomeFilter from './filters/RoundOutcomeFilter.vue'
import RoundWinFilter from './filters/RoundWinFilter.vue'
import TeamEcoFilter from './filters/TeamEcoFilter.vue'
import TradesFilter from './filters/TradesFilter.vue'
import WeaponAbilitySelector from './filters/WeaponAbilityFilter.vue'
import ZoneFilter from './filters/ZoneFilter.vue'
import V3BtnGroup from './v3dafi/V3BtnGroup.vue'
import V3Button from './v3dafi/V3Button.vue'
import V3MapFilter from './v3dafi/V3MapFilter.vue'
import V3Panel from './v3dafi/V3Panel.vue'
import V3ScrollableContainer from './v3dafi/V3ScrollableContainer.vue'

export default {
  name: 'MapFilters',
  mixins: [exposedDataState],
  components: {
    V3Container,
    V3MapFilter,
    IconArrowRepeat,
    V3Button,
    V3BtnGroup,
    // eslint-disable-next-line vue/no-unused-components
    IconTarget,
    V3ScrollableContainer,
    TeamEcoFilter,
    ZoneFilter,
    TeamSelect,
    FilterContainer,
    OrbsFilter,
    RoundWinFilter,
    RoundOutcomeFilter,
    PositionsFilter,
    KillsFilter,
    BombsiteSelector,
    WeaponAbilitySelector,
    FKDFilter,
    HalvesFilter,
    TradesFilter,
    OperatorFilter,
    AbilitiesUsageFilter,
    V3Panel,
    // eslint-disable-next-line vue/no-unused-components
    IconTimelineEvent,
  },
  props: {
    hasEconomy: px.bool.def(false),
    hasOrbs: px.bool.def(false),
    hasOutcome: px.bool.def(false),
    hasPlants: px.bool.def(false),
    hasWins: px.bool.def(false),
    isScrim: px.bool.def(false),
    hasAbilitiesUsage: px.bool.def(false),
  },
  data() {
    return {
      eventsExpanded: false,
      roundsExpanded: true,
      scenarioExpanded: true,
      presetExpanded: true,
      presetFilters: [],
      renamePreset: false,
    }
  },
  async mounted() {
    if (!this.isScrim) {
      let persitentData = await getPersistentData()
      this.presetFilters = 'presetFilters' in persitentData ? persitentData.presetFilters : []
    }
  },
  computed: {
    isFilterActive() {
      return this.exposed.isRoundFilterActive || this.exposed.isTimelineFilterActive
    },
    ecoOpponentsFilterSuffix() {
      if (Object.keys(this.data.teams).length > 2) {
        return 'opponents'
      } else {
        return Object.values(this.data.teams).find(team => team.id !== this.state.selected.team)?.name
      }
    },
    hasZones() {
      return this.data.zones && Object.keys(this.data.zones).length > 0
    },
    hasUtilitySmokeWall() {
      let utility = { smoke: false, wall: false, utility: false }
      if (this.mapController.mapToolEvents?.smokes.length > 0) utility.smoke = true
      if (this.mapController.mapToolEvents?.walls.length > 0) utility.wall = true
      if (this.mapController.mapToolEvents?.utilities.length > 0) utility.utility = true

      if (!utility.smoke && !utility.wall && !utility.utility) return false
      return true
    },
  },
  methods: {
    clearFilters() {
      this.exposed.clearRoundFilters()
      this.exposed.clearTimelineFilters()
    },
    update(key, value) {
      this.state.selected[key] = value
      this.$emit('update:selected', this.selected)

      mixpanel.track_2d_map_filter(this.format_selected(key), value)
    },
    format_selected(key) {
      // TODO refactor filter labels
      switch (key) {
        case 'agents':
          return 'Agents'
        case 'damage':
          return 'Damage'
        case 'eco':
          return 'ECO'
        case 'kills':
          return 'Kills'
        case 'outcome':
          return 'OUTCOME'
        case 'players':
          return 'Players'
        case 'positions':
          return 'Positions'
        case 'rounds':
          return 'Rounds'
        case 'site':
          return 'BOMBSITE'
        case 'team':
          return 'Team'
        case 'teamRole':
          return 'TEAM ROLE'
        case 'time':
          return 'time'
        case 'win':
          return 'WIN/LOSS'
        default:
          return key
      }
    },
    deleteSnapshot(index) {
      this.state.filters.round.scenario.snapshots.splice(index, 1)
    },
    addPreset() {
      this.presetFilters.push({
        name: 'New Preset',
        filter: JSON.parse(JSON.stringify(this.state.filters.round)),
      })
      this.savePreset('addOrDelete')
    },
    loadPreset(preset) {
      this.state.filters.round.half = preset.half
      this.state.filters.round.site = preset.site
      this.state.filters.round.outcome = preset.outcome
      this.state.filters.round.win = preset.win
      this.state.filters.round.eco = preset.eco
      this.state.filters.round.ecoOpponents = preset.ecoOpponents
      this.state.filters.round.operator = preset.operator
      this.state.filters.round.trades = preset.trades
    },
    checkPreset(preset) {
      if (preset.site === 'C' && this.data.map.bombsites.length < 3) return false
      return true
    },
    savePreset(fromButton = 'save') {
      if (fromButton === 'save') {
        this.renamePreset = false
        submitPersistentData({ body: { presetFilters: this.presetFilters } })
      } else if (fromButton === 'addOrDelete') {
        submitPersistentData({ body: { presetFilters: this.presetFilters } })
      }
    },
    deletePreset(index) {
      this.presetFilters.splice(index, 1)
      this.savePreset('addOrDelete')
    },
  },
}
</script>

<style lang="scss" scoped>
.map-filters {
  margin: 0;
  min-height: 0;
  flex: 1 1 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  background-color: #2f2c3b;

  &-container {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    gap: 0.5rem;
    width: 100%;

    & > *:not(.map-filters) {
      flex: 0 0 auto;
    }
  }

  &-list {
    position: relative;
    background: #2f2c3b;
    filter: drop-shadow(0 3px 8px rgb(11 16 20 / 69%));

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.2;
      background-color: #181721;
    }

    ::v-deep & > .v3p-content {
      padding: 0.25rem;
    }
  }

  ::v-deep {
    .v3p-content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      gap: 4px;
      background: transparent;
      filter: none;
    }
  }
}

.team-select {
  background: $body-bg;
}

.filter-container {
  &.section-container > ::v-deep summary {
    position: sticky;
    top: 0;
    background: $body-bg;

    // need to be bellow dropdown due to team selector
    z-index: ($zindex-dropdown - 1);
  }

  .filter-container {
    padding-inline: 1rem;
  }
}

.map-filters-container {
  ::v-deep {
  }
}

.v3-filter-container {
  .clear-filters {
    flex-flow: row nowrap;
    gap: 0.5rem;
    min-height: 44px;

    svg {
      font-size: 1rem;
      transform: rotate(90deg);
    }
  }
}

.snapshot-container {
  min-height: 24px;
}

.snapshot {
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 8px;
  align-items: center;
}

.preset {
  margin-left: 8px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.agent-icon {
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  margin: 2px;
}

.def {
  border-radius: 9999px;
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
  border-radius: 9999px;
}

::v-deep {
  .v3-container-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 0.25rem;
  }
}
</style>
