import { render, staticRenderFns } from "./TeamSelect.vue?vue&type=template&id=4035ae06&scoped=true&"
import script from "./TeamSelect.vue?vue&type=script&lang=js&"
export * from "./TeamSelect.vue?vue&type=script&lang=js&"
import style0 from "./TeamSelect.vue?vue&type=style&index=0&id=4035ae06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4035ae06",
  null
  
)

export default component.exports