<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MapInjector',
  props: {
    exposed: Object,
    mapController: Object,
  },
  provide() {
    return {
      exposed: this.exposed,
      mapController: this.mapController,
    }
  },
}
</script>
