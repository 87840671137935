<template>
  <form @submit.prevent="submit" class="add-note">
    <b-form-group label="Comment">
      <b-form-input ref="label" autofocus required v-model="note.text" />
    </b-form-group>
    <V3BtnGroup>
      <V3Button
        v-for="(option, key) in iconOptions"
        :key="key"
        :active="note.icon === option.value"
        @click="toggleIcon(option.value)"
      >
        <component v-if="option.icon" :is="option.icon" />
        <img v-else class="bookmark-icon" :src="option.asset" width="1em" height="1em" />
      </V3Button>
    </V3BtnGroup>
    <b-form-group label="Tags" v-if="false">
      <TagsDropdown :options="noteTags" :selected.sync="note.tags" />
    </b-form-group>
    <b-form-checkbox v-if="mapMode === 'replay2d'" v-model="note.startPlaying" switch>Start playing</b-form-checkbox>

    <V3BtnGroup>
      <V3Button @click="$listeners.cancel">
        <b-icon-x />
        Cancel
      </V3Button>
      <V3Button type="submit" :disabled="!note.text">
        <b-icon-journal-plus />
        Save
      </V3Button>
    </V3BtnGroup>
  </form>
</template>

<script>
import { BIconJournalPlus, BIconX } from 'bootstrap-vue'
import px from 'vue-types'

import TagsDropdown from '@/components/Form/TagsDropdown.vue'
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import formatTime from '@/components/Map/utils/formatTime'
import { BOOKMARK_ICONS } from '@/components/maptool2/consts'

export default {
  name: 'CreateBookmarkForm',
  mixins: [exposedDataState],
  components: { V3Button, V3BtnGroup, BIconJournalPlus, BIconX, TagsDropdown },
  props: {
    noteTags: px.array,
  },
  data() {
    return {
      note: {
        text: '',
        tags: [],
        icon: null,
        startPlaying: false,
      },
    }
  },
  computed: {
    iconOptions() {
      return Object.entries(BOOKMARK_ICONS).map(([value, op]) => ({
        value,
        text: op.label,
        icon: op.icon,
        asset: op.asset,
      }))
    },
    selectedRounds() {
      return (
        (this.selected.rounds && Object.values(this.data.rounds).filter(({ id }) => this.selected.rounds[id])) || []
      )
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const ecoFilters = [this.state.filters.round.eco, this.state.filters.round.ecoOpponents].map(list =>
        list?.join(',')
      )
      const eco = [ecoFilters[0], ecoFilters[1] ? 'vs' : '', ecoFilters[1]].filter(Boolean).join(' ')

      this.note.text = [
        this.selectedRounds.length > 0
          ? `R${this.selectedRounds.map(({ round_num }) => `${round_num + 1}`).join(',')}`
          : [
              this.state.filters.round.win != null ? (this.state.filters.round.win ? 'Won' : 'Lost') : null,
              this.state.filters.round.site,
              this.state.filters.round.outcome,
              eco,
            ],
        this.selected.time &&
          `${this.state.selected.alignRounds} ${formatTime(this.selected.time.start_time_millis)}-${formatTime(
            this.selected.time.end_time_millis
          )}`,
      ]
        .flat()
        .filter(Boolean)
        .join(' ')
      this.note.icon =
        this.selectedRounds.length === 1
          ? this.selectedRounds[0].outcome
          : this.state.filters.round.site
          ? 'Plant'
          : null
      this.note.startPlaying = Boolean(this.mapMode === 'replay2d' && this.selected.time)
      this.$refs.label.focus()
    },
    submit() {
      this.note.tags = this.noteTags?.length > 0 ? [this.noteTags[0]] : []
      if (this.mapMode === 'analytics') {
        this.note.startPlaying = false
      }
      this.$emit('submit', this.note)
    },
    toggleIcon(val) {
      this.note.icon === val ? (this.note.icon = null) : (this.note.icon = val)
    },
  },
}
</script>

<style scoped lang="scss">
.add-note {
  display: flex;
  flex-flow: column nowrap;
  padding: 0.25rem;
  overflow: hidden;
  font-size: 11px;

  .v3-button {
    gap: 0.25rem;

    svg {
      font-size: 1rem;
    }
  }

  .active .bookmark-icon {
    filter: brightness(0) invert(1);
  }

  .bookmark-icon {
    filter: grayscale(1);
    width: 1em;
    height: 1em;
    font-size: 1.25rem;
  }
}
</style>
