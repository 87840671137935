<template>
  <V3Panel>
    <template #header>
      <div class="trs-team">
        <TeamLogo class="trs-team-logo" :src="team.logo_url" />
        <span class="trs-team-name">{{ team.name }}</span>
        <span class="trs-team-abbr" v-if="data.teams[team.id].abbr"> - {{ data.teams[team.id].abbr }}</span>
      </div>
    </template>

    <div class="trs-table">
      <div class="trs-table-header"></div>
      <div class="trs-col-head trs-col-player">PLR</div>
      <div class="trs-col-head trs-col-ult">ULT</div>
      <div class="trs-col-head trs-col-armor">A</div>
      <div class="trs-col-head trs-col-weapon">W</div>
      <div class="trs-col-head trs-col-load">LOAD<br />(S/R)</div>
      <div class="trs-col-head trs-col-kda">KDA</div>
      <template v-for="(player, idx) in sortedPlayers">
        <div :key="`bg${idx}`" class="trs-table-row" :class="`r${idx}`"></div>

        <div :key="`player${idx}`" class="trs-cell trs-cell-player" :class="`r${idx}`">
          <img class="trs-cell-player-icon" :src="player.agent_icon_url" />
          <span class="trs-cell-player-name">{{ player.name }}</span>
        </div>

        <div :key="`ult${idx}`" class="trs-cell trs-cell-ult" :class="`r${idx}`">
          <div v-if="player.ultimates && player.ultimates.count !== null">
            <img
              v-if="player.ultimates.count !== null && player.ultimates.count === player.ultimates.max"
              class="trs-cell-ult-icon"
              :src="ultimateImageUrl(player.agent_name)"
              alt="READY"
            />
            <span v-else class="trs-cell-ult-label">{{ player.ultimates.count }}/{{ player.ultimates.max }}</span>
          </div>
          <span v-else class="trs-cell-ult-label">N/A</span>
        </div>

        <div :key="`armor${idx}`" class="trs-cell trs-cell-armor" :class="`r${idx}`">
          <img v-if="player.armor_url" class="trs-cell-armor-icon" :src="player.armor_url" :alt="player.armor_name" />
          <span v-else class="trs-cell-armor-label">-</span>
        </div>

        <div :key="`weapon${idx}`" class="trs-cell trs-cell-weapon" :class="`r${idx}`">
          <img
            v-if="player.weapon_name"
            class="trs-cell-weapon-icon"
            :src="weaponsByName[player.weapon_name].kill_stream_icon_url"
            :alt="weaponsByName[player.weapon_name].name"
          />
          <span v-else class="trs-cell-weapon-label">-</span>
        </div>

        <div :key="`load${idx}`" class="trs-cell trs-cell-load" :class="`r${idx}`">
          <div class="trs-cell-load-label">{{ player.loadout | to_credits }}</div>
          <div class="trs-cell-load-label2">{{ player.spent | to_credits }}/{{ player.remaining | to_credits }}</div>
        </div>

        <div :key="`kda${idx}`" class="trs-cell trs-cell-kda" :class="`r${idx}`">
          {{ player.kills }}/{{ player.deaths }}/{{ player.assists }}
        </div>
      </template>
    </div>
  </V3Panel>
</template>

<script>
import { mapGetters } from 'vuex'

import stringCompare from '../../../../utils/stringCompare.js'

import V3Panel from './V3Panel.vue'
import TeamLogo from './V3TeamLogo.vue'

export default {
  name: 'TeamRoundStats',
  components: { V3Panel, TeamLogo },
  props: {
    data: Object,
    team: Object,
  },
  computed: {
    ...mapGetters({
      getAgentByName: 'static/getAgentByName',
      weaponsByName: 'static/weaponsByName',
    }),
    sortedPlayers() {
      return [...this.team.players].sort(stringCompare(player => player.agent_name))
    },
  },
  methods: {
    ultimateImageUrl(agentName) {
      return this.getAgentByName(agentName).abilities.find(ab => ab.slot === 'Ultimate').displayIcon
    },
  },
}
</script>

<style scoped lang="scss">
.trs {
  &-container {
    align-self: stretch;
  }

  &-table-header {
    background-color: #141319;
  }

  &-team {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25rem;

    &-logo {
      display: inline-block;
      margin-right: 0.5rem;
    }

    &-name {
      color: #fff;
      text-transform: uppercase;
    }

    &-abbr {
      color: #a4a2ad;
      text-transform: uppercase;
    }
  }

  &-table {
    display: grid;
    padding: 0 0.25rem;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 60fr) minmax(0, 20fr) minmax(0, 15fr) minmax(0, 50fr) minmax(0, 40fr) minmax(
        0,
        30fr
      );
    column-gap: 0.25rem;
    font-size: 0.625rem;
    line-height: 1;
    align-items: center;
    justify-content: center;

    &-header {
      margin: 0 -0.25rem;
      grid-column: 1 / -1;
      grid-row: 1;
      align-self: stretch;
      justify-self: stretch;
    }

    &-row {
      margin: 0 -0.25rem;
      grid-column: 1 / -1;
      align-self: stretch;
      justify-self: stretch;

      &.r0,
      &.r2,
      &.r4 {
        background-image: linear-gradient(90deg, #312f3d 0%, #2f2c3b 100%);
      }

      &.r1,
      &.r3 {
        background-image: linear-gradient(90deg, #2a2836 0%, #2a2836 1%, #1c1a27 100%);
      }
    }

    .r0 {
      grid-row: 2;
    }

    .r1 {
      grid-row: 3;
    }

    .r2 {
      grid-row: 4;
    }

    .r3 {
      grid-row: 5;
    }

    .r4 {
      grid-row: 6;
    }
  }

  &-col {
    &-head {
      grid-row: 1;
      text-align: center;
      padding: 0.5rem 0;
      color: #c47d00;
      text-transform: uppercase;
    }

    &-player {
      grid-column: 1;
    }

    &-ult {
      grid-column: 2;
    }

    &-armor {
      grid-column: 3;
    }

    &-weapon {
      grid-column: 4;
    }

    &-load {
      grid-column: 5;
    }

    &-kda {
      grid-column: 6;
    }
  }

  &-cell {
    white-space: nowrap;
    text-align: center;

    &-player {
      grid-column: 1;
      padding: 0.25rem 0;

      &-icon {
        display: block;
        height: 1.375rem;
        margin: 0 auto 0.25rem;
      }

      &-name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 0.6875rem;
      }
    }

    &-ult {
      grid-column: 2;

      &-icon {
        height: 1.375rem;
      }
    }

    &-armor {
      grid-column: 3;

      &-icon {
        height: 1rem;
      }
    }

    &-weapon {
      grid-column: 4;

      &-icon {
        height: 0.75rem;
      }
    }

    &-load {
      grid-column: 5;

      &-label2 {
        font-size: 0.5rem;
      }
    }

    &-kda {
      grid-column: 6;
    }
  }
}
</style>
