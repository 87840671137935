<template>
  <b-form-checkbox-group name="kills" multiple stacked switches :checked="checked" :options="options" @input="update" />
</template>

<script>
import { BFormCheckboxGroup } from 'bootstrap-vue'

import mixpanel from '@/mixpanel.js'

import { pxSelectedData } from '../../types.js'

export default {
  name: 'KillsFilter',
  components: {
    BFormCheckboxGroup,
  },
  model: {
    prop: 'selected',
    event: 'update:selected',
  },
  inject: {
    setShortStatus: {
      from: 'setShortStatus',
      default: () => {},
    },
  },
  props: {
    selected: pxSelectedData().isRequired,
  },
  computed: {
    checked() {
      return Object.entries(this.selected.kills || [])
        .filter(([, selected]) => selected)
        .map(([key]) => key)
    },
    options() {
      return [
        { text: 'Kills', value: 'kills' },
        { text: 'Deaths', value: 'deaths' },
        { text: 'Only first', value: 'onlyFirst' },
      ]
    },
    shortStatus() {
      return (
        this.selected.kills &&
        (this.selected.kills.onlyFirst ? 'first ' : '') + (this.selected.kills.kills ? 'kills' : 'deaths')
      )
    },
  },
  watch: {
    shortStatus: {
      immediate: true,
      handler(val) {
        this.setShortStatus(val)
      },
    },
  },
  methods: {
    update(selected) {
      const newKills = selected.length > 0 ? Object.fromEntries(selected.map(key => [key, true])) : {}
      if (newKills.kills && !this.selected.kills.kills) {
        newKills.deaths = false
      } else if (!newKills.kills && this.selected.kills.kills) {
        newKills.deaths = true
      } else if (newKills.deaths && !this.selected.kills.deaths) {
        newKills.kills = false
      } else if (!newKills.deaths && this.selected.kills.deaths) {
        newKills.kills = true
      }
      this.selected.kills = newKills
      this.$emit('update:selected', this.selected)

      mixpanel.track_2d_map_filter(
        'KILLS SELECTION',
        Object.keys(this.selected.kills || [])
          .map(a => {
            const [opt] = this.options.filter(o => o.value === a)
            return opt.text
          })
          .join(', ')
      )
    },
  },
}
</script>

<style scoped></style>
