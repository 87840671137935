<template>
  <div
    class="container-app"
    :class="{
      'container-app-ow': isInOverwolf,
      'container-app-presenter': presenterMode,
    }"
  >
    <!--    <div class="container-part left">-->
    <div v-if="isInOverwolf" class="container-header left navbar-dark">
      <b-navbar-brand :title="version">
        <img class="expanded" :src="`${BASE_URL}/images/AugmentLogo_Horizontal_black_backgr.svg`" alt="Augment" />
        <span class="navbar-brand__tag">{{ backendName }}</span>
      </b-navbar-brand>
    </div>
    <div v-else class="container-header left navbar-dark">
      <b-navbar-brand to="/" :title="version">
        <img class="expanded" :src="`${BASE_URL}/images/AugmentLogo_Horizontal_black_backgr.svg`" alt="Augment" />
        <span class="navbar-brand__tag">{{ backendName }}</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <IconMenu />
      </b-navbar-toggle>
    </div>

    <div class="container-main left">
      <b-collapse id="nav-collapse" class="px-3 pb-3" is-nav>
        <portal-target name="menu" multiple tag="ul" class="navbar-nav"></portal-target>

        <b-navbar-nav>
          <b-nav-item v-if="canAccessPublicMatches" :active="$route.path === '/matches'" to="/matches">
            Pro Matches
          </b-nav-item>
          <b-nav-item
            v-if="canAccessGridEmeaMatches"
            :active="$route.path === '/grid/matches/upcoming'"
            to="/grid/matches/upcoming"
          >
            Upcoming Matches
          </b-nav-item>
          <b-nav-item v-if="canAccessGridEmeaMatches" :active="$route.path === '/grid/matches'" to="/grid/matches">
            Grid Matches
          </b-nav-item>
          <b-nav-item v-if="false" :active="$route.path === '/collegiate/matches'" to="/collegiate/matches">
            Collegiate Matches
          </b-nav-item>
          <b-nav-item disabled> Teams </b-nav-item>
          <b-nav-item v-if="canAccessScrimsV1" :active="$route.path === '/scrims'" to="/scrims"> Scrims </b-nav-item>
          <b-nav-item v-if="canAccessGridScrims" :active="$route.path === '/grid/scrims'" to="/grid/scrims">
            Grid Scrims
          </b-nav-item>
          <b-nav-item v-if="canAccessScrimsV2" :active="$route.path === '/scrims2'" to="/scrims2">
            Overwolf Scrims
          </b-nav-item>
          <b-nav-item disabled> Maps </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="mt-auto">
          <b-nav-item :active="$route.name === 'Feedback'" to="/feedback"> Submit feedback </b-nav-item>
          <b-nav-item v-if="!is_authenticated" :active="$route.name === 'Login'" to="/login"> Login </b-nav-item>
          <b-nav-item-dropdown
            v-if="is_authenticated"
            menu-class="bg-dark px-3 pb-3"
            :text="username"
            @click="profileMenuOpen = !profileMenuOpen"
          >
            <b-nav-item :active="$route.name === 'Profile'" to="/profile"> Account </b-nav-item>
            <b-nav-item :active="$route.name === 'Billing'" to="/billing"> Billing </b-nav-item>
            <b-nav-item @click="$emit('logout')"> Log out </b-nav-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>

      <portal-target slim name="sidebar-left"></portal-target>
    </div>
    <!--    </div>-->
    <!--    <div class="container-part center">-->
    <div class="container-header center">
      <portal-target slim name="header-center"></portal-target>
    </div>
    <div class="container-main center">
      <router-view />
    </div>
    <!--    </div>-->
    <!--    <div class="container-part right">-->
    <div v-if="!presenterMode" class="container-header right">
      <portal-target slim name="header-right"></portal-target>
    </div>
    <div v-if="!presenterMode" class="container-main right">
      <portal-target slim name="sidebar-right"></portal-target>
    </div>
    <!--    </div>-->
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import IconMenu from '../components/UI/IconMenu.vue'

export default {
  name: 'V3dafi',
  components: { IconMenu },
  props: {
    backendName: px.string,
  },
  data() {
    return {
      presenterMode: false,
    }
  },
  computed: {
    ...mapGetters({
      username: 'auth/username',
      is_authenticated: 'auth/is_authenticated',
      canAccessGridEmeaMatches: 'auth/can_access_grid_emea_matches',
      canAccessPublicMatches: 'auth/can_access_public_matches',
      canAccessCollegiateMatches: 'auth/can_access_collegiate_matches',
      canAccessScrimsV1: 'auth/can_access_scrims_v1',
      canAccessGridScrims: 'auth/can_access_grid_scrims',
      canAccessScrimsV2: 'auth/can_access_scrims_v2',
    }),
    BASE_URL() {
      return process.env.VUE_APP_PUBLIC_BASE || ''
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
    isInOverwolf() {
      return typeof this.$route.query.overwolf !== 'undefined' || process.env.VUE_APP_VARIANT === 'overwolf'
    },
  },
  watch: {
    $route: {
      handler(v) {
        this.presenterMode = v.query?.presenter === 'true'
      },
      immediate: true,
    },
  },
  provide() {
    return {
      layout: this,
    }
  },
}
</script>

<style lang="scss">
:root,
html,
body {
  touch-action: none;
}
</style>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Open+Sans:wght@400;800&family=Open+Sans+Roman:wght@800&family=Lato:wght@900&display=swap');

.container-app {
  display: grid;
  grid-template-areas: 'lh ch rh' 'l c r';
  grid-template-rows: 6rem 1fr;
  grid-template-columns: 13rem 1fr 19.125rem;
  flex-flow: row nowrap;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  align-items: stretch;
  overflow: hidden;
  overscroll-behavior: none;
  scroll-snap-type: both mandatory;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  column-gap: 0.5rem;

  // appearance
  background: #131319;

  &.container-app-presenter {
    grid-template-areas: 'lh ch' 'l c';
    grid-template-rows: 6rem 1fr;
    grid-template-columns: 13rem 1fr;
  }

  // text style
  letter-spacing: 0;
  font-weight: 500;
  font-family: Montserrat, sans-serif;

  .container-header {
    overflow: hidden;
    overscroll-behavior: none;

    &.left {
      grid-area: lh;
    }

    &.center {
      grid-area: ch;
    }

    &.right {
      grid-area: rh;
    }
  }

  .container-main {
    overflow: hidden;
    overscroll-behavior: none;

    &.left {
      grid-area: l;
    }

    &.center {
      grid-area: c;
    }

    &.right {
      grid-area: r;
    }
  }

  .container-part {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-width: 0;

    &.center {
      flex: 1 1 auto;
    }

    &.left,
    &.right {
      flex-basis: min-content;
    }

    &.left {
      flex-basis: 12rem;
    }

    &.right {
      flex-basis: 20rem;
    }
  }

  .container-header {
    display: flex;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    flex: 0 0 100%;
    flex-flow: row nowrap;
    align-items: center;

    &.center {
      justify-content: space-around;
    }
  }

  .container-main {
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    flex-flow: column nowrap;
  }

  .navbar-brand {
    position: relative;
    min-width: 4em;
    flex: 1 1 auto;

    img {
      height: 100%;
    }

    span {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  ::v-deep {
    .navbar-toggler {
      color: #7a7493;
      border: none;
      outline: none;
    }
  }
}
</style>
