<template functional>
  <g class="kill" :class="{ highlight: props.highlight }">
    <component
      v-if="props.event.killer.location != null && props.event.victim.location != null"
      :is="$options.components.MapLineEntry"
      :color="props.data.roundTeams[props.event.killer.round_team_id].role | roleColor(1)"
      :x1="props.event.killer.location.x"
      :y1="props.event.killer.location.y"
      :x2="props.event.victim.location.x"
      :y2="props.event.victim.location.y"
    />
    <g
      v-if="props.event.killer.location"
      :stroke="(props.highlight ? props.data.roundTeams[props.event.killer.round_team_id].role : '') | roleColor(1)"
    >
      <component :is="$options.components.MapEventEntry" :data="props.data" :event="props.event.killer" />
    </g>
    <g
      v-if="props.event.victim.location"
      :stroke="(props.highlight ? props.data.roundTeams[props.event.victim.round_team_id].role : '') | roleColor(1)"
    >
      <component :is="$options.components.MapEventEntry" :data="props.data" :event="props.event.victim" />
    </g>
  </g>
</template>

<script>
import px from 'vue-types'

import MapEventEntry from './MapEventEntry.vue'
import MapLineEntry from './MapLineEntry.vue'

export default {
  name: 'MapKillEventEntry',
  components: { MapLineEntry, MapEventEntry },
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
    highlight: px.bool.def(false),
  },
  filters: {
    roleColor(role) {
      switch (role) {
        case 'def':
          return 'rgba(127, 255, 212, 1)'
        case 'atk':
          return 'rgba(255, 99, 71, 1)'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
