<template>
  <LoadingController :loading="loading" :error="error" @retry="retry">
    <template v-if="permissionsError">
      <slot name="error">
        <PermissionsAlert :message="permissionsError" />
      </slot>
    </template>
    <template v-else>
      <slot v-if="data" :data="data" />
      <slot v-else name="no-data"> No data </slot>
    </template>

    <template #loading>
      <slot name="loading" />
    </template>
  </LoadingController>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import { NotAuthorizedError } from '@/api/errors'
import InsufficientQuotaError from '@/api/InsufficientQuotaError.js'
import { redirectToSSO } from '@/utils/sso'

import axios from '../../axios.js'
import PermissionsAlert from '../generic/PermissionsAlert.vue'

import LoadingController from './LoadingController.vue'

export default {
  name: 'ApiLoadingController',
  components: { LoadingController, PermissionsAlert },
  props: {
    fetch: px.func.isRequired,
    params: px.any,
  },
  data: () => ({
    error: null,
    loadingCounter: 0,
    data: null,
    permissionsError: null,
  }),
  computed: {
    loading() {
      return Boolean(this.loadingCounter)
    },
  },
  watch: {
    params: {
      immediate: true,
      handler() {
        this.load()
      },
    },
  },
  beforeDestroy() {
    this.cancel()
  },
  methods: {
    cancel() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    async load() {
      this.loadingCounter++
      try {
        this.error = null
        this.data = null
        this.cancel()
        this.cancelTokenSource = axios.CancelToken.source()
        this.data = await this.fetch(this.params, {
          cancelToken: this.cancelTokenSource.token,
        })
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        switch (e.name) {
          case InsufficientQuotaError.name:
            this.permissionsError = e.message
            break
          case NotAuthorizedError.name:
            if (this.$route.meta.auth === 'sso') {
              redirectToSSO()
            } else {
              await this.$router.push({
                path: '/login',
                query: {
                  redirect: this.$route.fullPath,
                },
              })
            }
            break
          default: {
            const error = (this.error = axios.extractErrorMessage(e))
            console.error(error, e, e.request, e.response)
            Sentry.captureException(e)
          }
        }
      } finally {
        this.loadingCounter--
      }
    },
    retry() {
      this.load()
    },
    reset() {
      this.data = null
    },
  },
}
</script>
