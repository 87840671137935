<template>
  <div class="ad-container">
    <div class="ad" id="video-ad"></div>
    <b-button v-if="!locked" variant="primary" class="btn-close" @click="closeAd">Close ad</b-button>
    <div v-if="error" class="error">Cannot load ad</div>
    <div v-if="loading" class="loading">Loading ad...</div>
  </div>
</template>

<script>
import loadScript from 'load-script'

export default {
  name: 'OverwolfVideoAd',
  data() {
    return {
      loading: true,
      completed: false,
      impression: false,
      videoAd: null,
      error: false,
    }
  },
  computed: {
    locked() {
      return !this.impression && !this.completed
    },
  },
  mounted() {
    this.loadAdsSDK()
  },
  methods: {
    loadAdsSDK() {
      loadScript('https://content.overwolf.com/libs/ads/latest/owads.min.js', err => {
        if (err) {
          console.log('onAdsLoadError', err)
          this.completed = true
        } else {
          this.initAd()
        }
      })
    },
    initAd() {
      if (this.videoAd) {
        return
      }

      // eslint-disable-next-line no-undef
      this.videoAd = new OwAd(document.getElementById('video-ad'), {
        size: {
          width: 400,
          height: 300,
        },
      })

      this.loading = true

      this.videoAd.addEventListener('player_loaded', this.onAdEvent)
      this.videoAd.addEventListener('display_ad_loaded', this.onAdEvent)
      this.videoAd.addEventListener('play', this.onAdEvent)
      this.videoAd.addEventListener('impression', this.onAdEvent)
      this.videoAd.addEventListener('complete', this.onAdEvent)
      this.videoAd.addEventListener('error', this.onAdEvent)
    },
    onAdEvent(event) {
      this.error = false
      this.loading = false

      if (event?.type === 'impression') {
        this.impression = true
        this.$emit('complete')
      }
      if (event?.type === 'complete') {
        this.completed = true
      }

      if (typeof event === 'string') {
        this.error = true
        this.completed = true
        this.$emit('complete')
      }
    },
    closeAd() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.ad-container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .ad {
    z-index: 1;
    width: 400px;
    height: 300px;
    background-color: black;
    background-image: url('/images/augment-logo-square.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 250px 250px;
  }

  .btn-close {
    z-index: 1;
    position: absolute;
    margin-bottom: -350px;
  }

  .error,
  .loading {
    z-index: 1;
    position: absolute;
    margin-top: 200px;
  }
}
</style>
