<template>
  <div class="player-container" :id="id" />
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import ensureTwitch from '@/utils/ensureTwitch.js'

export default {
  name: 'TwitchReplay',
  props: {
    currentTime: px.number.def(0),
    id: px.string,
    playing: px.bool.def(false),
    offsetTime: px.number.def(0),
    startTime: px.number,
    endTime: px.number,
  },
  data: () => ({
    loaded: false,
    state: null,
  }),
  beforeCreate() {
    ensureTwitch()
  },
  mounted() {
    this.$emit('update:supportedPlaybackRates', [1])
  },
  computed: {
    isPlaying() {
      return this.state === 'playing'
    },
    localCurrentTime() {
      return (this.startTime + this.currentTime + this.offsetTime) / 1000
    },
  },
  watch: {
    localCurrentTime(val) {
      if (this.player && val !== this.lastEmittedTime) {
        this.player.seek(this.localCurrentTime)
      }
    },
    playing(val) {
      if (!this.player) return
      if (val) {
        if (this.player.isPaused()) {
          this.player.play()
        }
      } else {
        if (!this.player.isPaused()) {
          this.player.pause()
        }
      }
    },
    isPlaying: {
      immediate: true,
      handler(val) {
        if (val) {
          this.updateCurrentTime()
        }
      },
    },
    id: {
      immediate: true,
      handler(val) {
        this.loadId(val)
      },
    },
  },
  methods: {
    async initTwitch(opts) {
      if (this.player) return true
      this.loaded = false
      this.loading = true
      try {
        const TwitchPlayer = (this.TwitchPlayer = await ensureTwitch())
        this.player = new TwitchPlayer(this.id, {
          width: '100%',
          height: '100%',
          autoplay: true,
          muted: true,
          ...opts,
        })
        const readyPromise = new Promise(resolve => {
          const onSeek = () => {
            this.player.removeEventListener(TwitchPlayer.SEEK, onSeek)
            resolve(this.player)
          }
          const onPlay = () => {
            this.player.removeEventListener(TwitchPlayer.PLAY, onPlay)
            if (!this.playing) {
              this.player.pause()
            }
            this.player.addEventListener(TwitchPlayer.SEEK, onSeek)
            this.player.seek(this.localCurrentTime)
          }
          this.player.addEventListener(TwitchPlayer.PLAY, onPlay)
        })
        // attach remaining event listeners
        readyPromise.then(() => {
          this.player.addEventListener(TwitchPlayer.ENDED, this.onEnded)
          this.player.addEventListener(TwitchPlayer.PAUSE, this.onPause)
          this.player.addEventListener(TwitchPlayer.PLAY, this.onPlay)
          this.player.addEventListener(TwitchPlayer.PLAYING, this.onPlaying)
          this.player.addEventListener(TwitchPlayer.SEEK, this.onSeek)
        })
        return readyPromise
      } finally {
        this.loading = false
      }
    },
    async loadId(id) {
      try {
        await this.initTwitch({ video: id })
        if (this.player.getVideo !== id) {
          this.player.setVideo(id)
        }
        if (this.player.getCurrentTime() !== this.localCurrentTime) {
          this.player.seek(this.localCurrentTime)
        }
        if (this.playing) {
          if (this.player.isPaused()) {
            this.player.play()
          }
        } else {
          if (!this.player.isPaused()) {
            this.player.pause()
          }
        }
        // this.player.setVideo(id, '02h07m42s')
      } catch (e) {
        console.error('Error loading twitch player', e)
        Sentry.captureException(e)
        this.error = e
      }
    },
    updateCurrentTime() {
      const update = () => {
        this.setPlayerTime(this.player.getCurrentTime())
        if (this.isPlaying) {
          requestAnimationFrame(update)
        }
      }

      requestAnimationFrame(update)
    },
    onEnded() {
      this.state = 'ended'
      this.$emit('update:playing', false)
    },
    onPause() {
      this.state = 'pause'
      this.$emit('update:playing', false)
    },
    onPlay() {
      this.state = 'play'
      this.$emit('update:playing', true)
    },
    onPlaying() {
      this.state = 'playing'
      this.$emit('update:playing', true)
    },
    onSeek(e) {
      this.setPlayerTime(e.position)
    },
    setPlayerTime(time) {
      let newTime = time * 1000 - this.offsetTime
      if (newTime < this.startTime) {
        newTime = this.startTime
      } else if (this.endTime && newTime > this.endTime) {
        newTime = this.endTime
      }
      newTime -= this.startTime
      if (this.lastEmittedTime !== time) {
        this.lastEmittedTime = time
        this.$emit('update:currentTime', newTime)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.player-container {
  height: 100%;
}
</style>
