<template>
  <div class="tef-container">
    <div class="tef-row">
      <V3Button :active="!!eco.length" class="tef-team" @click="clear">
        <V3TeamLogo :grayscale="!eco.length" :src="selectedTeam.image" />
        <div class="tef-team-name">{{ selectedTeam.abbr || selectedTeam.name }}</div>
      </V3Button>
      <V3BtnGroup>
        <V3Button class="btn-eco" title="Pistol" :active="eco.includes('P')" @click="toggleP()"> P </V3Button>
        <V3Button class="btn-eco" title="Eco 0-5k" :active="eco.includes('$')" @click="toggle('$')"> $ </V3Button>
        <V3Button class="btn-eco" title="Semi-eco 5-10k" :active="eco.includes('$$')" @click="toggle('$$')">
          $$
        </V3Button>
        <V3Button
          class="btn-eco"
          :title="isScrim ? 'Semi-buy 10-16.5k' : 'Semi-buy 10-20k'"
          :active="eco.includes('$$$')"
          @click="toggle('$$$')"
        >
          $$$
        </V3Button>
        <V3Button
          class="btn-eco"
          :title="isScrim ? 'Full-buy 16.5k+' : 'Full-buy 20k+'"
          :active="eco.includes('$$$$')"
          @click="toggle('$$$$')"
        >
          $$$$
        </V3Button>
      </V3BtnGroup>
    </div>

    <div v-if="!isScrim" class="tef-row">
      <V3Button :active="!!ecoOp.length" class="tef-team" @click="clearOp">
        <V3TeamLogo :grayscale="!ecoOp.length" :src="opponentTeam.image" />
        <div class="tef-team-name">{{ opponentTeam.abbr || opponentTeam.name }}</div>
      </V3Button>
      <V3BtnGroup>
        <V3Button class="btn-eco" title="Pistol" :active="eco.includes('P')" @click="toggleP()"> P </V3Button>
        <V3Button class="btn-eco" title="Eco 0-5k" :active="ecoOp.includes('$')" @click="toggleOp('$')"> $ </V3Button>
        <V3Button class="btn-eco" title="Semi-eco 5-10k" :active="ecoOp.includes('$$')" @click="toggleOp('$$')">
          $$
        </V3Button>
        <V3Button class="btn-eco" title="Semi-buy 10-20k" :active="ecoOp.includes('$$$')" @click="toggleOp('$$$')">
          $$$
        </V3Button>
        <V3Button class="btn-eco" title="Full-buy 20k+" :active="ecoOp.includes('$$$$')" @click="toggleOp('$$$$')">
          $$$$
        </V3Button>
      </V3BtnGroup>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import V3TeamLogo from '@/components/Map/components/v3dafi/V3TeamLogo.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'

export default {
  name: 'TeamEcoFilter',
  mixins: [exposedDataState],
  components: {
    V3Button,
    V3BtnGroup,
    V3TeamLogo,
  },
  props: {
    isScrim: px.bool.def(false),
  },
  computed: {
    eco() {
      return this.state.filters.round.eco?.length ? this.state.filters.round.eco : []
    },
    ecoOp() {
      return [
        ...(this.state.filters.round.ecoOpponents?.length
          ? this.state.filters.round.ecoOpponents.filter(e => e !== 'P')
          : []),
        ...(this.eco.includes('P') ? ['P'] : []),
      ]
    },
    opponentTeam() {
      if (Object.keys(this.data.teams).length > 2) return { name: 'vs' }
      return Object.entries(this.data.teams).find(([id]) => id !== this.selectedTeamId)[1]
    },
    selectedTeam() {
      return this.data.teams[this.selected.team]
    },
    selectedTeamId() {
      return this.selected.team
    },
    value() {
      const { eco, ecoOp } = this
      if (eco.length) {
        if (ecoOp.length) {
          return `${eco.join(',')} vs ${ecoOp.join(',')}`
        } else {
          return eco.join(',')
        }
      } else if (ecoOp.length) {
        return `vs ${ecoOp.join(',')}`
      } else {
        return null
      }
    },
  },
  methods: {
    clear() {
      this.state.filters.round.eco = []
      this.state.filters.round.ecoOpponents = this.ecoOp.filter(e => e !== 'P')
    },
    clearOp() {
      this.state.filters.round.ecoOpponents = []
      this.state.filters.round.eco = this.eco.filter(e => e !== 'P')
    },
    toggle(eco) {
      if (this.eco.includes(eco)) {
        this.state.filters.round.eco = this.eco.filter(e => e !== eco)
      } else {
        this.state.filters.round.eco = [...this.eco, eco]
      }
    },
    toggleP() {
      this.toggleOp('P')
      this.toggle('P')
    },
    toggleOp(eco) {
      if (this.ecoOp.includes(eco)) {
        this.state.filters.round.ecoOpponents = this.ecoOp.filter(e => e !== eco)
      } else {
        this.state.filters.round.ecoOpponents = [...this.ecoOp, eco]
      }
    },
  },
}
</script>

<style scoped lang="scss">
.tef {
  &-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 1px;
  }

  &-team {
    flex-flow: row nowrap;
    padding: 0;
    gap: 0.25rem;

    &-name {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 11px;
      color: #a4a2ad;
      transition: color 0.2s ease-in-out;
    }

    &.active {
      .tef-team-name {
        color: #fff;
      }
    }
  }
}

.v3-btn-group {
  gap: 3px;

  .v3-button {
    background-color: #211f2b;
    border-radius: 3px;

    &.btn-eco {
      letter-spacing: -0.1em;
      padding-inline: 0.25em;
    }

    &.active {
      background-color: #141319;
      color: #e03a47;
    }
  }
}
</style>
