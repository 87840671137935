<template>
  <div class="container">
    <dialog ref="modalfilter">
      <MapModal @close="onModalClose" />
    </dialog>
    <dialog v-if="renderUtilityModal" ref="modalutilfilter">
      <MapUtilityModal @close="onModalClose('utility')" />
    </dialog>
    <dialog v-if="singleMatch" ref="modaloverview">
      <OverviewModal @close="onModalClose('overview')" />
    </dialog>
    <dialog ref="modalboard" @cancel.prevent style="height: 100%; width: 100%">
      <DrawingBoardModal @close="onModalClose('board')" ref="drawingboard" />
    </dialog>
    <MapPortal to="header-right" class="d-flex flex-fill align-items-stretch align-self-stretch">
      <MainMapModeButton :active="mapMode === 'replay2d'" @click="mapMode = 'replay2d'">
        <BIconYoutube />
        Replay mode
      </MainMapModeButton>
      <MainMapModeButton
        :active="mapMode === 'analytics'"
        @click="mapMode = 'analytics'"
        :disabled="!mapSettings.analyticsEnabled"
      >
        <BIconFileBarGraphFill />
        Analytics mode
      </MainMapModeButton>
      <MainMapModeButton @click="onModalOpen('board')">
        <BIconBrush />
        Board
      </MainMapModeButton>
    </MapPortal>
    <MapPortal class="h-100" to="sidebar-left">
      <LeftSideBar
        class="menu"
        :bookmarks="notesForTimeline"
        :note-tags="noteTags"
        :saving-bookmark="savingBookmark"
        :vod-platform="vodPlatform"
        :has-playback-positions="hasPlaybackPositions"
        :has-minimap-vods="hasMinimapVods"
        :has-broadcast-vod="hasVod"
        @saveNote="saveNote"
        @selectBookmark="loadBookmark"
      >
        <V3Button v-if="singleMatch" class="overview" @click="onModalOpen('overview')"> Match Overview </V3Button>
      </LeftSideBar>
    </MapPortal>
    <div class="main-container">
      <div class="main-content" v-drag="dragHandler" v-pinch="pinchHandler">
        <div v-if="false" class="toolbox top">
          <div class="float-right box">
            <b-form v-if="this.exposed.mapSettings.reportsEnabled && savingReport" inline @submit.prevent="saveReport">
              <b-input-group prepend="Report name">
                <b-form-input autofocus required v-model="reportName" />
                <b-input-group-append>
                  <b-button type="submit" :disabled="!reportName">
                    <b-icon-cloud-plus />
                  </b-button>
                  <b-button @click="savingReport = false">
                    <b-icon-x />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>

            <b-button-group v-if="false" class="align-items-stretch">
              <b-button v-if="this.exposed.mapSettings.reportsEnabled" @click="savingReport = true">
                <b-icon-cloud-plus />
              </b-button>
              <b-button v-if="mapMode !== 'replay2d'" @click="downloadImage('svg')">
                <IconFiletypeSvg />
              </b-button>
              <b-button v-if="mapMode !== 'replay2d'" @click="downloadImage('png')">
                <IconFiletypePng />
              </b-button>
            </b-button-group>
          </div>
        </div>

        <div class="toolbox bottom">
          <div class="float-right box dbg">
            <MapLegend v-if="mapSubMode && mapSubMode.startsWith('traces')" :data="data" :legend="sub.legend" />
          </div>
        </div>

        <div v-if="true" class="toolbox bottom">
          <div class="float-left box dbg">
            <ul class="info">
              <li>{{ data.teams[selected.team].name }} - {{ state.filters.round.teamRole.toUpperCase() }}</li>
            </ul>
            <ul class="info">
              <li>Rounds: {{ Object.keys(data.rounds).filter(id => filters.round(id)).length }}</li>
            </ul>
            <ul class="info">
              <li>
                Wins:
                {{
                  Object.values(data.rounds).filter(r => filters.round(r.id) && r.round_team_winner === selected.team)
                    .length
                }}
              </li>
            </ul>
            <details v-if="mapMode === 'analytics' && mapSubMode !== 'kdzones'">
              <summary>
                <span>{{ eventsForMap.length }} positions/utilities</span>
              </summary>
              <ul class="info-and-list">
                <li v-if="this.state.filters.display.players != null">
                  <summary class="info-or-list">
                    <li v-for="(v, k) in this.state.filters.display.players" :key="k">
                      {{ data.players[k].name }}:
                      {{
                        eventsForMap.filter(
                          ev => data.players[k].id === data.matchPlayers[ev.match_player_id].player_id
                        ).length
                      }}
                    </li>
                  </summary>
                </li>
              </ul>
            </details>
          </div>
        </div>

        <div class="map-content">
          <!-- v-show as parent div for vue3 compatibility -->
          <div v-show="mapMode === 'replay2d' && vodPlayer !== 'playback'">
            <!-- Video and 2d minimap replays -->
            <slot name="replay2d"></slot>
          </div>

          <svg
            v-if="mapMode !== 'replay2d' || (vodPlayer !== 'twitch' && vodPlayer !== 'youtube')"
            ref="svg_root"
            class="svg-root"
            viewBox="0 0 1024 1024"
            preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
          >
            <svg:style>
              .smoke {
                fill: rgba(200, 200, 200, 0.25);
                stroke: rgba(200, 200, 200, 0.75);
              }

              .map-spike {
                filter: invert(52%) sepia(41%) saturate(6182%) hue-rotate(338deg) brightness(100%) contrast(91%);
              }

              .highlight {
                stroke-width: 3px;
              }
            </svg:style>

            <image v-if="screenshot" x="0" y="0" width="1024" height="1024" :href="screenshot" />

            <MapImageEntry
              class="img-only"
              image-url="/images/AugmentLogo_Horizontal_black_backgr.svg"
              :x="-80 / 1024"
              :y="980 / 1024"
              :size="200 / 1024"
            />

            <g
              v-if="mapMode !== 'replay2d' || vodPlayer === 'playback'"
              :transform="`rotate(${-data.map.rotate_deg} 512 512)`"
            >
              <MapBackground key="map-bg" :map-data="data.map" />
              <MapEvents
                v-if="hasSmokes && mapOptions.includes('smokes')"
                :current-time="mapMode === 'replay2d' ? currentTime : null"
                :data="data"
                :events="smokes"
                :filter-current-time="filterCurrentTime"
                :selected="selected"
                variant="smokes"
              />
              <MapEvents
                v-if="hasWalls && mapOptions.includes('walls')"
                :current-time="mapMode === 'replay2d' ? currentTime : null"
                :data="data"
                :events="walls"
                :filter-current-time="filterCurrentTime"
                :selected="selected"
                variant="walls"
              />
              <MapEvents
                v-if="mapOptions.includes('deaths') && mapSubMode !== 'kdzones'"
                :data="data"
                :events="mapMode === 'replay2d' ? deathsForReplay : deathsForMap"
                variant="deaths"
              />

              <MapEvents
                v-if="mapSubMode === 'agents' && canRender(2500)"
                :current-time="mapMode === 'replay2d' ? currentTime : null"
                :data="data"
                :events="eventsForMap"
                :filter-current-time="filterCurrentTime"
                :highlight-event="hoveredTimelineEvent"
                :highlighted-gids="state.filters.display.gids"
                :selected="selected"
                variant="positions"
                :icon-hide="this.state.iconHide"
                @update:legend="updateLegend"
              />

              <HeatmapEvents
                v-if="mapSubMode === 'heatmap' && canRender(250000)"
                :current-time="mapMode === 'replay2d' ? currentTime : null"
                :data="data"
                :events="
                  events &&
                  (mapMode === 'replay2d' ? (hasAdvanced ? eventsForPlayback : events.positions) : eventsForMap)
                "
                :filter-current-time="filterCurrentTime"
                :highlighted-gids="state.filters.display.gids"
                @update:legend="updateLegend"
              />

              <TraceMap
                v-if="mapSubMode === 'traces' && canRender(250000)"
                :current-time="mapMode === 'replay2d' ? currentTime : null"
                :data="data"
                :events="
                  events &&
                  (mapMode === 'replay2d' ? (hasAdvanced ? eventsForPlayback : events.positions) : eventsForMap)
                "
                :filter-current-time="filterCurrentTime"
                :highlighted-gids="state.filters.display.gids"
                variant="thick"
                @update:legend="updateLegend"
              />

              <KDZoneMap v-if="mapSubMode === 'kdzones'" />
              <MapEvents
                v-if="mapMode === 'replay2d' && mapSubMode === 'agents'"
                :current-time="mapMode === 'replay2d' ? currentTime : null"
                :data="data"
                :events="mapController._13_events_kills"
                :filter-current-time="filterCurrentTime"
                :selected="selected"
                variant="kills"
              />

              <MapEvents
                v-if="mapSubMode !== 'kdzones'"
                :data="data"
                :events="mapMode === 'replay2d' ? spikesForReplay : (events && events.plants) || []"
                variant="spikes"
              />
            </g>

            <SvgLegend v-if="false && mapSubMode !== 'agents'" :data="data" :legend="sub.legend" />

            <!-- Conflict between KD ZONE View Interactivty and MapDrawing Interactivity hence condition -->
            <MapDrawing
              v-if="drawingConfig && drawings && mapSubMode !== 'kdzones'"
              ref="mapDrawing"
              :config="drawingConfig"
              :strokes="drawings"
              :enabled="state.drawingEnabled"
              @update="addDrawing"
              @mousedown="onMapMouseDown"
              @mousemove="onMapMouseMove"
              @mouseup="onMapMouseUp"
              @mousewheel="onMapMouseWheel"
              @typeText="onTypeText"
              @erase="onErase"
            />

            <MinimapSelector
              v-if="mapMode === 'analytics' && mapSubMode === 'heatmap' && this.state.minimapSelector"
              :events="
                events && (mapMode === 'replay2d' ? (hasAdvanced ? eventsForPlayback : events.positions) : eventsForMap)
              "
            />
          </svg>

          <div class="clock">
            <span>{{ currentTime | formatTime }}</span>
          </div>

          <div v-if="textDrawing" class="drawTextInput">
            <div class="panel">
              Type text:
              <input ref="drawTextInput" type="text" v-model="textDrawing.text" />
              <div class="buttons">
                <b-button @click="textDrawing = null"> Cancel </b-button>
                <b-button @click="onTypeTextDone"> Done </b-button>
              </div>
            </div>
          </div>

          <!-- END OF Video and 2d minimap replays -->

          <div
            v-if="
              (mapMode !== 'replay2d' || vodPlayer === 'playback') &&
              ((mapSubMode === 'agents' && !canRender(2500)) ||
                (mapSubMode === 'heatmap' && !canRender(250000)) ||
                (mapSubMode && mapSubMode.startsWith('traces') && !canRender(250000)))
            "
            class="guard-container"
          >
            <div class="guard-content">
              <p>This view type may become unresponsive when rendering too many object.</p>
              <hr />
              <p>Please confirm rendering</p>
              <div>
                <b-button variant="danger" @click="forceRender = true"> Render </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data" class="timeline">
        <TimelineControls />
        <Timeline
          :events="eventsForTimeline"
          :user-marks="state.marks"
          :playable="playable"
          :playback-rate="playbackRate"
          @update:playbackRate="$emit('update:playbackRate', $event)"
          :supported-playback-rates="supportedPlaybackRates"
          :playing.sync="state.playing"
          :round-duration="roundDuration"
          :selectable="selectable"
          :hovered.sync="hoveredTimelineEvent"
        />
      </div>
      <div class="round-selector" v-if="!exposed.presenterMode">
        <slot name="rounds">
          <div class="placeholder">This is slot <kbd>rounds</kbd>. Should provide round selector component here.</div>
        </slot>
      </div>
    </div>
    <MapPortal class="sidebar-right h-100" to="sidebar-right">
      <div class="submenu">
        <RightSideTabs :value.sync="submenuDisplayType" />

        <V3ScrollableContainer class="submenu-content" vertical>
          <template v-if="submenuDisplayType === 'info'">
            <template v-if="singleRoundId && data && events">
              <V3Button v-if="mapMode === 'replay2d' && mapSubMode === 'agents'" @click="loadToBoard" class="overview">
                Snapshot to Board
              </V3Button>
              <Killfeed
                :agents="data.agents"
                :current-time="!['analytics'].includes(mapMode) ? currentTime : null"
                :data="data"
                :events="eventsForKillfeed"
                :expandable="hasAdvanced"
                :expanded.sync="showAbilitiesInKillfeed"
                :match-players="data.matchPlayers"
                :players="data.players"
                :rounds="data.rounds"
                :round-teams="data.roundTeams"
                :selected="selected"
                :weapons="data.weapons"
                @select="onKillfeedSelect"
              />

              <TeamRoundStats v-if="roundStatsSelectedTeam" :data="data" :team="roundStatsSelectedTeam" />
              <TeamRoundStats v-if="roundStatsEnemyTeam" :data="data" :team="roundStatsEnemyTeam" />
            </template>
            <div v-else>Select a single round to see killfeed</div>

            <SelectedRoundStatistics :is-scrim="isScrim" />
          </template>

          <MapFilters
            v-if="submenuDisplayType === 'filters'"
            :has-economy="hasEconomy"
            :has-orbs="hasOrbs"
            :has-outcome="hasOutcome"
            :has-plants="hasPlants"
            :has-wins="hasWins"
            :is-scrim="isScrim"
            :has-abilities-usage="hasAbilitiesUsage"
            @new-player-snapshot="onModalOpen"
            @new-util-snapshot="onModalOpen('utility')"
          />

          <template v-if="submenuDisplayType === 'xvy'">
            <MapToolRoundXvYSelector
              v-if="Object.keys(this.data.matches).length === 1"
              :data="data"
              :selected="selected"
              :state="this.state"
              @select="onXvYSelect"
            />

            <div v-else>Not Available</div>
          </template>
        </V3ScrollableContainer>
      </div>
      <div class="ad-container" v-if="adsEnabled">
        <OverwolfBannerAd />
      </div>
    </MapPortal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import {
  BIconCloudPlus,
  BIconFileBarGraphFill,
  BIconYoutube,
  BIconBrush,
  BIconX,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { Canvg, presets } from 'canvg'
import { clamp } from 'lodash'
import px from 'vue-types'

import MapImageEntry from '@/components/Map/components/map/MapImageEntry.vue'
import MapPortal from '@/components/Map/components/MapPortal.vue'
import SvgLegend from '@/components/Map/components/SvgLegend.vue'
import MainMapModeButton from '@/components/Map/components/v3dafi/MainMapModeButton.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import OverwolfBannerAd from '@/components/overwolf/OverwolfBannerAd.vue'
import IconFiletypePng from '@/components/UI/IconFiletypePng.vue'
import IconFiletypeSvg from '@/components/UI/IconFiletypeSvg.vue'
import mixpanel from '@/mixpanel.js'
import deepClone from '@/utils/deepClone.js'
import download from '@/utils/download'

import { BOOKMARK_THUMBNAIL_SIZE } from '../../constants.js'

import DrawingBoardModal from './components/DrawingBoardModal.vue'
import MapModal from './components/filters/MapModal.vue'
import MapUtilityModal from './components/filters/MapUtilityModal.vue'
import HeatmapEvents from './components/HeatmapEvents.vue'
import KDZoneMap from './components/KDZoneMap.vue'
import Killfeed from './components/Killfeed.vue'
import MapBackground from './components/MapBackground.vue'
import MapDrawing from './components/MapDrawing.vue'
import MapEvents from './components/MapEvents.vue'
import MapFilters from './components/MapFilters.vue'
import MapLegend from './components/MapLegend.vue'
import MapToolRoundXvYSelector from './components/MapToolRoundXvYSelector.vue'
import MinimapSelector from './components/MinimapSelector.vue'
import OverviewModal from './components/OverviewModal.vue'
import SelectedRoundStatistics from './components/SelectedRoundStatistics.vue'
import Timeline from './components/Timeline.vue'
import TimelineControls from './components/TimelineControls.vue'
import TraceMap from './components/TraceMap.vue'
import LeftSideBar from './components/v3dafi/LeftSideBar.vue'
import RightSideTabs from './components/v3dafi/RightSideTabs.vue'
import TeamRoundStats from './components/v3dafi/TeamRoundStats.vue'
import V3ScrollableContainer from './components/v3dafi/V3ScrollableContainer.vue'
import { pxEvent, pxId, pxKillEvent, pxWall, pxSmoke, pxNullable } from './types.js'
import alignRoundTime from './utils/alignRoundTime.js'
import formatTime from './utils/formatTime.js'
import genDamageId from './utils/genDamageId.js'

function throttle(fn) {
  let lastCall = 0
  let request = 0
  let fnArgs = null
  return function (...args) {
    fnArgs = args
    if (request) return
    if (Date.now() - lastCall < 25) return
    request = requestAnimationFrame(() => {
      try {
        fn.apply(this, fnArgs)
      } finally {
        request = 0
        lastCall = Date.now()
        fnArgs = null
      }
    })
  }
}

export default {
  mixins: [exposedDataState],
  components: {
    SelectedRoundStatistics,
    TimelineControls,
    V3ScrollableContainer,
    MapPortal,
    LeftSideBar,
    TeamRoundStats,
    BIconYoutube,
    BIconBrush,
    BIconFileBarGraphFill,
    RightSideTabs,
    MainMapModeButton,
    MapDrawing,
    MinimapSelector,
    KDZoneMap,
    IconFiletypePng,
    IconFiletypeSvg,
    SvgLegend,
    MapImageEntry,
    BIconCloudPlus,
    BIconX,
    BInputGroup,
    BInputGroupAppend,
    MapLegend,
    MapModal,
    MapUtilityModal,
    MapToolRoundXvYSelector,
    OverviewModal,
    DrawingBoardModal,
    TraceMap,
    HeatmapEvents,
    Killfeed,
    MapEvents,
    Timeline,
    MapFilters,
    MapBackground,
    OverwolfBannerAd,
    V3Button,
  },
  props: {
    disableReplay: Boolean,
    events: pxNullable(
      px.shape({
        deaths: px.arrayOf(pxEvent()).isRequired,
        defuses: px.arrayOf(pxEvent()).isRequired,
        kills: px.arrayOf(pxKillEvent()).isRequired,
        plants: px.arrayOf(pxEvent()).isRequired,
        positions: px.arrayOf(pxEvent()).isRequired,
        advancedPositions: px.arrayOf(pxEvent()),
        smokes: px.arrayOf(pxSmoke()),
        utilities: px.arrayOf(pxEvent()),
        walls: px.arrayOf(pxWall()),
      }).loose
    ),
    filters: pxNullable(
      px.shape({
        event: px.func.isRequired,
        matchPlayer: px.func.isRequired,
        round: px.func.isRequired,
      })
    ),
    hasAdvanced: px.bool.def(false),
    hasEconomy: px.bool.def(false),
    hasOrbs: px.bool.def(false),
    hasOutcome: px.bool.def(false),
    hasPlants: px.bool.def(false),
    hasWins: px.bool.def(false),
    hasVod: px.bool.def(true),
    playbackRate: px.number.def(1.0),
    roundDuration: px.number.def(45000),
    report: px.object,
    savingBookmark: Boolean,
    selectedRoundInfo: pxNullable(
      px.arrayOf(
        px.shape({
          id: pxId().isRequired,
          players: px.arrayOf(px.object).isRequired,
        }).loose
      )
    ),
    supportedPlaybackRates: Array,
    takeScreenshot: Function,
    vodPlatform: px.string,
    isScrim: px.bool.def(false),
    notes: px.arrayOf(px.object).def([]),
    noteTags: px.arrayOf(px.object).def([]),
    hasPlaybackPositions: px.bool.def(true),
    hasMinimapVods: px.bool.def(true),
    hasAbilitiesUsage: px.bool.def(true),
    adsEnabled: px.bool.def(false),
  },
  data() {
    return {
      fps: null,
      sub: {
        legend: null,
      },
      mapViewport: {
        center: { x: 512, y: 512 },
        scale: 1,
      },
      forceRender: false,
      submenuDisplayType: 'filters',
      savingReport: false,
      reportName: '',
      hoveredTimelineEvent: null,
      screenshot: null,
      showAbilitiesInKillfeed: false,
      textDrawing: null,
      drawingTextBoundaries: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    alignRoundTime() {
      return alignRoundTime(this)
    },
    drawingConfig: {
      get() {
        return this.state.drawingConfig
      },
      set(value) {
        this.state.drawingConfig = value
      },
    },
    mapSubMode: {
      get() {
        return this.state.mapSubMode
      },
      set(value) {
        this.state.mapSubMode = value
      },
    },
    spikeMode: {
      get() {
        return this.state.spikeMode
      },
      set(value) {
        this.state.spikeMode = value
      },
    },
    mapOptions: {
      get() {
        return this.state.mapOptions
      },
      set(value) {
        this.state.mapOptions = value
      },
    },
    selected: {
      get() {
        return this.state.selected
      },
      set(value) {
        this.state.selected = value
      },
    },
    eventsForKillfeedAndTimeline() {
      return Object.freeze(
        [
          this.events?.feed || [],
          this.events?.plants || [],
          this.events?.defuses || [],
          this.firstUtilityUses.filter(this.filterUtilityEvent),
          this.events?.utilitiesUsage || [],
        ]
          .flat()
          .sort((a, b) => a.round_time_millis - b.round_time_millis)
      )
    },
    eventsForKillfeed() {
      return this.eventsForKillfeedAndTimeline.filter(event => {
        return this.showAbilitiesInKillfeed ? true : event.type !== 'utility'
      })
    },
    eventsForPlayback() {
      return Object.freeze([
        // ...(this.hasAdvanced && this.events?.advancedPositions
        //   ? this.events.advancedPositions
        //   : this.events.positions || []
        // ).filter(event => {
        //   if (!this.filterAdvancedPositionEvent(event)) return false
        //   return true
        // }),
        // ...(this.events?.utilities?.filter(event => {
        //   if (!this.filterUtilityEvent(event)) return false
        //   return true
        // }) || []),
        ...(this.$parent._08_events_positions || []),
      ])
    },
    eventsForMap() {
      // return this.mapMode === 'replay2d'
      //   ? this.mapController._08_events_positions
      //   : this.mapController._13_events_positions
      if (this.mapMode === 'replay2d') return this.eventsForPlayback
      return Object.freeze(
        [
          this.mapMode === 'positions' ? this.events?.positions?.filter(this.filterEvent) : [],
          this.mapMode === 'kills' ? this.events?.kills?.filter(this.filterKillEvent) : [],
          this.mapMode === 'advanced' ? this.events?.advancedPositions?.filter(this.filterAdvancedPositionEvent) : [],
          this.mapMode === 'utility' ? this.events?.utilities?.filter(this.filterUtilityEvent) : [],
          this.mapMode === 'analytics'
            ? [
                ...(this.$parent._13_events_positions || []),
                // ...(this.hasAdvanced && this.events?.advancedPositions
                //   ? this.events.advancedPositions
                //   : this.events?.positions || []
                // ).filter(this.filterAdvancedPositionEvent),
                // ...(this.events?.utilities?.filter(e => this.filterUtilityEvent(e) && this.filterEvent(e)) || []),
                ...(this.events?.kills || []).filter(this.filterKillEvent),
              ].filter(event => {
                if (
                  (this.state.filters.display.gids != null || this.state.filters.display.players != null) &&
                  this.mapMode === 'analytics'
                ) {
                  if (this.state.filters.display.gids && this.state.filters.display.gids?.[event.gid]) {
                    return true
                  }

                  if (this.state.filters.display.players && event.type === 'position') {
                    const matchPlayer = this.data.matchPlayers[event.match_player_id]
                    if (this.state.filters.display.players?.[matchPlayer.player_id]) {
                      return true
                    }
                  }

                  return false
                }

                return true
              })
            : [],
        ]
          .flat()
          .filter(event => {
            if (event.type === 'kill') {
              if (this.state.filters.timeline.killWeapons != null) {
                const agent = this.data.agents[this.data.matchPlayers[event.killer.match_player_id].agent_id]
                const damageId = genDamageId(event.finishing_damage, agent)
                if (!this.state.filters.timeline.killWeapons[damageId]) return false
              }
            }
            return true
          })
      )
    },
    firstUtilityUses() {
      return Object.freeze(
        Object.values(
          this.events?.utilities?.reduce((firstEvents, event) => {
            const key = `${event.match_player_id}-${event.round_team_id}-${event.ability_slot}`
            if (!firstEvents[key] || firstEvents[key].round_time_millis > event.round_time_millis) {
              firstEvents[key] = event
            }
            return firstEvents
          }, {}) ?? {}
        )
      )
    },
    countSelectedTeamCompositions() {
      return Object.values(this.selected?.teamCompositions || {})
        .flatMap(items => items || [])
        .filter(Boolean).length
    },
    hasSmokes() {
      return this.smokes?.length > 0
    },
    hasWalls() {
      return this.walls?.length > 0
    },
    positionsForReplay() {
      return Object.freeze(this.events?.positions?.filter(e => this.alignRoundTime(e) === this.currentTime) || [])
    },
    killsForReplay() {
      return Object.freeze(this.events?.kills?.filter(e => this.alignRoundTime(e) === this.currentTime) || [])
    },
    deathsForReplay() {
      return Object.freeze(this.events?.deaths?.filter(e => this.alignRoundTime(e) < this.currentTime) || [])
    },
    deathsForMap() {
      return Object.freeze(this.events?.deaths?.filter(this.filterEvent) || [])
    },
    spikesForReplay() {
      return Object.freeze(
        this.events?.plants?.filter(e => {
          if (!(this.alignRoundTime(e) <= this.currentTime)) {
            return false
          }
          const defuseEvent = this.events?.defuses?.find(d => d.round_id === e.round_id)
          if (!defuseEvent) {
            return true
          }
          return !(this.alignRoundTime(defuseEvent) <= this.currentTime)
        }) || []
      )
    },
    eventsForTimeline() {
      return Object.freeze(
        [this.eventsForKillfeedAndTimeline || []]
          .flat()
          .filter(event => {
            if (event.type === 'kill') {
              if (this.state.filters.timeline.killWeapons != null) {
                const agent = this.data.agents[this.data.matchPlayers[event.killer.match_player_id].agent_id]
                const damageId = genDamageId(event.finishing_damage, agent)
                if (!this.state.filters.timeline.killWeapons[damageId]) return false
              }
            }
            if (event.type === 'utility') {
              if (this.state.filters.timeline.abilitiesUsage != null) {
                if (!this.state.filters.timeline.abilitiesUsage?.[event.match_player_id]?.[event.ability_slot]) {
                  return false
                }
              }
            }

            return true
          })
          .sort((a, b) => a.round_time_millis - b.round_time_millis)
      )
    },
    roundStatsSelectedTeam() {
      return this.selectedRoundInfo?.find(({ id }) => id === this.selected.team)
    },
    roundStatsEnemyTeam() {
      return this.selectedRoundInfo?.find(({ id }) => id !== this.selected.team)
    },
    smokes() {
      return Object.freeze(this.events?.smokes?.filter(this.filterEvent) || [])
    },
    walls() {
      return Object.freeze(this.events?.walls?.filter(this.filterEvent) || [])
    },
    playbackAdvanced() {
      return (
        this.state.playing &&
        (this.mapMode === 'playback' ||
          (this.mapMode === 'replay2d' && this.vodPlayer === 'playback' && this.hasAdvanced))
      )
    },
    mapOptionsItems() {
      return [
        ...(this.mapMode !== 'kills' ? [{ text: 'Smokes', value: 'smokes', disabled: !this.hasSmokes }] : []),
        ...(this.mapMode !== 'kills' ? [{ text: 'Walls', value: 'walls', disabled: !this.hasWalls }] : []),
        { text: 'Deaths', value: 'deaths' },
      ]
    },
    mapOptionsFilterStatus() {
      return this.mapOptions.filter(mapOption => this.mapOptionsItems.find(item => item.value === mapOption)).join(', ')
    },
    playable() {
      return (
        (['replay2d'].includes(this.mapMode) && (this.hasVod || (this.vodPlayer === 'playback' && this.hasAdvanced))) ||
        this.mapMode === 'playback'
      )
    },
    singleRoundId() {
      return (
        this.selected.rounds &&
        Object.entries(this.selected.rounds).length === 1 &&
        Object.keys(this.selected.rounds).pop()
      )
    },
    notesForTimeline() {
      return Object.freeze([...this.notes].sort((a, b) => a.round_millis - b.round_millis))
    },
    drawings() {
      return !this.isPlaying && this.exposed.drawings
    },
    mapViewbox() {
      return `${(this.mapViewport.center.x - 512) / this.mapViewport.scale} ${
        (this.mapViewport.center.y - 512) / this.mapViewport.scale
      } ${1024 / this.mapViewport.scale} ${1024 / this.mapViewport.scale}`
    },
    vodPlayer() {
      return this.state.vodPlayer
    },
    zoomingEnabled() {
      return this.mapMode !== 'replay2d' || this.vodPlayer === 'playback'
    },
    drawTextInputStyle() {
      return {
        top: `${this.drawingTextBoundaries.top || 0}px !important`,
        left: `${this.drawingTextBoundaries.left || 0}px !important`,
      }
    },
    selectable() {
      return this.mapMode === 'analytics' || this.state.timeline.selectable
    },
    renderUtilityModal() {
      let utility = { smoke: false, wall: false, utility: false }
      if (this.mapController.mapToolEvents?.smokes.length > 0) utility.smoke = true
      if (this.mapController.mapToolEvents?.walls.length > 0) utility.wall = true
      if (this.mapController.mapToolEvents?.utilities.length > 0) utility.utility = true

      if (!utility.smoke && !utility.wall && !utility.utility) return false
      return true
    },
    singleMatch() {
      return Object.keys(this.data.matches).length === 1
    },
  },
  filters: {
    formatTime: ms => formatTime(100000 - ms),
  },
  watch: {
    mapMode: {
      handler: function (button) {
        this.$refs.tabFilters?.activate()
        mixpanel.track_2d_map_navigation(
          {
            teams: Object.keys(this.data.teams).map(team_id => this.data.teams[team_id].name),
            match_ids: Object.keys(this.data.matches).join(', '),
          },
          button,
          this.data.map.name
        )
      },
      immediate: true,
    },
    spikeMode: {
      handler: function (val) {
        mixpanel.track_2d_map_spike(val)
      },
    },
    mapSubMode: {
      handler: function (mapSubMode) {
        mixpanel.track_2d_map_type(mapSubMode)
      },
    },
    mapOptions: {
      handler: function (mapOptions) {
        mixpanel.track_2d_map_options(mapOptions)
      },
    },
    playbackAdvanced: {
      handler: function () {
        let old
        const step = timestamp => {
          this.rafId = null
          if (!this.playbackAdvanced) {
            this.fps = null
            return
          }

          if (old) {
            const inc = (timestamp - old) * this.playbackRate
            if (inc > 0) {
              this.fps = Math.round(((this.fps || 0) + 1000 / (inc / this.playbackRate)) / 2)
            }
            this.currentTime += inc
          }
          old = timestamp
          this.rafId = requestAnimationFrame(step)
        }
        step()
      },
    },
    eventsForMap() {
      this.forceRender = false
    },
    forceRender(now, before) {
      if (now && !before) {
        mixpanel.track_2d_map_force_render(this.mapMode, this.mapSubMode, this.eventsForMap.length)
      }
    },
    eventsForKillfeed() {
      this.updateCurrentTimeIfNeed()
    },
    mapViewbox: {
      handler: function (val) {
        this.$refs.svg_root?.setAttribute('viewBox', val)
      },
      immediate: true,
    },
  },
  methods: {
    canRender(limit) {
      if (this.mapMode === 'replay2d') {
        limit *= 1000
      }
      return this.eventsForMap.length < limit || this.forceRender
    },
    async exportMapAsImage(type, { width = 1024, height = 1024 } = {}) {
      const svg = this.$refs.svg_root?.outerHTML
      if (!svg) {
        return null
      }

      switch (type) {
        case 'svg': {
          return 'data:text/plain;charset=utf-8,' + encodeURIComponent(svg)
        }
        case 'png': {
          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          const v = await Canvg.from(ctx, svg, presets.offscreen())

          // Render only first frame, ignoring animations and mouse.
          await v.render()

          try {
            return canvas.toDataURL()
          } catch (e) {
            console.warn(e)
            return null
          }
        }
        default:
          throw new Error(`Unhandled image type ${type}`)
      }
    },
    async downloadImage(type) {
      const dataURL = await this.exportMapAsImage(type)
      download(dataURL, `augment-${Date.now()}.${type}`)
    },
    filterEvent(event) {
      if (this.selected.time != null) {
        const eventTime = this.alignRoundTime(event)
        if (
          !eventTime ||
          eventTime < this.selected.time.start_time_millis ||
          this.selected.time.end_time_millis < eventTime
        ) {
          return false
        }
      }
      if (this.selected.zones != null) {
        if (!this.selected.zones[event.zone]) {
          return false
        }
      }

      return true
    },
    filterAdvancedPositionEvent(event) {
      if (!this.filters.round(event.round_id)) return false
      if (!this.filters.matchPlayer(event.match_player_id)) return false
      return this.filterEvent(event)
    },
    filterCurrentTime(event) {
      return Math.round(this.alignRoundTime(event) / 100) === Math.round(this.currentTime / 100)
    },
    filterUtilityEvent(event) {
      if (this.selected.utilities != null) {
        const agent = this.data.agents[this.data.matchPlayers[event.match_player_id].agent_id]
        const damageId = genDamageId({ damage_type: 'Ability', damage_item: event.ability_slot }, agent)
        if (!this.selected.utilities[damageId]) return false
      }

      // return this.filterEvent(event)
      return true
    },
    filterKillEvent(event) {
      const { kills, deaths, onlyFirst } = this.selected.kills || {
        kills: true,
        deaths: true,
      }

      let res = false
      if (kills) {
        if (this.filterEvent({ ...event, ...event.killer })) {
          res = true
        }
      }
      if (deaths) {
        if (this.filterEvent({ ...event, ...event.victim })) {
          res = true
        }
      }
      if (!res) {
        return false
      }

      if (onlyFirst) {
        if (this.data.firstRoundKills[event.round_id].round_time_millis !== event.round_time_millis) {
          return false
        }
      }

      if (this.selected.damage != null) {
        const agent = this.data.agents[this.data.matchPlayers[event.killer.match_player_id].agent_id]
        const damageId = genDamageId(event.finishing_damage, agent)
        if (!this.selected.damage[damageId]) return false
      }

      return true
    },
    getSelectedDamageName(id) {
      if (this.data.weapons[id]) {
        return this.data.weapons[id].name
      } else {
        const agentId = id.split('#').shift()
        const slot = id.split('#').pop()
        if (!this.data.agents[agentId]) {
          console.log('missing agent for ability', id)
          Sentry.captureException(new Error('Missing agent by ability id'))
          return 'ERR'
        }
        if (!this.data.agents[agentId].abilities[slot]) {
          console.log('missing slot for ability', id)
          Sentry.captureException(new Error('Missing slot by ability id'))
          return 'ERR'
        }
        return this.data.agents[agentId].abilities[slot].displayName
      }
    },
    saveReport() {
      this.$emit('saveReport', {
        name: this.reportName,
        mapSubMode: this.mapSubMode,
        spikeMode: this.spikeMode,
        mapOptions: this.mapOptions,
      })
      this.savingReport = false
      this.reportName = ''
    },
    updateLegend(legend) {
      this.sub.legend = legend
      console.log('legend', legend)
    },
    addDrawing(drawing) {
      this.exposed.drawings?.push(drawing)
      mixpanel.track_2d_map_drawing_add({ mapMode: this.mapMode })
    },
    onTypeText(drawing) {
      this.textDrawing = drawing
      this.drawingTextBoundaries = {
        left: drawing.path[0].x,
        top: drawing.path[0].y,
        width: drawing.path[drawing.path.length - 1].x - drawing.path[0].x,
        height: drawing.path[drawing.path.length - 1].y - drawing.path[0].y,
      }
    },
    onTypeTextDone() {
      this.addDrawing(this.textDrawing)
      this.textDrawing = null
    },
    updateCurrentTimeIfNeed() {
      if (
        !this.hasVod &&
        this.mapMode === 'replay2d' &&
        this.currentTime === 0 &&
        this.eventsForKillfeed &&
        this.eventsForKillfeed.length > 0
      ) {
        this.currentTime = this.alignRoundTime({
          round_time_millis: this.eventsForKillfeed[0].round_time_millis,
          round_id: this.singleRoundId,
        })
      }
    },
    clampMap({ center = this.mapViewport.center, scale = this.mapViewport.scale } = {}) {
      scale = clamp(scale, 0.5, 4)
      center = {
        x: clamp(center.x, 128, 1024 * scale - 128),
        y: clamp(center.y, 128, 1024 * scale - 128),
      }
      return { center, scale }
    },
    zoomMap(localPt, trueInFalseOut) {
      const oldScale = this.mapViewport.scale
      const scaleChange = Math.pow(1.1, trueInFalseOut ? 1 : -1)
      const { scale: newScale } = this.clampMap({
        scale: oldScale * scaleChange,
      })
      this.mapViewport = this.clampMap({
        scale: newScale,
        center: {
          x: (((this.mapViewport.center.x / oldScale - localPt.x) / newScale) * oldScale + localPt.x) * newScale,
          y: (((this.mapViewport.center.y / oldScale - localPt.y) / newScale) * oldScale + localPt.y) * newScale,
        },
      })
    },
    panMap(oldLocalPt, newLocalPt) {
      const scale = this.mapViewport.scale
      this.mapViewport = this.clampMap({
        scale,
        center: {
          x: (this.mapViewport.center.x / scale - newLocalPt.x + oldLocalPt.x) * scale,
          y: (this.mapViewport.center.y / scale - newLocalPt.y + oldLocalPt.y) * scale,
        },
      })
    },
    pinchMap(oldLocalPts, newLocalPts) {
      const center = list => ({
        x: (list[0].x + list[1].x) / 2,
        y: (list[0].y + list[1].y) / 2,
      })
      const dist = ([a, b]) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2)

      const oldCenter = center(oldLocalPts)
      const newCenter = center(newLocalPts)

      const oldScale = this.mapViewport.scale
      const { scale } = this.clampMap({
        scale: (oldScale / dist(oldLocalPts)) * dist(newLocalPts),
      })
      this.mapViewport = this.clampMap({
        scale,
        center: {
          x: (this.mapViewport.center.x / oldScale - newCenter.x + oldCenter.x) * scale,
          y: (this.mapViewport.center.y / oldScale - newCenter.y + oldCenter.y) * scale,
        },
      })
    },
    onMapMouseDown(event, localPt) {
      if (this.zoomingEnabled && event.buttons & 1) {
        this.lastMousePos = localPt
        event.preventDefault()
      }
    },
    onMapMouseMove: throttle(function (event, localPt) {
      if (this.zoomingEnabled && event.buttons & 1 && this.lastMousePos) {
        this.panMap(this.lastMousePos, localPt)
        event.preventDefault()
      }
    }),
    onMapMouseUp(event) {
      if (!event.buttons & 1) {
        this.lastMousePos = null
      }
    },
    onMapMouseWheel(event, localPt) {
      if (this.zoomingEnabled && event.ctrlKey && event.deltaY !== 0) {
        this.zoomMap(localPt, event.deltaY < 0)
        event.preventDefault()
      }
    },
    onErase(index) {
      this.exposed.drawings?.splice(index, 1)
    },
    dragHandler(dragState) {
      const isSwipe = dragState.swipe?.filter(value => value).length > 0
      if (isSwipe && this.playable && this.state.playing) {
        if (dragState.swipe[1] === 0) {
          // Horizontal swipe
          if (dragState.swipe[0] === 1) {
            // Swipe right
            this.currentTime =
              this.currentTime + 5000 > this.roundDuration ? this.roundDuration : this.currentTime + 5000
          } else if (dragState.swipe[0] === -1) {
            // Swipe left
            this.currentTime = this.currentTime - 5000 < 0 ? 0 : this.currentTime - 5000
          }
        } else if (dragState.swipe[0] === 0) {
          // Vertical swipe
          if (dragState.swipe[1] === 1) {
            console.log('swipe down')
          } else if (dragState.swipe[1] === -1) {
            console.log('swipe up')
          }
        }
      }
    },
    pinchHandler: throttle(function (pinchState) {
      const { event, pinching } = pinchState
      if (this.zoomingEnabled && pinching && this.$refs.mapDrawing && event.targetTouches) {
        const newTouches = [...event.targetTouches].map(touch => this.$refs.mapDrawing.cursorPoint(touch))
        if (this.oldTouches) {
          this.pinchMap(this.oldTouches, newTouches)
        } else {
          this.oldTouches = newTouches
        }
      } else {
        this.oldTouches = null
      }
    }),
    async saveNote(note) {
      if (this.mapMode === 'replay2d' && this.vodPlayer !== 'playback') {
        this.screenshot = this.takeScreenshot()
      }
      setTimeout(async () => {
        this.$emit('saveNote', {
          note,
          mapViewport: deepClone(this.mapViewport),
          forceRender: this.forceRender,
          submenuDisplayType: this.submenuDisplayType,
          showAbilitiesInKillfeed: this.showAbilitiesInKillfeed,
          replayTrack: this.replayTrack,
          preview: await this.exportMapAsImage('png', {
            width: BOOKMARK_THUMBNAIL_SIZE,
            height: BOOKMARK_THUMBNAIL_SIZE,
          }),
          icon: note.icon,
          startPlaying: note.startPlaying,
        })
        this.screenshot = null
      })
    },
    loadBookmark(bookmark) {
      this.mapViewport = deepClone(bookmark.state.mapViewport)
      this.forceRender = bookmark.state.forceRender
      this.submenuDisplayType = bookmark.state.submenuDisplayType
      this.showAbilitiesInKillfeed = bookmark.state.showAbilitiesInKillfeed
      this.replayTrack = bookmark.state.replayTrack
      this.$emit('loadBookmark', bookmark)
    },
    setSelected(key, value) {
      this.selected[key] = value
    },
    onKillfeedSelect(event) {
      this.currentTime = this.alignRoundTime(event)
    },
    onXvYSelect(event) {
      this.exposed.clearRoundFilters()
      this.exposed.clearTimelineFilters()

      this.selected.rounds = {
        [event.round_id]: true,
      }

      this.$nextTick(() => {
        this.currentTime = event.round_time_millis
      })
    },
    onModalOpen(modal = 'player') {
      if (modal === 'player') this.$refs.modalfilter.showModal()
      else if (modal === 'utility') this.$refs.modalutilfilter.showModal()
      else if (modal === 'overview') this.$refs.modaloverview.showModal()
      else if (modal === 'board') this.$refs.modalboard.showModal()
    },
    onModalClose(modal = 'player') {
      if (modal === 'player') this.$refs.modalfilter.close()
      else if (modal === 'utility') this.$refs.modalutilfilter.close()
      else if (modal === 'overview') this.$refs.modaloverview.close()
      else if (modal === 'board') this.$refs.modalboard.close()
    },
    loadToBoard() {
      const currSmokes =
        this.hasSmokes && this.mapOptions.includes('smokes')
          ? this.smokes.filter(s => {
              const eventTime = this.alignRoundTime(s)
              return eventTime && eventTime <= this.currentTime && this.currentTime <= eventTime + s.duration
            })
          : []

      this.$refs.drawingboard.loadFromMinimap({
        smokes: currSmokes,
        icons: this.eventsForMap,
        currTime: this.currentTime,
        spike: this.mapMode === 'replay2d' ? this.spikesForReplay : (this.events && this.events.plants) || [],
      })
      this.$refs.modalboard.showModal()
    },
  },
  beforeDestroy() {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId)
      this.rafId = null
    }
  },
}
</script>

<style scoped lang="scss">
.menu,
.submenu,
.submenu-content,
.map-filters-container {
  min-height: 0;
  flex: 1 1 100%;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}

.submenu,
.submenu-content {
  display: flex;
  flex-flow: column nowrap;

  & > * {
    flex: 0 0 auto;
  }
}

.submenu-content {
  flex: 1 1 auto !important;
}

.container {
  font-size: 1rem;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;

  .placeholder {
    box-sizing: border-box;
    background: aqua;
    border: 1px solid blueviolet;
    color: #234;
    width: 100%;
    height: 100%;
  }

  .menu {
    // background-color: #AD6E6E;
    // border-right: 2px solid $danger;
  }

  .main {
    &-container {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      min-height: 0;
      min-width: 0;
    }

    &-content {
      flex: 1;
      background-color: #191822;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      flex-direction: column;
      min-height: 0;
      overflow: hidden;
      overscroll-behavior: none;
      position: relative;

      ::v-deep {
        video {
          background-color: #191822;
        }

        svg .background {
          circle,
          rect {
            fill: #191822;
          }
        }
      }

      .toolbox {
        position: absolute;
        right: 0;
        left: 1em;
        z-index: 100;
        padding: 0.5em 0;

        &.top {
          top: 0;
        }

        &.bottom {
          bottom: 0;
        }

        .form-group {
          margin-bottom: 0;
        }
      }

      .map-content {
        position: relative;
        min-height: 0;

        &::before {
          content: '';
          padding-bottom: 100%;
          display: block;
        }

        & > * {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .submenu {
    .title {
      text-align: center;
    }
  }

  // Temporary sizing
  min-height: 50vh;

  .menu {
    width: 170px;
  }

  .submenu {
    width: 500px;
  }
}

.clock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;

  span {
    background: rgb(0 0 0 / 50%);
    padding: 0.5em 1em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    opacity: 0.666;
  }
}

.box {
  background: transparentize(#191822, 0.85);
  padding: 0.5em 1em 0.5em 0;

  &.dbg {
    font-size: 10px;
    opacity: 0.666;
  }

  &.float-left {
    border-top-right-radius: 0.5em;
  }

  &.float-right {
    border-top-left-radius: 0.5em;
  }
}

.info {
  padding-inline-start: 0;
  list-style: none;
  margin-bottom: 0;
}

.info-or-list,
.info-and-list {
  padding-inline-start: 1.5em;
  margin-bottom: 0;
}

.info-or-list {
  list-style-type: circle;
}

.info-and-list {
  list-style-type: disc;
}

.guard-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;

  .guard-content {
    max-width: 24rem;
    background: transparentize($primary, 0.3);
    padding: 1em 2em;
  }
}

.svg-root {
  width: 100%;
  height: 100%;

  &::v-deep {
    .img-only {
      display: none;
    }

    .drawText {
      padding: 5px;

      &.small {
        font-size: 1rem;
      }

      &.medium {
        font-size: 1.6em;
      }

      &.large {
        font-size: 2em;
      }
    }
  }
}

.toolbox {
  pointer-events: none;

  .filter-container {
    margin-bottom: 0;
  }

  .box {
    pointer-events: auto;
  }
}

.map-settings {
  h3,
  h4 {
    text-align: center;
  }

  select {
    width: 100%;
  }
}

.map-modal-button {
  width: 224px;
  margin-bottom: 8px;
  margin-top: 8px;
}

dialog {
  background-color: #131319;
}

::v-deep {
  .toolbox-tabs {
    position: sticky;
    top: 0;
    z-index: 100;
    background: $body-bg;
  }
}

.timeline {
  background: black;
}

.drawTextInput {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  input {
    background-color: #191822;
    color: white;
    margin-top: 1em;
  }

  .buttons {
    margin-top: 0.5em;
  }

  .panel {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;
    background-color: #141319;
    padding: 1em;
    border-radius: 0.5em;
  }
}

.ad-container {
  min-width: 300px;
  min-height: 250px;
  flex: 0 0 250px;
  margin-bottom: 8px;
}

.sidebar-right {
  display: flex;
  flex-flow: column nowrap;
}

.overview {
  color: white;
  background-color: #2e1065;
  height: 36px;
  width: 100%;
  align-self: center;
  justify-self: center;
  border-radius: 0.5rem;
  font-size: 0.65rem;
  line-height: 0.75rem;
  margin: 0px 8px 0px 8px;
}
</style>
