<template>
  <V3Button class="main-map-mode" :active="active" v-on="$listeners">
    <slot />
  </V3Button>
</template>

<script>
import V3Button from './V3Button.vue'
export default {
  name: 'MainMapModeButton',
  components: { V3Button },
  props: {
    active: Boolean,
  },
}
</script>

<style scoped lang="scss">
.main-map-mode {
  gap: 0.5em;

  // flex sizing
  flex: 1 1 auto;

  // text style
  font-size: 0.625rem;
  text-transform: uppercase;

  // text color
  color: #8e8c9a;

  ::v-deep > * {
    color: #7a7493;
    font-size: 1.125rem;
    transition: color 0.2s ease-in-out;
  }

  &.active {
    color: #fff;

    ::v-deep > * {
      color: #e03947;
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}
</style>
