<template>
  <div class="map-modal">
    <div class="map-content">
      <div class="settings-container">
        <V3BtnGroup>
          <V3Button :active="true" @click="addPlayerSearch">Add Player</V3Button>
        </V3BtnGroup>

        <div>
          <template v-for="(plr, idx) in this.playerSearch">
            <div class="player-container" :key="idx">
              <select v-model="plr.player">
                <option v-for="player in agentPlayerList" :key="player.id + player.agent" :value="player">
                  {{ player.agent }} - {{ player.name }}
                </option>
              </select>
              <span v-if="plr" :class="[plr.player.side]" class="role">{{ plr.player.side }}</span>
              <V3Button class="delete-button" @click="deletePlayer(idx)">DELETE</V3Button>
            </div>
          </template>
        </div>

        <div class="settings-button-group">
          <V3Button class="normal-button" @click="closeModal()">Cancel</V3Button>
          <V3Button class="save-button" :disabled="!this.canSave" @click="saveSnapshot">Save</V3Button>
        </div>
      </div>

      <svg
        width="512"
        height="512"
        viewBox="0 0 1024 1024"
        id="scenario-minimap"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mousemove="handleMouseMove"
        @mouseleave="handleMouseUp"
      >
        <image
          x="0"
          y="0"
          width="1024"
          height="1024"
          :href="data.map.display_icon_url"
          :transform="`rotate(${-data.map.rotate_deg} 512 512)`"
        />
        <g v-if="this.elements.length !== 0">
          <rect
            :x="this.elements[0].x"
            :y="this.elements[0].y"
            :width="this.elements[0].width"
            :height="this.elements[0].height"
            stroke="red"
            stroke-width="4"
            fill="none"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'

import exposedDataState from '../../mixins/exposedDataState.js'
import V3Button from '../v3dafi/V3Button.vue'

const makeUnique = (array = [], keys = []) => {
  if (!keys.length || !array.length) return []

  return array.reduce((list, item) => {
    const hasItem = list.find(listItem => keys.every(key => listItem[key] === item[key]))
    if (!hasItem) list.push(item)
    return list
  }, [])
}

export default {
  name: 'MapModal',
  mixins: [exposedDataState],
  components: {
    V3Button,
    V3BtnGroup,
  },
  data() {
    return {
      drawing: false,
      playerSearch: [],
      elements: [],
      pt: 0,
    }
  },
  mounted() {
    const svg = document.getElementById('scenario-minimap')
    this.pt = svg.createSVGPoint()
  },
  computed: {
    agentPlayerList() {
      let side = this.state.filters.round.teamRole
      let oppSide = this.state.filters.round.teamRole === 'atk' ? 'def' : 'atk'

      let players = []

      let listPlayerAgent = makeUnique(Object.values(this.data.matchPlayers), ['player_id', 'agent_id'])

      listPlayerAgent.forEach(player => {
        let matchPlayerList = Object.values(this.data.matchPlayers)
          .filter(p => p.player_id === player.player_id && p.agent_id === player.agent_id)
          .map(function (elem) {
            return elem.id
          })

        players.push({
          id: player.player_id,
          team: player.team_id,
          matchId: matchPlayerList,
          agent: this.data.agents[player.agent_id].name,
          agentImg: this.data.agents[player.agent_id].display_icon_small_url,
          name: this.data.players[player.player_id].name,
          side: this.state.selected.team === player.team_id ? side : oppSide,
        })
      })

      return players
    },
    canSave() {
      if (this.elements.length === 0) return false
      if (this.playerSearch.length === 0) return false

      for (let i = 0; i < this.playerSearch.length; i++) {
        if (this.playerSearch[i].player === '') return false
      }
      return true
    },
  },
  methods: {
    closeModal() {
      this.elements = []
      this.playerSearch = []
      this.$emit('close')
    },
    cursorPointer(e, svg) {
      this.pt.x = e.clientX
      this.pt.y = e.clientY
      return this.pt.matrixTransform(svg.getScreenCTM().inverse())
    },
    handleMouseDown(e) {
      const svg = document.getElementById('scenario-minimap')
      let loc = this.cursorPointer(e, svg)

      if (this.elements.length === 1 && !this.drawing) this.elements = []
      this.elements.push({
        initX: loc.x,
        initY: loc.y,
        currX: loc.x,
        currY: loc.y,
        x: loc.x,
        y: loc.y,
        width: 0,
        height: 0,
      })

      this.drawing = true
    },
    convertBoundingBox(bbox) {
      if (this.data.map.rotate_deg === 0) {
        return [
          [bbox.x, bbox.y].map(p => p / 1024),
          [bbox.x + bbox.width, bbox.y].map(p => p / 1024),
          [bbox.x + bbox.width, bbox.y + bbox.height].map(p => p / 1024),
          [bbox.x, bbox.y + bbox.height].map(p => p / 1024),
        ]
      } else {
        let newX = 1024 - bbox.x
        return [
          [bbox.y, newX - bbox.width].map(p => p / 1024),
          [bbox.y + bbox.height, newX - bbox.width].map(p => p / 1024),
          [bbox.y + bbox.height, newX].map(p => p / 1024),
          [bbox.y, newX].map(p => p / 1024),
        ]
      }
    },
    handleMouseMove(e) {
      if (!this.drawing) return
      const svg = document.getElementById('scenario-minimap')
      let loc = this.cursorPointer(e, svg)

      const ind = this.elements.length - 1
      this.elements[ind].currX = loc.x
      this.elements[ind].currY = loc.y
      this.elements[ind].x = Math.min(this.elements[ind].initX, this.elements[ind].currX)
      this.elements[ind].y = Math.min(this.elements[ind].initY, this.elements[ind].currY)
      this.elements[ind].width = Math.abs(this.elements[ind].currX - this.elements[ind].initX)
      this.elements[ind].height = Math.abs(this.elements[ind].currY - this.elements[ind].initY)
    },
    handleMouseUp() {
      if (!this.drawing) return
      this.drawing = false
    },
    addPlayerSearch() {
      this.playerSearch.push({
        player: '',
      })
    },
    deletePlayer(index) {
      this.playerSearch.splice(index, 1)
    },
    saveSnapshot() {
      this.state.filters.round.scenario.snapshots.push({
        players: this.playerSearch,
        boundingBox: this.elements[0],
        poly: this.convertBoundingBox(this.elements[0]),
        type: 'player',
      })
      this.closeModal()
    },
  },
}
</script>

<style scoped lang="scss">
.map-modal {
  width: 960px;
  height: 640px;
  display: flex;
  flex-direction: column;
}

.map-content {
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 100%;
}

.settings-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 50%;
}

.player-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 8px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 4px;
}

.settings-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.normal-button {
  background-color: white;
}

.save-button {
  background-color: white;
}

.delete-button {
  background-color: red;
  color: white;
}

#scenario-minimap {
  align-self: center;
  background-color: #191822;
}

.role {
  height: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0.75rem;
  color: white;
  width: 56px;
  text-align: center;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}
</style>
