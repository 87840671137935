<template>
  <div class="match">
    <div v-if="$scopedSlots.teams || (teams && teams.length)" class="teams">
      <slot name="teams" :teams="teams">
        <slot name="team" v-for="(team, index) in teams" :team="team" :index="index">
          <TeamCell :key="index" v-bind="team" class="team" :link="teamLinks" variant="row-reverse" size="sm" />
        </slot>
      </slot>
    </div>
    <V3ScrollableContainer class="rounds" horizontal>
      <slot name="rounds" :rounds="rounds">
        <slot
          v-for="(round, index) in rounds"
          name="round"
          :disabled="disabled && disabled[index]"
          :error="errored && errored[index]"
          :index="index"
          :highlighted="highlighted && highlighted[index]"
          :selected="selected && selected[index]"
          :round="round"
          :has-note="notes && notes[index]"
          :on-click="$event => onClick($event, round, index)"
        >
          <MatchRoundSelectorItem
            :key="index"
            :disabled="disabled && disabled[index]"
            :error="errored && errored[index]"
            :highlighted="highlighted && highlighted[index]"
            :selected="selected && selected[index]"
            :has-note="notes && notes[index]"
            v-bind="round"
            @click.native="$event => onClick($event, round, index)"
          />
        </slot>
      </slot>
      <div v-if="rounds.length > 12" class="first-half-end" @click="$emit('switch-role')">
        <IconArrowRepeat />
      </div>
      <div v-if="rounds.length > 24" class="second-half-end" @click="$emit('switch-role')">
        <IconArrowRepeat />
      </div>
    </V3ScrollableContainer>
    <div v-if="$scopedSlots.scores || (scores && scores.length)" class="scores">
      <slot name="scores" :scores="scores">
        <slot v-for="(score, index) in scores" name="score" :score="score" :index="index">
          <div :key="index" class="score">
            {{ score }}
          </div>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import MatchRoundSelectorItem from '@/components/match/roundSelector/MatchRoundSelectorItem.vue'
import TeamCell from '@/components/Table/cells/TeamCell.vue'
import IconArrowRepeat from '@/components/UI/IconArrowRepeat.vue'

import V3ScrollableContainer from '../Map/components/v3dafi/V3ScrollableContainer.vue'

export default {
  name: 'MatchRoundSelector',
  components: {
    V3ScrollableContainer,
    IconArrowRepeat,
    TeamCell,
    MatchRoundSelectorItem,
  },
  props: {
    disabled: px.arrayOf(px.bool),
    errored: px.arrayOf(px.bool),
    highlighted: px.arrayOf(px.bool),
    rounds: px.arrayOf(px.object).isRequired.def([]),
    scores: px.arrayOf(px.number),
    selected: px.arrayOf(px.bool),
    teams: px.arrayOf(px.object),
    teamLinks: px.bool.def(true),
    notes: px.arrayOf(px.bool),
  },
  methods: {
    onClick($event, round, index) {
      if (this.disabled?.[index]) {
        return
      }
      this.$emit('click', round, $event)
    },
  },
}
</script>

<style lang="scss" scoped>
.match {
  display: flex;
  background: #000;

  .teams,
  .rounds,
  .scores {
    display: flex;
    flex-wrap: nowrap;
  }

  .teams,
  .scores {
    flex-direction: column;
    justify-content: space-around;
  }

  .teams {
    align-items: flex-end;
  }

  .rounds {
    // v3 design
    gap: 3px;

    // shift-click selects the round's numbers otherwise
    user-select: none;

    // add some spacing for the scaled hover to fit in
    padding: 7px 0;

    .round {
      cursor: pointer;
      position: relative;

      &:nth-child(12) ~ * {
        order: 2;
      }

      &:nth-child(24) ~ * {
        order: 4;
      }

      //&:nth-child(13),
      //&:nth-child(25) {
      //  margin-left: calc(1.5rem + 6px);
      //  &::after {
      //    content: '';
      //    background: url('../../assets/arrowRepeat.svg');
      //    width: 1rem;
      //    height: 1rem;
      //    position: absolute;
      //    top: 26.5px;
      //    margin-top: -0.5rem;
      //    left: calc(-1.25rem - 3px);
      //    z-index: 10;
      //  }
      //}
    }

    .first-half-end {
      order: 1 !important;
    }

    .second-half-end {
      order: 3 !important;
    }

    .first-half-end,
    .second-height {
      flex: 0 0 1.5rem;
      width: 1.5rem;
      height: 100%;
      padding-top: 18.5px;
      font-size: 1rem;
      color: #898795;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .scores {
    align-items: center;
  }

  .team,
  .score {
    padding: 0 0.5em;
  }

  ::v-deep {
    .ic--label {
      max-width: 120px;
      white-space: nowrap;
    }
  }
}
</style>
