<template>
  <svg width="1em" height="1em" viewBox="0 0 512 512" fill="currentColor">
    <g>
      <path
        d="m467 0h-422c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-422c0-24.813-20.187-45-45-45zm15 467c0 8.271-6.729 15-15 15h-422c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h422c8.271 0 15 6.729 15 15z"
      />
      <path
        d="m266.645 287.854c-2.81-2.83-6.63-4.424-10.618-4.432-.009 0-.018 0-.027 0-3.978 0-7.793 1.58-10.606 4.394l-69.018 69.018c-4.498 4.498-5.67 11.343-2.924 17.081 5.008 10.468 7.548 21.263 7.548 32.085 0 8.319-1.654 17.54-4.657 25.963-1.638 4.593-.94 9.696 1.87 13.681s7.383 6.356 12.259 6.356h203.106c6.057 0 11.52-3.643 13.848-9.235s1.065-12.035-3.203-16.333zm-56.952 134.146c.859-5.419 1.307-10.821 1.307-16 0-11.869-2.112-23.624-6.292-35.073l51.253-51.253 101.589 102.326z"
      />
      <path
        d="m445.644 178.213c-3.985-2.81-9.087-3.507-13.681-1.87-8.423 3.003-17.644 4.657-25.963 4.657-10.822 0-21.617-2.54-32.086-7.549-5.739-2.747-12.583-1.573-17.081 2.924l-69.018 69.018c-5.858 5.858-5.858 15.355 0 21.213l138.578 138.578c4.297 4.298 10.752 5.569 16.347 3.252 5.605-2.322 9.26-7.792 9.26-13.858v-204.106c0-4.876-2.371-9.449-6.356-12.259zm-23.644 180.152-102.365-102.365 51.292-51.292c11.449 4.18 23.204 6.292 35.073 6.292 5.179 0 10.581-.448 16-1.307z"
      />
      <path
        d="m245.355 224.146c2.81 2.83 6.63 4.424 10.618 4.432h.027c3.978 0 7.793-1.58 10.606-4.394l69.018-69.018c4.498-4.498 5.67-11.343 2.924-17.081-5.008-10.468-7.548-21.263-7.548-32.085 0-8.319 1.654-17.54 4.657-25.963 1.638-4.593.94-9.696-1.87-13.681s-7.382-6.356-12.259-6.356h-203.106c-6.057 0-11.52 3.643-13.848 9.235s-1.065 12.035 3.203 16.333zm56.952-134.146c-.859 5.419-1.307 10.821-1.307 16 0 11.869 2.112 23.624 6.292 35.073l-51.253 51.253-101.589-102.326z"
      />
      <path
        d="m228.578 255.973c-.007-3.987-1.602-7.808-4.432-10.618l-138.578-137.578c-4.299-4.267-10.741-5.532-16.333-3.203-5.592 2.328-9.235 7.791-9.235 13.848v203.106c0 4.876 2.371 9.449 6.356 12.259 3.985 2.811 9.088 3.508 13.681 1.87 8.423-3.003 17.644-4.657 25.963-4.657 10.822 0 21.617 2.54 32.086 7.549 5.725 2.74 12.573 1.583 17.081-2.924l69.018-69.018c2.819-2.82 4.4-6.647 4.393-10.634zm-87.505 51.319c-11.449-4.18-23.204-6.292-35.073-6.292-5.179 0-10.581.448-16 1.307v-147.857l102.327 101.588z"
      />
      <path
        d="m106 361c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.187-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z"
      />
      <path
        d="m407 60c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.187-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMinimap',
}
</script>

<style scoped></style>
