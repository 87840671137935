<template functional>
  <component
    :is="$options.components.MapImageEntry"
    :rotate="props.data.map.rotate_deg"
    :x="props.event.location.x"
    :y="props.event.location.y"
    :image-url="'/images/spike/spike-minimap2.png'"
  />
</template>

<script>
import MapImageEntry from './MapImageEntry.vue'

export default {
  name: 'MapSpikeEventEntry',
  components: { MapImageEntry },
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
  },
}
</script>
