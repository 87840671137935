<template>
  <div class="left-side-tabs">
    <V3Button
      :active="vodPlayer === vodPlatform"
      @click="$emit('update:vodPlayer', vodPlatform)"
      :disabled="!hasBoradcast"
    >
      <IconBroadcast />
      <div class="lst-label">Broadcast</div>
    </V3Button>
    <V3Button
      :active="vodPlayer === 'playback'"
      @click="$emit('update:vodPlayer', 'playback')"
      :disabled="!hasPlayback"
    >
      <Icon2D />
      <div class="lst-label">2D Map</div>
    </V3Button>
    <V3Button :active="vodPlayer === 'vod'" @click="$emit('update:vodPlayer', 'vod')" :disabled="!hasMinimap">
      <IconMinimap />
      <div class="lst-label">Minimap</div>
    </V3Button>
  </div>
</template>

<script>
import Icon2D from '../../../UI/Icon2D.vue'
import IconBroadcast from '../../../UI/IconBroadcast.vue'
import IconMinimap from '../../../UI/IconMinimap.vue'

import V3Button from './V3Button.vue'
export default {
  name: 'LeftSideTabs',
  components: { V3Button, IconBroadcast, Icon2D, IconMinimap },
  props: {
    vodPlatform: String,
    vodPlayer: String,
    hasPlayback: {
      type: Boolean,
      default: true,
    },
    hasMinimap: {
      type: Boolean,
      default: true,
    },
    hasBoradcast: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped lang="scss">
.left-side-tabs {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  align-items: stretch;

  .v3-button {
    flex: 1 1 auto;
    flex-flow: column nowrap;
    font-size: 0.625rem;
    text-transform: uppercase;
    color: #e8e9ee;
    gap: 0.5rem;
    padding: 0.375rem 0.75rem 0.5rem;

    svg {
      font-size: 1.125rem;
    }

    &:not(.active) {
      color: #7a7493;

      .lst-label {
        display: none;
      }
    }

    &.active {
      background-color: #23212e;

      svg {
        color: #e03a47;
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}
</style>
