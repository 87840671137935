<template>
  <div class="tc-container" :class="{ 'tc-container-presenter': exposed.presenterMode }">
    <V3BtnGroup v-if="!hideBrokenStuff && !exposed.state.presenterMode" class="tc-align">
      <V3Button
        :active="state.selected.alignRounds === 'start'"
        @click="state.selected.alignRounds = 'start'"
        title="Align rounds by their start"
      >
        <IconAlignStart />
        Start
      </V3Button>
      <V3Button
        :active="state.selected.alignRounds === 'plant'"
        @click="state.selected.alignRounds = 'plant'"
        title="Align rounds by plant time"
      >
        <img src="/images/spike/spike-minimap.png" />
        Plant
      </V3Button>
      <V3Button
        :active="state.selected.alignRounds === 'end'"
        @click="state.selected.alignRounds = 'end'"
        title="Align rounds by their end"
      >
        <IconAlignEnd />
        End
      </V3Button>
    </V3BtnGroup>

    <V3BtnGroup v-if="exposed.state.mapMode === 'replay2d' && !exposed.presenterMode" class="tc-selection">
      <V3Button :active="selected.time != null" title="Clear selection" @click="clearSelection">
        <IconLinkInterrupt />
      </V3Button>

      <V3Button title="Set selection start" @click="setSelectionStart">
        <IconTextWrapOverflow />
      </V3Button>

      <V3Button title="Set selection end" @click="setSelectionEnd">
        <IconTextWrapOverflow class="t-sxm" />
      </V3Button>

      <V3Button title="Toggle selection mode" @click="toggleSelectable" :active="state.timeline.selectable">
        <IconSelectTimeline />
      </V3Button>
    </V3BtnGroup>

    <V3BtnGroup
      v-if="exposed.state.mapMode === 'replay2d' && !exposed.presenterMode"
      class="tc-marking"
      :class="{ invisible: !singleRound }"
    >
      <V3Button class="tc-marking-atk" @click="addMark('atk')">
        <IconBookmark />
        Mark
      </V3Button>

      <V3Button class="tc-marking-neutral" @click="addMark(null)">
        <IconBookmark />
        Mark
      </V3Button>

      <V3Button class="tc-marking-def" @click="addMark('def')">
        <IconBookmark />
        Mark
      </V3Button>
    </V3BtnGroup>

    <V3BtnGroup class="tc-zooming">
      <V3Button title="Fit zoom" @click="zoomFit">
        <IconZoomFit />
      </V3Button>

      <V3Button title="Zoom in" @click="zoomIn">
        <IconZoomIn />
      </V3Button>

      <V3Button title="Zoom out" @click="zoomOut">
        <IconZoomOut />
      </V3Button>
    </V3BtnGroup>
  </div>
</template>

<script>
import IconSelectTimeline from '@/components/UI/IconSelectTimeline.vue'

import IconAlignEnd from '../../UI/IconAlignEnd.vue'
import IconAlignStart from '../../UI/IconAlignStart.vue'
import IconBookmark from '../../UI/IconBookmark.vue'
import IconLinkInterrupt from '../../UI/IconLinkInterrupt.vue'
import IconTextWrapOverflow from '../../UI/IconTextWrapOverflow.vue'
import IconZoomFit from '../../UI/IconZoomFit.vue'
import IconZoomIn from '../../UI/IconZoomIn.vue'
import IconZoomOut from '../../UI/IconZoomOut.vue'
import exposedDataState from '../mixins/exposedDataState.js'

import V3BtnGroup from './v3dafi/V3BtnGroup.vue'
import V3Button from './v3dafi/V3Button.vue'

export default {
  name: 'TimelineControls',
  components: {
    IconZoomOut,
    IconZoomFit,
    IconZoomIn,
    IconBookmark,
    IconTextWrapOverflow,
    IconLinkInterrupt,
    IconAlignEnd,
    IconAlignStart,
    V3Button,
    V3BtnGroup,
    IconSelectTimeline,
  },
  mixins: [exposedDataState],
  computed: {
    singleRound() {
      return this.data && this.selected.rounds && Object.keys(this.selected.rounds).length === 1
        ? this.data.rounds[Object.keys(this.selected.rounds).pop()]
        : null
    },
  },
  methods: {
    clearSelection() {
      this.selected.time = null
    },
    setSelectionStart() {
      this.selected.time = Object.freeze({
        start_time_millis: this.currentTime,
        end_time_millis:
          this.selected.time?.end_time_millis > this.currentTime
            ? this.selected.time.end_time_millis
            : this.exposed.timelineEnd,
      })
    },
    setSelectionEnd() {
      this.selected.time = Object.freeze({
        start_time_millis:
          this.selected.time?.start_time_millis < this.currentTime
            ? this.selected.time.start_time_millis
            : this.exposed.timelineStart,
        end_time_millis: this.currentTime,
      })
    },
    addMark(type) {
      this.state.marks.push({
        round_id: this.singleRound.id,
        round_time_millis: Math.round(this.currentTime),
        type,
      })
    },
    zoomFit() {
      this.state.timeline.scale = 1
    },
    zoomIn() {
      this.state.timeline.scale = Math.min(this.state.timeline.scale * 1.1, 4)
    },
    zoomOut() {
      this.state.timeline.scale = Math.max(this.state.timeline.scale / 1.1, 1)
    },
    toggleSelectable() {
      this.state.timeline.selectable = !this.state.timeline.selectable
    },
  },
}
</script>

<style scoped lang="scss">
.tc {
  &-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 0.5rem;
    line-height: 1;

    &.tc-container-presenter {
      flex-flow: row-reverse nowrap;
    }

    .v3-btn-group {
      gap: 3px;
    }

    .v3-button {
      gap: 0.25rem;
      flex-wrap: wrap;
      border-radius: 3px;
      background-color: #191822;
      color: #898795;
      padding: 0.5rem;

      img {
        height: 1rem;
        transition: filter 0.2s ease-in-out;
      }

      svg {
        font-size: 1rem;
      }
    }
  }

  &-align {
    .v3-button {
      color: #898795;

      img {
        filter: invert(50%) sepia(23%) saturate(400%) hue-rotate(211deg) brightness(88%) contrast(86%);
      }

      svg {
        transition: color 0.2s ease-in-out;
      }

      &.active {
        background-image: linear-gradient(90deg, #1f1d2c 0%, #1f1d2c 11%, #252432 100%);

        img {
          filter: invert(70%) sepia(97%) saturate(914%) hue-rotate(321deg) brightness(98%) contrast(105%);
        }

        svg {
          color: #e03a47;
        }
      }
    }
  }

  &-selection,
  &-zooming {
    .v3-button {
      color: #c4c1d3;
      padding: 7px 13px;

      svg {
        font-size: 18px;
      }

      &:active,
      &:hover,
      &.active {
        background-image: linear-gradient(90deg, #333043 0%, #333043 1%, #252432 100%);
        color: #fff;
      }
    }
  }

  &-marking {
    .v3-button {
      &.active,
      &:active,
      &:hover {
        svg {
          color: inherit !important;
        }
      }

      &.tc-marking {
        &-def {
          svg {
            color: #099b83;
          }

          &.active,
          &:active,
          &:hover {
            background-image: linear-gradient(90deg, #093334 0%, #093334 11%, #143c3b 100%);
            color: #65cfbe;
          }
        }

        &-atk {
          svg {
            color: #ac493a;
          }

          &.active,
          &:active,
          &:hover {
            background-image: linear-gradient(90deg, #3d0c0c 0%, #3d0c0c 11%, #471818 100%);
            color: #eea8a8;
          }
        }

        &-neutral {
          svg {
            color: #c4c1d3;
          }

          &.active,
          &:active,
          &:hover {
            background-image: linear-gradient(90deg, #333043 0%, #333043 1%, #252432 100%);
            color: #c2bede;
          }
        }
      }
    }
  }
}

.t-sxm {
  transform: scaleX(-1);
}

.invisible {
  visibility: hidden;
}
</style>
