<template>
  <div class="ts-container">
    <div class="ts-row">
      <V3Button class="ts-team-container" @click="expanded = !expanded">
        <V3TeamLogo class="ts-team-logo" size="mdp" :src="selectedTeam.image" />
        <div class="ts-team-name">{{ selectedTeam.abbr || selectedTeam.name }}</div>
        <IconArrowBack class="ts-team-expand" :class="{ expanded }" />
      </V3Button>
      <V3BtnGroup class="ts-role-container">
        <V3Button :active="state.filters.round.teamRole === 'atk'" class="atk" @click="select('atk', selectedTeam)">
          <IconATK />
          ATK
        </V3Button>
        <V3Button :active="state.filters.round.teamRole === 'def'" class="def" @click="select('def', selectedTeam)">
          <IconDEF />
          DEF
        </V3Button>
      </V3BtnGroup>
    </div>

    <template v-if="expanded">
      <template v-for="(team, key) in data.teams">
        <div v-if="team.id !== selectedTeam.id" :key="key" class="ts-row">
          <div class="ts-team-container">
            <V3TeamLogo class="ts-team-logo" size="mdp" :src="team.image" />
            <div class="ts-team-name">{{ team.abbr || team.name }}</div>
          </div>
          <V3BtnGroup class="ts-role-container">
            <V3Button
              :active="state.selected.team === team.id && state.filters.round.teamRole === 'atk'"
              class="atk"
              @click="select('atk', team)"
            >
              <IconATK />
              ATK
            </V3Button>
            <V3Button
              :active="state.selected.team === team.id && state.filters.round.teamRole === 'def'"
              class="def"
              @click="select('def', team)"
            >
              <IconDEF />
              DEF
            </V3Button>
          </V3BtnGroup>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import V3TeamLogo from '@/components/Map/components/v3dafi/V3TeamLogo.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import IconArrowBack from '@/components/UI/IconArrowBack.vue'
import IconATK from '@/components/UI/IconATK.vue'
import IconDEF from '@/components/UI/IconDEF.vue'

export default {
  name: 'TeamSelect',
  mixins: [exposedDataState],
  components: {
    IconArrowBack,
    IconDEF,
    IconATK,
    V3BtnGroup,
    V3Button,
    V3TeamLogo,
  },
  data() {
    return {
      expanded: true,
    }
  },
  computed: {
    selectedTeam() {
      return this.data.teams[this.selected.team]
    },
  },
  methods: {
    select(role, team) {
      this.selected.team = team.id
      this.state.filters.round.teamRole = role
      this.expanded = false
    },
  },
}
</script>

<style lang="scss" scoped>
.ts {
  &-container {
    background: #211f2b;
  }

  &-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    & > * {
      flex: 1 1 50%;
    }
  }

  &-team {
    &-container {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: 0.5rem;
      justify-content: flex-start;
      padding: 0;
    }

    &-logo {
      padding-left: 0.75rem !important;
      padding-right: 1.5rem !important;
    }

    &-name {
      font-size: 14px;
      color: #a4a2ad;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-expand {
      color: #7a7493;
      margin-left: auto;
      margin-right: 0.5rem;
      transform: rotate(-180deg);
      transition: transform 0.2s ease-in-out;

      &.expanded {
        transform: rotate(-90deg);
      }
    }
  }
}

.v3-btn-group {
  gap: 0.25rem;

  .v3-button {
    gap: 0.25rem;
    font-size: 11px;
    border-radius: 3px;
    background-color: #191822 !important;

    svg {
      font-size: 1rem;
      color: #7a7493;
      transition: color 0.2s ease-in-out;
    }

    &.active {
      background-color: #131319 !important;

      &.atk {
        svg {
          color: #e03a47;
        }
      }

      &.def {
        svg {
          color: #65cfbe;
        }
      }
    }
  }
}
</style>
