<template>
  <g>
    <rect
      :x="this.x"
      :y="this.y"
      :width="this.width"
      :height="this.height"
      stroke="red"
      stroke-width="2"
      fill="lightblue"
      fill-opacity="0.5"
      @mousedown="onMouseDownSelectorMove"
      @mouseup="onMouseUpSelectorMove"
      @mousemove="onMouseMoveSelectorMove"
      style="cursor: move"
    />

    <circle
      :cx="this.x"
      :cy="this.y"
      r="6"
      fill="white"
      style="cursor: pointer"
      @mousedown="onMouseDownResizeMove($event, 'topleft')"
      @mouseup="onMouseUpResizeMove"
      @mousemove="onMouseMoveResizeMove"
    />

    <circle
      :cx="this.x"
      :cy="this.y + this.height"
      r="6"
      fill="white"
      style="cursor: pointer"
      @mousedown="onMouseDownResizeMove($event, 'botleft')"
      @mouseup="onMouseUpResizeMove"
      @mousemove="onMouseMoveResizeMove"
    />

    <circle
      :cx="this.x + this.width"
      :cy="this.y"
      r="6"
      fill="white"
      style="cursor: pointer"
      @mousedown="onMouseDownResizeMove($event, 'topright')"
      @mouseup="onMouseUpResizeMove"
      @mousemove="onMouseMoveResizeMove"
    />

    <circle
      :cx="this.x + this.width"
      :cy="this.y + this.height"
      r="6"
      fill="white"
      style="cursor: pointer"
      @mousedown="onMouseDownResizeMove($event, 'botright')"
      @mouseup="onMouseUpResizeMove"
      @mousemove="onMouseMoveResizeMove"
    />

    <g v-if="playerPercentage.length !== 0">
      <g
        v-for="(item, index) in playerPercentage"
        :key="item.id"
        :transform="`translate(1024, ${1014 - (index + 5) * 30})`"
      >
        <text x="0" y="20" stroke="white" fill="white" font-size="1.5rem">{{ item.posPercentage.toFixed(2) }} %</text>
        <image x="120" y="0" width="26" height="26" :href="item.agentImg" />
        <text x="160" y="20" stroke="white" fill="white" font-size="1.5rem">{{ item.name }}</text>
      </g>
    </g>
  </g>
</template>

<script>
import { polygonContains } from 'd3-polygon'

import exposedDataState from '../mixins/exposedDataState.js'
import { pxEvents } from '../types.js'

const makeUnique = (array = [], keys = []) => {
  if (!keys.length || !array.length) return []

  return array.reduce((list, item) => {
    const hasItem = list.find(listItem => keys.every(key => listItem[key] === item[key]))
    if (!hasItem) list.push(item)
    return list
  }, [])
}

export default {
  name: 'MinimapSelector',
  mixins: [exposedDataState],
  props: {
    events: pxEvents().isRequired,
  },
  data() {
    return {
      pt: 0,
      width: 100,
      height: 100,
      x: 0,
      y: 0,
      drag: false,
      resize: false,
      resizeAnchor: '',
      lastMouseX: 0,
      lastMouseY: 0,
    }
  },
  mounted() {
    this.pt = this.$el.ownerSVGElement.createSVGPoint()
  },
  computed: {
    playerPercentage() {
      if (this.drag || this.resize) return []
      let players = {}
      const bbox = this.convertBoundingBox()
      const eventsLocationFiltered = this.events.filter(e => polygonContains(bbox, [e.location.x, e.location.y]))

      eventsLocationFiltered.forEach(e => {
        if (e.match_player_id in players) players[e.match_player_id] += 1
        else players[e.match_player_id] = 1
      })

      const totalPositions = Object.values(players).reduce((acc, curr) => acc + curr, 0)

      let playerAgentList = makeUnique(
        Object.values(this.data.matchPlayers).filter(plr => plr.id in players),
        ['player_id', 'agent_id']
      )

      let legend = []

      playerAgentList.forEach(plr => {
        let matchPlayerList = Object.values(this.data.matchPlayers)
          .filter(p => p.player_id === plr.player_id && p.agent_id === plr.agent_id)
          .map(function (elem) {
            return elem.id
          })

        let playerPosTotal = 0

        matchPlayerList.forEach(id => {
          playerPosTotal += id in players ? players[id] : 0
        })

        legend.push({
          id: plr.player_id,
          team: plr.team_id,
          agent: this.data.agents[plr.agent_id].name,
          agentImg: this.data.agents[plr.agent_id].display_icon_small_url,
          name: this.data.players[plr.player_id].name,
          posTotal: playerPosTotal,
          posPercentage: (playerPosTotal / totalPositions) * 100,
        })
      })

      return legend.sort((a, b) => {
        if (a.team > b.team) return -1
        if (a.team < b.team) return 1

        if (a.posPercentage < b.posPercentage) return -1
        if (a.posPercentage > b.posPercentage) return 1

        return 0
      })
    },
  },
  methods: {
    cursorPoint(evt) {
      this.pt.x = evt.clientX
      this.pt.y = evt.clientY
      const localPt = this.pt.matrixTransform(this.$el.getScreenCTM().inverse())
      return Object.freeze({ x: localPt.x, y: localPt.y })
    },
    convertBoundingBox() {
      if (this.data.map.rotate_deg === 0) {
        return [
          [this.x, this.y].map(p => p / 1024),
          [this.x + this.width, this.y].map(p => p / 1024),
          [this.x + this.width, this.y + this.height].map(p => p / 1024),
          [this.x, this.y + this.height].map(p => p / 1024),
        ]
      } else {
        let newX = 1024 - this.x
        return [
          [this.y, newX - this.width].map(p => p / 1024),
          [this.y + this.height, newX - this.width].map(p => p / 1024),
          [this.y + this.height, newX].map(p => p / 1024),
          [this.y, newX].map(p => p / 1024),
        ]
      }
    },
    onMouseDownSelectorMove(e) {
      this.drag = true
      e.preventDefault()
      let currPoint = this.cursorPoint(e)
      this.lastMouseX = currPoint.x
      this.lastMouseY = currPoint.y
    },
    onMouseUpSelectorMove(e) {
      this.drag = false
      e.preventDefault()
    },
    onMouseMoveSelectorMove(e) {
      e.preventDefault()
      if (this.drag) {
        let currPoint = this.cursorPoint(e)
        let deltaX = currPoint.x - this.lastMouseX
        let deltaY = currPoint.y - this.lastMouseY

        this.x += deltaX
        this.y += deltaY
        this.lastMouseX = currPoint.x
        this.lastMouseY = currPoint.y
      }
    },
    onMouseDownResizeMove(e, anchor) {
      this.resize = true
      this.resizeAnchor = anchor
      e.preventDefault()
      let currPoint = this.cursorPoint(e)
      this.lastMouseX = currPoint.x
      this.lastMouseY = currPoint.y
    },
    onMouseUpResizeMove(e) {
      this.resize = false
      this.resizeAnchor = ''
      e.preventDefault()
    },
    onMouseMoveResizeMove(e) {
      e.preventDefault()
      if (this.resize) {
        let currPoint = this.cursorPoint(e)
        let deltaX = currPoint.x - this.lastMouseX
        let deltaY = currPoint.y - this.lastMouseY

        switch (this.resizeAnchor) {
          case 'topright':
            if (this.width + deltaX < 50 || this.height - deltaY < 50) break
            this.width += deltaX
            this.y += deltaY
            this.height -= deltaY
            break
          case 'topleft':
            if (this.width - deltaX < 50 || this.height - deltaY < 50) break
            this.x += deltaX
            this.width -= deltaX
            this.y += deltaY
            this.height -= deltaY
            break
          case 'botleft':
            if (this.width - deltaX < 50 || this.height + deltaY < 50) break
            this.x += deltaX
            this.width -= deltaX
            this.height += deltaY
            break
          case 'botright':
            if (this.width + deltaX < 50 || this.height + deltaY < 50) break
            this.width += deltaX
            this.height += deltaY
            break
        }

        this.lastMouseX = currPoint.x
        this.lastMouseY = currPoint.y
      }
    },
  },
}
</script>
