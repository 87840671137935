<template>
  <b-form-radio-group
    :options="options"
    name="positions"
    switches
    size="lg"
    :checked="value"
    @change="val => $emit('change', val)"
  />
</template>

<script>
import { BFormRadioGroup } from 'bootstrap-vue'
import px from 'vue-types'

export default {
  name: 'PositionsFilter',
  components: {
    BFormRadioGroup,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  inject: {
    setShortStatus: {
      from: 'setShortStatus',
      default: () => {},
    },
  },
  props: {
    value: px.oneOf([null, 'first', 'pre', 'post', 'on']),
  },
  data: () => ({
    options: [
      { text: 'All', value: null },
      { text: 'Plant', value: 'on' },
      { text: 'First 30 sec', value: 'first' },
      { text: 'Pre-Plant', value: 'pre' },
      { text: 'Post-Plant', value: 'post' },
    ],
  }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.setShortStatus(val && this.options.find(o => o.value === val)?.text)
      },
    },
  },
}
</script>

<style scoped></style>
