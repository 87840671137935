<template>
  <V3Panel expandable :expanded.sync="expanded" title="Ultimate orbs at start" variant="secondary">
    <V3Container :visible="expanded" class="orbs-filter">
      <div v-for="team in teams" :key="team.id" class="team-orbs">
        <V3Button :active="isTeamActive(team)" class="team-orbs-header">
          <V3TeamLogo class="team-orbs-header-logo" :grayscale="!isTeamActive(team)" :src="team.image" />
          <div class="team-orbs-header-name">{{ team.name }}</div>
        </V3Button>
        <template v-for="orbPlayer in teamPlayers(team.id)">
          <V3MapFilter
            :active="isActive(orbPlayer)"
            class="player"
            :icon="data.agents[orbPlayer.agentId].display_icon_small_url"
            :label="data.players[orbPlayer.playerId].name"
            :key="orbPlayer.id"
            @click="toggle(orbPlayer)"
          >
            <V3Button
              v-for="(group, idx) in getOrbGroups(orbPlayer)"
              :key="`${orbPlayer.id} + ${idx}`"
              :active="isGroupActive(orbPlayer, group)"
              @click="toggle(orbPlayer, group)"
            >
              <template v-if="group.label"> {{ group.label }} </template>
              <template v-if="group.image">
                <V3Image class="ability" :src="group.image" />
              </template>
            </V3Button>
          </V3MapFilter>
        </template>
      </div>
    </V3Container>
  </V3Panel>
</template>

<script>
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import V3Container from '@/components/Map/components/v3dafi/V3Container.vue'
import V3Image from '@/components/Map/components/v3dafi/V3Image.vue'
import V3MapFilter from '@/components/Map/components/v3dafi/V3MapFilter.vue'
import V3Panel from '@/components/Map/components/v3dafi/V3Panel.vue'
import V3TeamLogo from '@/components/Map/components/v3dafi/V3TeamLogo.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import stringCompare from '@/utils/stringCompare'

export default {
  name: 'OrbsFilter',
  mixins: [exposedDataState],
  components: {
    V3TeamLogo,
    V3MapFilter,
    V3Image,
    V3Button,
    V3Container,
    V3Panel,
  },
  model: {
    event: 'change',
    prop: 'selected',
  },
  data() {
    return {
      expanded: true,
    }
  },
  computed: {
    players() {
      return this.data.orbsPlayers
    },
    selectedTeamId() {
      return this.selected.team
    },
    teams() {
      return Object.values(this.data.teams).sort(a => (a.id === this.selectedTeamId ? -1 : 0))
    },
  },
  methods: {
    isActive(orbPlayer) {
      return Object.values(this.state.filters.round.orbs[orbPlayer.id] || {}).filter(Boolean).length > 0
    },
    isGroupActive(orbPlayer, group) {
      for (let i = group.min; i <= group.max; i++) {
        if ((this.state.filters.round.orbs[orbPlayer.id] || {})[i]) {
          return true
        }
      }
      return false
    },
    isTeamActive(team) {
      return this.teamPlayers(team.id).some(orbPlayer => this.isActive(orbPlayer))
    },
    getFullOrbGroup(orbPlayer) {
      return {
        min: 0,
        max: orbPlayer.maxUltimateOrbs,
      }
    },
    getOrbGroups(orbPlayer) {
      const orbs = orbPlayer.maxUltimateOrbs

      return [
        {
          min: 0,
          max: orbs - 4,
        },
        {
          min: orbs - 3,
          max: orbs - 3,
        },
        {
          min: orbs - 2,
          max: orbs - 2,
        },
        {
          min: orbs - 1,
          max: orbs - 1,
        },
        {
          min: orbs,
          max: orbs,
        },
      ].map(({ min, max }) => ({
        min,
        max,
        label: min === orbs ? null : min < max ? `${min}-${max}/${orbs}` : `${min}/${orbs}`,
        image: min === orbs ? this.data.agents[orbPlayer.agentId].abilities['Ultimate'].displayIcon : null,
      }))
    },
    toggle(orbPlayer, group = this.getFullOrbGroup(orbPlayer)) {
      const newValue = !this.isGroupActive(orbPlayer, group)
      const selected = { ...this.state.filters.round.orbs[orbPlayer.id] }
      for (let idx = group.min; idx <= group.max; idx++) {
        selected[idx] = newValue
      }
      this.$set(
        this.state.filters.round.orbs,
        orbPlayer.id,
        Object.values(selected).filter(Boolean).length ? selected : null
      )
    },
    teamPlayers(teamId) {
      return Object.freeze(
        Object.values(this.data.orbsPlayers)
          .filter(orbsPlayer => orbsPlayer.teamId === teamId)
          .sort(stringCompare(orbsPlayer => this.data.agents[orbsPlayer.agentId].name))
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.orbs-filter {
  ::v-deep {
    .v3-filter-label {
      overflow: hidden;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-container {
        flex-flow: row nowrap;
      }
    }
  }
}

.team-orbs {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: 3px;

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    background-color: #211f2b;
    font-size: 10px;
    color: #898795;
    padding: 0;

    &.active {
      background-color: #141319;
      color: #fefefe;
    }
  }

  & + & {
    margin-top: 0.5rem;
  }
}
</style>
