<template>
  <V3Panel title="KD Zone Filter" class="kd-filter-panel">
    <V3BtnGroup v-if="this.data.map.name === 'Ascent' && this.data.zones">
      <V3Button
        :active="this.state.kdZoneMode.zone"
        @click="() => (this.state.kdZoneMode.zone = !this.state.kdZoneMode.zone)"
      >
        Zone Mode
      </V3Button>
    </V3BtnGroup>

    <V3BtnGroup>
      <V3Button :active="this.state.kdZoneMode.view === 'all'" @click="setViewMode('all')"> Display All </V3Button>
      <V3Button :active="this.state.kdZoneMode.view === 'kill'" @click="setViewMode('kill')"> Kills Only </V3Button>
      <V3Button :active="this.state.kdZoneMode.view === 'death'" @click="setViewMode('death')"> Deaths Only </V3Button>
    </V3BtnGroup>

    <V3BtnGroup>
      <V3Button :active="this.state.kdZoneMode.plantTime === 'all'" @click="setPlantTime('all')"> All </V3Button>
      <V3Button :active="this.state.kdZoneMode.plantTime === 'pre'" @click="setPlantTime('pre')"> Pre-Plant </V3Button>
      <V3Button :active="this.state.kdZoneMode.plantTime === 'post'" @click="setPlantTime('post')">
        Post-Plant
      </V3Button>
    </V3BtnGroup>

    <V3BtnGroup>
      <V3Button :active="this.state.kdZoneMode.opps" @click="setShowOpps(true)"> Display Opponents </V3Button>
      <V3Button :active="!this.state.kdZoneMode.opps" @click="setShowOpps(false)"> Hide Opponents </V3Button>
    </V3BtnGroup>

    <V3BtnGroup>
      <V3Button
        :active="this.state.kdZoneMode.first"
        @click="() => (this.state.kdZoneMode.first = !this.state.kdZoneMode.first)"
        >Only Display FK/FD</V3Button
      >
    </V3BtnGroup>

    <V3BtnGroup>
      <V3Button
        :active="this.state.kdZoneMode.legend"
        @click="() => (this.state.kdZoneMode.legend = !this.state.kdZoneMode.legend)"
        >Display Player Color</V3Button
      >
    </V3BtnGroup>
  </V3Panel>
</template>

<script>
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'

import V3Button from './v3dafi/V3Button.vue'
import V3Panel from './v3dafi/V3Panel.vue'

export default {
  name: 'KDZoneFilter',
  mixins: [exposedDataState],
  components: {
    V3Button,
    V3BtnGroup,
    V3Panel,
  },
  methods: {
    setViewMode(val) {
      this.state.kdZoneMode.view = val
    },
    setShowOpps(val) {
      this.state.kdZoneMode.opps = val
    },
    setPlantTime(val) {
      this.state.kdZoneMode.plantTime = val
    },
  },
}
</script>

<style lang="scss" scoped>
.kd-filter-panel {
  margin: 0;
  padding: 4px;
  background-color: #2f2c3b;
  width: 100%;
}
</style>
