/**
 * A utility function to download a dataURI as a file.
 * @param {string} dataURL
 * @param {string} filename
 */
export default function download(dataURL, filename) {
  const element = document.createElement('a')
  element.setAttribute('href', dataURL)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
