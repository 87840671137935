<template>
  <div class="v3-container" :class="{ visible }" :style="styles">
    <transition name="slide">
      <div ref="content" v-if="visible" class="v3-container-content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'V3Container',
  props: {
    visible: Boolean,
  },
  data: () => ({
    trigger: 1,
    height: '100vh',
  }),
  computed: {
    contentHeight() {
      // need to reference the trigger to be able to trigger a re-render
      return this.trigger > 0 && this.$refs.content ? `${this.$refs.content.clientHeight}px` : '100vh'
    },
    styles() {
      return {
        '--v3-container-content-height': this.height,
      }
    },
  },
  watch: {
    contentHeight(val) {
      if (val !== '100vh' && val !== '0px') {
        this.height = val
      }
    },
    visible(val) {
      if (!val) {
        // trigger recompute when closing
        this.trigger++
      } else {
        // trigger recompute when opening after the content is rendered
        requestAnimationFrame(() => {
          this.trigger++
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.v3-container {
  overflow: hidden;
  scroll-snap-align: end;
  scroll-snap-stop: always;
}

.slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.2s ease-in-out;
    max-height: var(--v3-container-content-height);
  }

  &-enter,
  &-leave-to {
    //transform: translateY(-100%);
    max-height: 0;
  }
}
</style>
