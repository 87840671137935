<template>
  <div class="ad-container" id="banner" :style="`background-image: url('${BASE_URL}/images/logo.svg');`"></div>
</template>

<script>
import loadScript from 'load-script'

export default {
  name: 'OverwolfBannerAd',
  mounted() {
    this.loadAdsSDK()
  },
  data() {
    return {
      bannerAd: null,
    }
  },
  computed: {
    BASE_URL() {
      return process.env.VUE_APP_PUBLIC_BASE || ''
    },
  },
  methods: {
    loadAdsSDK() {
      loadScript('https://content.overwolf.com/libs/ads/latest/owads.min.js', err => {
        if (err) {
          console.log('onAdsLoadError', err)
          this.completed = true
        } else {
          this.initAd()
        }
      })
    },
    initAd() {
      if (this.bannerAd) {
        return
      }

      // eslint-disable-next-line no-undef
      this.bannerAd = new OwAd(document.getElementById('banner'), {
        size: {
          width: 300,
          height: 250,
        },
      })

      this.bannerAd.addEventListener('player_loaded', this.onAdEvent)
      this.bannerAd.addEventListener('display_ad_loaded', this.onAdEvent)
      this.bannerAd.addEventListener('play', this.onAdEvent)
      this.bannerAd.addEventListener('impression', this.onAdEvent)
      this.bannerAd.addEventListener('complete', this.onAdEvent)
      this.bannerAd.addEventListener('error', this.onAdEvent)
    },
    onAdEvent(event) {
      console.log('onAdEvent', event)
    },
  },
}
</script>

<style scoped lang="scss">
.ad-container {
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 300px;
  min-height: 250px;
  max-width: 300px;
  max-height: 250px;
}
</style>
