import axios from '../axios.js'

export const submitPersistentData = async ({ body } = {}) => {
  const currentData = (await getPersistentData()) || {}
  const response = await axios.put('/users/v2/persistent-data', {
    ...currentData,
    ...body,
  })

  return response.data
}

export const getPersistentData = async () => {
  const response = await axios.get('/users/v2/persistent-data')

  return response.data
}
