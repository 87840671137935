<template functional>
  <line
    :x1="props.x1 * 1024"
    :y1="props.y1 * 1024"
    :x2="props.x2 * 1024"
    :y2="props.y2 * 1024"
    :stroke="props.color"
    :stroke-width="props.width"
  />
</template>

<script>
import px from 'vue-types'

export default {
  name: 'MapLineEntry',
  props: {
    color: px.string.def('red'),
    x1: Number,
    y1: Number,
    x2: Number,
    y2: Number,
    width: { type: [Number, String], default: 1 },
  },
}
</script>

<style lang="scss" scoped>
.map-line-entry {
  width: 100%;
  height: 100%;
}
</style>
