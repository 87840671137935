// display only these abilities in filter
export default [
  'Blade Storm',
  'FRAG/ment',
  'Headhunter',
  "Hunter's Fury",
  'Orbital Strike',
  'Paint Shells',
  'Shock Bolt',
  'Showstopper',
  'Tour De Force',
  'TURRET',
]
