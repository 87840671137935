<template functional>
  <g :transform="`translate(${props.x * 1024},${props.y * 1024})`">
    <g v-if="props.direction != null" :transform="($options.filters.radToDeg(props.direction) + 90) | rot">
      <path :fill="props.color" :d="props.size | dDirection" />
    </g>
    <path v-else :fill="props.color" :d="props.size | dCircle" />
  </g>
</template>

<script>
export default {
  name: 'MapPoi',
  props: {
    color: { type: String, default: '#0f0' },
    direction: Number,
    rotate: Number,
    size: { type: Number, default: 35 },
    x: Number,
    y: Number,
  },
  filters: {
    dCircle(d) {
      const r = d / 2
      return `M ${-r},0 a${r},${r} 0 1,1 ${r * 2},0 a${r},${r} 0 1,1 ${-r * 2},0`
    },
    dDirection(d) {
      const r = d / 2
      const x0 = r * 1.5
      const a = (r * r) / x0
      const b = Math.sqrt(r * r - a * a)

      return `M${a},${b} a${r},${r} 0 1,1 0,${-b * 2} L ${x0},0Z`
    },
    radToDeg(rad) {
      return (rad * 180) / Math.PI + 180
    },
    rot(deg) {
      return `rotate(${deg},0,0)`
    },
  },
}
</script>
