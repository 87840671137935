<template>
  <V3MapFilter :active="value !== null" label="Trades" @click="$emit('change', null)">
    <V3Button :active="['killer', 'both'].includes(value)" @click="toggle('killer')">
      <div class="team-name">{{ selectedTeam.abbr || selectedTeam.name }}</div>
    </V3Button>
    <V3Button :active="['victim', 'both'].includes(value)" @click="toggle('victim')">
      <div class="team-name">{{ opponentTeam ? opponentTeam.abbr || opponentTeam.name : 'Opponent' }}</div>
    </V3Button>
  </V3MapFilter>
</template>

<script>
import px from 'vue-types'

import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import IconTrade from '@/components/UI/IconTrade.vue'

import { pxSelectedData, pxToolData } from '../../types.js'
import V3MapFilter from '../v3dafi/V3MapFilter.vue'

export default {
  name: 'TradesFilter',
  components: {
    V3MapFilter,
    // eslint-disable-next-line vue/no-unused-components
    IconTrade,
    V3Button,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  inject: {
    setShortStatus: {
      from: 'setShortStatus',
      default: () => {},
    },
  },
  props: {
    data: pxToolData().isRequired,
    selected: pxSelectedData().isRequired,
    value: px.oneOf(['killer', 'victim', 'none']),
  },
  computed: {
    opponentTeam() {
      const teams = Object.values(this.data.teams)
      if (teams.length !== 2) return null
      return teams.find(team => team.id !== this.selectedTeam.id)
    },
    selectedTeam() {
      return this.data.teams[this.selected.team]
    },
  },
  methods: {
    toggle(val) {
      const op = val === 'killer' ? 'victim' : 'killer'
      let newVal
      if (this.value == null) {
        newVal = val
      } else if (this.value === 'both') {
        newVal = op
      } else if (this.value === val) {
        newVal = null
      } else {
        newVal = 'both'
      }

      this.$emit('change', newVal)
    },
  },
}
</script>
