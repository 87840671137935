<template>
  <g :opacity="faded ? $options.meta.fadeOpacity : 1">
    <component
      :is="$options.components.MapPoi"
      :color="data.roundTeams[event.round_team_id].role | roleColor"
      :direction="event.location.view_radians"
      :rotate="data.map.rotate_deg"
      :x="event.location.x"
      :y="event.location.y"
    />
    <component
      :is="$options.components.MapImageEntry"
      :image-url="
        data.agents[data.matchPlayers[event.match_player_id].agent_id].abilities[event.ability_slot].displayIcon
      "
      :rotate="data.map.rotate_deg"
      :x="event.location.x"
      :y="event.location.y"
    />
  </g>
</template>

<script>
import { MAP_FADED_EVENTS_OPACITY } from '@/constants.js'

import MapImageEntry from './MapImageEntry.vue'
import MapPoi from './MapPoi.vue'

export default {
  name: 'MapUtilityEventEntry',
  components: { MapPoi, MapImageEntry },
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
    faded: { type: Boolean, default: false },
  },
  filters: {
    roleColor(role) {
      switch (role) {
        case 'def':
          return 'rgba(0, 95, 63, 0.5)'
        case 'atk':
          return 'rgba(163, 24, 0, 0.5)'
      }
    },
  },
  meta: {
    fadeOpacity: MAP_FADED_EVENTS_OPACITY,
  },
}
</script>
