<template>
  <div class="map-modal">
    <V3Button style="color: black; width: 60px; background-color: white" @click="$emit('close')"> Close </V3Button>

    <V3BtnGroup>
      <V3Button @click="updateModalMode('overview')" :active="modalMode === 'overview'"> Round Overview </V3Button>
    </V3BtnGroup>

    <div v-if="modalMode === 'overview'" class="wrapper">
      <div class="tooltipmain">
        ?
        <span class="tooltiptext">
          Players alive at the end of the round are colored. Players whose Ultimates are ready at the start of the round
          will have black borders (if Ultimate Data is available).
        </span>
      </div>
      <template v-for="(round, idx) in roundOverview">
        <div class="overviewRow" :key="idx">
          <!-- ROUND NUMBER -->
          <div class="roundNumber">
            {{ round.round_number }}
          </div>

          <!-- TEAMS -->
          <div class="teamGrid">
            <div class="teamRole" :class="[round.red_role]">
              <p class="truncate">{{ teams[round.red_team].name }}</p>
            </div>

            <div class="teamRole" :class="[round.blue_role]">
              <p class="truncate">{{ teams[round.blue_team].name }}</p>
            </div>
          </div>

          <!-- TEAMS Score -->
          <div class="teamGrid">
            <div class="score" :class="round.winner === 'red' ? 'winner' : ''">
              {{ round.red_score }}
            </div>

            <div class="score" :class="round.winner === 'blue' ? 'winner' : ''">
              {{ round.blue_score }}
            </div>
          </div>

          <!-- OUTCOME -->
          <div class="teamGrid">
            <div
              class="outcome"
              :class="['red' === round.winner ? round.red_role + ' redTeam' : round.blue_role + ' blueTeam']"
            >
              <component :is="icon(round.outcome)"></component>
              {{ round.plant }}
            </div>
          </div>

          <!-- ECON -->
          <div class="teamGrid" style="justify-content: start">
            <div class="econ">
              {{ round.red_econ }}
            </div>

            <div class="econ">
              {{ round.blue_econ }}
            </div>
          </div>

          <!-- END STATE and ULTS -->
          <div class="teamGrid" style="justify-content: start; row-gap: 4px">
            <div style="display: flex; flex-direction: row; column-gap: 10px; flex-grow: 0">
              <div
                v-for="(alive, red_id) in round.end_state.red"
                :class="[round.ults.red[red_id] ? 'ult' : '']"
                :key="red_id"
              >
                <img
                  :src="players[red_id].agent_img"
                  :alt="players[red_id].agent_name"
                  :class="[alive ? round.red_role : 'dead']"
                  style="width: 26px; height: 26px; border-radius: 9999px; padding: 2px"
                />
              </div>
              <span style="font-size: 0.55rem; align-self: end">{{ round.end_count.red }}x</span>
            </div>

            <div style="display: flex; flex-direction: row; column-gap: 10px; flex-grow: 0">
              <div
                v-for="(alive, blue_id) in round.end_state.blue"
                :class="[round.ults.blue[blue_id] ? 'ult' : '']"
                :key="blue_id"
              >
                <img
                  :src="players[blue_id].agent_img"
                  :alt="players[blue_id].agent_name"
                  :class="[alive ? round.blue_role : 'dead']"
                  style="width: 26px; height: 26px; border-radius: 9999px; padding: 2px"
                />
              </div>
              <span style="font-size: 0.55rem; align-self: end">{{ round.end_count.blue }}x</span>
            </div>
          </div>
        </div>

        <hr v-if="idx === 11 || (idx === 23 && roundOverview.length > 24)" :key="idx + 'break'" />
      </template>
    </div>
  </div>
</template>

<script>
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import { OUTCOME } from '@/components/maptool2/consts'

import exposedDataState from '../mixins/exposedDataState.js'

export default {
  name: 'OverviewModal',
  mixins: [exposedDataState],
  components: {
    V3Button,
    V3BtnGroup,
  },
  data() {
    return {
      modalMode: 'overview',
      players: {},
      teams: {},
      playerStats: [],
      teamStats: [],
      roundStats: [],
      roundOverview: [],
    }
  },
  mounted() {
    this.getPlayers()
    this.getTeams()
    this.getRoundOverview()
  },
  computed: {},
  methods: {
    updateModalMode(mode) {
      this.modalMode = mode
    },
    icon(outcome) {
      return OUTCOME[outcome]?.icon || 'span'
    },
    getPlayers() {
      Object.entries(this.data?.matchPlayers || {}).forEach(([, player]) => {
        this.players[player.id] = {
          id: player.id,
          name: this.data.players[player.player_id].name,
          agent_id: player.agent_id,
          agent_name: this.data.agents[player.agent_id].name,
          agent_img: this.data.agents[player.agent_id].display_icon_small_url,
          team: player.team_id,
          side_start: this.data.matchTeams[player.match_team_id].grid.toLowerCase(),
          max_orbs: player.max_ultimate_orbs,
        }
      })
    },
    getTeams() {
      Object.entries(this.data?.matchTeams || {}).forEach(([, team]) => {
        this.teams[team.id] = {
          id: team.id,
          team_img: this.data.teams[team.team_id].image,
          name: this.data.teams[team.team_id].abbr
            ? this.data.teams[team.team_id].abbr
            : this.data.teams[team.team_id].name,
          side_start: team.grid,
          players: team.match_players,
        }
      })
    },
    getSide(roundTeamId) {
      return this.data.roundTeams[roundTeamId].role
    },
    getRoundOverview() {
      let score = {
        red: 0,
        blue: 0,
      }

      let kills = {}
      this.mapController.mapToolEvents.kills.forEach(k => {
        if (!(k.round_id in kills)) kills[k.round_id] = []
        kills[k.round_id].push(k)
      })

      // Array of Players in the Round, subtract if victim
      Object.entries(this.data?.rounds || {}).forEach(([, r]) => {
        this.roundOverview.push({
          id: r.id,
          round_number: r.round_num + 1,
          round_duration: r.round_duration_millis,
          plant: r.plant_site,
          outcome: r.outcome,
          winner: '',
          red_team: '',
          blue_team: '',
          red_role: '',
          blue_role: '',
          red_econ: '',
          blue_econ: '',
          red_score: '',
          blue_score: '',
          end_state: { red: [], blue: [] },
          end_count: { red: 5, blue: 5 },
          ults: { red: [], blue: [] },
        })

        let index = this.roundOverview.length - 1
        for (let round_team of r.round_teams) {
          let curr_team = this.data.roundTeams[round_team]
          let side = curr_team.grid === 'Red' ? 'red' : 'blue'

          if (curr_team.win) {
            this.roundOverview[index].winner = curr_team.grid.toLowerCase()
            score[side] += 1
          }

          this.roundOverview[index][side + '_team'] = curr_team.match_team_id
          this.roundOverview[index][side + '_role'] = curr_team.role
          this.roundOverview[index][side + '_econ'] = curr_team.eco
          this.roundOverview[index][side + '_score'] = score[side]
          this.roundOverview[index].end_state[side] = Object.fromEntries(
            this.teams[curr_team.match_team_id].players.map(plr => [plr, true])
          )

          this.roundOverview[index].ults[side] = Object.fromEntries(
            this.teams[curr_team.match_team_id].players.map(plr => [plr, false])
          )
        }

        // END STATE
        kills[r.id].forEach(k => {
          let victim = this.players[k.victim.match_player_id]
          this.roundOverview[index].end_state[victim.side_start][victim.id] = false

          this.roundOverview[index].end_count[victim.side_start] -= 1

          if (k.finishing_damage.damage_type === 'Ability' && k.finishing_damage.damage_item === 'Ultimate') {
            if (this.players[k.killer.match_player_id].agent_name === 'Sage') {
              this.roundOverview[index].end_state[victim.side_start][victim.id] = true
              this.roundOverview[index].end_count[victim.side_start] += 1
            }
          }
        })

        // ORBS
        if (Object.keys(this.data.orbsPlayers).length) {
          r.round_players.forEach(plr => {
            let player = this.players[this.data.roundPlayers[plr].match_player_id]
            if (this.data.roundPlayers[plr].ultimate_orbs === player.max_orbs)
              this.roundOverview[index].ults[player.side_start][player.id] = true
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.map-modal {
  width: 960px;
  height: 640px;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: grid;
  height: 100%;
  overflow: auto;
  background-color: white;
  user-select: none;
}

.tooltipmain {
  display: inline-block;
  text-align: center;
  justify-self: end;
  margin-right: 12px;
  margin-top: 4px;
  background-color: #3f3f46;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  cursor: pointer;
  position: relative;
}

.tooltipmain .tooltiptext {
  visibility: hidden;
  width: 500px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: #e4e4e7;
  color: black;
  text-align: left;
  border-radius: 1rem;
  padding: 8px;
  position: absolute;
  z-index: 1;
  right: 105%;
  margin-left: -120px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.tooltipmain:hover .tooltiptext {
  visibility: visible;
}

.overviewRow {
  margin: 4px 2px 2px 2px;
  display: grid;
  grid-template-columns: 48px 95px 24px 70px 36px 1fr;
  height: 68px;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-radius: 0.25rem;
  padding-top: 4px;
  padding-bottom: 4px;
}

.roundNumber {
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  background-color: black;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  width: 32px;
  height: 32px;
  justify-self: start;
  align-self: center;
  margin-left: 8px;
}

.teamGrid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.teamRole {
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  border-radius: 9999px;
  height: 20px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
  padding-left: 4px;
  padding-right: 4px;
}

.score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 0.65rem;
}

.winner {
  text-decoration-line: underline;
  font-size: 0.75rem;
  color: black;
}

.outcome {
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  border-radius: 9999px;
  height: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.econ {
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
}

.agent-icon {
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  margin: 2px;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}

.redTeam {
  grid-row-start: 1;
}

.blueTeam {
  grid-row-start: 2;
}

.dead {
  background-color: gray;
  filter: grayscale(100%);
}

.ult {
  outline-width: 2px;
  outline-style: solid;
  outline-color: black;
  outline-offset: 1px;
  border-radius: 9999px;
}
</style>
