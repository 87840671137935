import { NotAuthorizedError } from '@/api/errors'
import axios from '@/axios'

import storage from '../store/storage.js'

const API_URL = 'https://c6pvhtgptaz2qbvcvbvoqrju7m0rmhvu.lambda-url.us-east-1.on.aws'

/**
 * @typedef { 'game_start'  | 'shopping'  | 'combat'  | 'end'  | 'game_end'} ScrimRoundPhases
 */

/**
 * @typedef {{
 *   alive: boolean,
 *   assists: number,
 *   character?: string,
 *   deaths: number,
 *   is_local: boolean,
 *   kills: number,
 *   money: number,
 *   name?: string,
 *   teammate: boolean,
 *   roster_idx?: number,
 *   player_id?: string,
 * }} ScrimScoreboardRecord
 */

/**
 * @typedef {{
 *   timestamp: number,
 *   scoreboard_end: ScrimScoreboardRecord[],
 * }} ScrimRoundPhase
 */

/**
 * @typedef {{
 *   damage_type: 'Weapon' | 'Ability' | 'Bomb' | 'Melee',
 *   damage_item: string,
 *   damage_agent?: string,
 * }} Damage
 */

/**
 * @typedef {{
 *   attacker: string,
 *   attacker_idx: number | null,
 *   victim: string,
 *   victim_idx: number | null,
 *   weapon: string,
 *   ult: string,
 *   assist1: string,
 *   assist2: string,
 *   assist3: string,
 *   assist4: string,
 *   headshot: boolean,
 *   timestamp: number,
 *   game_time_millis: number,
 *   round_time_millis: number,
 *   attacker_scoreboard_idx: number | null,
 *   attacker_roster_idx: number | null,
 *   attacker_player_id?: string | null,
 *   victim_scoreboard_idx: number | null,
 *   victim_roster_idx: number | null,
 *   victim_player_id?: string | null,
 *   finishing_damage?: Damage,
 * }} KillFeedEvent
 */

/**
 * @typedef {{
 *   round_num: number,
 *   killfeed: KillFeedEvent[],
 *   score: null | { won: number; lost: number },
 *   team: 'attack' | 'defense',
 *   phases: Record<ScrimRoundPhases, ScrimRoundPhase>,
 * }} ScrimRound
 */

/**
 * @typedef {{
 *   name?: string,
 *   player_id?: string,
 *   character?: string,
 *   agent?: string,
 *   rank?: number,
 *   locked?: boolean,
 *   local?: boolean,
 *   teammate?: boolean,
 * }} PlayerRoster
 */

/**
 * @typedef {{
 *   version: string,
 *   map?: {
 *     name: string,
 *     id: string,
 *     scene: string,
 *   },
 *   rounds: ScrimRound[],
 *   matchInfo: Record<string, any>,
 *   roster: PlayerRoster[],
 *   recording: {
 *     starting: number | null,
 *     started: number | null,
 *     ending: number | null,
 *     ended: number | null,
 *     startStatus: any,
 *     endStatus: any,
 *     guesstimated_offset_millis: number | null,
 *   }
 * }} ScrimData
 */

/**
 * @typedef {{
 *   extraDataVersion?: string,
 *   parsedData?: ScrimData,
 *   finalData?: ScrimData,
 *   recordingUploadUrl?: string,
 *   serverMatchId?: string,
 *   title: string,
 *   uploaded?: boolean,
 *   videoUploaded?: boolean,
 *   videoUrl?: string,
 * }} ScrimInfo
 */

/**
 * @typedef {'mkv' | 'mp4' | 'avi'} MatchRecordingExtension
 */

/**
 * @typedef {{
 *  name: string,
 *  agent_id: string,
 *  player_id: string,
 * }} CreateMatchPayloadPlayer
 */

/**
 * @typedef {{
 *   map_id: string,
 *   ally_players: Array<CreateMatchPayloadPlayer>,
 *   enemy_team_name: string,
 *   enemy_players: Array<CreateMatchPayloadPlayer>,
 *   started_at: number,
 * }} CreateMatchPayload
 */

/**
 * @typedef {CreateMatchPayload | {
 *   id: string,
 *   ally_score: number,
 *   ally_score_first_half: number,
 *   ally_score_second_half: number,
 *   ally_score_overtime: number,
 *   enemy_score: number,
 *   enemy_score_first_half: number,
 *   enemy_score_second_half: number,
 *   enemy_score_overtime: number,
 *   total_rounds: number,
 *   finished_at: number,
 *   created_at: number,
 *   updated_at: number,
 * }} CreateMatchResponse
 */

/**
 * @typedef {{
 *   round_num: number,
 *   started_at: number,
 *   finished_at: number,
 *   is_processed?: boolean,
 *   processed_minimap_url?: string,
 * }} MatchRecordingRound
 */

/**
 * @typedef {{
 *   extension: MatchRecordingExtension,
 *   rounds: Array<MatchRecordingRound>,
 *   id: string,
 *   state: string,
 *   created_at: number,
 *   updated_at: number,
 *   source_url?: string,
 *   created_at?: number,
 *   updated_at?: number,
 * }} MatchRecording
 */

/**
 * @typedef {{
 *   rounds: Array<{
 *     val_minimap: {
 *       frames: Array<Array<{
 *         cls: number,
 *         conf: number,
 *         xy?: Array<number>,
 *         xyxy?: Array<number>,
 *       }>>,
 *       frame_interval: number,
 *     }
 *   }>,
 *   metadata: {
 *     val_minimap: Record<string, string>,
 *   }
 * }} GetMatchInterferencesResponse
 */

/**
 * @typedef {[{round_num: number, minimap_url: string}]} GetMatchMinimapsResponse
 */

/**
 * @typedef {{
 *   type: string,
 *   timestamp: number,
 *   event: any,
 * }} ScrimEvent
 */

/**
 * @typedef {ScrimInfo & {
 *   version: string,
 *   events: ScrimEvent[],
 * }} MatchExtraData
 */

/**
 * @param {string|null} anchor
 * @param {AbortSignal} signal
 * @return {Promise<{items: Array<CreateMatchResponse>, anchor: null|string}>}
 */
export async function getMatches({ anchor = null } = {}, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches?${new URLSearchParams({ ...(anchor ? { anchor } : null) })}`, {
    method: 'GET',
    headers: {
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new NotAuthorizedError()
      default:
        throw new Error(`Failed to get matches: ${response.statusText}\n${await response.text()}`)
    }
  }

  return response.json()
}

/**
 * @param {string} matchId
 * @param {AbortSignal} signal
 * @return {Promise<CreateMatchResponse>}
 */
export async function getMatch(matchId, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches/${matchId}`, {
    method: 'GET',
    headers: {
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new NotAuthorizedError()
      default:
        throw new Error(`Failed to get match: ${response.statusText}\n${await response.text()}`)
    }
  }

  return response.json()
}

/**
 * @param {string} matchId
 * @param {AbortSignal} signal
 * @return {Promise<MatchRecording>}
 */
export async function getMatchRecording(matchId, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches/${matchId}/recording`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new NotAuthorizedError()
      default:
        throw new Error(`Failed to get match recording: ${response.statusText}\n${await response.text()}`)
    }
  }

  return response.json()
}

/**
 * @param {string} matchId
 * @param {AbortSignal} signal
 * @return {Promise<GetMatchInterferencesResponse>}
 */
export async function getMatchInferences(matchId, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches/${encodeURIComponent(matchId)}/inferences`, {
    headers: {
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new NotAuthorizedError()
      default:
        throw new Error(`Failed to get match inferences: ${response.statusText}\n${await response.text()}`)
    }
  }

  return response.json()
}

/**
 * @param {string} matchId
 * @param {AbortSignal} signal
 * @return {Promise<boolean>}
 */
export async function hasMatchInterferences(matchId, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches/${encodeURIComponent(matchId)}/inferences`, {
    method: 'HEAD',
    headers: {
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })

  switch (response.status) {
    case 200:
      return true
    case 401:
      throw new NotAuthorizedError()
    case 404:
    case 502:
      return false
    default:
      throw new Error(`Failed to get match minimap: ${response.statusText}\n${await response.text()}`)
  }
}

/**
 * @param {string} matchId
 * @param {AbortSignal} signal
 * @return {Promise<MatchExtraData>}
 */
export async function getMatchExtraData(matchId, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches/${encodeURIComponent(matchId)}/extradata`, {
    headers: {
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new NotAuthorizedError()
      default:
        throw new Error(`Failed to get match extra data: ${response.statusText}\n${await response.text()}`)
    }
  }

  return response.json()
}

/**
 * @param {string} matchId
 * @param {MatchExtraData} payload
 * @param {AbortSignal} signal
 * @return {Promise<MatchExtraData>}
 */
export async function updateMatchExtraData(matchId, payload, { signal } = {}) {
  const response = await fetch(`${API_URL}/matches/${encodeURIComponent(matchId)}/extradata`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-augment-session-key': storage.getItem('SESSION_KEY'),
    },
    body: JSON.stringify(payload),
    signal,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new NotAuthorizedError()
      default:
        throw new Error(`Failed to update match extra data: ${response.statusText}\n${await response.text()}`)
    }
  }

  return response.json()
}

export async function deleteMatch({ id }, { cancelToken } = {}) {
  const response = await axios.delete(`${API_URL}/matches/${encodeURIComponent(id)}`, {
    cancelToken,
  })

  return response.status === 200
}
