<template>
  <div class="filter-container" :class="{ expanded }">
    <summary
      class="col-form-label clearfix"
      :class="computedStatus != null && 'has-status'"
      @click="expanded = !expanded"
    >
      <BIconCaretDownFill v-if="!expanded" />
      <BIconCaretUpFill v-if="expanded" />
      <slot name="label">
        {{ label }}
      </slot>
      <span v-if="computedStatus != null">({{ computedStatus }})</span>
    </summary>
    <BCollapse v-model="expanded">
      <slot />
    </BCollapse>
  </div>
</template>

<script>
import { BIconCaretDownFill, BIconCaretUpFill } from 'bootstrap-vue'
import px from 'vue-types'

import { pxNullable } from '../../types'

export default {
  name: 'FilterContainer',
  components: {
    BIconCaretDownFill,
    BIconCaretUpFill,
  },
  props: {
    initiallyExpanded: px.bool.def(false),
    label: px.string,
    status: pxNullable(px.string),
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
      localStatus: null,
    }
  },
  computed: {
    computedStatus() {
      return this.status != null && this.status !== '' ? this.status : this.localStatus
    },
  },
  provide() {
    return {
      setShortStatus: this.setShortStatus,
    }
  },
  methods: {
    setShortStatus(newStatus) {
      this.localStatus = newStatus
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  margin-bottom: 1em;

  summary {
    display: block;
  }

  .has-status {
    color: white;
  }
}
</style>
