<template>
  <g>
    <component
      v-for="event in currentEvents"
      :is="eventComponent(variant, event)"
      :key="currentTime != null ? `${event.track_id}${event.gid}${event.round_id}` : event.id"
      :data="data"
      :event="event"
      :faded="fading && !highlightedGids[event.gid]"
      :hidden="hidden(event)"
      :highlight="highlightEvent === event.id"
    />
  </g>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import roleColor from '@/filters/roleColor.js'

import { MAX_AGENT_SPEED, MAX_TIME_DIFF } from '../../../constants.js'
import { pxNullable, pxEvent, pxId, pxSmoke, pxToolData, pxWall } from '../types.js'
import alignRoundTime from '../utils/alignRoundTime.js'

import MapDeathEventEntry from './map/MapDeathEventEntry.vue'
import MapEventEntry from './map/MapEventEntry.vue'
import MapKillEventEntry from './map/MapKillEventEntry.vue'
import MapSmokeEventEntry from './map/MapSmokeEventEntry.vue'
import MapSpikeEventEntry from './map/MapSpikeEventEntry.vue'
import MapUtilityEventEntry from './map/MapUtilityEventEntry.vue'
import MapWallEventEntry from './map/MapWallEventEntry.vue'

/**
 * Return 0 <= i <= array.length such that !pred(array[i - 1]) && pred(array[i]).
 */
function binarySearch(array, pred) {
  let lo = -1
  let hi = array.length
  while (1 + lo < hi) {
    const mi = lo + ((hi - lo) >> 1)
    if (pred(array[mi])) {
      hi = mi
    } else {
      lo = mi
    }
  }
  return hi
}

const eventComponent = (variant, event) => {
  switch (event.type) {
    case 'death':
      return variant === 'kills' ? MapKillEventEntry : MapDeathEventEntry
    case 'kill':
      return MapKillEventEntry
    case 'spike':
      return MapSpikeEventEntry
    case 'utility':
      return MapUtilityEventEntry
    case 'wall':
      return MapWallEventEntry
    case 'smoke':
      return MapSmokeEventEntry
    default:
      switch (variant) {
        case 'advanced':
        case 'positions':
        case 'playback':
        case 'analytics':
          return MapEventEntry
        case 'kills':
          return MapKillEventEntry
        case 'smokes':
          return MapSmokeEventEntry
        case 'spikes':
          return MapSpikeEventEntry
        case 'utility':
          return MapUtilityEventEntry
        case 'walls':
          return MapWallEventEntry
        default:
          throw new Error(`unhandled map variant ${variant}`)
      }
  }
}

/**
 * @param {BasicEvent} prevEvent
 * @param {BasicEvent} nextEvent
 * @param {number} currentTime
 * @return {*}
 */
const interpolateEvent = (prevEvent, nextEvent, currentTime) => {
  if (nextEvent.round_time_millis === currentTime) return nextEvent
  if (prevEvent.round_time_millis === currentTime) return prevEvent
  if (prevEvent.round_time_millis === nextEvent.round_time_millis) return
  if (prevEvent.round_time_millis > currentTime) return
  if (nextEvent.round_time_millis < currentTime) return

  const kDiff = nextEvent.round_time_millis - prevEvent.round_time_millis
  const kPrev = (nextEvent.round_time_millis - currentTime) / kDiff
  const kNext = (currentTime - prevEvent.round_time_millis) / kDiff

  if (kDiff > MAX_TIME_DIFF) return
  const speed =
    Math.sqrt(
      Math.pow(prevEvent.location.x - nextEvent.location.x, 2) +
        Math.pow(prevEvent.location.y - nextEvent.location.y, 2)
    ) / kDiff
  if (speed > MAX_AGENT_SPEED && prevEvent.track_id && prevEvent.track_id !== nextEvent.track_id) {
    console.log('skipping too quick movement', speed)
    return
  }

  return {
    ...nextEvent,
    location: {
      x: prevEvent.location.x * kPrev + nextEvent.location.x * kNext,
      y: prevEvent.location.y * kPrev + nextEvent.location.y * kNext,
    },
    conf:
      (prevEvent.conf != null ? prevEvent.conf : 1) *
      (nextEvent.conf != null ? nextEvent.conf : 1) *
      ((1 - Math.min(1, kDiff / MAX_TIME_DIFF)) * 0.75 + 0.25),
  }
}

export default {
  name: 'MapEvents',
  props: {
    currentTime: pxNullable(px.number),
    data: pxToolData().isRequired,
    events: px.arrayOf(px.oneOfType([pxWall(), pxEvent(), pxSmoke()])).isRequired,
    filterCurrentTime: px.func,
    selected: Object,
    variant: px
      .oneOf(['positions', 'kills', 'spikes', 'advanced', 'utility', 'walls', 'playback', 'smokes', 'deaths'])
      .def('positions'),
    highlightEvent: pxNullable(pxId()),
    highlightedGids: px.object,
    iconHide: {
      type: Object,
      default() {
        return { unselected: false }
      },
    },
  },
  computed: {
    alignRoundTime() {
      return alignRoundTime(this)
    },
    currentEvents() {
      if (this.currentTime == null) {
        return this.filteredEvents
      }
      let currentEvents = Object.values(this.groupedEvents)
        .flatMap(list => {
          switch (list[0].type) {
            case 'smoke':
            case 'wall':
              return list.filter(event => {
                const eventTime = this.alignRoundTime(event)
                return eventTime && eventTime <= this.currentTime && this.currentTime <= eventTime + event.duration
              })
            case 'position':
            case 'utility': {
              const idx = binarySearch(list, event => this.alignRoundTime(event) >= this.currentTime)
              if (idx >= list.length) return
              const event = list[idx]
              const eventTime = this.alignRoundTime(event)
              if (eventTime === this.currentTime) return event
              if (idx === 0) return
              const prevEvent = list[idx - 1]
              const prevEventTime = this.alignRoundTime(prevEvent)
              return interpolateEvent(
                { ...prevEvent, round_time_millis: prevEventTime },
                { ...event, round_time_millis: eventTime },
                this.currentTime
              )
            }
            case 'kill':
              return this.filterCurrentTime ? list.filter(this.filterCurrentTime) : list
            default: {
              console.log('unhandled event type', list[0].type)
              return this.filterCurrentTime ? list.filter(this.filterCurrentTime) : list
            }
          }
        })
        .filter(Boolean)
      return Object.freeze(currentEvents)
    },
    fading() {
      return this.highlightedGids && Object.keys(this.highlightedGids).length > 0
    },
    filteredEvents() {
      return Object.freeze(this.events?.filter(event => event.location) || [])
    },
    groupedEvents() {
      const grouped = this.filteredEvents.reduce((grouped, event) => {
        const key = event.track_id ? `track-${event.track_id}#${event.round_id}` : `${event.gid}#${event.round_id}`
        const list = (grouped[key] = grouped[key] || [])
        list.push(event)
        return grouped
      }, {})
      for (const key in grouped) {
        grouped[key] = grouped[key].sort((a, b) => a.round_time_millis - b.round_time_millis)
      }
      return Object.freeze(grouped)
    },
    legend() {
      const usedGids = {}
      this.filteredEvents.forEach(event => {
        if (!event.round_team_id) return
        usedGids[event.gid] = roleColor(this.data.roundTeams[event.round_team_id].role)
        if (event.rgid) {
          switch (event.type) {
            case 'kill':
              usedGids[event.rgid] = roleColor(this.data.roundTeams[event.victim.round_team_id].role)
              break
            case 'death':
              usedGids[event.rgid] = roleColor(this.data.roundTeams[event.killer.round_team_id].role)
              break
            default:
              Sentry.captureException(new Error(`Unhandled rgid for event ${event.type}`))
              break
          }
        }
      })
      return Object.freeze(usedGids)
    },
  },
  watch: {
    legend: {
      handler(val) {
        this.$nextTick(() => {
          this.$emit('update:legend', val)
        })
      },
      immediate: true,
    },
  },
  methods: {
    eventComponent,
    hidden(event) {
      if (this.iconHide.unselected) return this.fading && !this.highlightedGids[event.gid]
      else return false
    },
  },
  // functional: true,
  // render (h, context) {
  //   const usedGids = {}
  //
  //   const rendered = h('g', {}, context.props.events.filter(event => event.location).map(event => {
  //     usedGids[event.gid] = true
  //     return h(eventComponent(context.props.variant, event), {
  //       props: {
  //         data: context.props.data,
  //         event
  //       }
  //     })
  //   }))
  //
  //   const legend = Object.freeze(Object.fromEntries(Object.keys(usedGids).map(k => [k, 'white'])))
  //   if (context.listeners['update:legend']) {
  //     console.log('emitting legend', legend)
  //     context.listeners['update:legend'](legend)
  //   }
  //
  //   return rendered
  // }
}
</script>
