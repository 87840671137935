<template>
  <V3Image :alt="agent.name" :src="agent.display_icon_small_url" :title="agent.name" v-bind="$attrs" />
</template>

<script>
import px from 'vue-types'

import V3Image from './V3Image.vue'

export default {
  name: 'V3Agent',
  components: { V3Image },
  props: {
    id: px.string.isRequired.def(null),
  },
  computed: {
    agent() {
      return this.$store.getters['static/getAgentById'](this.id)
    },
  },
}
</script>
