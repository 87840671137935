<template>
  <ul v-if="items.length" class="legend-container">
    <li v-for="item in items" :key="item.id" :style="{ color: item.color }" class="legend-item">
      <img v-if="item.image" class="legend-img" :src="item.image" />
      <span class="legend-label">{{ item.label }}</span>
    </li>
  </ul>
</template>

<script>
import px from 'vue-types'

import { pxToolData, pxNullable } from '@/components/Map/types.js'

export default {
  name: 'MapLegend',
  props: {
    data: pxToolData().isRequired,
    legend: pxNullable(px.object),
  },
  computed: {
    items() {
      if (!this.legend) return []
      return Object.entries(this.legend)
        .map(([gid, color]) => {
          const gidInfo = this.data.gids[gid]
          if (!gidInfo) {
            console.warn(`Missing gid ${gid}`)
            return
          }
          let image
          let label
          switch (gidInfo.type) {
            case 'pos': {
              const player = this.data.players[gidInfo.player_id]
              if (!player) {
                console.warn(`Missing player ${gidInfo.player_id}`)
                return
              }
              const agent = this.data.agents[gidInfo.agent_id]
              if (!agent) {
                console.warn(`Missing agent ${gidInfo.agent_id}`)
                return
              }
              label = player.name
              image = agent.display_icon_small_url
              break
            }
            case 'util': {
              const player = this.data.players[gidInfo.player_id]
              if (!player) {
                console.warn(`Missing player ${gidInfo.player_id}`)
                return
              }
              const agent = this.data.agents[gidInfo.agent_id]
              if (!agent) {
                console.warn(`Missing agent ${gidInfo.agent_id}`)
                return
              }
              const ability = agent.abilities[gidInfo.ability_slot]
              if (!ability) {
                console.warn(`Missing ability ${gidInfo.ability_slot} of ${agent.name}`)
                return
              }
              label = `${player.name} ${ability.displayName}`
              image = ability.displayIcon
              break
            }
            case 'wall': {
              const team = this.data.teams[gidInfo.team_id]
              if (!team) {
                console.warn(`Missing team ${gidInfo.team_id}`)
                return
              }
              label = team.name
              break
            }
            default:
              label = gidInfo.type
              break
          }
          return {
            id: gid,
            color,
            label,
            image,
          }
        })
        .filter(Boolean)
    },
  },
}
</script>

<style lang="scss" scoped>
.legend {
  &-container {
    font-size: 0.75rem;
  }

  &-img {
    height: 1em;
    width: 2em;
    object-fit: contain;
  }
}
</style>
