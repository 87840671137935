<template functional>
  <circle
    :opacity="props.event.conf != null ? props.event.conf : 1"
    class="smoke"
    :cx="props.event.location.x * 1024"
    :cy="props.event.location.y * 1024"
    :r="props.event.radius < 1 ? props.event.radius * 1024 : props.event.radius"
  />
</template>

<script>
export default {
  name: 'MapSmokeEventEntry',
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
  },
}
</script>
