import { render, staticRenderFns } from "./OverwolfBannerAd.vue?vue&type=template&id=298b1620&scoped=true&"
import script from "./OverwolfBannerAd.vue?vue&type=script&lang=js&"
export * from "./OverwolfBannerAd.vue?vue&type=script&lang=js&"
import style0 from "./OverwolfBannerAd.vue?vue&type=style&index=0&id=298b1620&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298b1620",
  null
  
)

export default component.exports