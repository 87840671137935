<!--<template functional>-->
<!--  <Portal :to="props.to">-->
<!--    <MapInjector v-bind="data.attrs" :class="data.staticClass" :exposed="injections.exposed" v-on="listeners">-->
<!--      <slot />-->
<!--    </MapInjector>-->
<!--  </Portal>-->
<!--</template>-->

<script>
import { Portal } from 'portal-vue'

import MapInjector from '@/components/Map/components/MapInjector.vue'

export default {
  name: 'MapPortal',
  functional: true,
  inheritAttrs: false,
  components: { MapInjector },
  inject: ['exposed', 'mapController'],
  props: {
    to: String,
  },
  render(createElement, { children, data, injections, listeners, props }) {
    return createElement(
      Portal,
      {
        props: {
          slim: true,
          to: props.to,
        },
      },
      [
        createElement(
          MapInjector,
          {
            class: data.staticClass,
            attrs: data.attrs,
            props: { exposed: injections.exposed, mapController: injections.mapController },
            on: listeners,
          },
          children
        ),
      ]
    )
  },
}
</script>
