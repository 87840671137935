<template functional>
  <g :transform="`translate(${props.event.location.x * 1024},${props.event.location.y * 1024})`">
    <g transform="scale(1.3)">
      <path
        class="death"
        :stroke="props.data.roundTeams[props.event.round_team_id].role | roleColor(1)"
        d="M-10,-10l20,20m0,-20l-20,20"
      />
    </g>
  </g>
</template>

<script>
export default {
  name: 'MapSmokeEventEntry',
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
  },
  filters: {
    roleColor(role) {
      switch (role) {
        case 'def':
          return 'rgba(127, 255, 212, 1)'
        case 'atk':
          return 'rgba(255, 99, 71, 1)'
      }
    },
  },
}
</script>

<style scoped>
.death {
}
</style>
