<template>
  <g ref="svg" />
</template>

<script>
import * as d3 from 'd3'
import px from 'vue-types'

import { pxEvents, pxNullable } from '../types.js'

const color = d3.scaleSequential().interpolator(d3.interpolateSinebow)

const MAX_DIST = 0.01
const MAX_GAP = 2500
const dist = (a, b) => Math.pow(a.location.x - b.location.x, 2) + Math.pow(a.location.y - b.location.y, 2)

export default {
  name: 'TraceMap',
  props: {
    currentTime: pxNullable(px.number),
    data: px.object.isRequired,
    events: pxEvents().isRequired,
    filterCurrentTime: px.func,
    highlightedGids: px.object,
    variant: px.oneOf(['thick', 'thin']).def('thick'),
  },
  computed: {
    currentEvents() {
      if (this.currentTime == null || !this.filterCurrentTime) {
        return this.filteredEvents
      }

      let currentEvents = this.filteredEvents.filter(event => this.filterCurrentTime(event))

      if (this.highlightedGids && Object.keys(this.highlightedGids).length > 0) {
        currentEvents = currentEvents.filter(event => this.highlightedGids[event.gid])
      }

      return Object.freeze(currentEvents)
    },
    filteredEvents() {
      return Object.freeze(this.events.filter(event => event.location && !['kill', 'death'].includes(event.type)))
    },
    lines() {
      return Object.values(
        this.currentEvents.reduce((groups, e) => {
          const k = `${e.round_id}-${e.gid}`
          if (!(k in groups)) {
            groups[k] = []
          }
          groups[k].push(e)
          return groups
        }, {})
      )
    },
    legend() {
      return this.genLegend(Object.keys(this.usedGids))
    },
    usedGids() {
      return Object.freeze(Object.fromEntries(this.filteredEvents.map(event => [event.gid, true])))
    },
  },
  watch: {
    legend: {
      handler(val) {
        this.$emit('update:legend', val)
      },
      immediate: true,
    },
    lines: {
      handler() {
        this.update()
      },
      immediate: true,
    },
    variant: {
      handler() {
        this.update()
      },
      immediate: true,
    },
  },
  methods: {
    genLegend(items) {
      color.domain([0, items.length])
      return Object.freeze(Object.fromEntries(items.map((k, i) => [k, color(i)])))
    },
    update() {
      if (!this.$refs.svg) {
        this.$nextTick(this.update)
        return
      }

      const legend = this.legend
      const lines = this.lines
      const r = 1.5
      const circle = `a${r},${r} 0 1,1 ${r * 2},0 a${r},${r} 0 1,1 ${-r * 2},0`
      d3.select(this.$refs.svg)
        .selectAll('path')
        .data(lines, (e, i) => i)
        .join('path')
        .attr('stroke', ([e]) => legend[e.gid])
        .attr('fill', ([e]) => legend[e.gid])
        .attr('opacity', 0.5)
        .attr('stroke-width', this.variant === 'thick' ? 3 : 1)
        .attr('stroke-linecap', 'round')
        .attr('stroke-linejoin', 'round')
        .attr('d', d =>
          d
            .map((e, i, a) => {
              const x = e.location.x * 1024
              const y = e.location.y * 1024
              if (
                i > 0 &&
                Math.abs(e.round_time_millis - a[i - 1].round_time_millis) < MAX_GAP &&
                dist(e, a[i - 1]) < MAX_DIST
              ) {
                return `M${a[i - 1].location.x * 1024},${a[i - 1].location.y * 1024}L${x},${y}${
                  !(i < a.length - 1 && dist(e, a[i + 1]) < MAX_DIST) ? `m${-r},0${circle}` : ''
                }`
              } else {
                return `M${x - r},${y}${circle}`
              }
            })
            .join('\n')
        )
    },
  },
}
</script>

<style scoped></style>
