<template>
  <g ref="svg" />
</template>

<script>
import * as d3 from 'd3'
import px from 'vue-types'

import { pxEvents, pxNullable } from '../types.js'

const x = d3.scaleLinear().domain([0, 1]).range([0, 1024])
const y = d3.scaleLinear().domain([0, 1]).range([0, 1024])
const color = d3.scaleSequential().interpolator(d3.interpolateTurbo)
const opacity = d3.scaleSequential().range([0.1, 0.25])

export default {
  name: 'HeatmapEvents',
  props: {
    currentTime: pxNullable(px.number),
    events: pxEvents().isRequired,
    filterCurrentTime: px.func,
    highlightedGids: px.object,
  },
  computed: {
    currentEvents() {
      if (this.currentTime == null || !this.filterCurrentTime) {
        return this.filteredEvents
      }

      let currentEvents = this.filteredEvents.filter(event => this.filterCurrentTime(event))

      if (this.highlightedGids && Object.keys(this.highlightedGids).length > 0) {
        currentEvents = currentEvents.filter(event => this.highlightedGids[event.gid])
      }

      return Object.freeze(currentEvents)
    },
    filteredEvents() {
      return Object.freeze(this.events.filter(event => event.location))
    },
  },
  watch: {
    currentEvents: {
      handler() {
        this.update()
      },
      immediate: true,
    },
  },
  methods: {
    update() {
      this.$emit('update:legend', null)
      if (!this.$refs.svg) {
        this.$nextTick(this.update.bind(this))
        return
      }

      this.$refs.svg.replaceChildren()
      const svg = d3.select(this.$refs.svg)

      const cd = d3
        .contourDensity()
        .x(function (p) {
          return x(p.location.x)
        })
        .y(function (p) {
          return y(p.location.y)
        })
        .size([1024, 1014])
        .bandwidth(15.7)
      // .thresholds(50)

      const densityData = cd(this.currentEvents)

      if (densityData.length === 0) return

      // scale according to max density
      const maxDensity = densityData[densityData.length - 1].value
      color.domain([0, maxDensity])
      opacity.domain([0, maxDensity])

      svg
        .insert('g', 'g')
        .selectAll('path')
        .data(densityData)
        .enter()
        .append('path')
        .attr('d', d3.geoPath())
        .attr('fill', d => color(d.value))
        .attr('opacity', d => opacity(d.value))
    },
  },
}
</script>

<style lang="scss" scoped></style>
