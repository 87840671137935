<template>
  <div class="mvo-container">
    <V3Container :visible="expanded">
      <template v-if="mapMode === 'replay2d' && vodPlayer === vodPlatform">
        <template v-for="team in teams">
          <div :key="`team-${team.id}`" class="mvo-team-container" :title="team.name">
            <V3TeamLogo :src="team.logo_url || team.image" grayscale />
            <div class="mvo-team-name">{{ team.name }}</div>
          </div>
          <div :key="`agents-${team.id}`" class="mvo-player-container">
            <template v-for="teamAgent in team.agents">
              <V3Button :key="teamAgent.player.id" class="mvo-player" disabled :title="teamAgent.player.name">
                <V3Agent :id="teamAgent.agent.id" class="mvo-player-agent" grayscale />
                <div class="mvo-player-name">{{ teamAgent.player.name }}</div>
              </V3Button>
            </template>
          </div>
        </template>

        <V3BtnGroup>
          <V3Button active title="Broadcast"> BRT </V3Button>
          <V3Button disabled title="Spectator 1"> SPT1 </V3Button>
          <V3Button disabled title="Spectator 2"> SPT2 </V3Button>
          <V3Button disabled title="Spectator 3"> SPT3 </V3Button>
        </V3BtnGroup>
      </template>

      <template v-if="mapMode === 'replay2d' && vodPlayer === 'playback'">
        <template v-if="mapSubMode === 'agents'">
          <V3Panel expanded title="hide icons">
            <V3BtnGroup>
              <V3Button
                :active="this.state.iconHide.unselected"
                @click="() => (this.state.iconHide.unselected = !this.state.iconHide.unselected)"
              >
                Hide Unselected
              </V3Button>
            </V3BtnGroup>
          </V3Panel>
        </template>
        <template v-for="team in teams">
          <V3Panel
            :expandable="true"
            :expand-on-title-click="false"
            :expanded.sync="teamPanelExpanded[team.id]"
            :key="`panel-${team.id}`"
            @titleClick="togglePlaybackTeamPlayers(team)"
          >
            <template #header-text>
              <div class="mvo-team-name" :class="{ disabled: !team.enabled }">{{ team.name }}</div>
            </template>
            <template #header-icon>
              <V3TeamLogo :src="team.logo_url || team.image" grayscale :class="{ disabled: !team.enabled }" />
            </template>
            <template #default>
              <V3Container :visible="teamPanelExpanded[team.id]">
                <div :key="`agents-${team.id}`" class="agents-view-panel">
                  <AgentReplayDisplayOptions
                    v-for="teamAgent in team.agents"
                    :key="teamAgent.player.id"
                    :agent="teamAgent.agent"
                    :player="teamAgent.player"
                    :abilities="teamAgent.abilities"
                    :filters="filters"
                    :disabled="!team.enabled"
                  />
                </div>
              </V3Container>
            </template>
          </V3Panel>
        </template>

        <V3BtnGroup>
          <V3Button :active="mapSubMode === 'agents'" @click="mapSubMode = 'agents'"> Icons </V3Button>
          <V3Button :active="mapSubMode === 'heatmap'" @click="mapSubMode = 'heatmap'"> Heatmap </V3Button>
          <V3Button :active="mapSubMode === 'traces'" @click="mapSubMode = 'traces'"> Traces </V3Button>
        </V3BtnGroup>

        <V3BtnGroup>
          <V3Button :active="mapOptions.includes('smokes')" @click="toggleMapOptions('smokes')"> Smokes </V3Button>
          <V3Button :active="mapOptions.includes('walls')" @click="toggleMapOptions('walls')"> Walls </V3Button>
          <V3Button :active="mapOptions.includes('deaths')" @click="toggleMapOptions('deaths')"> Deaths </V3Button>
        </V3BtnGroup>
      </template>

      <template v-if="mapMode === 'analytics'">
        <template v-for="team in teams">
          <V3Panel
            :expandable="true"
            :expand-on-title-click="false"
            :expanded.sync="teamPanelExpanded[team.id]"
            :key="`panel-${team.id}`"
            @titleClick="toggleAnalyticsTeamPlayers(team)"
          >
            <template #header-text>
              <div class="mvo-team-name" :class="{ disabled: !team.enabled }">{{ team.name }}</div>
            </template>
            <template #header-icon>
              <V3TeamLogo :src="team.logo_url || team.image" grayscale :class="{ disabled: !team.enabled }" />
            </template>
            <template #default>
              <V3Container :visible="teamPanelExpanded[team.id]">
                <div :key="`agents-${team.id}`" class="agents-view-panel">
                  <AgentAnalyticsDisplayOptions
                    v-for="teamAgent in team.agents"
                    :key="teamAgent.player.id"
                    :agent="teamAgent.agent"
                    :player="teamAgent.player"
                    :abilities="teamAgent.abilities"
                    :filters="filters"
                    :disabled="!team.enabled"
                  />
                </div>
              </V3Container>
            </template>
          </V3Panel>
        </template>

        <KDZoneFilter v-if="mapSubMode === 'kdzones'" />

        <V3BtnGroup>
          <V3Button :active="mapSubMode === 'agents'" @click="mapSubMode = 'agents'"> Icons </V3Button>
          <V3Button :active="mapSubMode === 'heatmap'" @click="mapSubMode = 'heatmap'"> Heatmap </V3Button>
          <V3Button :active="mapSubMode === 'traces'" @click="mapSubMode = 'traces'"> Traces </V3Button>
          <V3Button :active="mapSubMode === 'kdzones'" @click="mapSubMode = 'kdzones'"> KD-Zone </V3Button>
        </V3BtnGroup>

        <V3BtnGroup>
          <V3Button
            v-if="mapSubMode === 'heatmap'"
            :active="this.state.minimapSelector"
            @click="() => (this.state.minimapSelector = !this.state.minimapSelector)"
          >
            Player Position %
          </V3Button>
        </V3BtnGroup>

        <V3BtnGroup>
          <V3Button :active="mapOptions.includes('smokes')" @click="toggleMapOptions('smokes')"> Smokes </V3Button>
          <V3Button :active="mapOptions.includes('walls')" @click="toggleMapOptions('walls')"> Walls </V3Button>
          <V3Button :active="mapOptions.includes('deaths')" @click="toggleMapOptions('deaths')"> Deaths </V3Button>
        </V3BtnGroup>
      </template>
    </V3Container>

    <LeftSideTabs
      v-if="mapMode === 'replay2d'"
      :vod-platform="vodPlatform"
      :vod-player.sync="vodPlayer"
      :has-playback="hasPlaybackPositions"
      :has-minimap="hasMinimapVods"
      :has-broadcast="hasBroadcastVod"
    />
  </div>
</template>

<script>
import px from 'vue-types'

import AgentAnalyticsDisplayOptions from '@/components/Map/components/AgentAnalyticsDisplayOptions.vue'
import AgentReplayDisplayOptions from '@/components/Map/components/AgentReplayDisplayOptions.vue'
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import V3Container from '@/components/Map/components/v3dafi/V3Container.vue'
import genAbilityHashId from '@/components/Map/utils/genAbilityHashId.js'
import genGid from '@/components/Map/utils/genGid'

import stringCompare from '../../../utils/stringCompare.js'
import exposedDataState from '../mixins/exposedDataState.js'

import KDZoneFilter from './KDZoneFilter.vue'
import LeftSideTabs from './v3dafi/LeftSideTabs.vue'
import V3Agent from './v3dafi/V3Agent.vue'
import V3Button from './v3dafi/V3Button.vue'
import V3Panel from './v3dafi/V3Panel.vue'
import V3TeamLogo from './v3dafi/V3TeamLogo.vue'

export default {
  name: 'MapViewOptions',
  mixins: [exposedDataState],
  components: {
    V3Container,
    V3BtnGroup,
    V3Agent,
    V3Button,
    V3TeamLogo,
    LeftSideTabs,
    KDZoneFilter,
    AgentReplayDisplayOptions,
    AgentAnalyticsDisplayOptions,
    V3Panel,
  },
  props: {
    expanded: Boolean,
    vodPlatform: px.string,
    hasPlaybackPositions: {
      type: Boolean,
      default: true,
    },
    hasMinimapVods: {
      type: Boolean,
      default: true,
    },
    hasBroadcastVod: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      teamPanelExpanded: {},
    }
  },
  mounted() {
    this.teamPanelExpanded = Object.fromEntries(this.teams.map(team => [team.id, team.enabled]))
  },
  computed: {
    activeAnalyticsTeams() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [
            team.id,
            this.filters.display.players &&
              Object.keys(this.teamPlayerIds[team.id]).some(id => this.filters.display.players?.[id]),
          ])
        )
      )
    },
    activePlaybackTeams() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [
            team.id,
            this.filters.display.gids &&
              Object.keys(this.teamGids[team.id]).some(gid => this.filters.display.gids[gid]),
          ])
        )
      )
    },
    filters: {
      get() {
        return this.state.filters
      },
      set(val) {
        this.state.filters = val
      },
    },
    mapOptions: {
      get() {
        return this.state.mapOptions
      },
      set(val) {
        this.state.mapOptions = Object.freeze(val)
      },
    },
    mapSubMode: {
      get() {
        return this.state.mapSubMode
      },
      set(val) {
        this.state.mapSubMode = val
      },
    },
    teams() {
      return Object.values(this.data.teams)
        .map(team => ({
          ...team,
          agents: this.calcTeamAgents(team),
          enabled:
            this.state.filters.base.matches.length > 0
              ? Object.entries(this.data.matchTeams).some(([, matchTeam]) => {
                  if (matchTeam.team_id === team.id && this.state.filters.base.matches.includes(matchTeam.match_id)) {
                    return true
                  }
                  return false
                })
              : true,
        }))
        .sort(
          stringCompare(team => {
            return `${this.state.selected.team === team.id ? 1 : 0}-${team.name}`
          })
        )
    },
    teamGids() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [
            team.id,
            team.agents.reduce((acc, agent) => {
              acc[agent.agent.gid] = true
              return Object.values(agent.abilities).reduce((acc, ability) => {
                acc[ability.gid] = true
                return acc
              }, acc)
            }, {}),
          ])
        )
      )
    },
    teamPlayerIds() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [team.id, Object.fromEntries(team.agents.map(({ player: { id } }) => [id, true]))])
        )
      )
    },
    vodPlayer: {
      get() {
        return this.state.vodPlayer
      },
      set(value) {
        this.state.vodPlayer = value
      },
    },
  },
  methods: {
    calcTeamAgents(team) {
      return Object.values(this.data.teamCompositions)
        ?.find(teamComposition => teamComposition.team_id === team.id)
        ?.composition?.map(composition => {
          if (!this.data.agents[composition.agent_id]) {
            console.log('Missing agent', composition, team)
          }
          return {
            agent: {
              ...this.data.agents[composition.agent_id],
              gid: genGid(
                'pos',
                team.id,
                this.data.teamPlayers[composition.team_player_id].player_id,
                composition.agent_id
              ),
            },
            player: this.data.players[this.data.teamPlayers[composition.team_player_id].player_id],
            abilities: Object.values(this.data.agents[composition.agent_id].abilities)
              .filter(
                ability =>
                  ability.slot.toLowerCase() !== 'passive' ||
                  composition.agent_id === '41fb69c1-4189-7b37-f117-bcaf1e96f1bf'
              )
              .reduce((acc, ability) => {
                acc[ability.slot] = {
                  ...ability,
                  gid: genGid(
                    'util',
                    team.id,
                    this.data.teamPlayers[composition.team_player_id].player_id,
                    composition.agent_id,
                    ability.slot
                  ),
                  abilityHashId: genAbilityHashId(this.data.agents[composition.agent_id], ability.slot),
                }
                return acc
              }, {}),
          }
        })
        .sort(stringCompare(({ agent }) => agent.name))
    },
    toggleMapOptions(option) {
      if ((this.mapOptions || []).includes(option)) {
        this.mapOptions = this.mapOptions.filter(o => o !== option)
      } else {
        this.mapOptions = [...(this.mapOptions || []), option]
      }
    },
    togglePlaybackTeamPlayers(team) {
      if (!team.enabled) {
        return
      }
      if (!this.teamPanelExpanded[team.id]) {
        this.teamPanelExpanded[team.id] = true
        return
      }
      const teamGids = this.teamGids[team.id]
      const otherGids = Object.fromEntries(
        Object.entries(this.filters.display.gids || {}).filter(([gid, enabled]) => enabled && !teamGids[gid])
      )
      if (this.activePlaybackTeams[team.id]) {
        this.filters.display.gids = Object.entries(otherGids).length === 0 ? null : otherGids
      } else {
        this.filters.display.gids = { ...otherGids, ...teamGids }
      }
    },
    toggleAnalyticsTeamPlayers(team) {
      if (!team.enabled) {
        return
      }
      if (!this.teamPanelExpanded[team.id]) {
        this.teamPanelExpanded[team.id] = true
        return
      }
      const playerIds = this.teamPlayerIds[team.id]
      const otherPlayerIds = Object.fromEntries(
        Object.entries(this.filters.display.players || {}).filter(([id, enabled]) => enabled && !playerIds[id])
      )
      if (this.activeAnalyticsTeams[team.id]) {
        this.filters.display.players = Object.keys(otherPlayerIds).length > 0 ? otherPlayerIds : null
      } else {
        this.filters.display.players = {
          ...otherPlayerIds,
          ...playerIds,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mvo {
  &-team {
    &-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      padding: 0;
      color: inherit;
    }

    &-name {
      font-size: 11px;
      text-transform: uppercase;
    }
  }

  &-player {
    flex: 1 1 auto;
    padding: 0;
    flex-flow: column nowrap;
    min-width: 0;
    gap: 0.25rem;

    &-container {
      display: flex;
      flex-flow: row nowrap;
      max-width: 100%;
      overflow: hidden;
      gap: 0.25rem;
      padding: 0.25rem;
    }

    &-agent {
      height: 1rem;
    }

    &-name {
      justify-self: stretch;
      max-width: 100%;
      font-size: 10px;
      color: #a4a2ad;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.agents-view-panel {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
