<template>
  <g v-if="kdZoneMode.zone">
    <template v-for="(z, idx) in this.kdData.zones">
      <g v-if="z !== kdZoneMode.selected" :key="idx">
        <polygon
          stroke="black"
          :fill="getColor(z)"
          :fill-opacity="z.perc"
          :points="polyCoords(z.poly)"
          stroke-width="3"
          @click="setZone(z)"
          style="cursor: pointer"
        />
      </g>
    </template>
    <g v-if="kdZoneMode.selected">
      <polygon
        stroke="yellow"
        fill="none"
        pointer-events="visible"
        :fill-opacity="kdZoneMode.selected.perc"
        :points="polyCoords(kdZoneMode.selected.poly)"
        stroke-width="2"
        @click="setZone(kdZoneMode.selected)"
        style="cursor: pointer"
      />
    </g>

    <template v-if="kdZoneMode.selected">
      <g :transform="`rotate(${data.map.rotate_deg} 512 512)`">
        <circle :cx="-20" :cy="900" r="7" fill="green" />
        <text fill="white" x="0" y="870" font-size="2rem"> {{ this.data.teams[this.selected.team].name }} </text>
        <text fill="white" x="0" y="910" font-size="2rem"> Kills: {{ kdZoneMode.selected.kill }} </text>

        <polygon transform="rotate(90 -20, 930)" :points="triangle(-20, 930, 8)" fill="red" />
        <text fill="white" x="0" y="940" font-size="2rem"> Deaths: {{ kdZoneMode.selected.death }} </text>

        <text fill="white" x="0" y="970" font-size="2rem"> KD Score: {{ kdZoneMode.selected.score }} </text>

        <g v-if="kdZoneMode.legend">
          <g
            v-for="(item, index) in this.presentPlayers"
            :key="item.id"
            :fill="legend[item.id]"
            :transform="`translate(1000, ${1014 - (index + 1) * 30})`"
          >
            <g v-for="(matchPlayerId, ind) in item.matchPlayerId" :key="matchPlayerId">
              <image
                :x="-(ind * 30)"
                y="0"
                width="26"
                height="26"
                :href="playerAgent(matchPlayerId).display_icon_small_url"
              />
            </g>
            <text x="30" y="20" font-size="1.5rem">{{ item.name }}</text>
          </g>
        </g>
      </g>

      <template v-for="(k, idx) in kdZoneMode.selected.list">
        <g
          v-if="
            k.type === 'kill' &&
            kdZoneMode.view !== 'death' &&
            (playerVisible(k.killer) || playerVisible(k.victim)) &&
            firstFlag(k.first) &&
            plantTimingFlag(k.pre_post_plant)
          "
          :key="idx"
        >
          <line
            v-if="kdZoneMode.opps"
            :x1="k.killer.location.x * 1024"
            :y1="k.killer.location.y * 1024"
            :x2="k.victim.location.x * 1024"
            :y2="k.victim.location.y * 1024"
            stroke="black"
            stroke-width="2"
          />

          <circle
            :transform="`translate(${k.killer.location.x * 1024}, ${k.killer.location.y * 1024})`"
            :cx="k.killer.location.x"
            :cy="k.killer.location.y"
            r="7"
            :fill="useLegend(k.killer.match_player_id, 'green')"
          />

          <polygon
            v-if="kdZoneMode.opps"
            :transform="`translate(${k.victim.location.x * 1024}, ${k.victim.location.y * 1024})`"
            :points="triangle(k.victim.location.x, k.victim.location.y, 5)"
            :fill="useLegend(k.victim.match_player_id, 'black')"
          />
        </g>

        <g
          v-if="
            k.type === 'death' &&
            kdZoneMode.view !== 'kill' &&
            (playerVisible(k.killer) || playerVisible(k.victim)) &&
            firstFlag(k.first) &&
            plantTimingFlag(k.pre_post_plant)
          "
          :key="idx"
        >
          <line
            v-if="kdZoneMode.opps"
            :x1="k.killer.location.x * 1024"
            :y1="k.killer.location.y * 1024"
            :x2="k.victim.location.x * 1024"
            :y2="k.victim.location.y * 1024"
            stroke="black"
            stroke-width="2"
          />

          <circle
            v-if="kdZoneMode.opps"
            :transform="`translate(${k.killer.location.x * 1024}, ${k.killer.location.y * 1024})`"
            :cx="k.killer.location.x"
            :cy="k.killer.location.y"
            r="4"
            :fill="useLegend(k.killer.match_player_id, 'black')"
          />

          <polygon
            :transform="`translate(${k.victim.location.x * 1024}, ${k.victim.location.y * 1024})`"
            :points="triangle(k.victim.location.x, k.victim.location.y)"
            :fill="useLegend(k.victim.match_player_id, 'red')"
          />
        </g>
      </template>
    </template>
  </g>

  <g v-else>
    <g :transform="`rotate(${data.map.rotate_deg} 512 512)`">
      <circle :cx="-20" :cy="900" r="7" fill="green" />
      <text fill="white" x="0" y="870" font-size="2rem"> {{ this.data.teams[this.selected.team].name }} </text>
      <text fill="white" x="0" y="910" font-size="2rem"> Kills: {{ this.kdData.kills.kill }} </text>

      <polygon transform="rotate(90 -20, 930)" :points="triangle(-20, 930, 8)" fill="red" />
      <text fill="white" x="0" y="940" font-size="2rem"> Deaths: {{ this.kdData.kills.death }} </text>

      <g v-if="kdZoneMode.legend">
        <g
          v-for="(item, index) in this.presentPlayers"
          :key="item.id"
          :fill="legend[item.id]"
          :transform="`translate(1000, ${1014 - (index + 1) * 30})`"
        >
          <g v-for="(matchPlayerId, ind) in item.matchPlayerId" :key="matchPlayerId">
            <image
              :x="-(ind * 30)"
              y="0"
              width="26"
              height="26"
              :href="playerAgent(matchPlayerId).display_icon_small_url"
            />
          </g>
          <text x="30" y="20" font-size="1.5rem">{{ item.name }}</text>
        </g>
      </g>
    </g>

    <template v-for="(k, idx) in this.kdData.kills.list">
      <g
        v-if="
          k.type === 'kill' &&
          kdZoneMode.view !== 'death' &&
          (playerVisible(k.killer) || playerVisible(k.victim)) &&
          firstFlag(k.first) &&
          plantTimingFlag(k.pre_post_plant)
        "
        :key="idx"
      >
        <line
          v-if="kdZoneMode.opps"
          :x1="k.killer.location.x * 1024"
          :y1="k.killer.location.y * 1024"
          :x2="k.victim.location.x * 1024"
          :y2="k.victim.location.y * 1024"
          stroke="black"
          stroke-width="2"
        />

        <circle
          :transform="`translate(${k.killer.location.x * 1024}, ${k.killer.location.y * 1024})`"
          :cx="k.killer.location.x"
          :cy="k.killer.location.y"
          r="7"
          :fill="useLegend(k.killer.match_player_id, 'green')"
        />

        <polygon
          v-if="kdZoneMode.opps"
          :transform="`translate(${k.victim.location.x * 1024}, ${k.victim.location.y * 1024})`"
          :points="triangle(k.victim.location.x, k.victim.location.y, 5)"
          :fill="useLegend(k.victim.match_player_id, 'black')"
        />
      </g>

      <g
        v-if="
          k.type === 'death' &&
          kdZoneMode.view !== 'kill' &&
          (playerVisible(k.killer) || playerVisible(k.victim)) &&
          firstFlag(k.first) &&
          plantTimingFlag(k.pre_post_plant)
        "
        :key="idx"
      >
        <line
          v-if="kdZoneMode.opps"
          :x1="k.killer.location.x * 1024"
          :y1="k.killer.location.y * 1024"
          :x2="k.victim.location.x * 1024"
          :y2="k.victim.location.y * 1024"
          stroke="black"
          stroke-width="2"
        />

        <circle
          v-if="kdZoneMode.opps"
          :transform="`translate(${k.killer.location.x * 1024}, ${k.killer.location.y * 1024})`"
          :cx="k.killer.location.x"
          :cy="k.killer.location.y"
          r="4"
          :fill="useLegend(k.killer.match_player_id, 'black')"
        />

        <polygon
          :transform="`translate(${k.victim.location.x * 1024}, ${k.victim.location.y * 1024})`"
          :points="triangle(k.victim.location.x, k.victim.location.y)"
          :fill="useLegend(k.victim.match_player_id, 'red')"
        />
      </g>
    </template>
  </g>
</template>

<script>
import * as d3 from 'd3'
import { mapGetters } from 'vuex'

import { getZones } from '../../../utils/zoning.js'
import exposedDataState from '../mixins/exposedDataState.js'

const color = d3.scaleSequential().interpolator(d3.interpolateSinebow)

export default {
  name: 'KDZoneMap',
  mixins: [exposedDataState],
  data() {
    return {
      legend: [],
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.legend = this.genLegend(this.data.players)
    })
  },
  computed: {
    ...mapGetters({
      getAgentById: 'static/getAgentById',
      weaponsByName: 'static/weaponsByName',
      getWeaponById: 'static/getWeaponById',
    }),
    kdZoneMode: {
      get() {
        return this.state.kdZoneMode
      },
    },
    kdData() {
      const selectedTeamId = this.state.selected.team
      const zones = getZones(this.data.map.id)
      const kills = JSON.parse(JSON.stringify(this.mapToolEvents?.kills || []))
      const first = this.data.firstRoundKills

      let highestScore = 0
      let lowestScore = 0

      let zoneList = {}
      let killList = {
        kill: 0,
        death: 0,
        list: [],
      }

      let time = this.selected.time
      kills.forEach(k => {
        if (k.finishing_damage.damage_type === 'Bomb') return
        if (
          time !== null &&
          (k.round_time_millis > time.end_time_millis || k.round_time_millis < time.start_time_millis)
        )
          return

        if (
          this.data.rounds[k.round_id].plant_id !== null &&
          this.data.rounds[k.round_id].plant_round_millis !== null
        ) {
          k.pre_post_plant = k.round_time_millis <= this.data.rounds[k.round_id].plant_round_millis ? 'pre' : 'post'
        } else {
          k.pre_post_plant = 'pre'
        }

        let eventType = ''
        let genEventType = ''

        let killerTeam = this.data.roundTeams[k.killer.round_team_id].team_id
        let victimTeam = this.data.roundTeams[k.victim.round_team_id].team_id

        let killerZone = k.killer.zone
        let victimZone = k.victim.zone

        if (killerTeam === selectedTeamId && victimTeam !== selectedTeamId) {
          eventType = killerZone === undefined ? '' : 'kill'
          genEventType = 'kill'
        } else if (victimTeam === selectedTeamId) {
          eventType = victimZone === undefined ? '' : 'death'
          genEventType = 'death'
        }

        if (eventType) {
          let targetZone = eventType === 'kill' ? killerZone : victimZone

          if (!(targetZone in zoneList)) {
            zoneList[targetZone] = {
              name: targetZone,
              poly: zones[targetZone].poly,
              kill: 0,
              death: 0,
              score: 0,
              perc: 0.9,
              list: [],
            }
          }

          zoneList[targetZone][eventType] += 1
          zoneList[targetZone].score = zoneList[targetZone].kill - zoneList[targetZone].death

          highestScore = zoneList[targetZone].score > highestScore ? zoneList[targetZone].score : highestScore
          lowestScore = zoneList[targetZone].score < lowestScore ? zoneList[targetZone].score : lowestScore

          k.type = eventType
          k.first = false
          if (k.round_id in first && first[k.round_id].kill_id === k.id) {
            k.first = true
          }
          zoneList[targetZone].list.push(k)
        }

        if (genEventType) {
          k.type = genEventType
          k.first = false
          killList[genEventType] += 1
          if (k.round_id in first && first[k.round_id].kill_id === k.id) {
            k.first = true
          }
          killList.list.push(k)
        }
      })
      Object.entries(zoneList).forEach(z => {
        if (z[1].score === 0) {
          z[1].perc = 0.9
        } else if (z[1].score > 0) {
          z[1].perc = z[1].score / highestScore >= 0.9 ? 0.9 : z[1].score / highestScore
        } else {
          z[1].perc = z[1].score / lowestScore >= 0.9 ? 0.9 : z[1].score / lowestScore
        }

        if (z[1].perc < 0.1) z[1].perc = 0.1
      })

      return {
        team: selectedTeamId,
        zones: zoneList,
        kills: killList,
      }
    },
    presentPlayers() {
      if (this.kdZoneMode.selected === '' && this.kdZoneMode.zone) return []
      let players = {}
      let killList = this.kdZoneMode.zone ? this.kdZoneMode.selected.list : this.kdData.kills.list

      killList.forEach(k => {
        let plr = ['killer', 'victim']
        if (this.kdZoneMode.first && !k.first) return
        if (this.kdZoneMode.view !== 'all' && this.kdZoneMode.view !== k.type) return
        if (!this.kdZoneMode.opps) plr = k.type === 'kill' ? ['killer'] : ['victim']
        if (this.kdZoneMode.plantTime !== 'all' && this.kdZoneMode.plantTime !== k.pre_post_plant) return
        if (!this.playerVisible(k.victim) && !this.playerVisible(k.killer)) return

        plr.forEach(p => {
          if (
            this.data.matchPlayers[k[p].match_player_id].player_id in players &&
            !players[this.data.matchPlayers[k[p].match_player_id].player_id].matchPlayerId.includes(
              k[p].match_player_id
            ) &&
            !this.playerAgentExist(
              k[p].match_player_id,
              players[this.data.matchPlayers[k[p].match_player_id].player_id].matchPlayerId
            )
          ) {
            players[this.data.matchPlayers[k[p].match_player_id].player_id].matchPlayerId.push(k[p].match_player_id)
          } else {
            players[this.data.matchPlayers[k[p].match_player_id].player_id] = {
              id: this.data.matchPlayers[k[p].match_player_id].player_id,
              team: this.data.teams[this.data.matchPlayers[k[p].match_player_id].team_id].name,
              name: this.data.players[this.data.matchPlayers[k[p].match_player_id].player_id].name,
              matchPlayerId: [k[p].match_player_id],
            }
          }
        })
      })

      return Object.values(players).sort((a, b) => {
        if (a.team > b.team) return -1
        if (a.team < b.team) return 1

        if (a.name > b.name) return -1
        if (a.name < b.name) return 1
        return 0
      })
    },
  },
  methods: {
    genLegend(items) {
      let players = []
      Object.keys(items).forEach(p => players.push(p))
      color.domain([0, players.length])
      return Object.freeze(Object.fromEntries(players.map((k, i) => [k, color(i)])))
    },
    polyCoords(d) {
      let dPath = d
        .flat()
        .map(c => c * 1024)
        .join(',')
      return dPath
    },
    getColor(zone) {
      if (zone.score === 0) {
        return 'hsl(217, 91%, 60%)'
      } else if (zone.score > 0) {
        return 'hsl(142, 76%, 36%)'
      } else {
        return 'hsl(347, 77%, 50%)'
      }
    },
    setZone(zone) {
      if (zone === this.kdZoneMode.selected) {
        this.kdZoneMode.selected = ''
      } else {
        this.kdZoneMode.selected = zone
      }
    },
    playerVisible(player) {
      if (this.state.filters.display.players === null) return true
      if (this.data.matchPlayers[player.match_player_id].player_id in this.state.filters.display.players) return true
      return false
    },
    playerAgentExist(player, playerList) {
      let exist = false
      playerList.forEach(plr => {
        if (
          this.data.agents[this.data.matchPlayers[plr].agent_id].name ===
          this.data.agents[this.data.matchPlayers[player].agent_id].name
        )
          exist = true
      })
      return exist
    },
    playerAgent(playerId) {
      return this.data.agents[this.data.matchPlayers[playerId].agent_id]
    },
    firstFlag(isFirst) {
      if (!this.kdZoneMode.first) return true
      return isFirst
    },
    plantTimingFlag(killPrePost) {
      if (this.kdZoneMode.plantTime === 'all') return true
      return this.kdZoneMode.plantTime === killPrePost
    },
    triangle(x, y, size = 7) {
      let leftPoint = x - size + ',' + y
      let rightPoint = x + size + ',' + (y + size)
      let topPoint = x + size + ',' + (y - size)
      let path = [topPoint, leftPoint, rightPoint]
      return path.join(' ')
    },
    useLegend(player, defaultColor) {
      if (!this.kdZoneMode.legend) return defaultColor
      return this.legend[this.data.matchPlayers[player].player_id]
    },
  },
  watch: {
    mapToolEvents: {
      handler() {
        this.kdZoneMode.selected = ''
      },
      immediate: true,
    },
  },
}
</script>
