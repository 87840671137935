<template functional>
  <g :opacity="props.faded ? $options.meta.fadeOpacity : 1">
    <component
      v-for="index in props.event.vector.length - 1"
      :key="index"
      :is="$options.components.MapLineEntry"
      :color="props.data.roundTeams[props.event.round_team_id].role | roleColor"
      :x1="props.event.vector[index - 1].x"
      :y1="props.event.vector[index - 1].y"
      :x2="props.event.vector[index].x"
      :y2="props.event.vector[index].y"
      width="4"
    />
  </g>
</template>

<script>
import { MAP_FADED_EVENTS_OPACITY } from '@/constants.js'

import MapLineEntry from './MapLineEntry.vue'

export default {
  name: 'MapWallEventEntry',
  components: { MapLineEntry },
  props: {
    data: { type: Object, required: true },
    event: { type: Object, required: true },
    faded: { type: Boolean, default: false },
  },
  filters: {
    roleColor(role) {
      switch (role) {
        case 'def':
          return 'rgba(127, 255, 212, 1)'
        case 'atk':
          return 'rgba(255, 99, 71, 1)'
      }
    },
  },
  meta: {
    fadeOpacity: MAP_FADED_EVENTS_OPACITY,
  },
}
</script>
