<template>
  <g class="background">
    <circle r="1e10" fill="black" />
    <image x="0" y="0" width="1024" height="1024" :href="mapData.display_icon_url" />
    <rect x="0" y="0" width="1024" height="1024" fill="black" opacity="0.8" />
  </g>
</template>

<script>
import px from 'vue-types'

export default {
  name: 'MapBackground',

  props: {
    mapData: px.shape({
      display_icon_url: px.string.isRequired,
      rotate_deg: px.integer.isRequired,
    }).loose.isRequired,
  },
}
</script>

<style scoped lang="scss">
.map-background {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 80%);
  }
}

.map-layout {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;
}
</style>
