<template>
  <div class="ado-container">
    <V3Button
      class="ado-player"
      :class="{ active: filters.display.gids && filters.display.gids[agent.gid] }"
      @click="toggleGid(agent.gid)"
      :disabled="disabled"
    >
      <V3Agent
        :id="agent.id"
        class="ado-player-agent"
        :grayscale="!(filters.display.gids && filters.display.gids[agent.gid])"
      />
      <div class="ado-player-name">{{ player.name }}</div>
    </V3Button>
    <div class="ado-ability-container">
      <V3Button
        v-for="ability in abilities"
        class="ado-ability"
        :class="ability.slot.toLowerCase()"
        :key="ability.slot"
        :style="{ order: ability.order }"
        @click="toggleGid(ability.gid)"
        :disabled="disabled"
      >
        <V3Image
          :alt="ability.displayName"
          class="ado-ability-image"
          :class="[ability.slot.toLowerCase(), { active: filters.display.gids && filters.display.gids[ability.gid] }]"
          :gray="!(filters.display.gids && filters.display.gids[ability.gid])"
          :src="ability.displayIcon"
          :title="ability.displayName"
        />
      </V3Button>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import V3Agent from './v3dafi/V3Agent.vue'
import V3Button from './v3dafi/V3Button.vue'
import V3Image from './v3dafi/V3Image.vue'

export default {
  name: 'AgentReplayDisplayOptions',
  components: {
    V3Button,
    V3Image,
    V3Agent,
  },
  props: {
    agent: px.object,
    player: px.object,
    abilities: px.object,
    filters: px.object,
    disabled: px.bool.def(false),
  },
  methods: {
    toggleGid(gid) {
      const newSelectedGids = {
        ...this.filters.display.gids,
        [gid]: !this.filters.display.gids?.[gid],
      }
      if (Object.entries(newSelectedGids).filter(([, v]) => v).length === 0) {
        this.filters.display.gids = null
      } else {
        this.filters.display.gids = Object.fromEntries(Object.entries(newSelectedGids).filter(([, v]) => v))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ado {
  &-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.25rem;
    gap: 0.25rem;
  }

  &-player {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 0.25rem;
    min-width: 0;

    &:disabled {
      opacity: 0.2;
    }

    &-agent {
      height: 1.5rem;
    }

    &-name {
      white-space: nowrap;
      text-align: left;
      justify-self: stretch;
      font-size: 11px;
      line-height: 0.75rem;
      max-height: 1.5rem;
      color: #a4a2ad;
      transition: color 0.2s ease-in-out;
    }

    &.active &-name {
      color: #fff;
    }
  }

  &-ability {
    padding: 0;
    align-items: flex-start;

    &:disabled {
      opacity: 0.2;
    }

    &.passive {
      order: -1;
    }

    &.ultimate {
      order: 1;
    }

    &-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    &-image {
      height: 1.5rem;

      &.ultimate {
        height: 2rem;
      }
    }
  }
}
</style>
