import { BIconClockFill } from 'bootstrap-vue'

import IconDefuse from '../UI/IconDefuse.vue'
import IconExplode from '../UI/IconExplode.vue'
import IconKill from '../UI/IconKill.vue'

export const BOMBSITE = {
  A: 'A',
  B: 'B',
  C: 'C',
}

export const ECONOMY = {
  P: 'Pistol',
  $: 'Eco: 0-5k',
  $$: 'Semi eco: 5-10k',
  $$$: 'Semi buy: 10-20k',
  $$$$: 'Full buy: 20k+',
}

export const OUTCOME = {
  Elimination: {
    label: 'Elimination',
    icon: IconKill,
  },
  Detonate: {
    label: 'Detonate',
    icon: IconExplode,
  },
  Defuse: {
    label: 'Defuse',
    icon: IconDefuse,
  },
  Time: {
    label: 'Time',
    icon: BIconClockFill,
  },
}

export const BOOKMARK_ICONS = {
  Elimination: {
    label: 'Elimination',
    icon: IconKill,
  },
  Detonate: {
    label: 'Detonate',
    icon: IconExplode,
  },
  Defuse: {
    label: 'Defuse',
    icon: IconDefuse,
  },
  Time: {
    label: 'Time',
    icon: BIconClockFill,
  },
  Plant: {
    label: 'Plant',
    icon: null,
    asset: '/images/spike/spike-minimap2.png',
  },
}
