<template>
  <div>
    <ErrorAlert dismissible @dismiss="onDismiss">
      <template #default>
        {{ message }}
      </template>
      <template #dismiss>
        <b-button variant="danger" size="sm" @click="goToBilling">Billing</b-button>
      </template>
    </ErrorAlert>
  </div>
</template>

<script>
import px from 'vue-types'

import ErrorAlert from '@/components/generic/ErrorAlert.vue'

export default {
  name: 'PermissionsAlert',
  components: { ErrorAlert },
  props: {
    message: px.string.def(null),
  },
  data: function () {
    return {
      localMessage: 'Insufficient permissions',
    }
  },
  computed: {
    messageValue: {
      get() {
        return this.message || this.localMessage
      },
      set(value) {
        this.localMessage = value
        this.$emit('update:message', value)
      },
    },
  },
  methods: {
    onDismiss() {
      this.message = null
    },
    goToBilling() {
      this.$router.push({ path: '/billing' })
    },
  },
}
</script>
