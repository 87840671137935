<template>
  <div class="main">
    <div style="justify-self: end">
      <div class="tooltipmain">
        ?
        <span class="tooltiptext">
          To add Agents and Utility Icons to the board simply click on them. Right clicking an Icon & Smoke will change
          their role. The Icon Eraser also erases Smoke and Spike. Holding CTRL when left clicking on an Agent, Utility,
          Smoke, and Spike on the board will delete them. In the Agent UI section, holding CTRL when left clicking an
          icon will clear it from the board; while holding ALT will clear all agent and utility icons of said clicked
          agent (both CTRL and ALT will clear icons regardless of side).
        </span>
      </div>

      <V3Button style="color: black; width: 56px; background-color: white" @click="closeBoard"> Close </V3Button>
    </div>

    <div class="sidebar">
      Drawing Tools

      <div class="tools-container">
        <V3Button :active="action === 'freehand'" @click="updateAction('freehand')">
          <BIconPencil />
        </V3Button>
        <V3Button :active="action === 'ellipse'" @click="updateAction('ellipse')">
          <IconEllipse />
        </V3Button>
        <V3Button :active="action === 'rect'" @click="updateAction('rect')">
          <BIconSquare />
        </V3Button>
        <V3Button :active="action === 'line'" @click="updateAction('line')">
          <IconLine />
        </V3Button>
      </div>

      <div class="generic-container">
        <V3Button :active="action === 'drawing-eraser'" @click="updateAction('drawing-eraser')">
          <IconEraser /> Drawings
        </V3Button>
        <V3Button :active="action === 'icon-eraser'" @click="updateAction('icon-eraser')">
          <IconEraser /> Icons
        </V3Button>
      </div>

      <div class="dc-container">
        <template v-for="(width, widthIdx) in widths">
          <V3Button
            v-for="(color, colorIdx) in colors"
            :key="`${color}-${width}`"
            :active="setting.color === colorIdx && setting.width === widthIdx"
            @click="updateSetting({ color: colorIdx, width: widthIdx })"
          >
            <span
              class="indicator"
              :class="[
                `color-${colorIdx} opacity-${setting.opacity} width-${widthIdx}`,
                { active: setting.color === colorIdx && setting.width === widthIdx },
              ]"
            />
            <span
              class="line"
              :class="[
                `color-${colorIdx} opacity-${setting.opacity} width-${widthIdx}`,
                { active: setting.color === colorIdx && setting.width === widthIdx },
              ]"
            />
          </V3Button>
        </template>
      </div>

      <div class="generic-container">
        <V3Button @click="addSmoke">
          <IconEllipse />
          Smoke
        </V3Button>
        <V3Button @click="addSpike">
          <img src="/images/spike/spike-minimap2.png" width="18" height="18" />
          Spike
        </V3Button>
        <V3Button @click="downloadImage">
          <BIconDownload />
          Save Image
        </V3Button>
      </div>
      DELETE
      <div class="generic-container">
        <V3Button @click="clearAgents">
          <BIconPersonFill />
          Agents
        </V3Button>
        <V3Button @click="clearUtils">
          <BIconLightningFill />
          Utils
        </V3Button>
        <V3Button @click="clearDrawings">
          <BIconPencilFill />
          Drawings
        </V3Button>
      </div>

      <div class="generic-container">
        <V3Button @click="clearSmokes">
          <IconEllipse />
          Smokes
        </V3Button>
        <V3Button @click="clearSpikes">
          <img src="/images/spike/spike-minimap2.png" width="18" height="18" />
          Spikes
        </V3Button>
        <V3Button @click="clearAll">
          <IconClear />
          Clear All
        </V3Button>
      </div>

      <span>AGENTS</span>
      <div class="agent-toggle">
        <V3Button :active="!allAgents" @click="toggleAllAgents(false)"> Only In Match </V3Button>
        <V3Button :active="allAgents" @click="toggleAllAgents(true)"> All Agents </V3Button>
      </div>

      <div class="agent-toggle">
        <V3Button :active="iconSide === 'atk'" :class="{ atk: iconSide === 'atk' }" @click="toggleIconSide('atk')">
          ATK
        </V3Button>
        <V3Button :active="iconSide === 'def'" :class="{ def: iconSide === 'def' }" @click="toggleIconSide('def')">
          DEF
        </V3Button>
      </div>

      <div class="agent-list">
        <template v-for="(agent, idx) in agentList">
          <div class="agent-row" :key="agent.name + idx">
            <V3Button @click="e => addIcon(e, agent, 'agent', agent.name)">
              <img :src="agent.icon" :alt="agent.name" class="agent-icon" :class="[iconSide]" />
            </V3Button>
            <div class="ability-row">
              <template v-for="util in Object.values(agent.abilities)">
                <V3Button @click="e => addIcon(e, util, 'util', agent.name)" :key="util.name + agent.name">
                  <img :src="util.icon" :alt="util.name" class="util-icon" :class="[iconSide]" />
                </V3Button>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <svg
      viewBox="0 0 1024 1024"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      id="board"
      @mousedown.left="handleActionMouseDown"
      @mouseup="handleActionMouseUp"
      @mousemove="handleActionMouseMove"
      @mouseleave="handleActionMouseUp"
      @contextmenu.prevent
    >
      <g :transform="`rotate(${-data.map.rotate_deg} 512 512)`">
        <g class="background">
          <circle r="1e10" fill="#191822" />
          <image x="0" y="0" width="1024" height="1024" :href="data.map.display_icon_url" />
          <rect x="0" y="0" width="1024" height="1024" fill="#191822" opacity="0.8" />
        </g>
      </g>

      <template v-if="this.others.length !== 0">
        <g
          v-for="(a, index) in this.others"
          style="cursor: pointer"
          :key="index + a.type"
          @mousedown.left="e => handleOtherMouseDown(e, index)"
          @mouseover.left="handleIconMouseOver('other', index)"
        >
          <image
            v-if="a.type === 'spike'"
            :x="a.x"
            :y="a.y"
            :href="'/images/spike/spike-minimap2.png' | resolveUrl"
            width="28"
            height="28"
          />
          <circle
            v-else-if="a.type === 'smoke'"
            :cx="a.x"
            :cy="a.y"
            r="30"
            @contextmenu="e => handleSmokeRightClick(e, index)"
            :fill="smokeColor(a.role)"
            stroke="rgba(200, 200, 200, 0.75)"
          />
        </g>
      </template>

      <template v-if="this.icons.length !== 0">
        <g
          v-for="(a, index) in this.icons"
          style="cursor: pointer"
          :key="index + a.type + 'icon'"
          @mousedown.left="e => handleIconMouseDown(e, index)"
          @mouseover.left="handleIconMouseOver('icon', index)"
          @contextmenu="e => handleIconRightClick(e, index)"
        >
          <defs>
            <clipPath :id="'icon' + a.type + index">
              <circle :cx="a.x + 28 / 2" :cy="a.y + 28 / 2" r="14" />
            </clipPath>
          </defs>
          <circle :cx="a.x + 28 / 2" :cy="a.y + 28 / 2" :fill="roleColor(a.role)" r="16" />

          <image
            :clip-path="'url(#' + 'icon' + a.type + index + ')'"
            :x="a.x"
            :y="a.y"
            :href="a.icon"
            :height="28"
            :width="28"
          />
          <!-- Instead of clipPath this can give border radius - issue is when saving png CSS is not applied -->
          <!-- style="clip-path: inset(0% round 50%)"  -->
        </g>
      </template>

      <g v-if="this.drawings.length !== 0">
        <template v-for="(dr, index) in this.drawings">
          <rect
            v-if="dr.type === 'rect'"
            :key="dr.type + index"
            :x="dr.x"
            :y="dr.y"
            :width="dr.width"
            :height="dr.height"
            :stroke="dr.color"
            :stroke-width="dr.stroke"
            fill="none"
            @mouseover.left="handleMouseOverDrawing(index)"
          />

          <line
            v-if="dr.type === 'line'"
            :key="dr.type + index"
            :x1="dr.initX"
            :y1="dr.initY"
            :x2="dr.currX"
            :y2="dr.currY"
            :stroke="dr.color"
            :stroke-width="dr.stroke"
            @mouseover.left="handleMouseOverDrawing(index)"
          />

          <ellipse
            v-if="dr.type === 'ellipse'"
            :key="dr.type + index"
            :cx="dr.cx"
            :cy="dr.cy"
            :rx="dr.rx"
            :ry="dr.ry"
            :stroke="dr.color"
            :stroke-width="dr.stroke"
            fill="none"
            @mouseover.left="handleMouseOverDrawing(index)"
          />

          <path
            v-if="dr.type === 'freehand'"
            :key="dr.type + index"
            :d="dr.path"
            :stroke="dr.color"
            :stroke-width="dr.stroke"
            fill="none"
            @mouseover.left="handleMouseOverDrawing(index)"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script>
import {
  BIconSquare,
  BIconPencil,
  BIconPencilFill,
  BIconPersonFill,
  BIconLightningFill,
  BIconDownload,
} from 'bootstrap-vue'
import { Canvg, presets } from 'canvg'

import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import IconClear from '@/components/UI/IconClear.vue'
import IconEllipse from '@/components/UI/IconEllipse.vue'
import IconEraser from '@/components/UI/IconEraser.vue'
import IconLine from '@/components/UI/IconLine.vue'
import resolveUrl from '@/filters/resolveUrl.js'
import binarySearch from '@/utils/binarySearch'
import download from '@/utils/download'

import { MAX_AGENT_SPEED, MAX_TIME_DIFF } from '../../../constants.js'
import exposedDataState from '../mixins/exposedDataState.js'
import alignRoundTime from '../utils/alignRoundTime.js'

export default {
  name: 'DrawingBoardModal',
  mixins: [exposedDataState],
  components: {
    V3Button,
    BIconSquare,
    BIconPencil,
    BIconPencilFill,
    BIconPersonFill,
    BIconLightningFill,
    BIconDownload,
    IconEraser,
    IconEllipse,
    IconLine,
    IconClear,
  },
  data() {
    return {
      action: '',
      colors: ['#c80a19', '#fff200', '#7fffd4', '#ffffff'],
      opacities: [1 / 3, 2 / 3, 1],
      widths: [3, 5],
      setting: { color: 0, width: 0 },
      allAgents: false,
      iconSide: 'atk',
      icons: [],
      drawings: [],
      others: [],
      pt: 0,
      isAction: false,
      pathBuffer: [],
      iconDrag: null,
      otherDrag: null,
      lastPoint: { x: 0, y: 0 },
      count: 0,
    }
  },
  mounted() {
    const svg = this.$el.querySelector('#board')
    this.pt = svg.createSVGPoint()
  },
  filters: {
    resolveUrl,
  },
  computed: {
    agentList() {
      const agentMatch = {}
      Object.values(this.data.matchPlayers).forEach(mP => (agentMatch[mP.agent_id] = true))

      let agents = []
      Object.values(this.data.agents).forEach(a => {
        if (!this.allAgents && !(a.id in agentMatch)) return
        agents.push({
          name: a.name,
          icon: a.display_icon_small_url,
          abilities: Object.values(a.abilities)
            .filter(
              ability => ability.slot.toLowerCase() !== 'passive' || a.id === '41fb69c1-4189-7b37-f117-bcaf1e96f1bf'
            )
            .reduce((acc, ability) => {
              acc[ability.slot] = {
                name: ability.displayName,
                icon: ability.displayIcon,
              }
              return acc
            }, {}),
        })
      })
      return agents
    },
    alignRoundTime() {
      return alignRoundTime(this)
    },
    playerIconsList() {
      const playerMatchIcons = {}
      Object.values(this.data.matchPlayers).forEach(mP => {
        playerMatchIcons[mP.id] = {
          name: this.data.agents[mP.agent_id].name,
          icon: this.data.agents[mP.agent_id].display_icon_small_url,
          abilities: Object.values(this.data.agents[mP.agent_id].abilities)
            .filter(
              ability =>
                ability.slot.toLowerCase() !== 'passive' ||
                this.data.agents[mP.agent_id].id === '41fb69c1-4189-7b37-f117-bcaf1e96f1bf'
            )
            .reduce((acc, ability) => {
              acc[ability.slot.toLowerCase()] = {
                name: ability.displayName,
                icon: ability.displayIcon,
              }
              return acc
            }, {}),
        }
      })
      return playerMatchIcons
    },
  },
  methods: {
    groupedEvents(events) {
      const grouped = events.reduce((grouped, event) => {
        const key = event.track_id ? `track-${event.track_id}#${event.round_id}` : `${event.gid}#${event.round_id}`
        const list = (grouped[key] = grouped[key] || [])
        list.push(event)
        return grouped
      }, {})
      for (const key in grouped) {
        grouped[key] = grouped[key].sort((a, b) => a.round_time_millis - b.round_time_millis)
      }
      return grouped
    },
    interpolateEvent(prevEvent, nextEvent, currentTime) {
      if (nextEvent.round_time_millis === currentTime) return nextEvent
      if (prevEvent.round_time_millis === currentTime) return prevEvent
      if (prevEvent.round_time_millis === nextEvent.round_time_millis) return
      if (prevEvent.round_time_millis > currentTime) return
      if (nextEvent.round_time_millis < currentTime) return

      const kDiff = nextEvent.round_time_millis - prevEvent.round_time_millis
      const kPrev = (nextEvent.round_time_millis - currentTime) / kDiff
      const kNext = (currentTime - prevEvent.round_time_millis) / kDiff

      if (kDiff > MAX_TIME_DIFF) return
      const speed =
        Math.sqrt(
          Math.pow(prevEvent.location.x - nextEvent.location.x, 2) +
            Math.pow(prevEvent.location.y - nextEvent.location.y, 2)
        ) / kDiff
      if (speed > MAX_AGENT_SPEED && prevEvent.track_id && prevEvent.track_id !== nextEvent.track_id) {
        return
      }

      return {
        ...nextEvent,
        location: {
          x: prevEvent.location.x * kPrev + nextEvent.location.x * kNext,
          y: prevEvent.location.y * kPrev + nextEvent.location.y * kNext,
        },
        conf:
          (prevEvent.conf != null ? prevEvent.conf : 1) *
          (nextEvent.conf != null ? nextEvent.conf : 1) *
          ((1 - Math.min(1, kDiff / MAX_TIME_DIFF)) * 0.75 + 0.25),
      }
    },
    loadFromMinimap(data) {
      this.clearAll()

      data.smokes.forEach(s => {
        let { x, y } = this.convertCoords(s.location)
        this.others.push({
          type: 'smoke',
          x: x,
          y: y,
          role: 'none',
        })
      })

      data.spike.forEach(s => {
        let { x, y } = this.convertCoords(s.location)
        this.others.push({
          type: 'spike',
          x: x - 14,
          y: y - 14,
        })
      })

      let grouped = Object.values(this.groupedEvents(data.icons))
      let icons = grouped
        .flatMap(list => {
          const idx = binarySearch(list, event => this.alignRoundTime(event) >= data.currTime)
          if (idx >= list.length) return
          const event = list[idx]
          const eventTime = this.alignRoundTime(event)
          if (eventTime === data.currTime) return event
          if (idx === 0) return
          const prevEvent = list[idx - 1]
          const prevEventTime = this.alignRoundTime(prevEvent)
          return this.interpolateEvent(
            { ...prevEvent, round_time_millis: prevEventTime },
            { ...event, round_time_millis: eventTime },
            data.currTime
          )
        })
        .filter(Boolean)

      icons.forEach(i => {
        let iconToUse = ''

        if (i.type === 'position') iconToUse = this.playerIconsList[i.match_player_id].icon
        else if (i.type === 'utility')
          iconToUse = this.playerIconsList[i.match_player_id].abilities[i.ability_slot].icon

        let { x, y } = this.convertCoords(i.location)
        this.icons.push({
          type: i.type === 'position' ? 'agent' : 'util',
          role: this.data.roundTeams[i.round_team_id].role,
          icon: iconToUse,
          x: x - 14,
          y: y - 14,
        })
      })
    },
    convertCoords(coords) {
      if (this.data.map.rotate_deg === 0) return { x: coords.x * 1024, y: coords.y * 1024 }
      else {
        let newX = coords.x * 1024
        let newY = 1024 - coords.y * 1024
        return { x: newY, y: newX }
      }
    },
    roleColor(role) {
      if (role === 'def') return 'rgba(127, 255, 212, 1)'
      else return 'rgba(255, 99, 71, 1)'
    },
    reverseRole(role) {
      if (role === 'atk') return 'def'
      if (role === 'def') return 'atk'
    },
    updateSetting(val) {
      this.setting = val
    },
    updateAction(val) {
      if (this.action === val) this.action = ''
      else this.action = val
    },
    toggleAllAgents(val) {
      this.allAgents = val
    },
    toggleIconSide(val) {
      this.iconSide = val
    },
    addIcon(e, icon, type, agent) {
      if (e.ctrlKey) {
        this.icons = this.icons.filter(i => i.icon !== icon.icon)
        return
      }
      if (e.altKey) {
        this.icons = this.icons.filter(i => i.agent !== agent)
        return
      }
      this.icons.push({
        type: type,
        agent: agent,
        role: this.iconSide,
        icon: icon.icon,
        x: 100 + 36 * this.count,
        y: 100,
      })
      this.count += 1
    },
    addSmoke() {
      this.others.push({
        type: 'smoke',
        x: 100 + 36 * this.count,
        y: 100,
        role: 'none',
      })
      this.count += 1
    },
    addSpike() {
      this.others.push({
        type: 'spike',
        x: 100 + 36 * this.count,
        y: 100,
      })
      this.count += 1
    },
    smokeColor(role) {
      switch (role) {
        case 'def':
          return 'rgba(127, 255, 212, 0.25)'
        case 'atk':
          return 'rgba(255, 99, 71, 0.25)'
        default:
          return 'rgba(200, 200, 200, 0.25)'
      }
    },
    cursorPointer(e) {
      this.pt.x = e.clientX
      this.pt.y = e.clientY
      return this.pt.matrixTransform(this.$el.querySelector('#board').getScreenCTM().inverse())
    },
    getAveragePoint(offset) {
      let len = this.pathBuffer.length

      if (len % 2 === 1 || len >= 8) {
        let totalX = 0
        let totalY = 0
        let pt
        let count = 0
        for (let i = offset; i < len; i++) {
          count++
          pt = this.pathBuffer[i]
          totalX += pt.x
          totalY += pt.y
        }
        return {
          x: totalX / count,
          y: totalY / count,
        }
      }
      return null
    },
    handleActionMouseDown(e) {
      let loc = this.cursorPointer(e)

      if (!this.isAction && this.action !== '') {
        this.isAction = true
        if (this.action === 'rect') {
          this.drawings.push({
            type: 'rect',
            initX: loc.x,
            initY: loc.y,
            currX: loc.x,
            currY: loc.y,
            x: loc.x,
            y: loc.y,
            width: 0,
            height: 0,
            color: this.colors[this.setting.color],
            stroke: this.widths[this.setting.width],
          })
        } else if (this.action === 'line') {
          this.drawings.push({
            type: 'line',
            initX: loc.x,
            initY: loc.y,
            currX: loc.x,
            currY: loc.y,
            color: this.colors[this.setting.color],
            stroke: this.widths[this.setting.width],
          })
        } else if (this.action === 'ellipse') {
          this.drawings.push({
            type: 'ellipse',
            initX: loc.x,
            initY: loc.y,
            currX: loc.x,
            currY: loc.y,
            cx: loc.x,
            cy: loc.y,
            rx: 0,
            ry: 0,
            color: this.colors[this.setting.color],
            stroke: this.widths[this.setting.width],
          })
        } else if (this.action === 'freehand') {
          this.drawings.push({
            type: 'freehand',
            path: `M ${loc.x} ${loc.y}`,
            color: this.colors[this.setting.color],
            stroke: this.widths[this.setting.width],
          })
        }
      }
    },
    handleActionMouseMove(e) {
      if (this.iconDrag !== null) {
        this.handleIconMouseMove(e)
        return
      }

      if (this.otherDrag !== null) {
        this.handleOtherMouseMove(e)
        return
      }

      if (!this.isAction) return
      let loc = this.cursorPointer(e)

      const ind = this.drawings.length - 1

      if (this.action === 'rect') {
        this.drawings[ind].currX = loc.x
        this.drawings[ind].currY = loc.y
        this.drawings[ind].x = Math.min(this.drawings[ind].initX, this.drawings[ind].currX)
        this.drawings[ind].y = Math.min(this.drawings[ind].initY, this.drawings[ind].currY)
        this.drawings[ind].width = Math.abs(this.drawings[ind].currX - this.drawings[ind].initX)
        this.drawings[ind].height = Math.abs(this.drawings[ind].currY - this.drawings[ind].initY)
      } else if (this.action === 'line') {
        this.drawings[ind].currX = loc.x
        this.drawings[ind].currY = loc.y
      } else if (this.action === 'ellipse') {
        this.drawings[ind].currX = loc.x
        this.drawings[ind].currY = loc.y
        this.drawings[ind].cx = (this.drawings[ind].initX + this.drawings[ind].currX) / 2
        this.drawings[ind].cy = (this.drawings[ind].initY + this.drawings[ind].currY) / 2
        this.drawings[ind].rx = Math.abs(this.drawings[ind].currX - this.drawings[ind].initX) / 2
        this.drawings[ind].ry = Math.abs(this.drawings[ind].currY - this.drawings[ind].initY) / 2
      } else if (this.action === 'freehand') {
        this.pathBuffer.push({ x: loc.x, y: loc.y })
        while (this.pathBuffer.length > 8) {
          this.pathBuffer.shift()
        }
        let pt = this.getAveragePoint(0)
        if (pt) {
          const strPath = ` L ${pt.x} ${pt.y}`
          let tmpPath = ''
          for (let offset = 2; offset < this.pathBuffer.length; offset += 2) {
            let npt = this.getAveragePoint(offset)
            tmpPath += ` L ${npt.x} ${npt.y}`
          }
          this.drawings[ind].path += strPath + tmpPath
        }
      }
    },
    handleActionMouseUp() {
      this.iconDrag = null
      this.otherDrag = null
      this.isAction = false
      this.pathBuffer = []
    },
    handleIconMouseDown(e, index) {
      if (this.action !== '') return
      if (e.ctrlKey) {
        this.icons.splice(index, 1)
        return
      }
      this.icons.push(this.icons.splice(index, 1)[0])
      this.iconDrag = this.icons[this.icons.length - 1]
      let loc = this.cursorPointer(e)
      this.lastPoint.x = loc.x
      this.lastPoint.y = loc.y
      this.count = 0
    },
    handleIconMouseMove(e) {
      if (this.iconDrag) {
        let loc = this.cursorPointer(e)

        let deltaX = loc.x - this.lastPoint.x
        let deltaY = loc.y - this.lastPoint.y

        this.iconDrag.x += deltaX
        this.iconDrag.y += deltaY

        this.lastPoint.x = loc.x
        this.lastPoint.y = loc.y
      }
    },
    handleIconMouseOver(type, index) {
      if (this.action === 'icon-eraser' && this.isAction) {
        if (type === 'icon') this.icons.splice(index, 1)
        else if (type === 'other') this.others.splice(index, 1)
      }
    },
    handleOtherMouseDown(e, index) {
      if (this.action !== '') return
      if (e.ctrlKey) {
        this.others.splice(index, 1)
        return
      }
      this.others.push(this.others.splice(index, 1)[0])
      this.otherDrag = this.others[this.others.length - 1]
      let loc = this.cursorPointer(e)
      this.lastPoint.x = loc.x
      this.lastPoint.y = loc.y
      this.count = 0
    },
    handleOtherMouseMove(e) {
      if (this.otherDrag) {
        let loc = this.cursorPointer(e)

        let deltaX = loc.x - this.lastPoint.x
        let deltaY = loc.y - this.lastPoint.y

        this.otherDrag.x += deltaX
        this.otherDrag.y += deltaY

        this.lastPoint.x = loc.x
        this.lastPoint.y = loc.y
      }
    },
    handleIconRightClick(e, index) {
      e.preventDefault()
      this.icons[index].role = this.reverseRole(this.icons[index].role)
    },
    handleSmokeRightClick(e, index) {
      e.preventDefault()
      const roles = { none: 'atk', atk: 'def', def: 'none' }
      this.others[index].role = roles[this.others[index].role]
    },
    handleMouseOverDrawing(index) {
      if (this.action === 'drawing-eraser' && this.isAction) this.drawings.splice(index, 1)
    },
    clearDrawings() {
      this.drawings = []
    },
    clearAgents() {
      this.icons = this.icons.filter(icon => icon.type !== 'agent')
      this.count = 0
    },
    clearUtils() {
      this.icons = this.icons.filter(icon => icon.type !== 'util')
      this.count = 0
    },
    clearSmokes() {
      this.others = this.others.filter(o => o.type !== 'smoke')
    },
    clearSpikes() {
      this.others = this.others.filter(o => o.type !== 'spike')
    },
    clearAll() {
      this.clearDrawings()
      this.clearAgents()
      this.clearUtils()
      this.clearSmokes()
      this.clearSpikes()
    },
    async exportMapAsImage() {
      const svg = this.$el.querySelector('#board').outerHTML
      if (!svg) {
        return null
      }

      const canvas = document.createElement('canvas')
      canvas.width = 1920
      canvas.height = 1080
      const ctx = canvas.getContext('2d')
      const v = await Canvg.from(ctx, svg, presets.offscreen())

      // Render only first frame, ignoring animations and mouse.
      await v.render()

      try {
        return canvas.toDataURL()
      } catch (e) {
        console.warn(e)
        return null
      }
    },
    async downloadImage() {
      const dataURL = await this.exportMapAsImage()
      download(dataURL, `augment-${Date.now()}.png`)
    },
    closeBoard() {
      this.action = ''
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  display: grid;
  color: white;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  column-gap: 8px;
  min-width: 720px;
}

.sidebar {
  user-select: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  grid-row: 1 / span 2;
}

.tools-container {
  background-color: #191822;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  .v3-button {
    font-size: 1.4rem;
    min-height: 1.5rem;
    padding: 4px;
    border-radius: 0.25rem;
    svg {
      min-width: 0.5em;
      min-height: 0.5em;
    }

    &.active {
      background-color: #7a7493;
      svg {
        color: white;
      }
    }
  }

  svg {
    color: #7a7492;
  }
}

.generic-container {
  background-color: #191822;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  .v3-button {
    font-size: 0.8rem;
    min-height: 1rem;
    color: white;
    border-radius: 0.25rem;

    svg {
      min-width: 0.5em;
      min-height: 0.5em;
      margin-right: 4px;
    }

    &.active {
      background-color: #7a7493;
      svg {
        color: white;
      }
    }
  }

  svg {
    color: #7a7492;
  }
}

.dc {
  &-container {
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
    padding: 0.5rem;
    place-items: center;

    .v3-button {
      width: min-content;
      gap: 0.25rem;
      padding: 0.6875rem 0;

      .line {
        width: 2rem;
        border-radius: var(--half-height);
      }
    }
  }

  &-btn-group {
    grid-column: 1 / -1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    background-color: #191822;

    .v3-button {
      border-radius: 0;
      font-size: 0.8rem;
      color: #898795;
      gap: 0.5rem;
      height: 2rem;
      min-height: 2rem;
      color: white;

      svg {
        font-size: 0.8rem;
        color: #7a7493;
      }
    }
  }
}

.agent-toggle {
  background-color: #191822;
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;

  .v3-button {
    font-size: 0.8rem;
    min-height: 1rem;
    color: white;
    width: 100%;

    &.active {
      background-color: #7a7493;
      border-radius: 999px;

      &.def {
        background-color: #099b83;
      }

      &.atk {
        background-color: #ac493a;
      }
    }
  }

  svg {
    color: #7a7492;
  }
}

.line,
.indicator {
  display: block;

  &.color-0 {
    background: #c80a19;
  }

  &.color-1 {
    background: #fff200;
  }

  &.color-2 {
    background: #099b83;
  }

  &.color-3 {
    background: #ffffff;
  }

  &.width-0 {
    --half-height: 2px;
  }

  &.width-1 {
    --half-height: 4px;
  }
}

.line {
  display: block;
  min-height: 1px;
  min-width: 0.5em;
  height: calc(var(--half-height) * 2);

  &:not(.active) {
    opacity: (1/3);
  }
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &:not(.active) {
    opacity: 0;
  }
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}

.agent-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 8px;
  row-gap: 4px;
  background-color: #1f1d2c;
}

.agent-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ability-row {
  width: 100%;
  display: grid;
  margin-left: 8px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.agent-icon {
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  padding: 2px;
}

.util-icon {
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  padding: 2px;
}

#board {
  align-self: center;
  background-color: #191822;
  height: 100%;
  width: 100%;
}

.map-background {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 80%);
  }
}

.map-layout {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;
}

.interact {
  cursor: pointer;

  &:hover {
    stroke: grey;
  }
}

.tooltipmain {
  display: inline-block;
  text-align: center;
  justify-self: end;
  margin-right: 12px;
  margin-top: 4px;
  background-color: #3f3f46;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  cursor: pointer;
  position: relative;
}

.tooltipmain .tooltiptext {
  visibility: hidden;
  width: 500px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: #e4e4e7;
  color: black;
  text-align: left;
  border-radius: 1rem;
  padding: 8px;
  position: absolute;
  z-index: 1;
  right: 105%;
  margin-left: -120px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.tooltipmain:hover .tooltiptext {
  visibility: visible;
}
</style>
