import px from 'vue-types'

export default {
  props: {
    initialState: px.string.def(null),
  },
  methods: {
    updateInitialState(initialState) {
      const newl = new URL(location)
      newl.searchParams.set('state', initialState)
      history.replaceState(null, null, newl.toString())
    },
  },
}
