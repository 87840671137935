<template>
  <div>
    <div class="hint">Hold ALT key to draw.</div>
    <div class="tools-container">
      <V3Button :active="value.tool === 'pencil'" @click="update({ tool: 'pencil' })"> <IconPencil /></V3Button>
      <V3Button :active="value.tool === 'arrow'" @click="update({ tool: 'arrow' })">
        <IconUpArrow />
      </V3Button>
      <V3Button :active="value.tool === 'circle'" @click="update({ tool: 'circle' })">
        <IconEllipse />
      </V3Button>
      <V3Button :active="value.tool === 'text'" @click="update({ tool: 'text' })">
        <IconText />
      </V3Button>
      <V3Button :active="value.tool === 'line'" @click="update({ tool: 'line' })">
        <IconLine />
      </V3Button>
      <V3Button :active="value.tool === 'eraser'" @click="update({ tool: 'eraser' })">
        <IconEraser />
      </V3Button>
    </div>
    <div class="dc-container">
      <template v-for="(width, widthIdx) in widths">
        <V3Button
          v-for="(color, colorIdx) in colors"
          :key="`${color}-${width}`"
          :active="value.color === colorIdx && value.width === widthIdx"
          @click="update({ color: colorIdx, width: widthIdx })"
        >
          <span
            class="indicator"
            :class="[
              `color-${colorIdx} opacity-${value.opacity} width-${widthIdx}`,
              { active: value.color === colorIdx && value.width === widthIdx },
            ]"
          />
          <span
            class="line"
            :class="[
              `color-${colorIdx} opacity-${value.opacity} width-${widthIdx}`,
              { active: value.color === colorIdx && value.width === widthIdx },
            ]"
          />
        </V3Button>
      </template>

      <div class="dc-btn-group">
        <V3Button @click="$emit('clear')">
          <IconClear />
          Clear
        </V3Button>
        <V3Button @click="$emit('undo')">
          <BIconArrowCounterclockwise />
          Undo
        </V3Button>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconArrowCounterclockwise } from 'bootstrap-vue'

import exposedDataState from '@/components/Map/mixins/exposedDataState'
import IconEllipse from '@/components/UI/IconEllipse.vue'
import IconEraser from '@/components/UI/IconEraser.vue'
import IconLine from '@/components/UI/IconLine.vue'
import IconPencil from '@/components/UI/IconPencil.vue'
import IconText from '@/components/UI/IconText.vue'
import IconUpArrow from '@/components/UI/IconUpArrow.vue'

import IconClear from '../../UI/IconClear.vue'

import drawing from './drawing.js'
import V3Button from './v3dafi/V3Button.vue'

export default {
  name: 'DrawingConfig',
  mixins: [exposedDataState],
  components: {
    IconClear,
    V3Button,
    IconPencil,
    IconUpArrow,
    IconEllipse,
    IconText,
    IconLine,
    BIconArrowCounterclockwise,
    IconEraser,
  },
  computed: {
    colors() {
      return drawing.colors
    },
    opacities() {
      return drawing.opacities
    },
    value: {
      get() {
        return this.state.drawingConfig
      },
      set(value) {
        this.state.drawingConfig = value
      },
    },
    widths() {
      return drawing.widths
    },
  },
  methods: {
    update(change) {
      const newConfig = { ...this.value, ...change }
      this.value = newConfig
    },
  },
}
</script>

<style lang="scss" scoped>
.dc {
  &-container {
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, auto);
    padding: 0.5rem;

    .v3-button {
      width: min-content;
      gap: 0.25rem;
      padding: 0.6875rem 0;

      .line {
        width: 2rem;
        border-radius: var(--half-height);
      }
    }
  }

  &-btn-group {
    grid-column: 1 / -1;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

    .v3-button {
      width: 100%;
      border-radius: 0;
      background-color: #1f1d2c;
      font-size: 11px;
      color: #898795;
      gap: 0.5rem;
      height: 2.75rem;
      min-height: 2.75rem;

      &:first-child {
        background-image: linear-gradient(-90deg, #1f1d2c 0%, #1f1d2c 11%, #252432 100%);
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        background-image: linear-gradient(90deg, #1f1d2c 0%, #1f1d2c 11%, #252432 100%);
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      svg {
        font-size: 13px;
        color: #7a7493;
      }
    }
  }
}

.btn-group {
  display: flex;

  .btn {
    min-width: 0;
    flex: 1;
    min-height: 2em;
  }
}

.mode {
  &.mode-auto {
  }
}

.line,
.indicator {
  display: block;

  &.color-0 {
    background: #c80a19;
  }

  &.color-1 {
    background: #fff200;
  }

  &.color-2 {
    background: #099b83;
  }

  &.width-0 {
    --half-height: 3px;
  }

  &.width-1 {
    --half-height: 5px;
  }

  &.width-2 {
    --half-height: 7px;
  }
}

.line {
  display: block;
  min-height: 1px;
  min-width: 0.5em;
  height: calc(var(--half-height) * 2);

  &:not(.active) {
    opacity: (1/3);
  }
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &:not(.active) {
    opacity: 0;
  }
}

.tools-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  .v3-button {
    font-size: 1.4rem;
    min-height: 2.75rem;

    svg {
      min-width: 1em;
      min-height: 1em;
    }

    &.active {
      svg {
        color: white;
      }
    }
  }

  svg {
    color: #7a7492;
  }
}

.hint {
  font-size: 0.7rem;
  color: #8e8b9a;
  margin-top: 0.5rem;
  text-align: center;
}

@media (hover: none) {
  .hint {
    display: none;
  }
}
</style>
