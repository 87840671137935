<template>
  <ImgCell v-if="map" v-bind="$attrs" :image="map.splash_url" :label="map.name" :variant="variant" :size="size" />
</template>

<script>
import px from 'vue-types'

import ImgCell from '@/components/generic/ImgCell.vue'

export default {
  name: 'MapCell',
  components: { ImgCell },
  props: {
    id: px.string.isRequired,
    variant: px.oneOf(['col', 'row']).def('col'),
    size: px.oneOf(['xs', 'sm', 'md', 'lg']).def('md'),
  },
  computed: {
    map() {
      return this.$store.getters['static/getMapById'](this.id)
    },
  },
}
</script>

<style lang="scss" scoped></style>
