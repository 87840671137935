<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor" d="M0 0h24v24z" />
  </svg>
</template>

<script>
export default {
  name: 'IconTriangle',
}
</script>

<style scoped></style>
