<template>
  <div>
    <b-form-tags id="tags-with-dropdown" v-model="selectedValue" no-outer-focus class="mb-2">
      <template #default="{ tags, disabled, addTag, removeTag }">
        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
          <li v-for="tag in tags" :key="tag" class="list-inline-item">
            <b-form-tag @remove="onRemoveClick({ tag, removeTag })" :title="tag" :disabled="disabled" variant="info">{{
              tag
            }}</b-form-tag>
          </li>
        </ul>

        <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
          <template #button-content> <b-icon icon="tag-fill"></b-icon> Choose tags </template>
          <b-dropdown-item-button
            v-for="option in availableOptions"
            :key="option.id"
            @click="onOptionClick({ option, addTag })"
          >
            {{ option.name }}
          </b-dropdown-item-button>
          <b-dropdown-text v-if="availableOptions.length === 0"> All tags selected </b-dropdown-text>
        </b-dropdown>
      </template>
    </b-form-tags>
  </div>
</template>

<script>
import { BFormTags, BFormTag, BDropdown, BDropdownItemButton, BDropdownText, BIcon } from 'bootstrap-vue'
import px from 'vue-types'

export default {
  components: {
    BFormTags,
    BFormTag,
    BDropdown,
    BDropdownItemButton,
    BDropdownText,
    BIcon,
  },
  props: {
    options: px.arrayOf(px.string).def([]),
    selected: px.arrayOf(px.object).def([]),
  },
  data() {
    return {
      localValue: [],
      localSelected: [],
    }
  },
  computed: {
    availableOptions() {
      // Filter out already selected options
      const options = this.options.filter(opt => !this.localSelected.some(value => value.id === opt.id))
      // Show all options available
      return options
    },
    selectedValue: {
      get() {
        return this.selected != null ? this.selected.map(opt => opt.name) : this.localValue
      },
      set(value) {
        this.localValue = value
      },
    },
  },
  methods: {
    onOptionClick({ option, addTag }) {
      this.localSelected.push(option)
      addTag(option.name)
      this.$emit('update:selected', this.localSelected)
    },
    onRemoveClick({ tag, removeTag }) {
      this.localSelected = this.localSelected.filter(opt => opt.name !== tag)
      removeTag(tag)
      this.$emit('update:selected', this.localSelected)
    },
  },
}
</script>
