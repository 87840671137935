import axios from '../axios.js'

/**
 * @param {any} body
 * @return {Promise<string>}
 */
export async function createShortcut(body) {
  const { data } = await axios.post('/val/v3/shortcuts', { body })
  return data.id
}

/**
 * @param {string} id
 * @return {Promise<any>}
 */
export async function getShortcut(id, { cancelToken } = {}) {
  const { data } = await axios.get(`/val/v3/shortcuts/${id}`, { cancelToken })
  return data.body
}
