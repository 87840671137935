<template>
  <V3Panel class="k-container" expandable :expanded.sync="expanded" title="Kills with" variant="secondary">
    <V3Container :visible="expanded" class="k-filter">
      <V3MapFilter class="k-list-container">
        <template v-for="option in options">
          <V3Button
            v-if="!option.disabled"
            :active="checked[option.value]"
            class="k-item"
            :disabled="option.disabled"
            :key="option.value"
            :title="option.text"
            @click="toggle(option)"
          >
            <V3Image :alt="option.text" class="k-item-icon" :gray="!checked[option.value]" :src="option.icon" />
            <div class="k-item-label">{{ option.text }}</div>
          </V3Button>
        </template>
      </V3MapFilter>
    </V3Container>
  </V3Panel>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import displayedAbilities from '../../displayedAbilities.js'
import { pxToolData } from '../../types.js'
import genAbilityHashId from '../../utils/genAbilityHashId.js'
import V3Button from '../v3dafi/V3Button.vue'
import V3Container from '../v3dafi/V3Container.vue'
import V3Image from '../v3dafi/V3Image.vue'
import V3MapFilter from '../v3dafi/V3MapFilter.vue'
import V3Panel from '../v3dafi/V3Panel.vue'

export default {
  name: 'WeaponSelector',
  components: {
    V3Image,
    V3Button,
    V3MapFilter,
    V3Container,
    V3Panel,
  },
  model: {
    event: 'update',
    prop: 'selected',
  },
  props: {
    data: pxToolData().isRequired,
    selected: px.object,
  },
  data: () => ({
    displayType: 'weapon',
    expanded: true,
  }),
  computed: {
    abilitiesMap() {
      return Object.freeze(
        Object.fromEntries(
          displayedAbilities
            .map(name => {
              for (const agentId in this.data.agents) {
                const agent = this.data.agents[agentId]
                for (const slot in agent.abilities) {
                  const ability = agent.abilities[slot]
                  if (ability.displayName === name)
                    return [genAbilityHashId(agent, slot), { icon: ability.displayIcon, name }]
                }
              }
              console.error(`Cannot find ability ${name}`)
              Sentry.captureException(new Error(`Cannot find ability ${name} in agent abilities`))
            })
            .filter(Boolean)
        )
      )
    },
    abilityOptions() {
      return Object.freeze(
        Object.entries(this.abilitiesMap).map(([value, { name: text, icon }]) => ({
          icon,
          text,
          value,
          disabled: !this.data.usedDamages[value],
        }))
      )
    },
    checked() {
      return this.selected || {}
    },
    weaponsMap() {
      return Object.freeze(
        Object.fromEntries([
          ...Object.entries(this.data.weapons).map(([key, { name, kill_stream_icon_url: icon }]) => [
            key,
            { icon, name },
          ]),
          // ['bomb', { name: 'Bomb', icon: '/images/spike/spike-minimap2.png' }],
        ])
      )
    },
    weaponOptions() {
      return Object.freeze(
        Object.entries(this.weaponsMap).map(([value, { name: text, icon }]) => ({
          icon,
          text,
          value,
          disabled: !this.data.usedDamages[value],
        }))
      )
    },
    options() {
      return Object.freeze(
        [...this.abilityOptions, ...this.weaponOptions].sort(
          ({ text: A, a = A.toLowerCase() }, { text: B, b = B.toLowerCase() }) => (a < b ? -1 : 1)
        )
      )
    },
  },
  methods: {
    toggle({ value }) {
      const newSelected = { ...this.selected }
      newSelected[value] = !newSelected[value]
      if (Object.entries(newSelected).filter(([, v]) => v).length) {
        this.$emit('update', newSelected)
      } else {
        this.$emit('update', null)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.k {
  &-list {
    &-container {
      ::v-deep {
        .v3-btn-group {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));

          .v3-button {
            flex-flow: row wrap;
            align-items: center;
            gap: 0.5rem;
            max-height: 2rem;
            overflow: hidden;
            padding: 0.5rem;

            img {
              height: 1rem;
            }
          }
        }
      }
    }
  }

  &-item {
    &-label {
      flex: 1 1 2rem;
      min-width: 2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.v3-button {
  &.active {
    img {
      filter: invert(74%) sepia(100%) saturate(1674%) hue-rotate(334deg) brightness(93%) contrast(88%);
    }
  }
}
</style>
