<template>
  <b-form-checkbox-group
    :checked="checked"
    :options="options"
    class="zone__items"
    name="zones"
    switches
    size="lg"
    @input="update"
  />
</template>

<script>
import { BFormCheckboxGroup } from 'bootstrap-vue'

import stringCompare from '../../../../utils/stringCompare.js'
import { pxSelectedData, pxToolData } from '../../types.js'

export default {
  name: 'ZoneFilter',
  components: {
    BFormCheckboxGroup,
  },
  model: {
    event: 'update:selected',
    prop: 'selected',
  },
  inject: {
    setShortStatus: {
      from: 'setShortStatus',
      default: () => {},
    },
  },
  props: {
    data: pxToolData().isRequired,
    selected: pxSelectedData().isRequired,
  },
  computed: {
    checked() {
      return Object.freeze(
        Object.entries(this.selected.zones || {})
          .filter(([, selected]) => selected)
          .map(([key]) => key)
      )
    },
    options() {
      return Object.freeze(
        Object.entries(this.data.zones || {})
          .map(([value, zone]) => Object.freeze({ text: zone.name, value }))
          .sort(stringCompare())
      )
    },
    selectedCount() {
      return this.checked.length
    },
    shortStatus() {
      return this.selectedCount || null
    },
  },
  watch: {
    shortStatus: {
      immediate: true,
      handler(val) {
        this.setShortStatus(val)
      },
    },
  },
  methods: {
    update(list) {
      this.selected.zones = list.length > 0 ? Object.fromEntries(list.map(key => [key, true])) : null
      this.$emit('update:selected', this.selected)
    },
  },
}
</script>

<style scoped></style>
