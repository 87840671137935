<template>
  <div v-if="data" class="mh-container" :class="{ loading }">
    <MapCell v-if="data && data.map" :border="false" class="mh-map" :id="data.map.id" size="lg" variant="row" />

    <div v-if="singleMatch && winnerTeam && loserTeam" class="mh-center">
      <div class="mh-team left">
        <V3TeamLogo v-if="winnerTeam.image" :src="winnerTeam.image" size="lg" />
        <span class="mh-team-name">{{ winnerTeam.abbr || winnerTeam.name }}</span>
      </div>
      <div class="mh-score">
        <div v-if="winnerTeamWin != null" class="mh-score-win">{{ winnerTeamWin ? 'win' : 'lose' }}</div>
        <div class="mh-score-win2"><IconMapHeaderRight :class="winnerGrid" /></div>
        <div class="mh-score-winner">{{ (winnerTeam && winnerTeam.score) || '-' }}</div>
        <div class="mh-score-sep">/</div>
        <div class="mh-score-loser">{{ (loserTeam && loserTeam.score) || '-' }}</div>
        <div class="mh-score-lose2">
          <IconMapHeaderLeft :class="loserGrid" />
        </div>
        <div v-if="loserTeamLose != null" class="mh-score-lose">{{ loserTeamLose ? 'lose' : 'win' }}</div>
      </div>
      <div class="mh-team right">
        <span class="mh-team-name">{{ loserTeam.abbr || loserTeam.name }}</span>
        <V3TeamLogo v-if="loserTeam.image" :src="loserTeam.image" size="lg" dir="left" />
      </div>
    </div>
    <div v-if="!singleMatch && selectedTeam" class="mh-center">
      <div class="mh-team left">
        <V3TeamLogo v-if="selectedTeam.image" :src="selectedTeam.image" size="lg" />
        <span class="mh-team-name">{{ selectedTeam.abbr || selectedTeam.name }}</span>
      </div>
      <div class="mh-score">
        <div class="mh-score-sel2"><IconMapHeaderRight :class="state.filters.round.teamRole" /></div>
      </div>
    </div>

    <div v-if="commonEvent" class="mh-event">
      <div class="mh-event-name-container">
        <div class="mh-event-name">
          {{ commonEvent.name }}
        </div>
      </div>
      <ImgCell
        v-if="commonEvent.logo_url"
        :border="false"
        variant="icon"
        :image="commonEvent.logo_url"
        size="lg"
        transparent
      />
    </div>
  </div>
</template>

<script>
import exposedDataState from '@/components/Map/mixins/exposedDataState'

import ImgCell from '../../../generic/ImgCell.vue'
import MapCell from '../../../Table/cells/MapCell.vue'
import IconMapHeaderLeft from '../../../UI/IconMapHeaderLeft.vue'
import IconMapHeaderRight from '../../../UI/IconMapHeaderRight.vue'

import V3TeamLogo from './V3TeamLogo.vue'

export default {
  name: 'V3MatchHeader',
  mixins: [exposedDataState],
  components: { IconMapHeaderLeft, IconMapHeaderRight, ImgCell, V3TeamLogo, MapCell },
  props: {
    loading: Boolean,
  },
  computed: {
    commonEvent() {
      const events = Object.values(
        Object.fromEntries(Object.values(this.data?.matches || {}).map(match => [match.event?.id, match.event]))
      )
      if (events.length === 1) return events.pop()
      return null
    },
    loserGrid() {
      return this.loserTeam?.grid.toLowerCase()
    },
    loserTeam() {
      if (this.matchTeams.length === 2) {
        const matchTeam = this.matchTeams.find(team => team.id !== this.winnerTeam.id)
        return Object.freeze({ ...this.data.teams[matchTeam.team_id], ...matchTeam })
      }
      return null
    },
    loserTeamLose() {
      return this.winnerTeam?.score != null && this.loserTeam?.score != null
        ? this.winnerTeam.score > this.loserTeam.score
        : null
    },
    matchTeams() {
      return Object.freeze(Object.values(this.data.matchTeams || {}).filter(Boolean) || [])
    },
    selectedTeam() {
      return this.data?.teams?.[this.selected.team]
    },
    singleMatch() {
      return Object.values(this.data?.matches || {})?.length === 1 ? Object.values(this.data.matches)[0] : null
    },
    winnerGrid() {
      return this.winnerTeam?.grid?.toLowerCase() || this.state.filters.round.teamRole
    },
    winnerTeam() {
      if (this.matchTeams.length === 2) {
        const selectedMatchTeam = this.matchTeams.find(team => team.team_id === this.selected.team)
        return Object.freeze({ ...this.data.teams[selectedMatchTeam?.team_id], ...selectedMatchTeam })
      }
      return this.selectedTeam
    },
    winnerTeamWin() {
      return this.winnerTeam?.score != null && this.loserTeam?.score != null
        ? this.winnerTeam.score > this.loserTeam.score
        : null
    },
  },
}
</script>

<style scoped lang="scss">
@keyframes loading {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 120%;
  }
}

.mh {
  &-container {
    width: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 1fr) minmax(min-content, max-content) minmax(auto, 1fr);
    padding: 1rem 0;
    gap: 0.5rem;

    &.loading {
      position: absolute;
      margin: 0;
      top: 50%;
      transform: translateY(-100%);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        background: #131319;
        z-index: 1;
        animation: 1.25s linear infinite alternate none running loading;
        transform: translateX(-50%);
      }
    }
  }

  &-map,
  &-event {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    min-width: 0;
    max-height: 4rem;
    overflow: hidden;
    gap: 0.375rem;
    justify-content: flex-end;
  }

  &-map {
    color: #9795a1;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    min-width: auto;
    flex-direction: row-reverse;

    ::v-deep {
      .ic--space {
        display: none;
      }

      .ic--label {
        display: block;
        line-height: 4rem;
        order: -1;
        font-size: 0.625rem;
      }
    }
  }

  &-center {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) auto minmax(min-content, 1fr);
    align-items: center;
    justify-content: center;
  }

  &-team,
  &-event {
    display: flex;

    &-name {
      line-height: 1.25;
      max-height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
      -webkit-box-orient: vertical;
      text-transform: uppercase;
    }
  }

  &-team-name,
  &-event-name-container {
    flex-basis: min-content;
    min-width: 0;
  }

  &-team {
    align-items: center;
    flex-flow: row nowrap;
    gap: 0.625rem;

    &-name {
      font-size: 1.25rem;
      color: #a4a2ad;
      font-weight: 800;
      font-family: 'Open Sans Roman', 'Open Sans', sans-serif;
      -webkit-line-clamp: 2;
    }

    &.left {
      justify-content: flex-end;
      text-align: left;
    }

    &.right {
      justify-content: flex-start;
      text-align: right;
    }
  }

  &-event {
    flex-direction: row;

    &-name {
      font-size: 0.625rem;
      color: #6d697c;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      text-align: right;
      -webkit-line-clamp: 4;

      &-container {
        display: flex;
        align-items: center;
        justify-content: stretch;
        height: 4rem;
        padding: 0.125rem 0;
      }
    }
  }

  &-score {
    width: min-content;
    display: grid;
    grid-template-columns: auto auto minmax(min-content, 1fr) auto minmax(min-content, 1fr) auto auto;
    align-items: center;
    gap: 0.25rem;

    &-win,
    &-lose {
      --arrow-width: 0.5rem;

      font-size: 0.625rem;
      text-transform: uppercase;
      color: #9795a1;
      font-weight: 700;
      font-family: Lato, sans-serif;
      line-height: 1.25rem;
      padding: 0 0.5rem;
    }

    &-win {
      background-image: linear-gradient(90deg, #131319 0%, #131319 35%, #393945 92%, #393945 100%);
      clip-path: polygon(
        calc(100% - var(--arrow-width)) 0%,
        100% 50%,
        calc(100% - var(--arrow-width)) 100%,
        0% 100%,
        0 0
      );
    }

    &-lose {
      background-image: linear-gradient(90deg, #393945 0%, #393945 8%, #131319 65%, #131319 100%);
      clip-path: polygon(calc(var(--arrow-width)) 0, 0 50%, calc(var(--arrow-width)) 100%, 100% 100%, 100% 0);
    }

    &-winner,
    &-sep,
    &-loser {
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: 'Open Sans Roman', 'Open Sans', sans-serif;
    }

    &-winner {
      color: #ac4939;
    }

    &-sep {
      color: #8e8b9a;
    }

    &-loser {
      color: #099b83;
    }

    &-win2 {
      margin-left: -1rem;
    }

    &-lose2 {
      margin-right: -1rem;
    }

    &-sel2,
    &-win2,
    &-lose2 {
      .red,
      .atk {
        ::v-deep {
          .cls-1 {
            fill: #b7443e;
          }

          .cls-2 {
            fill: #7e3830;
          }

          .cls-3 {
            fill: #422323;
          }
        }
      }

      .blue,
      .def {
        ::v-deep {
          .cls-1 {
            fill: #099b83;
          }

          .cls-2 {
            fill: #0c7566;
          }

          .cls-3 {
            fill: #123c39;
          }
        }
      }
    }
  }
}
</style>
