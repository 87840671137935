<template>
  <div class="player-container">
    <div ref="player" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/vue'
import deepEqual from 'deep-equal'
import px from 'vue-types'

import ensureYoutube from '@/utils/ensureYoutube.js'

import { pxNullable } from '../Map/types.js'

const UNSTARTED = -1
const ENDED = 0
const PLAYING = 1
const PAUSED = 2
const BUFFERING = 3
const CUED = 5

export default {
  name: 'YoutubeReplay',
  props: {
    autoplay: px.bool.def(true),
    currentTime: px.number.def(0),
    id: px.string,
    playing: px.bool.def(false),
    playbackRate: px.number.def(1.0),
    playerVars: pxNullable(px.object).def(null),
    offsetTime: px.number.def(0),
    startTime: px.number,
    endTime: px.number,
    supportedPlaybackRates: px.arrayOf(px.number).def([1]),
  },
  data() {
    return {
      loaded: false,
      state: null,
    }
  },
  beforeCreate() {
    ensureYoutube()
  },
  mounted() {
    this.$emit('update:supportedPlaybackRates', [1])
  },
  computed: {
    isPlaying() {
      return [PLAYING, BUFFERING].includes(this.state)
    },
    localCurrentTime() {
      return (this.startTime + this.currentTime + this.offsetTime) / 1000
    },
  },
  watch: {
    localCurrentTime(val) {
      if (this.player && val !== this.lastEmittedTime) {
        this.seeking = true
        setTimeout(() => (this.seeking = false), 100)
        this.player.seekTo(val, true)
      }
    },
    playing(val) {
      if (!this.player) return
      if (val) {
        if (![PLAYING, BUFFERING].includes(this.player.getPlayerState())) {
          this.player.playVideo()
        }
      } else {
        if ([PLAYING, BUFFERING].includes(this.player.getPlayerState())) {
          this.player.pauseVideo()
        }
      }
    },
    playbackRate(val) {
      if (!this.player) return
      this.player.setPlaybackRate(val)
    },
    isPlaying: {
      handler(val) {
        if (val) {
          this.updateCurrentTime()
        }
        this.$emit('update:playing', val)
      },
    },
    id: {
      immediate: true,
      handler(val) {
        this.loadId(val)
      },
    },
    // startSeconds () {
    //   this.loadId(this.id)
    // },
    // endSeconds () {
    //   this.loadId(this.id)
    // },
  },
  methods: {
    async initYoutube(opts) {
      if (this.player) return true
      this.loaded = false
      this.loading = true
      try {
        const YoutubePlayer = await ensureYoutube()
        return new Promise((resolve, reject) => {
          let resolved = false
          let ready = ev => {
            ready = this.onReady
            if (this.localCurrentTime) {
              this.player.seekTo(this.localCurrentTime)
            }
            this.player.setPlaybackRate(this.playbackRate)
          }
          let stateChange = e => {
            if (e.data === 1) {
              stateChange = this.onStateChange
              if (!this.playing) {
                this.player.pauseVideo()
              } else {
                this.onStateChange(e)
              }

              if (!resolved) {
                resolved = true
                resolve()
              }
            }
          }
          this.player = new YoutubePlayer(this.$refs.player, {
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: this.autoplay,
              // controls: 0, // Player controls do not display in the player.
              iv_load_policy: 3, // Setting the parameter's value to 1 causes video annotations to be shown by default, whereas setting to 3 causes video annotations to not be shown by default. The default value is 1.
              modestbranding: 1, // This parameter lets you use a YouTube player that does not show a YouTube logo. Set the parameter value to 1 to prevent the YouTube logo from displaying in the control bar. Note that a small YouTube text label will still display in the upper-right corner of a paused video when the user's mouse pointer hovers over the player.
              ...opts?.playerVars,
            },
            events: {
              onReady: e => ready(e),
              onStateChange: e => stateChange(e),
              onPlaybackRateChange: this.onPlaybackRateChange,
              onError: ev => {
                if (!resolved) {
                  resolved = true
                  reject(new Error(`Youtube player error #${ev.data}`))
                }
                this.onError(ev)
              },
            },
            ...opts,
          })
        })
      } finally {
        this.loading = false
      }
    },
    async loadId(id) {
      try {
        await this.initYoutube({
          videoId: id,
          playerVars: this.playerVars,
          // playerVars: { start: this.startSeconds, end: this.endSeconds }
        })
        // console.log('setVideo', id)
        // this.player.loadVideoById({
        //   videoId: id,
        //   // startSeconds: this.startSeconds,
        //   // endSeconds: this.endSeconds,
        // })
        // if (this.player.getCurrentTime() !== this.localCurrentTime) {
        //   console.log('seek', this.localCurrentTime)
        //   this.player.seekTo(this.localCurrentTime, true)
        // }
        // if (this.playing) {
        //   if (![PLAYING, BUFFERING].includes(this.player.getPlayerState())) {
        //     console.log('[YOUTUBE] play()')
        //     this.player.playVideo()
        //   }
        // } else {
        //   if ([PLAYING, BUFFERING].includes(this.player.getPlayerState())) {
        //     console.log('[YOUTUBE] pause()')
        //     this.player.pauseVideo()
        //   }
        // }
        // if (this.player.getPlaybackRate() !== this.playbackRate) {
        //   console.log(`[YOUTUBE] setPlaybackRate(${this.playbackRate})`)
        //   this.player.setPlaybackRate(this.playbackRate)
        // }
      } catch (e) {
        console.error('Error loading youtube player', e)
        Sentry.captureException(e)
        this.error = e
      }
    },
    updateCurrentTime() {
      const update = () => {
        if (!this.seeking) {
          this.setPlayerTime(this.player.getCurrentTime())
        }
        if (this.isPlaying) {
          requestAnimationFrame(update)
        }
      }

      requestAnimationFrame(update)
    },
    onError(e) {
      console.error('[YOUTUBE] onError', e)
      Sentry.captureException(e)
    },
    onReady() {},
    onStateChange(e) {
      this.state = e.data
      this.setSupportedPlaybackRates(this.player.getAvailablePlaybackRates())
    },
    onPlaybackRateChange(e) {
      this.$emit('update:playbackRate', e.data)
    },
    setPlayerTime(time) {
      let newTime = time * 1000 - this.offsetTime
      if (newTime < this.startTime) {
        newTime = this.startTime
      } else if (this.endTime && newTime > this.endTime) {
        newTime = this.endTime
      }
      newTime -= this.startTime
      if (this.lastEmittedTime !== time) {
        this.lastEmittedTime = time
        this.$emit('update:currentTime', newTime)
      }
    },
    setSupportedPlaybackRates(rates) {
      if (!rates?.length) {
        rates = [1]
      }
      if (!deepEqual(rates, this.supportedPlaybackRates)) {
        this.$emit('update:supportedPlaybackRates')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.player-container {
  height: 100%;
}
</style>
