<template>
  <V3MapFilter
    :active="value != null"
    icon="/images/spike/spike-minimap2.png"
    :outline="false"
    @click="$emit('change', null)"
  >
    <V3Button
      v-for="(option, key) in bombsiteOptions"
      :key="key"
      :active="value === option.value"
      @click="$emit('change', option.value !== value ? option.value : null)"
      >{{ option.text }}</V3Button
    >
  </V3MapFilter>
</template>

<script>
import px from 'vue-types'

import V3Button from '../v3dafi/V3Button.vue'
import V3MapFilter from '../v3dafi/V3MapFilter.vue'

export default {
  name: 'BombsiteSelector',
  components: {
    V3Button,
    V3MapFilter,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    bombsitesCount: px.integer.def(2),
    value: px.oneOf(['A', 'B', 'C']),
  },
  computed: {
    bombsiteOptions() {
      return [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
      ].slice(0, this.bombsitesCount)
    },
  },
}
</script>
