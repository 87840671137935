<template>
  <div class="mtrs-container">
    <div class="mtrs-teams-container">
      <div v-for="(team, index) of teams" class="mtrs-team" :key="index">
        {{ team.abbr || team.name }}
      </div>
    </div>
    <MatchRoundSelector
      v-if="data && data.rounds && data.roundTeams && data.teams && filters && filters.round"
      :rounds="rounds"
      :disabled="disabled"
      :highlighted="highlighted"
      :selected="selectedRounds"
      :errored="errored"
      :notes="roundNotes"
      @click="onClick"
      @switch-role="$emit('switch-role')"
    >
      <template #team="{ team }">
        <div class="team-name">{{ team.abbr || team.name }}</div>
      </template>
    </MatchRoundSelector>
    <div class="mtrs-teams-container">
      <div v-for="(team, index) of teams" class="mtrs-team" :key="index">
        {{ team.abbr || team.name }}
      </div>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import { pxRoundData, pxRoundTeam, pxTeam } from '@/components/Map/types'
import MatchRoundSelector from '@/components/match/MatchRoundSelector.vue'

export default {
  name: 'MapToolRoundSelector',
  components: { MatchRoundSelector },
  props: {
    data: px.shape({
      rounds: px.objectOf(pxRoundData()).isRequired,
      roundTeams: px.objectOf(pxRoundTeam()).isRequired,
      teams: px.objectOf(pxTeam()).isRequired,
    }).loose.isRequired,
    filters: px.shape({
      round: px.func.isRequired,
    }).loose.isRequired,
    multiple: px.bool.def(false),
    selected: px.objectOf(px.bool),
    notes: px.arrayOf(px.object).def([]),
  },
  computed: {
    disabled() {
      return Object.values(this.data.rounds).map(({ id }) => !this.filters.round(id, { includeSelectedRounds: false }))
    },
    errored() {
      return Object.values(this.data.rounds).map(({ id, missing_kills }) => {
        try {
          if (missing_kills) {
            return 'Missing killfeed'
          }
          this.filters.round(id, { includeSelectedRounds: false, failOnMissingData: true })
          return false
        } catch (e) {
          return e.message
        }
      })
    },
    highlighted() {
      return Object.values(this.data.rounds).map(({ id }) => this.filters.round(id, { includeSelectedRounds: false }))
    },
    roundNotes() {
      return Object.values(this.data.rounds).map(({ id }) =>
        this.notes.find(({ round_id }) => round_id === id) ? true : false
      )
    },
    rounds() {
      return Object.values(this.data.rounds).map(({ id, round_num: round_number, round_teams, outcome }) => ({
        id,
        number: round_number + 1,
        first_half: round_number < 12,
        outcome,
        loser: this.getTeam(this.getLoser(round_teams)),
        winner: this.getTeam(this.getWinner(round_teams)),
      }))
    },
    selectedRounds() {
      if (!this.selected) {
        return this.highlighted
      }
      return Object.values(this.data.rounds).map(({ id }) => this.selected[id])
    },
    teams() {
      return [...Object.values(this.data.rounds)[0].round_teams]
        .sort(a => (this.data.roundTeams[a].role === 'atk' ? -1 : 1))
        .map(roundTeamId => this.data.teams[this.data.roundTeams[roundTeamId].team_id])
    },
  },
  methods: {
    getTeam(roundTeamId) {
      return {
        id: this.data.roundTeams[roundTeamId].team_id,
        role: this.data.roundTeams[roundTeamId].role,
        grid: this.data.roundTeams[roundTeamId].grid,
      }
    },
    getLoser(roundTeams) {
      return roundTeams.find(roundTeamId => !this.data.roundTeams[roundTeamId].win)
    },
    getWinner(roundTeams) {
      const loserId = this.getLoser(roundTeams)
      return roundTeams.find(roundTeamId => roundTeamId !== loserId)
    },
    onClick(round, $event) {
      const { [round.id]: selected, ...rest } = this.selected || {}
      const multipleEnabled = this.multiple
      const multipleEvent =
        $event.ctrlKey ||
        $event.metaKey ||
        (($event.pointerType === 'touch' || $event.pointerType == null) && !($event.ctrlKey || $event.metaKey))
      const multiple = multipleEnabled && multipleEvent
      const hasOther = Object.values(rest).filter(Boolean).length !== 0
      if (selected) {
        if (!hasOther) {
          this.$emit('update:selected', null)
        } else if (!multiple) {
          this.$emit('update:selected', Object.freeze({ [round.id]: true }))
        } else {
          this.$emit('update:selected', Object.freeze(rest))
        }
      } else if (multiple) {
        rest[round.id] = true
        this.$emit('update:selected', Object.freeze(rest))
      } else {
        this.$emit('update:selected', Object.freeze({ [round.id]: true }))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.mtrs {
  &-container {
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;
    flex: 1 1 100%;
    width: 100%;
    gap: 0.5rem;
    background: black;
    padding: 0.5rem;

    .match {
      min-width: 0;
    }
  }

  &-teams-container {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 35px;
    gap: 3px;
    color: #a4a2ad;
    justify-content: flex-end;
    align-items: stretch;

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  }

  &-team {
    min-height: 21px;
    height: 21px;
    max-height: 21px;
    flex: 1 1 21px;
    font-size: 10px;
    text-transform: uppercase;
  }
}
</style>
