<template>
  <ApiLoadingController class="map-wrapper" :fetch="fetch" :params="params">
    <template #default="{ data }">
      <MapController v-bind="data" :initial-state="initialState" @update:initialState="updateInitialState" />
    </template>
  </ApiLoadingController>
</template>

<script>
import { mapGetters } from 'vuex'

import { getMatch, getMatchExtraData, getMatchInferences, getMatchRecording } from '@/api/val-scrims.js'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import MapController from '@/components/Map/MapController.vue'
import genRoundId from '@/components/Map/utils/genRoundId.js'
import v3dafi from '@/layouts/v3dafi.vue'
import mapPage from '@/pages/mixins/mapPage.js'
import processMapToolData from '@/utils/processMapToolData.js'
import reportException from '@/utils/reportException'
import rotate from '@/utils/rotateLocation'

export default {
  name: 'OverwolfMap',
  mixins: [mapPage],
  components: { MapController, ApiLoadingController },
  provide() {
    return {
      layout: v3dafi,
    }
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      agentsByModelName: 'static/agentsByModelName',
      agentsByName: 'static/agentsByName',
      agentsList: 'static/agentsList',
      gearsList: 'static/gearsList',
      mapsById: 'static/mapsById',
      weaponsList: 'static/weaponsList',
    }),
    params() {
      return {
        id: this.id,
        localUrl: window.overwolf ? window.overwolf.windows.getMainWindow().scrimUrl : null,
        localScrimData: window.overwolf ? window.overwolf.windows.getMainWindow().scrimData : null,
        showAds: window.overwolf ? !window.overwolf.windows.getMainWindow().isLoggedIn : false,
      }
    },
  },
  methods: {
    createBookmark() {},
    // eslint-disable-next-line no-unused-vars
    serializeState(state) {
      return null
    },
    // eslint-disable-next-line no-unused-vars
    deserializeState(stateId) {
      return null
    },
    async fetch({ id, localUrl, localScrimData, showAds }) {
      /**
       * @type {Array<{match: CreateMatchResponse, inferences: GetMatchInterferencesResponse, recording: MatchRecording, extraData: MatchExtraData}>}
       */
      let scrimData = null
      let scrimUrl = null
      let scrimId = null
      let created = null
      let inferences = null
      let recording = null

      if (id) {
        const [match, matchInferences, matchRecording, extraData] = await Promise.all([
          getMatch(id),
          getMatchInferences(id),
          getMatchRecording(id),
          getMatchExtraData(id),
        ])
        scrimData = extraData?.finalData
        scrimUrl = matchRecording?.source_url
        scrimId = id
        created = Math.round(match.created_at * 1000)
        inferences = matchInferences
        recording = matchRecording
      } else {
        scrimData = localScrimData?.finalData
        scrimUrl = localUrl
        scrimId = localUrl
        created = scrimData.recording.started
      }

      if (!scrimData) {
        throw new Error('No scrim data')
      }

      const map = scrimData.map.id
      const mapData = this.mapsById[map]
      const rotateDeg = mapData?.rotate_deg || 0

      const matchesData = [
        {
          id: scrimId,
          created: created,
          teams: [
            {
              id: 'self',
              abbr: 'MY',
              name: 'My Team',
              grid: scrimData.team_grid,
              team_side: scrimData.team_grid,
              rounds_won: scrimData.score.won,
              is_self_team: true,
              players: scrimData.roster
                .filter(player => player.teammate && player.wasAlive && player.agent)
                .map(player => {
                  return {
                    id: player.player_id,
                    puuid: player.player_id,
                    name: player.killfeedName || player.name,
                    game_name: player.killfeedName || player.name,
                    // todo: add this if possible
                    avr_round_score: 0,
                    agent_id: this.agentsByName[player.agent]?.id,
                  }
                }),
            },
            {
              id: 'enemy',
              abbr: 'FOE',
              name: 'Enemy Team',
              grid: scrimData.team_grid === 'Red' ? 'Blue' : 'Red',
              team_side: scrimData.team_grid === 'Red' ? 'Blue' : 'Red',
              rounds_won: scrimData.score.lost,
              is_self_team: false,
              players: scrimData.roster
                .filter(player => !player.teammate && player.wasAlive && player.agent)
                .map(player => ({
                  id: player.player_id,
                  puuid: player.player_id,
                  name: player.killfeedName || player.name,
                  game_name: player.killfeedName || player.name,
                  // todo: add this if possible
                  avr_round_score: 0,
                  agent_id: this.agentsByName[player.agent].id,
                })),
            },
          ],
          rounds: scrimData.rounds
            .filter(round => round)
            .map(roundInfo => ({
              round_num: roundInfo.round_num,
              round_length_millis: roundInfo.phases.end.timestamp + 7000 - roundInfo.phases.combat.timestamp,
              teams: [
                {
                  id: 'self',
                  grid: scrimData.team_grid,
                  role: roundInfo.teamStats.find(team => team.is_self_team).role,
                  win: roundInfo.won,
                  won: roundInfo.won,
                },
                {
                  id: 'enemy',
                  grid: roundInfo.teamStats.find(team => !team.is_self_team).grid,
                  role: roundInfo.teamStats.find(team => !team.is_self_team).role,
                  win: !roundInfo.won,
                  won: !roundInfo.won,
                },
              ],
              kills: roundInfo.killfeed.map(kill => ({
                round_time_millis: kill.timestamp - roundInfo.phases.combat.timestamp,
                killer_puuid: kill.attacker_player_id,
                victim_puuid: kill.victim_player_id,
                finishing_damage: kill.finishing_damage,
              })),
              // TODO: fill player stats
              player_stats: roundInfo.playerStats.map(player => ({
                puuid: player.player_id,
                team_side: player.teammate
                  ? roundInfo.teamStats.find(team => team.is_self_team).grid
                  : roundInfo.teamStats.find(team => !team.is_self_team).grid,
                assists: player.assists,
                kills: player.kills,
                deaths: player.deaths,
                loadout_value: player.loadout_value,
                remaining: player.remaining,
                // TODO: calculate this instead of using loadaout_value
                spent: player.loadout_value,
                armor_id: player.armor,
                weapon_id: player.weapon,
                ultimates: player.ultimates,
                // TODO: compute this
                damages: [
                  {
                    bodyshots: 0,
                    headshots: 0,
                    legshots: 0,
                    total: 0,
                  },
                ],
              })),
            })),
          advancedData: scrimData.rounds
            .filter(round => round)
            .map(roundInfo => ({
              round_num: roundInfo.round_num,
              minimap_video_url: recording
                ? recording.rounds.find(round => round.round_num === roundInfo.round_num)?.processed_minimap_url
                : null,
              replay_video_start_time:
                roundInfo.phases.combat.timestamp -
                scrimData.recording.started -
                (scrimData.recording.guesstimated_offset_millis || 0),
              replay_video_end_time:
                roundInfo.phases.end.timestamp +
                7000 -
                scrimData.recording.started +
                (scrimData.recording.guesstimated_offset_millis || 0),
            })),
          replay: {
            video_id: scrimUrl,
            video_platform: 'augment',
            cropper_status: recording ? recording.state : 'processed',
            // TODO: set correct values
            video_start_time: 0,
            video_end_time: 10000000,
          },
          vod_status: recording ? recording.state : 'processed',
        },
      ]

      const orbsData = {
        [scrimId]: matchesData[0].rounds.map(round => ({
          round_num: round.round_num,
          ultimates: round.player_stats.map(player => ({
            puuid: player.puuid,
            count: player.ultimates.count,
            max: player.ultimates.max,
          })),
        })),
      }

      /**
       *
       * @param {number} roundNum
       * @param {MatchExtraData} extraData
       * @returns {ScrimRound|null}
       */
      const findRoundByRoundNum = roundNum => {
        const round = scrimData.rounds.find(round => round?.round_num === roundNum)
        if (!round) {
          reportException(`Could not find round ${roundNum}`, {
            scrimId: scrimId,
            roundNum,
            type: 'findRoundByRoundNum',
          })
        }
        return round
      }

      /**
       *
       * @param {CreateMatchResponse} match
       * @param {MatchExtraData} extraData
       * @param {number} roundNum
       * @param {string} inferenceClass
       * @return {string|null}
       */
      const findPlayerByInferenceClass = (roundNum, inferenceClass) => {
        const round = findRoundByRoundNum(roundNum)
        if (!round) {
          return null
        }
        const parts = inferenceClass.split('-')
        const role = parts.pop()
        // works for ability-agentMlName-slotName-role and agent-agentMlName-role
        const agentMlName = parts[1]
        const agent = this.agentsByModelName[agentMlName]
        if (!agent) {
          reportException(`Could not find agent by ml name ${agentMlName}`, {
            scrimId: scrimId,
            inferenceClass,
            roundNum,
            type: 'findPlayerByInferenceClass',
            agentMlName,
          })
          return null
        }
        const agentId = agent.id
        const ownTeamRole = round.team === 'attack' ? 'atk' : 'def'
        const player = matchesData[0].teams
          .find(team => (role === ownTeamRole ? team.is_self_team : !team.is_self_team))
          ?.players.find(player => player.agent_id === agentId)
        if (!player) {
          reportException(`Could not find player for inference class ${inferenceClass}`, {
            scrimId: scrimId,
            inferenceClass,
            roundNum,
            type: 'findPlayerByInferenceClass',
            agentMlName,
          })
          return null
        }
        return player.id
      }

      const data = await processMapToolData({
        staticData: { agentsData: this.agentsList, weaponsData: this.weaponsList, gearsData: this.gearsList },
        matchesData,
        orbsData,
        ...(inferences
          ? {
              roundAdvancedPositionsData: Object.fromEntries(
                inferences.rounds.map((roundInferences, roundNum) => [
                  genRoundId(scrimId, roundNum),
                  roundInferences.val_minimap.frames.flatMap((frameInferences, frameIdx) =>
                    frameInferences
                      .filter(
                        inference => inferences.metadata.val_minimap[inference.cls].split('-').shift() === 'agent'
                      )
                      .map(inference => ({
                        round_time_millis: frameIdx * roundInferences.val_minimap.frame_interval * 1000,
                        puuid: findPlayerByInferenceClass(roundNum, inferences.metadata.val_minimap[inference.cls]),
                        location: rotate(
                          {
                            x: inference.xy[0],
                            y: inference.xy[1],
                          },
                          -rotateDeg
                        ),
                      }))
                      // remove bad positions for agents that were not found
                      .filter(({ puuid }) => puuid)
                  ),
                ])
              ),
              roundSmokesData: Object.fromEntries(
                inferences.rounds.map((roundInferences, roundNum) => [
                  genRoundId(roundNum),
                  roundInferences.val_minimap.frames.flatMap((frameInferences, frameIdx) =>
                    frameInferences
                      .filter(inference => inferences.metadata.val_minimap[inference.cls] === 'smoke-circle')
                      .map(inference => ({
                        round_time_millis: frameIdx * roundInferences.val_minimap.frame_interval * 1000,
                        location: rotate(
                          {
                            x: inference.xywh[0],
                            y: inference.xywh[1],
                          },
                          -rotateDeg
                        ),
                        // need radius but we get diameter
                        radius: (inference.xywh[2] + inference.xywh[3]) / 2 / 2,
                      }))
                  ),
                ])
              ),
              roundUtilitiesData: Object.fromEntries(
                inferences.rounds.map((roundInferences, roundNum) => [
                  genRoundId(scrimId, roundNum),
                  roundInferences.val_minimap.frames.flatMap((frameInferences, frameIdx) =>
                    frameInferences
                      .filter(
                        inference => inferences.metadata.val_minimap[inference.cls].split('-').shift() === 'ability'
                      )
                      .map(inference => ({
                        round_time_millis: frameIdx * roundInferences.val_minimap.frame_interval * 1000,
                        puuid: findPlayerByInferenceClass(roundNum, inferences.metadata.val_minimap[inference.cls]),
                        ability_slot: inferences.metadata.val_minimap[inference.cls].split('-')[2],
                        location: rotate(
                          {
                            x: inference.xy[0],
                            y: inference.xy[1],
                          },
                          -rotateDeg
                        ),
                      }))
                      .filter(({ puuid }) => puuid)
                  ),
                ])
              ),
              roundWallsData: Object.fromEntries(
                inferences.rounds.map((roundInferences, roundNum) => [
                  genRoundId(scrimId, roundNum),
                  roundInferences.val_minimap.frames.flatMap((frameInferences, frameIdx) =>
                    frameInferences
                      .filter(inference => inferences.metadata.val_minimap[inference.cls].split('-').shift() === 'wall')
                      .map(inference => ({
                        round_time_millis: frameIdx * roundInferences.val_minimap.frame_interval * 1000,
                        role: inferences.metadata.val_minimap[inference.cls].split('-').pop(),
                        location: rotate(
                          {
                            x: (inference.xyxy[0] + inference.xyxy[2]) / 2,
                            y: (inference.xyxy[1] + inference.xyxy[3]) / 2,
                          },
                          -rotateDeg
                        ),
                        vector: [
                          {
                            x: inference.xyxy[0],
                            y: inference.xyxy[1],
                          },
                          {
                            x: inference.xyxy[2],
                            y: inference.xyxy[3],
                          },
                        ].map(l => rotate(l, -rotateDeg)),
                      }))
                  ),
                ])
              ),
            }
          : {
              roundAdvancedPositionsData: null,
              roundSmokesData: null,
              roundUtilitiesData: null,
              roundWallsData: null,
            }),
        hasKillLocations: false,
        reportMissingLocations: false,
      })

      return {
        isScrim: true,
        isLocalScrim: true,
        adsEnabled: showAds,
        mapSettings: {
          bookmarksEnabled: false,
          ...(id
            ? {}
            : {
                startingVodPlayer: 'augment',
                reportsEnabled: false,
                drawingEnabled: false,
                analyticsEnabled: false,
              }),
        },
        data: {
          ...data,
          map: mapData,
          matchesData: Object.fromEntries(matchesData.map(match => [match.id, Object.freeze(match)])),
        },
        map,
        notes: [],
        noteTags: this.noteTags,
        team: 'self',
        createBookmarkApi: this.createBookmark,
        ...(id ? {} : { serializeState: this.serializeState, deserializeState: this.deserializeState }),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper,
.map-wrapper2 {
  position: relative;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
}
</style>
